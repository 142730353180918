import { filter } from "rxjs/operators";
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { AppStateService } from "shared/services/app-state.service";
import { CommonService } from "shared/services/common.service";
import { environment } from '../../../environments/environment';
import { GlobalFilterService } from "shared/services/global-filter.service";
import { Subscription } from "rxjs";
import { ReturnOrder } from "shared/sdk";
import { Router } from "@angular/router";
import { BaseVendorViewComponent } from "msp/shared/base-vendor-view/base-vendor-view.component";
import * as _ from 'lodash';
import { isArray } from "util";

@Component({
  selector: "app-global-filters",
  templateUrl: "./global-filters.component.html",
  styleUrls: ["./global-filters.component.scss"],
})
export class GlobalFiltersComponent extends BaseVendorViewComponent implements OnInit, OnDestroy {
  private _sub: Subscription;
  private _sub1: Subscription;
  selectedAccCommunityObj: any;
  DateSelected: string;
  selectedCategoryValue: string;
  @Output() vendorAccessType = new EventEmitter();
  @Input() isEmployeeTypeMultipleSelected;
  @Input() isWorkerMultipleSelected;
  @Input() fromPage;
  @Output() closeFilter = new EventEmitter();
  @Output() searchGlobalFilter = new EventEmitter();
  @Input() displayFooter = false;
  @Input() fromDashboard = false;
  @Input() selectedHelpDeskType;
  @Input() disableProjectType = false;
  @Input() defaultSelectedAccountCommunity;
  @Input() enableCustomerFilter = false;
  @Input() currentPage;
  @Input() dateLabel;
  @Input() preselectStatusType;  
  @Input() realtimeTaskConversation = false;
  dateFilterName: string;
  @Input() set filterOptions(e) {
    /* Properties:
    * disabled - to disable a filter
    * restrictReset - not clear filter on Clear All button click
    * default - default value for lookup
    * label - to display your lable for lookup
    */
    this._filterOptions = e ? e : {};
  }
  loadFilters = false;

  _enableFilters = [];
  @Input()
  set enableFilters(e) {
    this.setEnabledFilters(e);
  }

  @Input()
  set clearAppliedFilters(e) {
    if (e) {
      setTimeout(() => { this.resetData(); }, 0);
    }
  }
  @Input() communityFor;

  // to show count per selected option
  countSelected = {};
  _filterOptions: any = {};
  accessType: any;
  isClear: any;
  filterCondition = {};
  setVendorAccountObj: any;
  setContactAccountObj: any;
  setPartnerAccountObj: any;
  setProgramObj: any;
  setJobsiteProgramObj: any;
  setJobsiteAccountObj: any;
  selectedCountryObj: any;
  selectedRegionObj: any;
  setMilestoneObj: any;
  setMilestoneTaskObj:any;
  setProjectConsoleType: any;
  setProjectObj: any;
  setTaskObj: any;
  setJobOrderObj: any;
  setTaskStatusObj: any;
  setTaskRequestorObj: any;
  setTaskTypeObj: any;
  jobIds: any;
  stageStatuses: any;
  setWorkerObj: any;
  setVendorsiteObj: any;
  setApplicationObj: any = {};
  setDocumentCategoryObj: any;
  setPageViewGroupObj: any;
  serviceTerritoryObj = {
    states: [],
    statesManager: [],
    countries: []
  };
  totalFilterCount = 0;
  filterObj = {
    countries: [],
    regions: [],
    orderTypes: [],
    accounts: [],
    vendorAccounts: [],
    contactAccounts: [],
    programs: [],
    projectTypes: [],
    teamProjectTypes: [],
    projects: [],
    milestones: [],
    tasks: [],
    taskStatuses: [],
    milestoneTask: [],
    taskRequestors: [],
    taskTypes: [],
    talentTypes: [],
    statgeStatus: [],
    jobs: [],
    jobSites: [],
    jobTypes: [],
    caseTypes: [],
    recordTypeIds: [],
    caseCategory: [],
    vendorSites: [],
    priority: [],
    jobStatus: [],
    geoMetro: [],
    geoMetroJobSite: [],
    workerNames: [],
    workerIds: [],
    dateOptions: [],
    jobCreatedDate: [],
    dateClosed: [],
    pmsCaseStatus: [],
    teamShifts: [],
    teamShiftUsers: [],
    pmsCaseNumbers: [],
    pmcCaseNumbers: [],
    escalationType: [],
    accountCommunities: [],
    taskAccounts: [],
    employeeType: [],
    leaveStatusType: [],
    ironApprovalStatuses: [],
    customerApprovalStatuses: [],
    actualCoverageHours: [],
    coverageHours: [],
    actualEndTimeCoverages: [],
    actualCoverageOveralls: [],
    workerBillingTypes: [],
    purchaseOrder: [],
    serviceContractNumbers: [],
    serviceContractCategory: [],
    purchaseInvoiceStatus: [],
    divisions: [],
    departments: [],
    departmentRoleAccesses: [],
    pgmo_departments: [],
    expenseTypes: [],
    expenseSubTypes: [],
    paymentTypes: [],
    contractCategory: [],
    states: [],
    statesManager: [],
    activityType: [],
    workerType: [],
    workerTypeSub: [],
    technicalLevels: [],
    taskPriorities: [],
    users: [],
    teams: [],
    teamTypes: [],
    dateFrom: [],
    dateTo: [],
    startFromDateOptions: [],
    customerDateOptions: [],
    workOrderDateOptions: [],
    customers: [],
    subCategories: [],
    groupRecordTypes: [],
    workOrderCaseIds: [],
    category: [],
    isBillable: [],
    taskLibraries: [],
    milestoneLibraries: [],
    tmpltLibraryTypes: [],
    templateName: [],
    templateCategories: [],
    templateSubCategories: [],
    apvpGroup: [],
    communityType: [],
    slaGroup: [],
    wslAccountStatus: [],
    wslAccounts: [],
    address: [],
    workerStatus: [],
    radius: [],
    driving_distance: [],
    salary: [],
    gtpResourceType: [],
    gtpPoolAccessType: [],
    gtpMemberType: [],
    gtpCommunityType: [],
    backFillWorkerService: [],
    displayCoverageType: [],
    vendorSubTypeCommunity: [],
    covrageHoursAccountType: [],
    slaAccountType: [],
    displayVendorsiteType: [],
    geoCode: [],
    city: [],
    vendorStatusType: [],
    mvvpDisplayType: [],
    serviceTerritory: [],
    candidateType: [],
    workerTalentTypes: [],
    slaLabourCloudType: [],
    workerScheduled: [],
    coverHoursMvvp: [],
    problemTaskStatuses: [],
    jobOrderStatus: [],
    jobOrderStage: [],
    jobOrderPriority: [],
    jobOrders: [],
    applications: [],
    userTypeId: [],
    pageName: [],
    isTestuser: [],
    conversationSource: [],
    includeTestAccount: [],
    isServiceoLogin: [],
    uniqueUser: [],
    isActiveUser: [],
    targetCost: [],
    member: [],
    locations: [],
    cityList:[],
    pcUserCount: [],
    pageType: [],
    weeklyDateOption: [],
    statesManagerJobOrder: [],
    jobTitles: [],
    applicationID: [],
    appStatus: [],
    candidateCurrentSalary: [],
    candidatePayRateRequested: [],
    noticePeriod: [],
    userIdLookup: [],
    messageType: [],
    employeeTypeList: [],
    timeChangeRequestStatus:[],
    chatBoardbacklogTodo: [],
    jobInvoiceTypes: [],
    salesInvoiceStatus: [],
    documentCategory: [],
    workerTypePB: [],
    customerScheduled: [],
    pageViewGroup: [],
    objectiveType: [],
    objectiveStatus: [],
    period: [],
    formName: [],
    objective: [],
    keyResult: [],
    initiative: [],
    completedStatus: [],
    requirementType: [],
    employmentFilter: [],
    jobCreationReason: [],
    approvalStatus: [],
    jobAssigned: [],
    jobPostingType: [],
    overallStatus:[],
    checkInStatus:[],
    checkInFrequency:[],
    stageStatus: [],
    projectManagers: [],
    showOnlyJobType: [],
    fileNames: [],
    excludeMagicLink:[],
    businessRequestor: [],
    jobsfdcIds: [],
    recruitmentManager: [],
    recruiter: [],
    businessUnit: [],
    interviewFeedback: [],
    job: [],
    interviewRound: [],
    singleDate: [],
    communityTypeRmc: [],
    releaseNotesTypes:[],
    releaseNotesVersions:[],
    priceListCoverageHours: [],
    priceListTechnicalLevel: [],
    priceListSLA: [],
    servicetalentTypes: [],
    projectStatus: [],
    serviceDispatchPriceListSLA: [],
    Tcr: [],
    talentTypeList: [],
    talentLevel:[],
    talentTypesName:[],
    workerEmail:[],
    isVendorApplied:[],
    nptProgram: [],
    custReqDate: [],
    passiveJob:[],
    smsType: [],
    smsStatus: [],
    messageScheduledDate: [],
    messageSentTo: '',
    messageSentFrom: '',
    messageCommunity:[]
  };
  drivingRangeData = {
    min: 0,
    max: 2000,
    key: 'driving_distance'
  };
  salaryRangeData = {
    min: 0,
    max: 50000,
    key: 'salary'
  };
  initialLoad = false;
  hasProjectSelected = false;
  selectedStateManagerObj: any;
  isSfdcIdPass = true;
  routerUrl = '';

  constructor(
    private _appState: AppStateService,
    private _commonService: CommonService,
    private _globalFilterService: GlobalFilterService,
    private _cd: ChangeDetectorRef,
    private router: Router
  ) {
    super(_appState);
   }

  ngOnInit() {
    this.initialLoad = !this.initialLoad;
    this.accessType = this._appState.getAccessType();
    this.vendorAccessType = this.accessType;
    this.routerUrl = this.router.url;
    // To check current page & update date filter name
    if (!this.dateLabel) {
      if (this.communityFor === 'jobAccessAnalytics') {
        this.dateLabel = 'Accessed Date';
      } else {
        this.dateLabel = 'Date Created'
      }
    }
    // if(this.currentPage && this.currentPage === 'userCountAnalytics') {
    //   this.dateFilterName = 'Registration Date';
    // } else if(this.currentPage && this.currentPage === 'globalChatAnalytics') {
    //   this.dateFilterName = 'Conversation Date';
    // } else {
    //   this.dateFilterName = 'Date Created';
    // }
    // To check if view as PMS mode is activated.
    if (localStorage.getItem('viewAsInfo')) {
      const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
      const viewAsPartner = (viewAsInfo && viewAsInfo.viewAsType === 'Partner');
      this.accessType = (viewAsPartner) ? 'partner' : this.accessType;
    }
    if ((this.accessType === "vendor" || this.viewAsVendor) ) {
      this.setPartnerAccountObj = this._appState.getSelectedAccount();
    } else {
      this.setPartnerAccountObj = "";
    }
    if (localStorage.getItem("filterObj")) {
      this.filterObj = JSON.parse(localStorage.getItem("filterObj"));
      this.setTotalFilterCount();
    }
    if (this._enableFilters.includes('customers')) {
      this._enableFilters.splice(this._enableFilters.indexOf('customers'), 1);
    }
    // else {
    //   this.setDefaultRangeForGlobalFilter();
    // }
    this._sub = this._globalFilterService.getEnabledFilters().subscribe(enabledFilters => {
      if (enabledFilters && enabledFilters.length) {
        this.setEnabledFilters(enabledFilters);
      }
    });

    // Subscribe value when user click on left side filter from HelpDesk Console
    this._sub1 = this._commonService.getLeftSideFilters().subscribe(leftFilter => {
      if (leftFilter && leftFilter.selectedTab) {
        setTimeout(() => { this.filterData(); }, 0);
      }
    });
    if (this.currentPage== 'rmc') {
      this.isSfdcIdPass = false;
      const appData = JSON.parse(localStorage.getItem('appData'));
      if ((appData.user.accessType === 'vendor' || this.viewAsVendor)) {
        this.setApplicationObj = {
          'rmc': [
            {
              ts2__Agency__c: appData.user.AccountId
            }
          ]
        }
      }     
    }
  }

  setEnabledFilters(e) {
    if (e) {
      this.loadFilters = true;
      this._enableFilters = e;
      if (localStorage.getItem("filterObj")) {
        this.filterObj = JSON.parse(localStorage.getItem("filterObj"));
        // if (this._enableFilters.includes('projects') && this.filterObj["projects"]) {
        //   this.removeProjectsDataFromLocal();
        // }
        this.setTotalFilterCount();
      }
    }
  }

  /**
   * Remove ProjectFilter Data from local storage
   */
  removeProjectsDataFromLocal() {
    const modifiedFilterObj = this.filterObj;
    this.filterObj["projects"] = [];
    this.filterObj = modifiedFilterObj;
    localStorage.setItem("filterObj", JSON.stringify(this.filterObj));
    this.prepareFilterObj(this.filterObj);
  }

  /**
   * hit on apply filter
   */
  filterData() {
    // don't do anything is there isn't any filter is enabled
    if (this._enableFilters.length <= 0) {
      return;
    }
    if (this.filterObj && Object.keys(this.filterObj).length) {
      // set local storage for mantain filter page selected
      localStorage.setItem("filterObj", JSON.stringify(this.filterObj));
      this.setTotalFilterCount();
      // bake whereObj
      this.prepareFilterObj(this.filterObj);
    } else {
      // localStorage.setItem('filterObj', JSON.stringify(this.filterObj));
      // this.searchGlobalFilter.emit({});
    }
    if (this.filterObj && this.filterObj.accountCommunities &&
      (this.filterObj.accountCommunities.length === 0) && this.currentPage != 'teamMembers' &&
      this.defaultSelectedAccountCommunity) {
      this.defaultSelectedAccountCommunity.length = 0;
    }
  }
  /**
   *
   * @param filterObj | bake all stuff to search data
   */
  prepareFilterObj(filterObj) {
    let filterCondition = {};
    // if (localStorage.getItem('filterCondition')
    //   && JSON.parse(localStorage.getItem('filterCondition'))
    //   && Object.keys(JSON.parse(localStorage.getItem('filterCondition'))).length) {
    //   filterCondition = JSON.parse(localStorage.getItem('filterCondition'));
    // }

    if (
      filterObj.teamProjectTypes &&
      filterObj.teamProjectTypes.length &&
      this._enableFilters.indexOf("teamProjectTypes") > -1
    ) {
      filterCondition["TeamProjectType"] = { inq: filterObj.teamProjectTypes };
    }

    if (
      filterObj.objective &&
      filterObj.objective.length &&
      this._enableFilters.indexOf("objective") > -1
    ) {
      filterCondition["objective"] = { inq: filterObj.objective };
    }

    if (
      filterObj.keyResult &&
      filterObj.keyResult.length &&
      this._enableFilters.indexOf("keyResult") > -1
    ) {
      filterCondition["keyResult"] = { inq: filterObj.keyResult };
    }

    if (
      filterObj.initiative &&
      filterObj.initiative.length &&
      this._enableFilters.indexOf("initiative") > -1
    ) {
      filterCondition["initiative"] = { inq: filterObj.initiative };
    }

    if (
      filterObj.period &&
      filterObj.period.length &&
      this._enableFilters.indexOf("period") > -1
    ) {
      filterCondition["Period__c"] = { inq: filterObj.period };
    }
    if (
      filterObj.milestoneTask &&
      filterObj.milestoneTask.length &&
      this._enableFilters.indexOf("milestoneTask") > -1
    ) {
      filterCondition["Show_Milestones__c"] = { inq: filterObj.milestoneTask };
    }
    if (
      filterObj.workOrderCaseIds &&
      filterObj.workOrderCaseIds.length &&
      this._enableFilters.indexOf("workOrderCaseIds") > -1
    ) {
      filterCondition["Job_Order__c"] = { inq: filterObj.workOrderCaseIds };
    }

    if (
      filterObj.formName &&
      filterObj.formName.length &&
      this._enableFilters.indexOf("formName") > -1
    ) {
      filterCondition["Form_Name__c"] = { inq: filterObj.formName };
    }

    if (
      filterObj.objectiveType &&
      filterObj.objectiveType.length &&
      this._enableFilters.indexOf("objectiveType") > -1
    ) {
      filterCondition['Objective_Assignment_Type__c'] = { inq: filterObj.objectiveType };
    }

    if (
      filterObj.objectiveStatus &&
      filterObj.objectiveStatus.length &&
      this._enableFilters.indexOf("objectiveStatus") > -1
    ) {
      filterCondition["Objective_Status__c"] = { inq: filterObj.objectiveStatus };
    }

    if (
      filterObj.vendorAccounts &&
      filterObj.vendorAccounts.length &&
      this._enableFilters.indexOf("vendorAccounts") > -1
    ) {
      filterCondition["Vendor__c"] = { inq: filterObj.vendorAccounts };
    }

    if (
      filterObj.contactAccounts &&
      filterObj.contactAccounts.length &&
      this._enableFilters.indexOf("contactAccounts") > -1
    ) {
      filterCondition["contactAccount__c"] = { inq: filterObj.contactAccounts };
    }
    if (
      filterObj.accounts &&
      filterObj.accounts.length &&
      this._enableFilters.indexOf("accounts") > -1
    ) {
      filterCondition["CKSW_BASE__Account__c"] = { inq: filterObj.accounts };
    }
    if (
      filterObj.accountCommunities &&
      filterObj.accountCommunities.length &&
      this._enableFilters.indexOf("accountCommunities") > -1
    ) {
      filterCondition["Account_Type__c"] = { inq: filterObj.accountCommunities };
    }
    if (
      filterObj.jobOrders &&
      filterObj.jobOrders.length &&
      this._enableFilters.indexOf("jobOrders") > -1
    ) {
      filterCondition["job_Order__c"] = { inq: filterObj.jobOrders };
    }
    if (
      filterObj.applications &&
      filterObj.applications.length &&
      this._enableFilters.indexOf("applications") > -1
    ) {
      filterCondition["Application__c"] = { inq: filterObj.applications };
    }    
    if (
      filterObj.programs &&
      filterObj.programs.length &&
      this._enableFilters.indexOf("programs") > -1
    ) {
      filterCondition["Project_SOP__c"] = { inq: filterObj.programs };
    }
    if (
      filterObj.jobSites &&
      filterObj.jobSites.length &&
      this._enableFilters.indexOf("jobSites") > -1
    ) {
      filterCondition["Jobsite__c"] = { inq: filterObj.jobSites };
    }
    if (
      filterObj.jobs &&
      filterObj.jobs.length &&
      this._enableFilters.indexOf("jobs") > -1
    ) {
      filterCondition["Iron_Job_num__c"] = { inq: filterObj.jobs };
    }
    if (
      filterObj.jobsfdcIds &&
      filterObj.jobsfdcIds.length &&
      this._enableFilters.indexOf("jobsfdcIds") > -1
    ) {
      filterCondition["jobsfdcIds"] = { inq: filterObj.jobsfdcIds };
    }

    if (
      filterObj.statgeStatus &&
      filterObj.statgeStatus.length &&
      this._enableFilters.indexOf("statgeStatus") > -1
    ) {
      filterCondition["Dispatch_Service_Resolution_Status__c"] = {
        inq: filterObj.statgeStatus,
      };
    }
    if (
      filterObj.jobTypes &&
      filterObj.jobTypes.length &&
      this._enableFilters.indexOf("jobTypes") > -1
    ) {
      filterCondition["and"] = [{ or: filterObj.recordTypeIds }];
    }
    if (
      filterObj.caseTypes &&
      filterObj.caseTypes.length &&
      this._enableFilters.indexOf("caseTypes") > -1
    ) {
      filterCondition["caseType"] = [{ or: filterObj.pmsRecordTypeIds }];
    }
    if (filterObj.talentTypes && filterObj.talentTypes.length &&
      this._enableFilters.indexOf("talentTypes") > -1) {
      filterCondition["Talent_Type__c"] = { inq: filterObj.talentTypes };
    }
    if (filterObj.workerTalentTypes && filterObj.workerTalentTypes.length &&
      this._enableFilters.indexOf("workerTalentTypes") > -1) {
      filterCondition["Worker_Talent_Type__c"] = { inq: filterObj.workerTalentTypes };
    }

    if (filterObj.dateOptions && filterObj.dateOptions[0] && this._enableFilters.indexOf("dateOptions") > -1) {
      const startDate = filterObj.dateOptions[0];
      filterCondition["CreatedDate"] = {
        between: [startDate[0], startDate[1]],
      };
    }

    if (filterObj.jobCreatedDate && filterObj.jobCreatedDate[0] && this._enableFilters.indexOf("jobCreatedDate") > -1) {
      const startDate = filterObj.jobCreatedDate[0];
      filterCondition["jobCreatedDate"] = {
        between: [startDate[0], startDate[1]],
      };
    }

    if (filterObj.customerScheduled && filterObj.customerScheduled[0] && this._enableFilters.indexOf("customerScheduled") > -1) {
      const startDate = filterObj.customerScheduled[0];
      filterCondition["Customer_Appointment_DateTime_Scheduled__c"] = {
        between: [startDate[0], startDate[1]],
      };
    }

    if (filterObj.dateClosed && filterObj.dateClosed[0] && this._enableFilters.indexOf("dateClosed") > -1) {
      const startDate = filterObj.dateClosed[0];
      filterCondition["ClosedDate"] = {
        between: [startDate[0], startDate[1]],
      };
    }

    if (filterObj.workerScheduled && filterObj.workerScheduled[0] && this._enableFilters.indexOf("workerScheduled") > -1) {
      const startDate = filterObj.workerScheduled[0];
      filterCondition["WorkerScheduled"] = {
        between: [startDate[0], startDate[1]],
      };
    }

    if (
      filterObj.caseCategory &&
      filterObj.caseCategory.length &&
      this._enableFilters.indexOf("caseCategory") > -1
    ) {
      filterCondition["RecordTypeId"] = { inq: filterObj.caseCategory };
    }

    if (
      filterObj.priority &&
      filterObj.priority.length &&
      this._enableFilters.indexOf("priority") > -1
    ) {
      filterCondition["Service_Dispatch_SLA_Priority__c"] = {
        inq: filterObj.priority,
      };
    }

    if (
      filterObj.jobStatus &&
      filterObj.jobStatus.length &&
      this._enableFilters.indexOf("jobStatus") > -1
    ) {
      filterCondition["Job_Status_Internal__c"] = { inq: filterObj.jobStatus };
    }

    if (
      filterObj.workerNames &&
      filterObj.workerNames.length &&
      this._enableFilters.indexOf("workerNames") > -1
    ) {
      filterCondition["Dispatch_Worker_Name__c"] = {
        inq: filterObj.workerNames,
      };
    }

    if (
      filterObj.workerIds &&
      filterObj.workerIds.length &&
      this._enableFilters.indexOf("workerIds") > -1
    ) {
      filterCondition["Dispatch_Worker_num__c"] = {
        inq: filterObj.workerIds,
      };
    }

    if (
      filterObj.vendorSites &&
      filterObj.vendorSites.length &&
      this._enableFilters.indexOf("vendorSites") > -1
    ) {
      filterCondition["Vendorsite__c"] = { inq: filterObj.vendorSites };
    }

    if (
      filterObj.geoMetro &&
      filterObj.geoMetro.length &&
      this._enableFilters.indexOf("geoMetro") > -1
    ) {
      let jobsiteSfdcIds = [];
      // jobsiteSfdcIds = jobsiteSfdcIds.concat(filterObj.jobSites, filterObj.geoMetroJobSite);
      if (filterObj.geoMetroJobSite && filterObj.geoMetroJobSite.length) {
        if (filterObj.jobSites && filterObj.jobSites.length) {
          filterObj.jobSites.forEach((jobsite) => {
            if (filterObj.geoMetroJobSite.indexOf(jobsite) > -1) {
              jobsiteSfdcIds.push(jobsite);
            }
          });
        } else {
          jobsiteSfdcIds = filterObj.geoMetroJobSite;
        }
        jobsiteSfdcIds = Array.from(new Set(jobsiteSfdcIds));
      }
      filterCondition["Jobsite__c"] = { inq: jobsiteSfdcIds };
    }
    // It's use for Team Shift
    if (
      filterObj.teamShifts &&
      filterObj.teamShifts.length &&
      filterObj.teamShiftUsers &&
      this._enableFilters.indexOf("teamShiftUsers") > -1
    ) {
      filterCondition["Case__c"] = { inq: filterObj.teamShiftUsers };
    }

    if (
      filterObj.pmsCaseStatus &&
      filterObj.pmsCaseStatus.length &&
      this._enableFilters.indexOf("pmsCaseStatus") > -1
    ) {
      filterCondition["PMS_Case_Status"] = { inq: filterObj.pmsCaseStatus };
    }
    /// direct search from case table pmsCaseNumbers
    if (filterObj.pmsCaseNumbers && filterObj.pmsCaseNumbers.length && this._enableFilters.indexOf("pmsCaseNumbers") > -1) {
      filterCondition["PMS_Case_Number"] = { inq: filterObj.pmsCaseNumbers };
    }

    /// direct search from case table pmcCaseNumbers
    if (filterObj.pmcCaseNumbers && filterObj.pmcCaseNumbers.length && this._enableFilters.indexOf("pmcCaseNumbers") > -1) {
      filterCondition["PMC_Case_Number"] = { inq: filterObj.pmcCaseNumbers };
    }

    // escalation type filter
    if (filterObj.escalationType && filterObj.escalationType.length && this._enableFilters.indexOf("escalationType") > -1) {
      filterCondition["Escalation_Type"] = filterObj.escalationType;
    }

    // project filter
    if (filterObj.projects && filterObj.projects.length && this._enableFilters.indexOf("projects") > -1) {
      filterCondition["PgMO_Projects__c"] = { inq: filterObj.projects };
    }
    // project categirt filter
    if (filterObj.projectCategory && filterObj.projectCategory.length && this._enableFilters.indexOf("projectCategory") > -1) {
      filterCondition["projectCategory"] = { inq: filterObj.projectCategory };
    }

    // milestone filter
    if (filterObj.milestones && filterObj.milestones.length && this._enableFilters.indexOf("milestones") > -1) {
      filterCondition["PgMO_Milestones__c"] = { inq: filterObj.milestones };
    }

    // task type filter
    if (filterObj.tasks && filterObj.tasks.length && this._enableFilters.indexOf("tasks") > -1) {
      filterCondition["PgMo_Task__c"] = { inq: filterObj.tasks };
    }

    // task status type filter
    if (filterObj.taskStatuses && filterObj.taskStatuses.length && this._enableFilters.indexOf('taskStatuses') > -1) {
      filterCondition['Task_Status__c'] = { inq: filterObj.taskStatuses };
    }

      // task status type filter
      if (filterObj.milestoneTask && filterObj.milestoneTask.length && this._enableFilters.indexOf('milestoneTask') > -1) {
        filterCondition['PgMO_Milestones__c'] = { inq: filterObj.milestoneTask };
      }

    // problem task status type filter
    if (filterObj.problemTaskStatuses && filterObj.problemTaskStatuses.length && this._enableFilters.indexOf('problemTaskStatuses') > -1) {
      filterCondition['Problem_Resolution_Status__c'] = { inq: filterObj.problemTaskStatuses };
    }

    // task requestor type filter
    if (filterObj.taskRequestors && filterObj.taskRequestors.length && this._enableFilters.indexOf('taskRequestors') > -1) {
      filterCondition['Requestor__c'] = { inq: filterObj.taskRequestors };
    }

    // taskTypes type filter

    if (filterObj.taskTypes && filterObj.taskTypes.length && this._enableFilters.indexOf('taskTypes') > -1) {
      filterCondition['Request_Type__c'] = { inq: filterObj.taskTypes };
    }

    // project type filter
    if (filterObj.projectTypes && filterObj.projectTypes.length && this._enableFilters.indexOf("projectTypes") > -1 &&
      filterObj.projectTypes.filter(Boolean).length) {
      filterCondition["Project_Type__c"] = { inq: filterObj.projectTypes };
    }

    if (filterObj.taskAccounts && filterObj.taskAccounts.length && this._enableFilters.indexOf("taskAccounts") > -1) {
      filterCondition["Internal_Account_Id"] = { inq: filterObj.taskAccounts };
    }

    // employee type filter
    //if all is selected in employee type then don't apply that filter
    filterObj['fromPage'] = this.fromPage;
    if (filterObj.employeeType && filterObj.employeeType.length && this._enableFilters.indexOf("employeeType") > -1 &&
      (!filterObj.employeeType.includes("") || this.fromPage === 'leave-approval-list')) {
      if (this.fromPage === 'leave-approval-list')
        filterCondition["Dispatch_worker_type"] = { inq: filterObj.employeeType };
      else
        filterCondition["Dispatch_worker_type"] = filterObj.employeeType;
    }

    if (filterObj.leaveStatusType && filterObj.leaveStatusType.length && this._enableFilters.indexOf("leaveStatusType") > -1) {
      filterCondition["leaveStatusType"] = { inq: filterObj.leaveStatusType };
    }

    // Implementing the timecard global filters
    if (filterObj.ironApprovalStatuses && filterObj.ironApprovalStatuses.length &&
      this._enableFilters.indexOf('ironApprovalStatuses') > -1) {
      filterCondition['IRON_Approval_Status__c'] = { inq: filterObj.ironApprovalStatuses };
    }
    if (filterObj.customerApprovalStatuses && filterObj.customerApprovalStatuses.length &&
      this._enableFilters.indexOf('customerApprovalStatuses') > -1) {
      filterCondition['Customer_Approval_Status__c'] = { inq: filterObj.customerApprovalStatuses };
    }
    if (filterObj.actualCoverageHours && filterObj.actualCoverageHours.length) {
      filterCondition['Actual_Coverage_Hour__c'] = { inq: filterObj.actualCoverageHours };
    }
    if (filterObj.coverageHours && filterObj.coverageHours.length) {
      filterCondition['Coverage_Hours__c'] = { inq: filterObj.coverageHours };
    }
    // if (filterObj.actualEndTimeCoverages && filterObj.actualEndTimeCoverages.length) {
    //     filterCondition['Actual_End_Time_Coverage__c'] = { inq: filterObj.actualEndTimeCoverages };
    // }
    // if (filterObj.actualCoverageOveralls && filterObj.actualCoverageOveralls.length) {
    //     filterCondition['Actual_Coverage_Overall__c'] = { inq: filterObj.actualCoverageOveralls };
    // }
    //apply filter on worker_billing_Type_C worker under timecard
    if (filterObj.workerBillingTypes && filterObj.workerBillingTypes.ids && filterObj.workerBillingTypes.ids.length &&
      this._enableFilters.indexOf('workerBillingTypes') > -1
    ) {
      filterCondition['Worker_Billing_Type__c'] = { inq: filterObj.workerBillingTypes.ids };
    }

    if (filterObj.regions && filterObj.regions.length &&
      this._enableFilters.indexOf('regions') > -1) {
      filterCondition['Region'] = { inq: filterObj.regions.map(item => item.sfdcId) };
    }
    if (filterObj.countries && filterObj.countries.length &&
      this._enableFilters.indexOf('countries') > -1) {
      filterCondition['CountryCode'] = { inq: filterObj.countries.map(item => item.Name) };
    }
    if (filterObj.statesManager && filterObj.statesManager.length && 
      this._enableFilters.indexOf('statesManager') > -1) {
        if(this.router.url == '/pms/search-job' || this.router.url == '/vms/search-job') {
          filterCondition['StateManager__c'] = { inq: filterObj.statesManager.map(item => item.stateName) };
        } else {
          filterCondition['StateManager__c'] = { inq: filterObj.statesManager.map(item => item.sfdcId) };
        }
    }

    if (
      filterObj.purchaseOrder &&
      filterObj.purchaseOrder.length &&
      this._enableFilters.indexOf("purchaseOrder") > -1
    ) {
      filterCondition["Work_Order_num__c"] = {
        inq: filterObj.purchaseOrder,
      };
    }

    if (
      filterObj.serviceContractNumbers &&
      filterObj.serviceContractNumbers.length &&
      this._enableFilters.indexOf("serviceContractNumbers") > -1
    ) {
      filterCondition["Service_Contract__c"] = {
        inq: filterObj.serviceContractNumbers,
      };
    }

    if (filterObj.serviceContractCategory && filterObj.serviceContractCategory.length &&
      this._enableFilters.indexOf('serviceContractCategory') > -1) {
      filterCondition['Service_Contract_Category__c'] = { inq: filterObj.serviceContractCategory };
    }

    if (filterObj.divisions && filterObj.divisions.length && this._enableFilters.indexOf('divisions') > -1) {
      filterCondition['Division__c'] = { inq: filterObj.divisions };
    }

    if (filterObj.departments && filterObj.departments.length && this._enableFilters.indexOf('departments') > -1) {
      filterCondition['Department__c'] = { inq: filterObj.departments };
    }

    if (filterObj.businessUnit && filterObj.businessUnit.length && this._enableFilters.indexOf('businessUnit') > -1) {
      filterCondition['businessUnit'] = { inq: filterObj.businessUnit };
    }

    if (filterObj.teamTypes && filterObj.teamTypes.length) {
      filterCondition['Team_Type'] = { inq: filterObj.teamTypes };
    }

    if (filterObj.departmentRoleAccesses && filterObj.departmentRoleAccesses.length) {
      filterCondition['Access__c'] = { inq: filterObj.departmentRoleAccesses };
    }

    if (filterObj.pgmo_departments && filterObj.pgmo_departments.length && this._enableFilters.indexOf('pgmo_departments') > -1) {
      filterCondition['PgMO_Departments__c'] = { inq: filterObj.pgmo_departments };
    }
    if (filterObj.taskLibraries && filterObj.taskLibraries.length && this._enableFilters.indexOf('taskLibraries') > -1) {
      filterCondition['PgMO_Task_Library__c'] = { inq: filterObj.taskLibraries };
    }

    if (filterObj.milestoneLibraries && filterObj.milestoneLibraries.length && this._enableFilters.indexOf('milestoneLibraries') > -1) {
      filterCondition['PgMO_Milestone_Library__c'] = { inq: filterObj.milestoneLibraries };
    }

    if (filterObj.tmpltLibraryTypes && filterObj.tmpltLibraryTypes.length && this._enableFilters.indexOf('tmpltLibraryTypes') > -1) {
      filterCondition['Library_Type_Selected__c'] = { inq: filterObj.tmpltLibraryTypes };
    }

    if (filterObj.expenseTypes && filterObj.expenseTypes.length && this._enableFilters.indexOf('expenseTypes') > -1) {
      filterCondition['Expense_Type__c'] = { inq: filterObj.expenseTypes };
    }

    if (filterObj.expenseSubTypes && filterObj.expenseSubTypes.length && this._enableFilters.indexOf('expenseSubTypes') > -1) {
      filterCondition['Expense_sub_Type__c'] = { inq: filterObj.expenseSubTypes };
    }

    if (filterObj.paymentTypes && filterObj.paymentTypes.length && this._enableFilters.indexOf('paymentTypes') > -1) {
      filterCondition['Payment_Type__c'] = { inq: filterObj.paymentTypes };
    }
    // activity type filter in dashboard
    if (filterObj.activityType && filterObj.activityType.length && this._enableFilters.indexOf('activityType') > -1) {
      filterCondition['Activity_Type__c'] = { inq: filterObj.activityType };
    }
    // worker type filter
    if (filterObj.workerType && filterObj.workerType.length && this._enableFilters.indexOf('workerType') > -1) {
      filterCondition['Worker_Type__c'] = { inq: filterObj.workerType };
    }
    // worker type Sub filter
    if (filterObj.workerTypeSub && filterObj.workerTypeSub.length && this._enableFilters.indexOf('workerTypeSub') > -1) {
      filterCondition['Worker_Type_Sub__c'] = { inq: filterObj.workerTypeSub };
    }
    // technical Levels filter
    if (filterObj.technicalLevels && filterObj.technicalLevels.length && this._enableFilters.indexOf('technicalLevels') > -1) {
      filterCondition['Technical_Level__c'] = { inq: filterObj.technicalLevels };
    }
    // TASK PRIORITY LOOK UP
    if (filterObj.taskPriorities && filterObj.taskPriorities.length) {
      filterCondition['Priority__c'] = { inq: filterObj.taskPriorities };
    }

    // User LOOK UP
    if (filterObj.users && filterObj.users.length) {
      filterCondition['Worker__c'] = { inq: filterObj.users };
    }

    // Team LOOK UP
    if (filterObj.teams && filterObj.teams.length) {
      filterCondition['PgMO_Groups__c'] = { inq: filterObj.teams };
    }
    // DATE FROM LOOK UP
    if (filterObj.dateFrom && filterObj.dateFrom.length) {
      filterCondition['Start_Date__c'] = { inq: filterObj.dateFrom };
    }

    // DATE TO LOOK UP
    if (filterObj.dateTo && filterObj.dateTo.length) {
      filterCondition['Start_Date__c'] = { inq: filterObj.dateTo };
    }

    // START FROM DATE RANGE LOOK UP
    if (filterObj.startFromDateOptions && filterObj.startFromDateOptions[0] &&
      this._enableFilters.indexOf('startFromDateOptions') > -1) {
      const startDate = filterObj.startFromDateOptions[0];
      filterCondition['startFromDate'] = { between: [startDate[0], startDate[1]] };
    }

    // customer requested filter
    if (filterObj.customerDateOptions && filterObj.customerDateOptions[0] &&
      this._enableFilters.indexOf("customerDateOptions") > -1) {
      const startDate = filterObj.customerDateOptions[0];
      filterCondition["Customer_Appointment_Start_Scheduled__c"] = {
        between: [startDate[0], startDate[1]],
      }
    }
    // work-order-date-plan
    if (filterObj.workOrderDateOptions && filterObj.workOrderDateOptions[0] &&
      this._enableFilters.indexOf("workOrderDateOptions") > -1) {
      const startDate = filterObj.workOrderDateOptions[0];
      filterCondition["Work_Order_Plan_Date__c"] = {
        between: [startDate[0], startDate[1]],
      }
    }

    if (filterObj.customers && filterObj.customers.length) {
      filterCondition['Customer__c'] = { inq: filterObj.customers };
    }

    if (filterObj.subCategories && filterObj.subCategories.length &&
      this._enableFilters.indexOf('subCategories') > -1) {
      filterCondition['Sub_Catergory__c'] = { inq: filterObj.subCategories };
    }

    // category Lookup
    if (filterObj.category && filterObj.category.length &&
      this._enableFilters.indexOf('category') > -1) {
      filterCondition['Expense_sub_Type__c'] = { inq: filterObj.category };
    }

    // isBillable Lookup
    if (filterObj.isBillable && filterObj.isBillable.length &&
      this._enableFilters.indexOf('isBillable') > -1) {
      filterCondition['Is_Billable__c'] = { inq: filterObj.isBillable };
    }

    // TEMPLATE NAME LOOK UP
    if (filterObj.templateName && filterObj.templateName.length) {
      filterCondition['Template_Name__c'] = { inq: filterObj.templateName };
    }

    // TEMPLATE CATEGORY LOOK UP
    if (filterObj.templateCategories && filterObj.templateCategories.length) {
      filterCondition['Category__c'] = { inq: filterObj.templateCategories };
    }

    // TEMPLATE SUB-CATEGORY LOOK UP
    if (filterObj.templateSubCategories && filterObj.templateSubCategories.length) {
      filterCondition['Sub_Category__c'] = { inq: filterObj.templateSubCategories };
    }

    if (filterObj.apvpGroup && filterObj.apvpGroup.length) {
      filterCondition['APVP_Group_Number__c'] = { inq: filterObj.apvpGroup };
    }

    // COMMUNITY TYPE LOOK UP
    if (filterObj.communityType && filterObj.communityType.length) {
      filterCondition['Community_Type__c'] = { inq: filterObj.communityType };
    }

    // SLA GROUP NAME LOOK UP
    if (filterObj.slaGroup && filterObj.slaGroup.length) {
      filterCondition['SLA_Group_Name__c'] = { inq: filterObj.slaGroup };
    }

    if (filterObj.wslAccountStatus && filterObj.wslAccountStatus.length) {
      filterCondition['FSL_Location_Status__c'] = { inq: filterObj.wslAccountStatus }
    }

    if (filterObj.wslAccounts && filterObj.wslAccounts.length) {
      filterCondition['WSL_Accounts'] = { inq: filterObj.wslAccounts }
    }

    if (filterObj.address && filterObj.address.length) {
      filterCondition['address'] = { inq: filterObj.address };
    }

    if (filterObj.geoCode && filterObj.geoCode.length) {
      filterCondition['GeoCode'] = { inq: filterObj.geoCode };
    }

    if (filterObj.city && filterObj.city.length) {
      filterCondition['City__c'] = { inq: filterObj.city };
    }

    if (filterObj.workerStatus && filterObj.workerStatus.length) {
      filterCondition['Worker_Status'] = { inq: filterObj.workerStatus };
    }

    if (filterObj.radius && filterObj.radius.length) {
      filterCondition['radius'] = { inq: filterObj.radius };
    }

    if (filterObj.driving_distance && filterObj.driving_distance[0] &&
      this._enableFilters.indexOf("driving_distance") > -1) {
      const dd = filterObj.driving_distance[0];
      filterCondition["DrivingDistance"] = {
        between: [dd['min'], dd['max']],
      };
    }

    if (filterObj.salary && filterObj.salary[0] && this._enableFilters.indexOf("salary") > -1) {
      const salary = filterObj.salary[0];
      filterCondition["Salary"] = {
        between: [salary['min'], salary['max']],
      };
    }

    if (filterObj.targetCost && filterObj.targetCost[0] && this._enableFilters.indexOf("targetCost") > -1) {
      const targetCost = filterObj.targetCost[0];
      filterCondition["Target_Cost__c"] = {
        between: [targetCost['min'], targetCost['max']],
      };
    }

    if (filterObj.gtpResourceType && filterObj.gtpResourceType.length) {
      filterCondition['gtpResourceType'] = { inq: filterObj.gtpResourceType };
    }

    if (filterObj.messageType && filterObj.messageType.length) {
      filterCondition['Is_System_Generated__c'] = { inq: filterObj.messageType };
    }

    if (filterObj.gtpPoolAccessType && filterObj.gtpPoolAccessType.length) {
      filterCondition['gtpPoolAccessType'] = { inq: filterObj.gtpPoolAccessType };
    }

    if (filterObj.gtpMemberType && filterObj.gtpMemberType.length) {
      filterCondition['gtpMemberType'] = { inq: filterObj.gtpMemberType };
    }

    if (filterObj.gtpCommunityType && filterObj.gtpCommunityType.length) {
      filterCondition['gtpCommunityType'] = { inq: filterObj.gtpCommunityType };
    }

    if (filterObj.backFillWorkerService && filterObj.backFillWorkerService.length) {
      filterCondition['backFillWorkerService'] = { inq: filterObj.backFillWorkerService };
    }


    if (filterObj.displayCoverageType && filterObj.displayCoverageType.length) {
      filterCondition['displayCoverageType'] = { inq: filterObj.displayCoverageType };
    }

    if (filterObj.vendorSubTypeCommunity && filterObj.vendorSubTypeCommunity.length) {
      filterCondition['vendorSubTypeCommunity'] = { inq: filterObj.vendorSubTypeCommunity };
    }

    if (filterObj.covrageHoursAccountType && filterObj.covrageHoursAccountType.length) {
      filterCondition['covrageHoursAccountType'] = { inq: filterObj.covrageHoursAccountType };
    }

    if (filterObj.slaAccountType && filterObj.slaAccountType.length) {
      filterCondition['slaAccountType'] = { inq: filterObj.slaAccountType };
    }


    if (filterObj.displayVendorsiteType && filterObj.displayVendorsiteType.length) {
      filterCondition['displayVendorsiteType'] = { inq: filterObj.displayVendorsiteType };
    }
    if (filterObj.vendorStatusType && filterObj.vendorStatusType.length) {
      filterCondition['vendorStatusType'] = { inq: filterObj.vendorStatusType };
    }

    if (filterObj.mvvpDisplayType && filterObj.mvvpDisplayType.length) {
      filterCondition['mvvpDisplayType'] = { inq: filterObj.mvvpDisplayType };
    }

    if (filterObj.candidateType && filterObj.candidateType.length) {
      filterCondition['candidateType'] = { inq: filterObj.candidateType };
    }

    if (filterObj.slaLabourCloudType && filterObj.slaLabourCloudType.length) {
      filterCondition['slaLabourCloudType'] = { inq: filterObj.slaLabourCloudType };
    }

    if (filterObj.coverHoursMvvp && filterObj.coverHoursMvvp.length) {
      filterCondition['coverHoursMvvp'] = { inq: filterObj.coverHoursMvvp };
    }

    if (filterObj.jobOrderStatus && filterObj.jobOrderStatus.length) {
      filterCondition['jobOrderStatus'] = { inq: filterObj.jobOrderStatus };
    }

    if (filterObj.jobOrderStage && filterObj.jobOrderStage.length) {
      filterCondition['jobOrderStage'] = { inq: filterObj.jobOrderStage };
    }

    if (filterObj.jobOrderPriority && filterObj.jobOrderPriority.length) {
      filterCondition['jobOrderPriority'] = { inq: filterObj.jobOrderPriority };
    }

    if (filterObj.userTypeId && filterObj.userTypeId.length) {
      filterCondition['userTypeId'] = { inq: filterObj.userTypeId };
    }
    if (filterObj.pageName && filterObj.pageName.length) {
      filterCondition['pageName'] = { inq: filterObj.pageName };
    }
    if (filterObj.userIdLookup && filterObj.userIdLookup.length) {
      filterCondition['userIdLookup'] = { inq: filterObj.userIdLookup };
    }
    if(filterObj.locations && filterObj.locations.length) {
      filterCondition['Location_List_New__c'] = {inq: filterObj.locations};
    }
    if(filterObj.cityList && filterObj.cityList.length) {
      filterCondition['cityList'] = {inq: filterObj.cityList};
    }
    // isTestUser Lookup
    if (filterObj.isTestUser && filterObj.isTestUser.length &&
      this._enableFilters.indexOf('isTestUser') > -1) {
        filterCondition['isTest'] = filterObj.isTestUser.toString();
      // filterCondition['isTest'] = { inq: filterObj.isTestUser };
    }
    // Conversation Source Lookup
    if (filterObj.conversationSource && filterObj.conversationSource.length &&
      this._enableFilters.indexOf('conversationSource') > -1) {
        filterCondition['conversationSource__c'] = filterObj.conversationSource.toString();
    }
    if (filterObj.includeTestAccount && filterObj.includeTestAccount.length &&
      this._enableFilters.indexOf('includeTestAccount') > -1) {
        filterCondition['Is_Test_Account__c'] = filterObj.includeTestAccount.toString();
    }

    // isServiceo Lookup
    if (filterObj.isServiceoLogin && filterObj.isServiceoLogin.length &&
      this._enableFilters.indexOf('isServiceoLogin') > -1) {
        filterCondition['isServiceoLogin'] = filterObj.isServiceoLogin.toString();
    }
    // uniqueUser Lookup
    if (filterObj.uniqueUser && filterObj.uniqueUser.length &&
      this._enableFilters.indexOf('uniqueUser') > -1) {
        filterCondition['uniqueUser'] = filterObj.uniqueUser.toString();
    }

    if (filterObj.pcUserCount && filterObj.pcUserCount.length &&
      this._enableFilters.indexOf('pcUserCount') > -1) {
        filterCondition['pcUserCount'] = filterObj.pcUserCount;
    }

    if (filterObj.pageType && filterObj.pageType.length &&
      this._enableFilters.indexOf('pageType') > -1) {
        filterCondition['pageType'] = filterObj.pageType.toString();
    }

    if (filterObj.weeklyDateOption && filterObj.weeklyDateOption.length &&
      this._enableFilters.indexOf('weeklyDateOption') > -1) {
        filterCondition["weeklyDateOption"] = filterObj.weeklyDateOption;
    }

    // isActive Lookup
    if (filterObj.isActiveUser && filterObj.isActiveUser.length &&
      this._enableFilters.indexOf('isActiveUser') > -1) {
      filterCondition['isActive'] = filterObj.isActiveUser.toString();
    }
    // member Lookup
    if (filterObj.member && filterObj.member.length &&
      this._enableFilters.indexOf('member') > -1) {
      filterCondition['member'] = { inq: filterObj.member };
    }
    // application status
    if (filterObj.appStatus && filterObj.appStatus.length &&
      this._enableFilters.indexOf('appStatus') > -1) {
      filterCondition['appStatus'] = { inq: filterObj.appStatus };
    }
    // job titles
    if (filterObj.jobTitles && filterObj.jobTitles.length &&
      this._enableFilters.indexOf('jobTitles') > -1) {
      filterCondition['jobTitles'] = { inq: filterObj.jobTitles };
    }
    // notice period
    if (filterObj.noticePeriod && filterObj.noticePeriod.length &&
      this._enableFilters.indexOf('noticePeriod') > -1) {
      filterCondition['noticePeriod'] = { inq: filterObj.noticePeriod };
    }
    // requirement type
    if (filterObj.requirementType && filterObj.requirementType.length &&
      this._enableFilters.indexOf('requirementType') > -1) {
      const requirementType = [];
      filterObj.requirementType.forEach(element => {
        if (element === 'All Primary') {
          requirementType.push('Primary New', 'Primary Replacemen');
        } else if (element === 'All Backfill') {
          requirementType.push('Backfill New', 'Backfill Replacement');
        } else if (element === 'All Dispatch') {
          requirementType.push('Dispatch New', 'Dispatch Replacement')
        } else {
          requirementType.push(element)
        }
      });
      filterCondition['Type_of_Requirement__c'] = { inq: _.uniq(requirementType) };
    }
    // employment Filter
    if (filterObj.employmentFilter && filterObj.employmentFilter.length &&
      this._enableFilters.indexOf('employmentFilter') > -1) {
      filterCondition['employmentFilter'] = { inq: filterObj.employmentFilter };
    }
    // jobAssigned
    if (filterObj.jobAssigned && filterObj.jobAssigned.length &&
      this._enableFilters.indexOf('jobAssigned') > -1) {
      filterCondition['jobAssigned'] = { inq: filterObj.jobAssigned };
    }
    // jobPostingType
    if (filterObj.jobPostingType && filterObj.jobPostingType.length &&
      this._enableFilters.indexOf('jobPostingType') > -1) {
      filterCondition['jobPostingType'] = { inq: filterObj.jobPostingType };
    }
    // jobCreationReason
    if (filterObj.jobCreationReason && filterObj.jobCreationReason.length &&
      this._enableFilters.indexOf('jobCreationReason') > -1) {
      filterCondition['Job_Creation_Reason__c'] = { inq: filterObj.jobCreationReason };
    }
    //setApprovalStatus
    if (filterObj.approvalStatus && filterObj.approvalStatus.length &&
      this._enableFilters.indexOf('approvalStatus') > -1) {
      filterCondition['Approval_Status__c'] = { inq: filterObj.approvalStatus };
    }
    // candidateCurrentSalary
    if (filterObj.candidateCurrentSalary && filterObj.candidateCurrentSalary.length &&
      this._enableFilters.indexOf('candidateCurrentSalary') > -1) {
      filterCondition['candidateCurrentSalary'] = { inq: filterObj.candidateCurrentSalary };
    }
    // candidatePayRateRequested
    if (filterObj.candidatePayRateRequested && filterObj.candidatePayRateRequested.length &&
      this._enableFilters.indexOf('candidatePayRateRequested') > -1) {
      filterCondition['candidatePayRateRequested'] = { inq: filterObj.candidatePayRateRequested };
    }
    // Employee Type List
    if (filterObj.employeeTypeList && filterObj.employeeTypeList.length) {
      filterCondition['employeeTypeList'] = { inq: filterObj.employeeTypeList };
    }
    // workerTypePB
    if (filterObj.workerTypePB && filterObj.workerTypePB.length) {
      filterCondition['Is_Backfill_Work_Order__c'] = { inq: filterObj.workerTypePB };
    }
    // Time Change Request Status
    if (filterObj.timeChangeRequestStatus && filterObj.timeChangeRequestStatus.length) {
      filterCondition['timeChangeRequestStatus'] = { inq: filterObj.timeChangeRequestStatus };
    }
     // Chat board backlog
     if (filterObj.chatBoardbacklogTodo && filterObj.chatBoardbacklogTodo.length) {
      filterCondition['chatBoardbacklogTodo'] =  filterObj.chatBoardbacklogTodo ;
    }


    // COMMUNITY TYPE LOOK UP
    if (filterObj.jobInvoiceTypes && filterObj.jobInvoiceTypes.length) {
      filterCondition['Job_Type__c'] = { inq: filterObj.jobInvoiceTypes };
    }

    // Document Category LOOK UP
    if (filterObj.documentCategory && filterObj.documentCategory.length) {
      filterCondition['DocumentCategoryId'] = { inq: filterObj.documentCategory };
    }

    // Page View Group LOOK UP
    if (filterObj.pageViewGroup && filterObj.pageViewGroup.length) {
      filterCondition['PageViewGroupId'] = { inq: filterObj.pageViewGroup };
    }

     // completed application status
     if (filterObj.completedStatus && filterObj.completedStatus.length &&
      this._enableFilters.indexOf('completedStatus') > -1) {
      filterCondition['completedStatus'] = { inq: filterObj.completedStatus };
    }

    // overall-status overallStatus
    if (filterObj.overallStatus && filterObj.overallStatus.length &&
      this._enableFilters.indexOf('overallStatus') > -1) {
      filterCondition['overallStatus'] = { inq: filterObj.overallStatus };
    }

    // check In Status
    if (filterObj.checkInStatus && filterObj.checkInStatus.length &&
      this._enableFilters.indexOf('checkInStatus') > -1) {
      filterCondition['Check_In_Status__c'] = { inq: filterObj.checkInStatus };
    }

    // check In Frequency
    if (filterObj.checkInFrequency && filterObj.checkInFrequency.length &&
      this._enableFilters.indexOf('checkInFrequency') > -1) {
      filterCondition['Frequency__c'] = { inq: filterObj.checkInFrequency };
    }
    
    //stageStatus application page
    if (filterObj.stageStatus && filterObj.stageStatus.length &&
      this._enableFilters.indexOf('stageStatus') > -1) {
      filterCondition['stageStatus'] = { inq: filterObj.stageStatus };
    }

    
    //Project Managers
    if (filterObj.projectManagers && filterObj.projectManagers.length &&
      this._enableFilters.indexOf('projectManagers') > -1) {
      filterCondition['projectManagers'] = { inq: filterObj.projectManagers };
    }

    //showOnlyJobType
    if (filterObj.showOnlyJobType && filterObj.showOnlyJobType.length &&
      this._enableFilters.indexOf('showOnlyJobType') > -1) {
      filterCondition['showOnlyJobType'] = { inq: filterObj.showOnlyJobType };
    }
     //magiclink exclude joborder page
     if (filterObj.excludeMagicLink && filterObj.excludeMagicLink.length &&
      this._enableFilters.indexOf('excludeMagicLink') > -1) {
      filterCondition['ExcludeMagicLink'] = { neq: filterObj.excludeMagicLink };
    }

    //fileNames
    if (filterObj.fileNames && filterObj.fileNames.length &&
      this._enableFilters.indexOf('fileNames') > -1) {
      filterCondition['fileNames'] = { inq: filterObj.fileNames };
    }
    // business requester type
    if (filterObj.businessRequestor && filterObj.businessRequestor.length &&
      this._enableFilters.indexOf('businessRequestor') > -1) {
      filterCondition['Business_Requestor__c'] = { inq: filterObj.businessRequestor };
    }

    if (
      filterObj.recruitmentManager &&
      filterObj.recruitmentManager.length &&
      this._enableFilters.indexOf("recruitmentManager") > -1
    ) {
      filterCondition["recruitmentManager"] = { inq: filterObj.recruitmentManager };
    }

    if (
      filterObj.recruiter &&
      filterObj.recruiter.length &&
      this._enableFilters.indexOf("recruiter") > -1
    ) {
      filterCondition["recruiter"] = { inq: filterObj.recruiter };
    }

    //interviewFeedback
    if (filterObj.interviewFeedback && filterObj.interviewFeedback.length &&
      this._enableFilters.indexOf('interviewFeedback') > -1) {
      filterCondition['interviewFeedback'] = { inq: filterObj.interviewFeedback };
    }

    //job
    if (filterObj.job && filterObj.job.length &&
      this._enableFilters.indexOf('job') > -1) {
      filterCondition['job'] = { inq: filterObj.job };
    }

    //interviewRound
    if (filterObj.interviewRound && filterObj.interviewRound.length &&
      this._enableFilters.indexOf('interviewRound') > -1) {
      filterCondition['interviewRound'] = { inq: filterObj.interviewRound };
    }

    //single date
    if (filterObj.singleDate && filterObj.singleDate.length) {
      filterCondition['singleDate'] = { inq: filterObj.singleDate };
    }

     //Community Type Rmc
     if (filterObj.communityTypeRmc && filterObj.communityTypeRmc.length) {
      filterCondition['Community_Type_Rmc__c'] = { inq: filterObj.communityTypeRmc };
    }
    if (filterObj.releaseNotesTypes && filterObj.releaseNotesTypes.length && this._enableFilters.indexOf('releaseNotesTypes') > -1) {
      filterCondition['types'] = { inq: filterObj.releaseNotesTypes };
    }

    //Coverage Hours
    if (filterObj.priceListCoverageHours && filterObj.priceListCoverageHours.length && this._enableFilters.indexOf('priceListCoverageHours') > -1) {
      filterCondition['Coverage_Hours__c'] = { inq: filterObj.priceListCoverageHours };
    }
    // Service Technical Level
    if (filterObj.priceListTechnicalLevel && filterObj.priceListTechnicalLevel.length && this._enableFilters.indexOf('priceListTechnicalLevel') > -1) {
      filterCondition['Service_Technical_Level__c'] = { inq: filterObj.priceListTechnicalLevel };
    }
    //SLA Priority
    if (filterObj.priceListSLA && filterObj.priceListSLA.length && this._enableFilters.indexOf('priceListSLA') > -1) {
      filterCondition['SLA__c'] = { inq: filterObj.priceListSLA };
    }
    //Service Talent Type
    if (filterObj.servicetalentTypes && filterObj.servicetalentTypes.length && this._enableFilters.indexOf('servicetalentTypes') > -1) {
      filterCondition['Service_Talent_Type__c'] = { inq: filterObj.servicetalentTypes };
    }

    //Project status for Partner Project Manager Console
    if (filterObj.projectStatus && filterObj.projectStatus.length && this._enableFilters.indexOf('projectStatus') > -1) {
      filterCondition['Status__c'] = { inq: filterObj.projectStatus };
    }
    //Service Dispatch SLA Price List for Partner Project Manager Console
    if (filterObj.serviceDispatchPriceListSLA && filterObj.serviceDispatchPriceListSLA.length && this._enableFilters.indexOf('serviceDispatchPriceListSLA') > -1) {
      filterCondition['Service_Dispatch_SLA_Priority__c'] = { inq: filterObj.serviceDispatchPriceListSLA };
    }
    if (filterObj.talentTypeList && filterObj.talentTypeList.length && this._enableFilters.indexOf('talentTypeList') > -1) {
      filterCondition['talentTypeList'] = { inq: filterObj.talentTypeList };
    }
    if (filterObj.talentLevels && filterObj.talentLevels.length && this._enableFilters.indexOf('talentLevels') > -1) {
      filterCondition['talentLevels'] = { inq: filterObj.talentLevels };
    }
    if (filterObj.talentTypesName && filterObj.talentTypesName.length && this._enableFilters.indexOf('talentTypesName') > -1) {
      filterCondition['talentTypesName'] = { inq: filterObj.talentTypesName };
    }
    if (filterObj.workerEmail && filterObj.workerEmail.length && this._enableFilters.indexOf('workerEmail') > -1) {
      filterCondition['workerEmail'] = { inq: filterObj.workerEmail };
    }
    if (filterObj.isVendorApplied && filterObj.isVendorApplied.length && this._enableFilters.indexOf('isVendorApplied') > -1) {
      filterCondition['isVendorApplied'] = filterObj.isVendorApplied;
    }
    if (filterObj.nptProgram && filterObj.nptProgram.length && this._enableFilters.indexOf('nptProgram') > -1) {
      filterCondition['NPT_program__c'] = { inq: filterObj.nptProgram };
    }
    if (filterObj.custReqDate && filterObj.custReqDate[0] && this._enableFilters.indexOf("custReqDate") > -1) {
      const startDate = filterObj.custReqDate[0];
      filterCondition["Customer_Appointment_Schedule_Start_Date__c"] = {
        between: [startDate[0], startDate[1]],
      };
    }
    if (filterObj.passiveJob && filterObj.passiveJob.length && this._enableFilters.indexOf('passiveJob') > -1) {
      filterCondition['passiveJob'] = { inq: filterObj.passiveJob };
    }
    if (filterObj.smsType && filterObj.smsType.length && this._enableFilters.indexOf("smsType") > -1) {
      filterCondition["messageName"] = { inq: filterObj.smsType };
    }    
    if (filterObj.smsStatus && filterObj.smsStatus.length && this._enableFilters.indexOf("smsStatus") > -1) {
      filterCondition["MessageStatus"] = { inq: filterObj.smsStatus };
    }
    if (filterObj.messageScheduledDate && filterObj.messageScheduledDate[0] && this._enableFilters.indexOf("messageScheduledDate") > -1) {
      const startDate = filterObj.messageScheduledDate[0];
      filterCondition["messageScheduledDate"] = {
        between: [startDate[0], startDate[1]],
      };
    }
    if ( filterObj.messageSentTo && this._enableFilters.indexOf("messageSentTo") > -1 ) {
      filterCondition["MessageSentTo"] = { inq: filterObj.messageSentTo };
    }
    if (filterObj.messageSentFrom && this._enableFilters.indexOf("messageSentFrom") > -1) {
      filterCondition["MessageSentFrom"] = filterObj.messageSentFrom
    }
    if (filterObj.messageCommunity && filterObj.messageCommunity.length && this._enableFilters.indexOf("messageCommunity") > -1) {
      filterCondition["MessageCommunity"] = {inq: filterObj.messageCommunity};
    }
    // set local storage for mantain search filter on page changed
    this._commonService.setGlobalFilter(filterObj);
    // localStorage.setItem('filterCondition', JSON.stringify(filterCondition));
    // applied search hit api
    this.searchGlobalFilter.emit(filterCondition);
  }

  /**
   *
   * @param fields
   * return total applied filter
   */
  setTotalFilterCount() {
    this.totalFilterCount = 0;
    const savedFilterLookups = JSON.parse(localStorage.getItem('filterObj'));
    if (savedFilterLookups && Object.keys(savedFilterLookups).length) {
      if (this._enableFilters && this._enableFilters.length) {
        this._enableFilters.forEach((f) => {
          if (f === 'workerBillingTypes' && savedFilterLookups[f] && savedFilterLookups[f]['selectedCount']) {
            this.totalFilterCount += savedFilterLookups[f]['selectedCount'];
          } else {
            this.totalFilterCount += savedFilterLookups[f] && savedFilterLookups[f].length ? (isArray(savedFilterLookups[f]) ? savedFilterLookups[f].length : 1) : 0;
          }
        });
      }
    }
    if (this.selectedHelpDeskType && !this.filterObj.projectTypes && this.selectedHelpDeskType.length && this.currentPage != 'teamMembers') {
      this.totalFilterCount += this.selectedHelpDeskType.length;
    }
    if (this.defaultSelectedAccountCommunity && !this.filterObj.accountCommunities &&
      this.defaultSelectedAccountCommunity.length && this.currentPage != 'teamMembers') {
      this.totalFilterCount += this.defaultSelectedAccountCommunity.length;
    }
  }

  /**
   * to close main component
   * global filters
   */
  closeNav() {
    this.closeFilter.emit();
  }

  /**
   *
   * @param account | pass selected vendor's account
   *  field : Vendor__c
   */
  setVendorAccountFilter(event) {
    this.filterObj["vendorAccounts"] = event;
    this.setVendorAccountObj = event;
  }

  setContactAccountFilter(event) {
    this.filterObj["contactAccounts"] = event;
    this.setContactAccountObj = event;
  }

  /**
   *  Set DocumentCategory Filter
   *  @param event 
   */
  setDocumentCategoryFilter(event) {
    this.filterObj["documentCategory"] = event;
    this.setDocumentCategoryObj = event;
  }

  /**
   *  Set PageViewGroup Filter
   *  @param event 
   */
   setPageViewGroupFilter(event) {
    this.filterObj["pageViewGroup"] = event;
    this.setPageViewGroupObj = event;
  }


  /**
   *
   * @param event | pass selected account
   * field : CKSW_BASE__Account__c
   */
  setPartnerAccountFilter(event) {
    this.filterObj["accounts"] = event;
    this.setProgramObj = event;
    this.setJobsiteAccountObj = event;
    // if (event && event.length === 0) {
    if (this.initialLoad) {
      this.initialLoad = !this.initialLoad;
    } else {
      this.filterObj["jobSites"] = [];
      this.filterObj["programs"] = [];
    }

    // check if we have to show customer filter to display or not
    // show customer filter only if microsoft is selected in partner account
    this.filterObj["customers"] = [];
    if (event && event.length && event.includes(environment.microsoftAccountId ? environment.microsoftAccountId : '0011a000005AHhxAAG')) {
      if (!this._enableFilters.includes('customers') && this.enableCustomerFilter) {
        this._enableFilters.push('customers');
      }
    } else {
      if (this._enableFilters.includes('customers')) {
        this._enableFilters.splice(this._enableFilters.indexOf('customers'), 1);
      }
    }
    this._cd.detectChanges();
    // }
  }

  /**
   *
   * @param event | pass selected account
   * field : CKSW_BASE__Account__c
   */
  setTaskAccountFilter(event) {
    if (this.selectedHelpDeskType === undefined) {
      this.filterObj['projects'] = [];
      this.setProjectObj = {};
      this.setProjectObj["Account__c"] = event;
     }
    this.filterObj["taskAccounts"] = event;
    this.setMilestoneObj = {};
    this.setTaskObj = {};
  //  this.filterObj["projects"] = [];
    this.filterObj["milestones"] = [];
    this.filterObj["tasks"] = [];
    // if (event && event.length === 0) {
    // // if (event) {
    //   this.setProjectObj["Account__c"] = event;
    //   }
    this.setMilestoneObj["Account__c"] = event;
    this.setTaskObj["Account__c"] = event;
    // } else {
    //   this.setProjectObj['Account__c'] = [];
    //   this.setMilestoneObj['Account__c'] = [];
    //   this.setTaskObj['Account__c'] = [];
    // }
    // }
  }

  /**
   *
   * @param event | pass selected program
   * field : Project_SOP__c
   */
  setProgramFilter(event) {
    this.filterObj["programs"] = event;
    this.setJobsiteProgramObj = event;
    // if (event && event.length === 0) {
    this.filterObj["jobSites"] = [];
    // }
  }

  /**
   *
   * @param jobsite | pass selected jobsite
   * field : Jobsite__c
   */
  setJobsiteFilter(event) {
    this.filterObj["jobSites"] = event;
    // this.setJobsiteObj = event;
  }

  /**
   *
   * @param jobIds | pass selected jobids
   * field : Iron_Job_num__c
   */

  setJobIdsFilter(event) {
    this.filterObj["jobs"] = event;
  }
 
 /**
   *
   * @param jobIds | pass selected jobids
   * field : sfdcId
   */

  setJobIdsfdcIdFilter(event) {
    this.filterObj["jobsfdcIds"] = event;
  }

  /**
   *
   * @param event | pass selected stages
   * field : Dispatch_Service_Resolution_Status__c
   */
  setStageStatusesFilter(event) {
    this.filterObj["statgeStatus"] = event;
  }

  /**
   *
   * @param jobTypes | pass selected jobTypes
   * field : icc_recordtype_id
   */
  setJobTypeFilter(event) {
    this.filterObj["jobTypes"] =
      event && event["jobTypes"] ? event["jobTypes"] : [];
    this.filterObj["recordTypeIds"] =
      event && event["recordTypeIds"] ? event["recordTypeIds"] : [];
  }

  /**
   *
   * @param caseTypes | pass selected caseTypes
   * field : RecordTypeId
   */
  setCaseTypeFilter(event) {
    this.filterObj["caseTypes"] =
      event && event["caseTypes"] ? event["caseTypes"] : [];
    this.filterObj["pmsRecordTypeIds"] =
      event && event["pmsRecordTypeIds"] ? event["pmsRecordTypeIds"] : [];
  }

  /**
   *
   * @param event | pass selected geometro
   * field : Jobsite__c
   */
  setGeoMetroFilter(event) {
    this.filterObj["geoMetro"] =
      event && event["geoMetro"] ? event["geoMetro"] : [];
    this.filterObj["geoMetroJobSite"] =
      event && event["geoMetroJobSite"] ? event["geoMetroJobSite"] : [];
  }

  /**
   *
   * @param event | pass selected locations
   * field : ts2_Location__c
   */
  setLocationFilter(event) {
    this.filterObj["locations"] = event;
  }

  /**
   *
   * @param event
   * field : PMS_Case_Status
   */
  setPMSCaseStatusFilter(event) {
    if(event.includes('All')){
      this.filterObj["pmsCaseStatus"] = [];
    }
    else {
    this.filterObj["pmsCaseStatus"] = event;
    }
  }
  /**
   *
   * @param event
   * field : Service_Dispatch_SLA_Priority__c
   */
  setPriorityFilter(event) {
    this.filterObj["priority"] = event;
  }

  /**
   *
   * @param event | pass selected tcr
   * field : Name
   */

   setTimeChangeRequestLookup(event) {
    this.filterObj["Tcr"] = event;
  }

  /**
   *
   * @param event
   * field : RecordTypeId
   */
  setCaseCategoryFilter(event) {
    this.filterObj["caseCategory"] = event;
  }

  /**
   *
   * @param event
   * field : Talent_Type__c
   */
  setTalentTypeFilter(event) {
    this.filterObj["talentTypes"] = event;
  }

  setWorkerTalentTypeFilter(event) {
    this.filterObj["workerTalentTypes"] = event;
  }

  /**
   *
   * @param event
   * field : Dispatch_Worker_Name__c
   */

  setWorkerFilter(event) {
    this.filterObj["workerNames"] = event;
  }

  /**
 *
 * @param event
 * field : Dispatch_Worker_Name__c
 */

  setWorkerIdFilter(event) {
    this.filterObj["workerIds"] = event;
  }

  /**
   *
   * @param event
   * field : Vendorsite__c
   */

  setVendorsiteFilter(event) {
    this.filterObj["vendorSites"] = event;
    this.setVendorsiteObj = event;
  }
  /**
   *
   * @param event
   * field: customerScheduled
   */
  setCustomerScheduledFilter(selectedRange) {
    // date is an array, from date
    if (selectedRange && selectedRange.length) {
      let _dateFrom =
        selectedRange && selectedRange[0] ? selectedRange[0].setSeconds(0) : "";
      let _dateTo = "";
      if (selectedRange && selectedRange[1]) {
        selectedRange[1].setHours(23);
        selectedRange[1].setMinutes(59);
        selectedRange[1].setSeconds(59);
        _dateTo = selectedRange[1].getTime();
      }
      if (_dateFrom && _dateTo) {
        const timeZoneDate = this._commonService.getDateInterval(
          _dateFrom,
          _dateTo,
          "",
          "YYYY-MM-DDTHH:mm:ss"
        );
        let dateFrom = timeZoneDate["start"];
        let dateTo = timeZoneDate["end"];
        this.filterObj['customerScheduled'] = [{ 0: dateFrom, 1: dateTo }];
      }
    } else if (this._enableFilters.length && (this._enableFilters.indexOf('customerScheduled') !== -1)) {
      this.filterObj['customerScheduled'] = [];
    }
  }

  /**
   *
   * @param event
   * field: CreatedDate
   */
  setCreatedDateFilter(selectedRange, type, from?, cb?) {
    // date is an array, from date
    if (selectedRange && selectedRange.length) {
      // logic for the same day
      // if (!(selectedRange[0] - selectedRange[1])) {
      //   selectedRange[1].setHours(23, 59, 59);
      // }
      let _dateFrom =
        selectedRange && selectedRange[0] ? selectedRange[0].setSeconds(0) : "";
      let _dateTo = "";
      if (selectedRange && selectedRange[1]) {
        selectedRange[1].setHours(23);
        selectedRange[1].setMinutes(59);
        selectedRange[1].setSeconds(59);
        _dateTo = selectedRange[1].getTime();
      }
      if (_dateFrom && _dateTo) {
        const timeZoneDate = this._commonService.getDateInterval(
          _dateFrom,
          _dateTo,
          "",
          "YYYY-MM-DDTHH:mm:ssZZ"
        );
        let dateFrom = timeZoneDate["start"];
        let dateTo = timeZoneDate["end"];
          // in case of reset, date will be an empty array
        if (this._enableFilters.length && (this._enableFilters.indexOf('startFromDateOptions') !== -1)
          && type === 'startFromDate') {
          dateFrom = dateFrom ? dateFrom.substring(0, 10) : '';
          dateTo = dateTo ? dateTo.substring(0, 10) : '';
          if (dateFrom && dateTo) {
            this.filterObj['startFromDateOptions'] = [{ 0: dateFrom, 1: dateTo }];
          } else if (dateFrom) {
            this.filterObj['startFromDateOptions'] = [{ 0: dateFrom }];
          } else if (dateTo) {
            this.filterObj['startFromDateOptions'] = [{ 1: dateTo }];
          }
        } else if (this._enableFilters.length && (this._enableFilters.indexOf('jobCreatedDate') !== -1) && type === 'jobCreatedDate') {
            if (dateFrom && dateTo) {
          this.filterObj['jobCreatedDate'] = [{ 0: dateFrom, 1: dateTo }];
          }
        } else if (this._enableFilters.length && (this._enableFilters.indexOf('messageScheduledDate') !== -1) && type === 'messageScheduledDate') {
          if (dateFrom && dateTo) {
            this.filterObj['messageScheduledDate'] = [{ 0: dateFrom, 1: dateTo }];
          } 
        }
         else {
          if (dateFrom && dateTo) {
            if (type === 'closedDate') {
              this.filterObj['dateClosed'] = [{ 0: dateFrom, 1: dateTo }];
            } else {
              this.filterObj['dateOptions'] = [{ 0: dateFrom, 1: dateTo }];
            }
          } else if (dateFrom) {
            this.filterObj['dateOptions'] = [{ 0: dateFrom }];
          } else if (dateTo) {
            this.filterObj['dateOptions'] = [{ 1: dateTo }];
          }
        }
      }
      if (cb) {
        cb();
      }
    } else {
      if (this._enableFilters.length && (this._enableFilters.indexOf('startFromDateOptions') !== -1)
        && type === 'startFromDate') {
        this.filterObj['startFromDateOptions'] = [];
      } else if(this._enableFilters.length && (this._enableFilters.indexOf('jobCreatedDate') !== -1) && type === 'jobCreatedDate') {
        this.filterObj['jobCreatedDate'] = [];
      } else if (this._enableFilters.length && (this._enableFilters.indexOf('messageScheduledDate') !== -1) && type === 'messageScheduledDate') {
        this.filterObj['messageScheduledDate'] = [];
      }
       else {
        if (type === 'closedDate') {
          this.filterObj['dateClosed'] = [];
        } else {
          this.filterObj['dateOptions'] = [];
        }
      }
      if (cb) {
        cb();
      }
    }
  }

  /**
   *
   * @param event
   * field: WorkerScheduled
   */
  setWorkerScheduledFilter(selectedRange, from?, cb?) {
    // date is an array, from date
    if (selectedRange && selectedRange.length) {
      // logic for the same day
      // if (!(selectedRange[0] - selectedRange[1])) {
      //   selectedRange[1].setHours(23, 59, 59);
      // }
      let _dateFrom =
        selectedRange && selectedRange[0] ? selectedRange[0].setSeconds(0) : "";
      let _dateTo = "";
      if (selectedRange && selectedRange[1]) {
        selectedRange[1].setHours(23);
        selectedRange[1].setMinutes(59);
        selectedRange[1].setSeconds(59);
        _dateTo = selectedRange[1].getTime();
      }
      if (_dateFrom && _dateTo) {
        const timeZoneDate = this._commonService.getDateInterval(
          _dateFrom,
          _dateTo,
          "",
          "YYYY-MM-DDTHH:mm:ssZZ"
        );
        let dateFrom = timeZoneDate["start"];
        let dateTo = timeZoneDate["end"];
        if (dateFrom && dateTo) {
          this.filterObj['workerScheduled'] = [{ 0: dateFrom, 1: dateTo }];
        } else if (dateFrom) {
          this.filterObj['workerScheduled'] = [{ 0: dateFrom }];
        } else if (dateTo) {
          this.filterObj['workerScheduled'] = [{ 1: dateTo }];
        }
      }
      if (cb) {
        cb();
      }
    } else {
      this.filterObj['workerScheduled'] = [];
      if (cb) {
        cb();
      }
    }
  }

  /**
   *
   * @param event
   * field: CustomerRequestedDate
   */
  setCustomerRequestedFilter(selectedRange, cb?) {
    if (selectedRange && selectedRange.length) {
      let _dateFrom =
        selectedRange && selectedRange[0] ? selectedRange[0].setSeconds(0) : "";
      let _dateTo = "";
      if (selectedRange && selectedRange[1]) {
        selectedRange[1].setHours(23);
        selectedRange[1].setMinutes(59);
        selectedRange[1].setSeconds(59);
        _dateTo = selectedRange[1].getTime();
      }
      if (_dateFrom && _dateTo) {
        const timeZoneDate = this._commonService.getDateInterval(
          _dateFrom,
          _dateTo,
          "",
          "YYYY-MM-DDTHH:mm:ssZZ"
        );
        let dateFrom = timeZoneDate["start"];
        let dateTo = timeZoneDate["end"];
        dateFrom = dateFrom ? dateFrom.substring(0, 10) : '';
        dateTo = dateTo ? dateTo.substring(0, 10) : '';
        // in case of reset, date will be an empty array
        if (dateFrom && dateTo) {
          this.filterObj["customerDateOptions"] = [{ 0: dateFrom, 1: dateTo }];
        } else if (dateFrom) {
          this.filterObj["customerDateOptions"] = [{ 0: dateFrom }];
        } else if (dateTo) {
          this.filterObj["customerDateOptions"] = [{ 1: dateTo }];
        }
      }
      if (cb) {
        cb();
      }
    } else {
      this.filterObj["customerDateOptions"] = [];
      if (cb) {
        cb();
      }
    }
  }
   /**
   *
   * @param event
   * field: CustomerRequestedDate
   */
    setWorkOrderRangeFilter(selectedRange, cb?) {
      if (selectedRange && selectedRange.length) {
        // logic for the same day
        // if (!(selectedRange[0] - selectedRange[1])) {
        //   selectedRange[1].setHours(23, 59, 59);
        // }
        let _dateFrom =
          selectedRange && selectedRange[0] ? selectedRange[0].setSeconds(0) : "";
        let _dateTo = "";
        if (selectedRange && selectedRange[1]) {
          selectedRange[1].setHours(23);
          selectedRange[1].setMinutes(59);
          selectedRange[1].setSeconds(59);
          _dateTo = selectedRange[1].getTime();
        }
        if (_dateFrom && _dateTo) {
          const timeZoneDate = this._commonService.getDateInterval(
            _dateFrom,
            _dateTo,
            "",
            "YYYY-MM-DDTHH:mm:ssZZ"
          );
          let dateFrom = timeZoneDate["start"];
          let dateTo = timeZoneDate["end"];
            // in case of reset, date will be an empty array
          if (this._enableFilters.length && (this._enableFilters.indexOf('workOrderDateOptions') !== -1)) {
            dateFrom = dateFrom ? dateFrom.substring(0, 10) : '';
            dateTo = dateTo ? dateTo.substring(0, 10) : '';
            if (dateFrom && dateTo) {
              this.filterObj['workOrderDateOptions'] = [{ 0: dateFrom, 1: dateTo }];
            } else if (dateFrom) {
              this.filterObj['workOrderDateOptions'] = [{ 0: dateFrom }];
            } else if (dateTo) {
              this.filterObj['workOrderDateOptions'] = [{ 1: dateTo }];
            }
          }
        }
      } else {
        this.filterObj["workOrderDateOptions"] = [];
        if (cb) {
          cb();
        }
      }
    }

      /**
  *
  * @param event
  * field: CustomerRequestedDate Filter Under PDMC
  */
       setCustomerRequestedDateFilter(selectedRange, cb?) {
        if (selectedRange && selectedRange.length) {
          // logic for the same day
          // if (!(selectedRange[0] - selectedRange[1])) {
          //   selectedRange[1].setHours(23, 59, 59);
          // }
          let _dateFrom =
            selectedRange && selectedRange[0] ? selectedRange[0].setSeconds(0) : "";
          let _dateTo = "";
          if (selectedRange && selectedRange[1]) {
            selectedRange[1].setHours(23);
            selectedRange[1].setMinutes(59);
            selectedRange[1].setSeconds(59);
            _dateTo = selectedRange[1].getTime();
          }
          if (_dateFrom && _dateTo) {
            const timeZoneDate = this._commonService.getDateInterval(
              _dateFrom,
              _dateTo,
              "",
              "YYYY-MM-DDTHH:mm:ssZZ"
            );
            let dateFrom = timeZoneDate["start"];
            let dateTo = timeZoneDate["end"];
            // in case of reset, date will be an empty array
            if (this._enableFilters.length && (this._enableFilters.indexOf('custReqDate') !== -1)) {
              dateFrom = dateFrom ? dateFrom.substring(0, 10) : '';
              dateTo = dateTo ? dateTo.substring(0, 10) : '';
              if (dateFrom && dateTo) {
                this.filterObj['custReqDate'] = [{ 0: dateFrom, 1: dateTo }];
              } else if (dateFrom) {
                this.filterObj['custReqDate'] = [{ 0: dateFrom }];
              } else if (dateTo) {
                this.filterObj['custReqDate'] = [{ 1: dateTo }];
              }
            }
          }
        } else {
          this.filterObj["custReqDate"] = [];
          if (cb) {
            cb();
          }
        }
      }

  setPMSCaseNumIdsFilter(event) {
    this.filterObj["pmsCaseNumbers"] = event;
  }

  setPMCCaseNumIdsFilter(event) {
    this.filterObj["pmcCaseNumbers"] = event;
  }

  /**
   *
   * @param event | team shift will be goes with worker
   * field: Dispatch_Worker_Name__c
   */
  setTeamShiftFilter(event) {
    this.filterObj["teamShifts"] =
      event && event["teamShifts"] ? event["teamShifts"] : [];
    this.filterObj["teamShiftUsers"] =
      event && event["teamShiftIccCaseNumberList"]
        ? event["teamShiftIccCaseNumberList"]
        : [];
  }

  setJobStatusFilter(event) {
    this.filterObj["jobStatus"] = event;
  }

  /**
   *
   * @param event
   * field : escalationType
   */
  setEscalationTypeObj(event) {
    this.filterObj["escalationType"] = event;
  }

  /**
   *
   * @param event
   * field : employeeType
   */
  setEmployeeTypeObj(event) {
    this.filterObj["employeeType"] = event;
  }

  /**
   *
   * @param event
   * field : project
   */
  setProjectFilter(event) {
    if(event && event.length){
      this.hasProjectSelected = true;
    } else {
      this.hasProjectSelected = false;
    }
    this.filterObj["milestones"] = [];
    this.filterObj["tasks"] = [];
    this.setTaskObj = {};
    this.setMilestoneObj = {};
    // if (event) {
    this.filterObj["projects"] = event;
    this.setMilestoneObj["PgMO_Projects__c"] = event;
    this.setTaskObj["PgMO_Projects__c"] = event;
    // } else {
    //   this.filterObj['projects'] = [];
    //   this.setMilestoneObj = [];
    //   this.setTaskObj['PgMO_Projects__c'] = [];
    // }
  }
  /**
   *
   * @param event
   * field : project-type
   */
  setProjectTypeFilter(event) {
    this.filterObj["projectTypes"] = [];
    this.filterObj["projects"] = [];
    this.filterObj["milestones"] = [];
    this.filterObj["tasks"] = [];
    this.setProjectObj = {};
    this.setTaskObj = {};
    this.setMilestoneObj = {};
    if (event) {
      this.filterObj["projectTypes"] = event;
      this.setProjectObj["Project_Type__c"] = event;
      this.setProjectConsoleType = event;
    } else {
      this.filterObj["projectTypes"] = [];
      this.setProjectObj["Project_Type__c"] = [];
      this.setProjectConsoleType = [];
    }
  }


  setTeamProjectTypeFilter(event) {
    this.filterObj["teamProjectTypes"] = [];
    if (event) {
      this.filterObj["teamProjectTypes"] = event;
    }
  }

  /**
   *
   * @param event
   * field : milestone
   */
  setMilestoneFilter(event) {
    this.filterObj["tasks"] = [];
    this.setTaskObj = {};
    if (event) {
      this.filterObj["milestones"] = event;
      this.setTaskObj["PgMO_Milestones__c"] = event;
    } else {
      this.filterObj["milestones"] = [];
      this.setTaskObj["PgMO_Milestones__c"] = [];
    }
  }

    /**
   *
   * @param event
   * field : milestoneTask
   */
     setMilestoneTaskFilter(event) {
      this.filterObj["milestoneTask"] = [];
      if (event && event.length) {
        if (this.setProjectConsoleType && this.setProjectConsoleType.length && this.setProjectConsoleType[0] === "ProjectManager" || this.setProjectConsoleType[0] === "TaskCase") {
          this.filterObj["milestoneTask"] = [event];
        } else {
          this.filterObj["milestoneTask"] = event;
        }
      } else {
        this.filterObj["milestoneTask"] = [];
      }
    }
      /**
   *
   * @param event
   * field : milestoneTask
   */
       setFormNameFilter(event) {
        this.filterObj["formName"] = [];
        if (event) {
          this.filterObj["formName"] = event;
        } else {
          this.filterObj["formName"] = [];
      }
    }


  /**
  *
  * @param event
  * field : workOrder
  */
  setWorkOrderFilter(event) {
    this.filterObj["workOrderCaseIds"] = [];
    if (event) {
      this.filterObj["workOrderCaseIds"] = event;
    } else {
      this.filterObj["workOrderCaseIds"] = [];
    }
  }

  /**
   *
   * @param event
   * field : task
   */
  setTaskFilter(event) {
    if (event) {
      this.filterObj["tasks"] = event;
    } else {
      this.filterObj["tasks"] = [];
    }
  }

  // SET TASK PRIORITY
  setTaskPriorityFilter(event) {
    if (event) {
      this.filterObj['taskPriorities'] = event;
    } else {
      this.filterObj['taskPriorities'] = [];
    }
  }


  setTaskStatusFilter(event) {
    if (event) {
      this.filterObj['taskStatuses'] = event;
    } else {
      this.filterObj['taskStatuses'] = [];
    }
  }

  setTaskRequestorFilter(event) {
    if (event) {
      this.filterObj['taskRequestors'] = event;
    } else {
      this.filterObj['taskRequestors'] = [];
    }
    // this._enablebusinessRequestorFiter(event);
  }

  setTaskTypeFilter(event) {
    if (event) {
      this.filterObj['taskTypes'] = event;
    } else {
      this.filterObj['taskTypes'] = [];
    }
  }

  setIronApprovalStatusesFilter(event) {
    if (event) {
      this.filterObj["ironApprovalStatuses"] = event;
    } else {
      this.filterObj["ironApprovalStatuses"] = [];
    }
  }

  setCustomerApprovalStatusesFilter(event) {
    if (event) {
      this.filterObj["customerApprovalStatuses"] = event;
    } else {
      this.filterObj["customerApprovalStatuses"] = [];
    }
  }

  setActualCoverageHoursFilter(event) {
    if (event) {
      this.filterObj["actualCoverageHours"] = event;
    } else {
      this.filterObj["actualCoverageHours"] = [];
    }
  }

  setCoverageHoursFilter(event) {
    if (event) {
      this.filterObj["coverageHours"] = event;
    } else {
      this.filterObj["coverageHours"] = [];
    }
  }

  setActualEndTimeCoveragesFilter(event) {
    if (event) {
      this.filterObj["actualEndTimeCoverages"] = event;
    } else {
      this.filterObj["actualEndTimeCoverages"] = [];
    }
  }

  setActualCoverageOverallsFilter(event) {
    if (event) {
      this.filterObj["actualCoverageOveralls"] = event;
    } else {
      this.filterObj["actualCoverageOveralls"] = [];
    }
  }

  setWorkerBillingTypesFilter(event) {
    if (event && event.ids) {
      this.filterObj["workerBillingTypes"] = event;
    } else {
      this.filterObj["workerBillingTypes"] = [];
    }
  }

  setRegionsFilter(event) {
    if (event) {
      this.filterObj["regions"] = event;
    } else {
      this.filterObj["regions"] = [];
    }
    this.selectedRegionObj = event;
  }

  setCountriesFilter(event) {
    if (event) {
      this.filterObj["countries"] = event;
      this.serviceTerritoryObj["countries"] = event;
    } else {
      this.filterObj["countries"] = [];
      this.serviceTerritoryObj["countries"] = [];
    }
    this.selectedCountryObj = event;
  }

  setAccountCommunityFilter(event) {
    if (event) {
      this.filterObj['accountCommunities'] = event;
      this.selectedAccCommunityObj = event;
    } else {
      this.filterObj['accountCommunities'] = [];
      // this.defaultSelectedAccountCommunity.length = 0;
    }
  }

  setStatesFilter(event) {
    if (event) {
      this.filterObj["states"] = event;
      this.serviceTerritoryObj["states"] = event;

    } else {
      this.filterObj["states"] = [];
      this.serviceTerritoryObj["states"] = [];

    }
  }

  setStatesManagerFilter(event) {
    if (event) {
      this.filterObj["statesManager"] = event;
      this.serviceTerritoryObj["states"] = event;
      this.selectedStateManagerObj = event;

    } else {
      this.filterObj["statesManager"] = [];
      this.selectedStateManagerObj = [];
      this.serviceTerritoryObj["states"] = [];
    }
    this.serviceTerritoryObj = {...this.serviceTerritoryObj};
  }

  setServiceContractNumbersFilter(event) {
    if (event) {
      this.filterObj["serviceContractNumbers"] = event;
    } else {
      this.filterObj["serviceContractNumbers"] = [];
    }
  }

  setPurchaseOrderFilter(event) {
    if (event) {
      this.filterObj["purchaseOrder"] = event;
    } else {
      this.filterObj["purchaseOrder"] = [];
    }
  }

  setServiceContractCategoryFilter(event) {
    if (event) {
      this.filterObj['serviceContractCategory'] = event;
    } else {
      this.filterObj['serviceContractCategory'] = [];
    }
  }

  /**
   * This function sets the value coming from lookup to filterObj
   * @param event | Array of value
   */
  setpurchaseInvoiceStatusFilter(event) {
    this.filterObj["purchaseInvoiceStatus"] = event;
  }

  setsalesInvoiceStatusFilter(event) {
    this.filterObj["salesInvoiceStatus"] = event;
  }

  getSelectedDivision(event) {
    this.filterObj["divisions"] = event;
  }

  getSelectedDepartment(event) {
    this.filterObj["departments"] = event;
  }

  setBusinessUnitFilter(event) {
    this.filterObj["businessUnit"] = event;
  }

  setdeptRoleAccessFilter(event) {
    this.filterObj["departmentRoleAccesses"] = event;
  }

  setGroupTypeFilter(event) {
    this.filterObj['groupRecordTypes'] = event;
  }

  getSelectedExpenseType(event) {
    this.filterObj["expenseSubTypes"] = [];
    this.filterObj["expenseTypes"] = event instanceof Array ? event : [event];
  }

  setFileNameObj(event) {
    this.filterObj["fileNames"] = [];
    this.filterObj["fileNames"] = event instanceof Array ? event : [event];
  }

  getSelectedExpenseSubType(event) {
    this.filterObj["expenseSubTypes"] =
      event instanceof Array ? event : [event];
  }

  getSelectedPaymentType(event) {
    this.filterObj["paymentTypes"] = event;
  }

  setSelectedCustomer(event) {
    this.filterObj["customers"] =
      event instanceof Array ? event : [event];
  }

  // SET TEMPLATE NAME
  setTemplateNameFilter(event) {
    if (event) {
      this.filterObj['templateName'] = event;
    } else {
      this.filterObj['templateName'] = [];
    }
  }

  // SET TEMPLATE CATEGORY
  setTemplateCategoryFilter(event) {
    this.selectedCategoryValue = event;
    if (event) {
      this.filterObj['templateCategories'] = event;
    } else {
      this.filterObj['templateCategories'] = [];
    }
  }

  // SET TEMPLATE SUB-CATEGORY
  setTemplateSubCategoryFilter(event) {
    if (event) {
      this.filterObj['templateSubCategories'] = event;
    } else {
      this.filterObj['templateSubCategories'] = [];
    }
  }

  setSmsTypeFilter(event) {
    this.filterObj["smsType"] = event;
  }
  
  setSmsStatusFilter(event) {
    this.filterObj["smsStatus"] = event;
  }
  
  setMessageSentToFilter(event) {
    this.filterObj["messageSentTo"] = event;
  }
  setMessageSentFromFilter(event) {
    this.filterObj["messageSentFrom"] = event;
  }
  setCommnityFilterObj(event) {
    this.filterObj["messageCommunity"] = event;
  }
  /**`
   * reset all component data
   * reset filterConditions
   */
  resetData() {
    // if we don't have anything in enable filters we should not clear anything
    if (this._enableFilters.length <= 0) {
      return;
    }
    // for disabling customer filter on reset
    if (this._enableFilters.includes('customers')) {
      this._enableFilters.splice(this._enableFilters.indexOf('customers'), 1);
    }
    this.isClear = new Date().getTime();
    this.filterCondition = {};
    this.countSelected = {};
    this.setTaskObj = {};
    this.setMilestoneObj = {};
    this.totalFilterCount = this.accessType === 'partner' ? 1 : 0;
    this.filterObj = {
      countries: [],
      orderTypes: [],
      regions: [],
      accounts: this.accessType === 'partner' ? this.filterObj.accounts : [],
      vendorAccounts: [],
      contactAccounts: [],
      programs: [],
      projectTypes: this.disableProjectType ? this.filterObj.projectTypes : [],
      teamProjectTypes: [],
      projects: [],
      milestones: [],
      tasks: [],
      taskStatuses: [],
      taskRequestors: [],
      taskTypes: [],
      talentTypes: [],
      statgeStatus: [],
      jobs: [],
      jobSites: [],
      jobTypes: [],
      caseTypes: [],
      recordTypeIds: [],
      caseCategory: [],
      vendorSites: [],
      priority: [],
      jobStatus: [],
      geoMetro: [],
      geoMetroJobSite: [],
      workerNames: [],
      workerIds: [],
      dateOptions: [],
      jobCreatedDate: [],
      dateClosed: [],
      pmsCaseStatus: [],
      teamShifts: [],
      teamShiftUsers: [], //worker
      pmsCaseNumbers: [],
      pmcCaseNumbers: [],
      escalationType: [],
      accountCommunities: this._filterOptions['accountCommunities'] && this._filterOptions['accountCommunities']['disabled'] ? this.filterObj.accountCommunities : [],
      taskAccounts: [],
      employeeType: [],
      leaveStatusType: [],
      ironApprovalStatuses: [],
      customerApprovalStatuses: [],
      actualCoverageHours: [],
      coverageHours: [],
      actualEndTimeCoverages: [],
      actualCoverageOveralls: [],
      workerBillingTypes: [],
      purchaseOrder: [],
      serviceContractNumbers: [],
      serviceContractCategory: [],
      purchaseInvoiceStatus: [],
      divisions: [],
      departments: [],
      departmentRoleAccesses: [],
      pgmo_departments: [],
      expenseTypes: [],
      expenseSubTypes: [],
      paymentTypes: [],
      contractCategory: [],
      states: [],
      statesManager: [],
      activityType: [],
      workerType: [],
      workerTypeSub: [],
      technicalLevels: [],
      taskPriorities: [],
      users: [],
      teams: [],
      teamTypes: [],
      dateFrom: [],
      dateTo: [],
      startFromDateOptions: [],
      customerDateOptions: [],
      workOrderDateOptions: [],
      customers: [],
      subCategories: [],
      groupRecordTypes: [],
      workOrderCaseIds: [],
      category: [],
      isBillable: [],
      taskLibraries: [],
      milestoneLibraries: [],
      tmpltLibraryTypes: [],
      templateName: [],
      templateCategories: [],
      templateSubCategories: [],
      apvpGroup: [],
      communityType: [],
      slaGroup: [],
      wslAccountStatus: [],
      wslAccounts: [],
      address: [],
      workerStatus: [],
      radius: [],
      driving_distance: [],
      salary: [],
      gtpResourceType: [],
      gtpPoolAccessType: [],
      gtpMemberType: [],
      gtpCommunityType: [],
      backFillWorkerService: [],
      displayCoverageType: [],
      vendorSubTypeCommunity: [],
      covrageHoursAccountType: [],
      slaAccountType: [],
      displayVendorsiteType: [],
      geoCode: [],
      city: [],
      vendorStatusType: [],
      mvvpDisplayType: [],
      serviceTerritory: [],
      candidateType: [],
      workerTalentTypes: [],
      slaLabourCloudType: [],
      workerScheduled: [],
      coverHoursMvvp: [],
      problemTaskStatuses: [],
      jobOrderStatus: [],
      jobOrderStage: [],
      jobOrderPriority: [],
      jobOrders: [],
      applications: [],
      userTypeId: [],
      pageName: [],
      isTestuser: [],
      conversationSource: [],
      includeTestAccount: [],
      isServiceoLogin: [],
      uniqueUser: [],
      isActiveUser: [],
      targetCost: [],
      member: [],
      locations: [],
      cityList:[],
      statesManagerJobOrder:[],
      jobTitles: [],
      applicationID: [],
      appStatus: [],
      candidateCurrentSalary: [],
      candidatePayRateRequested: [],
      noticePeriod: [],
      userIdLookup:[],
      messageType: [],
      employeeTypeList: [],
      timeChangeRequestStatus:[],
      chatBoardbacklogTodo: [],
      jobInvoiceTypes: [],
      salesInvoiceStatus: [],
      documentCategory: [],
      workerTypePB: [],
      customerScheduled: [],
      pageViewGroup:[],
      objectiveType: [],
      objectiveStatus: [],
      period: [],
      objective: [],
      keyResult: [],
      initiative: [],
      completedStatus: [],
      requirementType: [],
      employmentFilter: [],
      jobCreationReason: [],
      approvalStatus: [],
      jobAssigned: [],
      jobPostingType: [],
      overallStatus: [],
      checkInStatus:[],
      checkInFrequency:[],
      stageStatus: [],
      projectManagers: [],
      showOnlyJobType: [],
      fileNames: [],
      excludeMagicLink:[],
      businessRequestor: [],
      jobsfdcIds: [],
      milestoneTask: [],
      formName : [],
      recruitmentManager: [],
      recruiter: [],
      pcUserCount: [],
      pageType: [],
      weeklyDateOption: [],
      businessUnit: [],
      interviewFeedback: [],
      job: [],
      interviewRound: [],
      singleDate: [],
      communityTypeRmc: [],
      releaseNotesTypes:[],
      releaseNotesVersions:[],
      priceListCoverageHours: [],
      priceListTechnicalLevel: [],
      priceListSLA: [],
      servicetalentTypes: [],
      projectStatus: [],
      serviceDispatchPriceListSLA: [],
      Tcr: [],
      talentTypeList: [],
      talentLevel:[],
      talentTypesName:[],
      workerEmail:[],
      isVendorApplied:[],
      nptProgram: [],
      custReqDate: [],
      passiveJob:[],
      smsType:[],
      smsStatus:[],
      messageScheduledDate:[],
      messageSentTo:'',
      messageSentFrom: '',
      messageCommunity:[]
    }
  }

  /**
   *
   * @param event
   * field : Activity_Type__c
   */
  setActivityTypeFilter(event) {
    this.filterObj["activityType"] = event;
  }

  /**
    *
    * @param event
    * field : workerType
    */
  setWorkerTypeFilter(event) {
    if (event) {
      this.filterObj["workerType"] = event;
    } else {
      this.filterObj["workerType"] = [];
    }
  }

    /**
    *
    * @param event
    * field : workerTypeSub
    */
     setWorkerTypeSubFilter(event) {
      if (event) {
        this.filterObj["workerTypeSub"] = event;
      } else {
        this.filterObj["workerTypeSub"] = [];
      }
    }

    
  setEmployeeTypeListLookup(event) {
    this.filterObj['employeeTypeList'] = event instanceof Array ? event : [event];
  }

  setWorkerTypePBListLookup(event) {
    this.filterObj['workerTypePB'] = event instanceof Array ? event : [event];
  }




  /**
    *
    * @param event
    * field : technicalLevels
    */
  setTechnicalLevelFilter(event) {
    if (event) {
      this.filterObj["technicalLevels"] = event;
    } else {
      this.filterObj["technicalLevels"] = [];
    }
  }

  /**
  *
  * @param event
  * field : Project Status
  */
  setProjectStatusFilter(event) {
    if (event) {
      this.filterObj["projectStatus"] = event;
    } else {
      this.filterObj["projectStatus"] = [];
    }
  }

  /**
  *
  * @param event
  * field : Service_Dispatch_SLA_Priority__c
  */
  setServiceDispatchSLAPriceList(event) {
    this.filterObj['serviceDispatchPriceListSLA'] = event;
  }

  /**
  * 
  * @param event 
  * field : worker__C
  */
  setUserFilter(event) {
    this.filterObj['users'] = event;
  }
  /**
  * 
  * @param event 
  * field : PgMO_Groups__c
  */
  setTeamFilter(event) {
    this.filterObj['teams'] = event;
  }
  /**
  * 
  * @param event 
  * field : PgMO_Groups__c
  */
  setTeamTypeFilter(event) {
    this.filterObj['teamTypes'] = event;
  }
  /**
  * 
  * @param event 
  * field : PgMO_Groups__c
  */
  // setDateFromFilter(event) {
  //   this.filterObj['Start_Date__c'] = event;
  // }
  setDateFromFilter(selectedDate, cb?) {
    this.DateSelected = 'DateFrom';
    // date is an array, from date
    if (selectedDate) {
      // logic for the same day
      // if (!(selectedRange[0] - selectedRange[1])) {
      //   selectedRange[1].setHours(23, 59, 59);
      // }
      const _dateFrom = (selectedDate)
        ? selectedDate.setSeconds(0)
        : '';
      if (_dateFrom) {
        const timeZoneDate = this._commonService.getDateInterval(_dateFrom, '', '', 'YYYY-MM-DDTHH:mm:ssZZ');
        const dateFrom = timeZoneDate['start'];
        if (dateFrom) {
          this.filterObj['dateFrom'] = [{ 0: dateFrom }];
        }
      }
    } else {
      this.filterObj['dateFrom'] = [];
      if (cb) {
        cb()
      }
    }
  }

  setDateToFilter(selectedDate, cb?) {
    this.DateSelected = 'DateTo';
    // date is an array, from date
    if (selectedDate) {
      const _dateTo = (selectedDate)
        ? selectedDate.setSeconds(0)
        : '';
      if (_dateTo) {
        const timeZoneDate = this._commonService.getDateInterval(_dateTo, '', '', 'YYYY-MM-DDTHH:mm:ssZZ');
        const dateTo = timeZoneDate['end'];
        if (dateTo) {
          this.filterObj['dateTo'] = [{ 0: dateTo }];
        }
      }
    } else {
      this.filterObj['dateTo'] = [];
      if (cb) {
        cb()
      }
    }
  }

  setSingleDateFilter(selectedDate, cb?) {
    this.DateSelected = 'SingleDate';
    // date is an array, from date
    if (selectedDate) {
      // logic for the same day
      // if (!(selectedRange[0] - selectedRange[1])) {
      //   selectedRange[1].setHours(23, 59, 59);
      // }
      const _dateFrom = (selectedDate)
        ? selectedDate.setSeconds(0)
        : '';
      if (_dateFrom) {
        const timeZoneDate = this._commonService.getDateInterval(_dateFrom, '', '', 'YYYY-MM-DDTHH:mm:ssZZ');
        const singleDate = timeZoneDate['start'];
        if (singleDate) {
          this.filterObj['singleDate'] = [{ 0: singleDate }];
        }
      }
    } else {
      this.filterObj['singleDate'] = [];
      if (cb) {
        cb()
      }
    }
  }

  setSubCategoryFilter(event) {
    if (event) {
      this.filterObj['subCategories'] = event;
    } else {
      this.filterObj['subCategories'] = [];
    }
  }

  /**
    *
    * @param event
    * field : APVP_Group_Number__c
    */
  setApvpGroupFilter(event) {
    if (event) {
      this.filterObj['apvpGroup'] = event;
    } else {
      this.filterObj['apvpGroup'] = [];
    }
  }

  /**
    *
    * @param event
    * field : Community_Type__c
    */
  setCommunityTypeFilter(event) {
    if (event) {
      this.filterObj['communityType'] = event;
    } else {
      this.filterObj['communityType'] = [];
    }
  }

  /**
    *
    * @param event
    * field : SLA_Group_Name__c
    */
  setSlaGroupFilter(event) {
    if (event) {
      this.filterObj['slaGroup'] = event;
    } else {
      this.filterObj['slaGroup'] = [];
    }
  }

  setCategoryFilter(event) {
    if (event) {
      this.filterObj['category'] = event;
    } else {
      this.filterObj['category'] = [];
    }
  }
  setWSLAccountStatus(event) {
    this.filterObj['wslAccountStatus'] = event;
  }

  setWSLAccounts(event) {
    this.filterObj['wslAccounts'] = event;
  }

  setSearchedAddress(event) {
    this.filterObj['address'] = event instanceof Array ? event : [event];
  }

  setGeoCode(event) {
    this.filterObj['geoCode'] = event instanceof Array ? event : [event];
  }

  setCity(event) {
    this.filterObj['city'] = event instanceof Array ? event : [event];
  }

  setWorkerStatus(event) {
    this.filterObj['workerStatus'] = event;
  }

  setisBillableFilter(event) {
    if (event) {
      this.filterObj['isBillable'] = event;
    } else {
      this.filterObj['isBillable'] = [];
    }
  }

  setRadius(event) {
    this.filterObj['radius'] = [event];
  }

  setDrivingDistance(event) {
    this.filterObj['driving_distance'] = [event];
  }

  setSalaryRange(event) {
    this.filterObj['salary'] = [event];
  }

  getProjectCategory(event) {
    if (event) {
      this.filterObj['projectCategory'] = event instanceof Array ? event : [event];;
    } else {
      this.filterObj['projectCategory'] = [];
    }
  }

  setTargetCost(event) {
    this.filterObj['targetCost'] = [event];
  }

  setGtpResouceTypeLookup(event) {
    this.filterObj['gtpResourceType'] = event instanceof Array ? event : [event];
  }

  setMessageType(event) {
    this.filterObj['messageType'] = event instanceof Array ? event : [event];
  }

  setGtpPoolAccesssTypeLookup(event) {
    this.filterObj['gtpPoolAccessType'] = event instanceof Array ? event : [event];
  }

  setGtpMemberTypeLookup(event) {
    this.filterObj['gtpMemberType'] = event instanceof Array ? event : [event];
  }

  setGtpCommunityTypeLookup(event) {
    this.filterObj['gtpCommunityType'] = event instanceof Array ? event : [event];
  }

  setBackFillWorkerServiceLookup(event) {
    this.filterObj['backFillWorkerService'] = event instanceof Array ? event : [event];
  }

  setDisplayCoverageTypeLookup(event) {
    this.filterObj['displayCoverageType'] = event instanceof Array ? event : [event];
  }


  setVendorSubTypeCommunityLookup(event) {
    this.filterObj['vendorSubTypeCommunity'] = event instanceof Array ? event : [event];
  }

  setslaAccountTypLookup(event) {
    this.filterObj['slaAccountType'] = event instanceof Array ? event : [event];
  }

  setCovrageHoursAccountTypeLookup(event) {
    this.filterObj['covrageHoursAccountType'] = event instanceof Array ? event : [event];
  }

  setdisplayVendorsiteTypeLookup(event) {
    this.filterObj['displayVendorsiteType'] = event instanceof Array ? event : [event];
  }

  setVendorStatusLookup(event) {
    this.filterObj['vendorStatusType'] = event instanceof Array ? event : [event];
  }

  setMvvpDisplayTypeLookup(event) {
    this.filterObj['mvvpDisplayType'] = event instanceof Array ? event : [event];
  }

  setCandidateTypeLookup(event) {
    this.filterObj['candidateType'] = event instanceof Array ? event : [event];
  }

  setslaLabourCloudTypLookup(event) {
    this.filterObj['slaLabourCloudType'] = event instanceof Array ? event : [event];
  }

  setcoverHoursMvvpTypLookup(event) {
    this.filterObj['coverHoursMvvp'] = event instanceof Array ? event : [event];
  }

  setjobOrderStatusLookup(event) {
    this.filterObj['jobOrderStatus'] = event instanceof Array ? event : [event];
  }

  setjobOrderStageLookup(event) {
    this.filterObj['jobOrderStage'] = event instanceof Array ? event : [event];
  }

  setjobOrderPriorityLookup(event) {
    this.filterObj['jobOrderPriority'] = event instanceof Array ? event : [event];
  }
  setUserTypeLookup(event) {
    this.filterObj['userTypeId'] = event instanceof Array ? event : [event];
  }
  setPageNameLookup(event) {
    this.filterObj['pageName'] = event instanceof Array ? event : [event];
  }
  setUserIdLookup(event) {
    this.filterObj['userIdLookup'] = event instanceof Array ? event : [event];
  }
  setisTestUserFilter(event) {
    if (event) {
      this.filterObj['isTestUser'] = event;
    } else {
      this.filterObj['isTestUser'] = [];
    }
  }
  setConversationSourceFilter(event) {
    if (event) {
      this.filterObj['conversationSource'] = event;
    } else {
      this.filterObj['conversationSource'] = [];
    }
  }
  setincludeTestAccountFilter(event) {
    if (event) {
      this.filterObj['includeTestAccount'] = event;
    } else {
      this.filterObj['includeTestAccount'] = [];
    }
  }
  
setisServiceoLoginFilter(event) {
    if (event) {
      this.filterObj['isServiceoLogin'] = event;
    } else {
      this.filterObj['isServiceoLogin'] = [];
    }
  }

  setuniqueUserFilter(event) {
    if (event) {
      this.filterObj['uniqueUser'] = event;
    } else {
      this.filterObj['uniqueUser'] = [];
    }
  }

  setisActiveUserFilter(event) {
    if (event) {
      this.filterObj['isActiveUser'] = event;
    } else {
      this.filterObj['isActiveUser'] = [];
    }
  }

  setTimeChangeRequestStatusLookup(event) {
    this.filterObj['timeChangeRequestStatus'] = event instanceof Array ? event : [event];
  }

  setEmpTypeListLookup(event) {
    this.filterObj['employeeTypeList'] = event instanceof Array ? event : [event];
  }

  setJobInvoiceTypeStatusLookup(event) {
    this.filterObj['jobInvoiceTypes'] = event instanceof Array ? event : [event];
  }

  
   /**
   *
   * @param event
   * field : Service_Talent_Type__c
   */
    setServiceTalentTypeFilter(event) {
      this.filterObj['servicetalentTypes'] = event;
    }
    
    /**
     *
     * @param event
     * field : Coverage_Hours__c
     */
    setCoverageHoursPriceList(event) {
      this.filterObj['priceListCoverageHours'] = event;
    }
  
    /**
     *
     * @param event
     * field : Service_Technical_Level__c
     */
    setTechnicalLevelPriceList(event) {
      this.filterObj['priceListTechnicalLevel'] = event;
    }
  
  
    /**
     *
     * @param event
     * field : SLA__c
     */
    setSLAPriceList(event) {
      this.filterObj['priceListSLA'] = event;
    }


  
  /* THIS METHOD IS TO SET DATE FROM CURRENT TO LAST GIVEN NUMBER OF DAYS */
  getDate = (days) => {
    const result = new Date();
    result.setDate(result.getDate() - days);
    return result;
  };

  setDefaultRangeForGlobalFilter = () => {
    const fromDate: Date = this.getDate(90 - 1);
    fromDate.setHours(0, 0, 0, 0);
    const toDate: Date = new Date();
    toDate.setHours(0, 0, 0, 0);
    // const dateRange = {
    //   0: fromDate,
    //   1: toDate
    // }
    const dateRange = [];
    dateRange.push(fromDate);
    dateRange.push(toDate);
    // this.filterObj.dateOptions.push(dateRange);
    this.setCreatedDateFilter(dateRange, 'createdDate', () => {
      this.filterData();
    })
  }

  getFilterCount = (filterKey: string) => {
    let count: number;
    switch (filterKey) {
      case 'projectTypes':
        count = (this.filterObj.projectTypes && this.filterObj.projectTypes.length) || 0
          + (this.currentPage != 'teamMembers' && this.selectedHelpDeskType && this.selectedHelpDeskType.length) || 0;
        break;
    }
    return count ? '(' + count + ')' : '';
  }

  setFilterObj(key, value) {
    if (value) {
      this.filterObj[key] = value instanceof Array ? value : [value];
    } else {
      this.filterObj[key] = [];
    }
  }

  setServiceTerritoryFilter(event) {
    if (event) {
      this.filterObj['serviceTerritory'] = event;
    } else {
      this.filterObj['serviceTerritory'] = [];
    }
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
    this._sub1.unsubscribe();
  }

   /**
   *
   * @param event | pass selected city
   * field : ts2_Location__c
   */
    setCityFilter(event) {
      this.filterObj["cityList"] = event;
    }

  /**
   *
   * @param event | pass selected status
   * field : appStatus
   */
  setAppStatusLookup(event) {
    this.filterObj["appStatus"] = event;
  }

  /**
  *
  * @param event | pass selected notice period
  * field : noticePeriod
  */
  setNoticePeriodLookup(event) {
    this.filterObj["noticePeriod"] = event;
  }

  /**
  *
  * @param event | pass selected requirement type
  * field : requirementType
  */
   setRequirementType(event) {
    this.filterObj["requirementType"] = event;
  }

  setEmploymentFilter(event) {
    this.filterObj['employmentFilter'] = event;
  }

  setJobAssigned(event) {
    this.filterObj['jobAssigned'] = event;
  }

  setJobPostingType(event) {
    this.filterObj['jobPostingType'] = event;
  }

  setJobCreationReason(event) {
    this.filterObj['jobCreationReason'] = event;
  }

  setApprovalStatus(event) {
    this.filterObj['approvalStatus'] = event;
  }

  setpcUserCountFilter(event) {
    if (event) {
      this.filterObj['pcUserCount'] = event;
    } else {
      this.filterObj['pcUserCount'] = [];
    }
  }

  setPageTypeFilter(event) {
    if (event) {
      this.filterObj['pageType'] = event;
    } else {
      this.filterObj['pageType'] = [];
    }
  }

  setweeklyDateOptionFilter(event) {
    if (event) {
      this.filterObj['weeklyDateOption'] = event;
    } else {
      this.filterObj['weeklyDateOption'] = [];
    }
  }

  /**
  *
  * @param event | pass selected notice period
  * field : noticePeriod
  */
  setCandidateCurrentSalary(event) {
    this.filterObj['candidateCurrentSalary'] = [event];
  }

  /**
   *
   * @param event | pass selected notice period
   * field : noticePeriod
   */
  setCandidatePayRateRequested(event) {
    this.filterObj['candidatePayRateRequested'] = [event];
  }

  /**
 *
 * @param event | pass selected completed aplication status
 * field : completedStatus
 */
  setCompetedStatusLookup(event) {
    this.filterObj["completedStatus"] = event;
  }

  /**
 *
 * @param event | overall status
 * field : overallStatus
 */
  setOverallStatusLookup(event) {
    this.filterObj["overallStatus"] = event;
  }

   /**
 *
 * @param event | stage status
 * field : stageStatus
 */
    setStageStatusLookup(event) {
      this.filterObj["stageStatus"] = event;
    }

  maxSelect() {
    let max = 20;
    if (this.currentPage === 'jobAccessAnalytics') {
      max = 1;
    }
    return max;
  }
  
  /**
   *
   * @param event
   * field : excludeMagicLink
   */
  setMagicLinkFilter(event){
    const e = event ? [event] : [];
    this.filterObj["excludeMagicLink"] = e;
  }

  /* this method is used to add/remove business requestor filter in enable filters */
  // private _enablebusinessRequestorFiter(e) {
  //   if (e.length && e[0] === 'Requested for') {
  //     this._enableFilters.push('businessRequestor');
  //   } else {
  //     const index = this._enableFilters.indexOf('businessRequestor');
  //     if (index !== -1) {
  //       this._enableFilters.splice(index, 1);
  //     }
  //   }
  // }
  setRecruitmentManagerObj(event){
    this.filterObj["recruitmentManager"] = event;
  }
  setRecruiterFilterObj(event){
    this.filterObj["recruiter"] = event;
  }
  /**
  *
  * @param event
  * field : release note type
  */
   setReleaseNoteTypeFilter(event) {
    if (event) {
      this.filterObj["releaseNotesTypes"] = event;
    } else {
      this.filterObj["releaseNotesTypes"] = [];
    }
  }
  /**
  *
  * @param event
  * field : release note version
  */
   setReleaseNoteVersionFilter(event) {
    if (event) {
      console.log('event',event)
      this.filterObj["releaseNotesVersions"] = event;
    } else {
      this.filterObj["releaseNotesVersions"] = [];
    }
  }

  setLeaveType(event) {
    this.filterObj['leaveStatusType'] = event;
  }

  isArray(obj: any) {
    return Array.isArray(obj);
  }
  /**
  *
  * @param event
  * field : release note type
  */
 setTalentTypeListFilter(event) {
  if (event) {
    this.filterObj["talentTypeList"] = event;
  } else {
    this.filterObj["talentTypeList"] = [];
  }
}
 /**
*
* @param event
* field : release note type
*/
setTalentLevelFilter(event) {
    if (event) {
      this.filterObj["talentLevels"] = event;
    } else {
      this.filterObj["talentLevels"] = [];
    }
  }
   /**
*
* @param event
* field : release note type
*/
setTalentTypesNameFilter(event) {
  if (event) {
    this.filterObj["talentTypesName"] = event;
  } else {
    this.filterObj["talentTypesName"] = [];
  }
}
  setWorkerEmailFilter(event){
    if (event) {
      this.filterObj["workerEmail"] = event;
    } else {
      this.filterObj["workerEmail"] = [];
    }
  }
  setVendorAppliedLookup(event) {
    this.filterObj["isVendorApplied"] = event;
  }

}
