import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EMPTY } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { MilestoneApi } from 'shared/sdk';
import { PreloaderService } from 'shared/services/preloader.service';
import { DeliveryManagerService } from 'shared/views/pms/delivery-manager/delivery-manager.service';

@Component({
  selector: 'app-milestone-lookup',
  templateUrl: './milestone-lookup.component.html',
  styleUrls: ['./milestone-lookup.component.css']
})
export class MilestoneLookupComponent implements OnInit {
  isComponentInitialized: boolean;
  isLoading = false;

  @Input() set resetData(isClear) {
    if (isClear) {
      this.milestoneSelectedList = [];
      this.milestoneOptionList = [];
    }
  }
  @Input() placeholder = 'Milestone';
  @Input() isdisabled;
  @Input() boxedLayout;
  @Input() hasProjectSelected = false;
  @Input()
  set isMultipleSelected(e) {
    this._multipleSelect = e;
    this.maxSelectedItems = e ? 20 : 1;
  }

  @Output() getMilestoneObj: EventEmitter<any> = new EventEmitter;
  @Output() setInitialMilestone: EventEmitter<any> = new EventEmitter;
  public searchInput = new EventEmitter<string>();
  public consoleType = [];
  public projectsByConsole: any;
  projectsByLoggedInUser: any;
  // setMilestoneObj will be the array of 'PgMo_Projects_c'
  @Input()
  set setMilestoneObj(params) {
    this.whereObj = this.whereObj && Object.keys(this.whereObj).length ? this.whereObj : {};
    this.milestoneOptionList = [];
    this.milestoneSelectedList = [];
    // if (params && Object.keys(params).length) { // Commenting this as clear filter is not working properly due to this check.
      if (params['PgMO_Projects__c'] && params['PgMO_Projects__c'].length) {
        this.whereObj['PgMO_Projects__c'] = { inq: params['PgMO_Projects__c'] };
      } else if (this.consoleType && this.consoleType.length) {
        this.whereObj['PgMO_Projects__c'] = { inq: this.projectsByConsole };
      } else if (this.whereObj['PgMO_Projects__c']) {
        delete this.whereObj['PgMO_Projects__c']
      }

      if (params['Account__c'] && params['Account__c'].length) {
        this.whereObj['Account__c'] = { inq: params['Account__c'] };
      } else if (this.whereObj['Account__c']) {
        delete this.whereObj['Account__c']
      }
    // }
  }

  @Input() set setProjectConsoleType(e) {
    if(e) {
      if(e.length) {
        this.consoleType = e;
        this.getProjectsByConsole();
      }else{
        this.consoleType = [];
        this.projectsByConsole = null;
      }      
    }
  }

  @Input() set selectedConversatonMileStone(e) {
    console.log('selectedConversatonMileStone > ', e);
    if (e) {
      this.selectedMilestone = e;
      if (this.isComponentInitialized) {        
        if(e.sfdcId) {
          this.milestoneSelectedList = e.sfdcId;
          this.fetchAndAssignMilestoneOptList({ sfdcId: e.sfdcId });
        } else {
          this.milestoneSelectedList = e;
          this.fetchAndAssignMilestoneOptList({ sfdcId: e });
        }        
      } else {
        this.getMilestoneById();
      }
    } else {
      this.selectedMilestone = '';
    }
  }

  selectedMilestone: any;
  whereObj = {};

  maxSelectedItems = 20;
  _multipleSelect = true;

  milestoneOptionList: any = [];
  milestoneSelectedList = [];

  /**
   * @constructor
   * Represents a MilestoneLoopkupComponent.
   */
  constructor(
    private _milestoneApi: MilestoneApi,
    private _preloaderService: PreloaderService,
    private _deliveryManagerService: DeliveryManagerService
  ) {
  }

  ngOnInit() {
    // this.whereObj = {};
    // if(this.consoleType && this.consoleType.length){
    //   await this._deliveryManagerService.getProjectsByConsole(this.consoleType, true).then(data => {
    //     this.projects = data;
    //     if(!(this.whereObj['PgMO_Projects__c'] && this.whereObj['PgMO_Projects__c']['inq'] && this.whereObj['PgMO_Projects__c']['inq'].length) && this.projects && this.projects.length) {
    //       this.whereObj['PgMO_Projects__c'] = {inq: this.projects};
    //     }
    //   });
    // }
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    if (preselected && preselected['milestones'] && preselected['milestones'].length) {
      this.milestoneSelectedList = preselected['milestones'];
      this.onMilestoneChange();
      this.fetchAndAssignMilestoneOptList({sfdcId : { inq: this.milestoneSelectedList }});
    }
    
    // if (preselected && preselected['projects'] && preselected['projects'].length) {
    //   this.whereObj['PgMO_Projects__c'] = { inq: preselected['projects'] };
    // }
    // this.getMilestone(this.whereObj);
    this.getMilestoneObj.emit(this.milestoneSelectedList);
    this.getMilestoneList();
    if (this.selectedMilestone) {
      this.milestoneSelectedList = this.selectedMilestone && this.selectedMilestone.sfdcId;
      this.getMilestoneById()
    }
    if (!this._deliveryManagerService.isAdmin()) {
      this._deliveryManagerService.getCurrentUserGroupIds({ project: [] }).then(projs => {
        this.projectsByLoggedInUser = projs
      });
    }
    this.isComponentInitialized = true;
  }

  fetchAndAssignMilestoneOptList(whereObj) {
    this.isLoading = true;
    const filterObj = {
      where: {
        ...this.whereObj,
        ...whereObj
      },
      fields: ['id', 'sfdcId', 'Name', 'Milestone_No__c', 'PgMO_Projects__c'],
      order: 'createdAt desc',
      limit: 200
    }
    this._milestoneApi.find(filterObj).subscribe(data => {
      this.isLoading = false;
      if (data && data.length) {
        this.milestoneOptionList = data;
      } else {
        this.milestoneOptionList = [];
      }
    }, error => {
      this.isLoading = false;
      this.milestoneOptionList = [];
      console.error(error);
    })
  }

  getProjectsByConsole() {
    if(this.consoleType && this.consoleType.length){
      this._deliveryManagerService.getProjectsByConsole(this.consoleType, true).then(data => {
        this.projectsByConsole = data;
        if ((!this.hasProjectSelected) && this.projectsByConsole && this.projectsByConsole.length) {
          this.whereObj['PgMO_Projects__c'] = { inq: this.projectsByConsole };
        }
      });
    }
  }

  prepareWhereClause() {
    if (this.projectsByLoggedInUser) {
      let projectSfdcIds: Array<string>;
      if (this.whereObj && this.whereObj['PgMO_Projects__c'] && this.whereObj['PgMO_Projects__c']['inq']) {
        projectSfdcIds = this.whereObj['PgMO_Projects__c']['inq'].filter(sfdcId => this.projectsByLoggedInUser.includes(sfdcId))
      } else {
        projectSfdcIds = this.projectsByLoggedInUser
      }
      this.whereObj['PgMO_Projects__c'] = { inq: projectSfdcIds }
    }
    return this.whereObj;
  }

  getMilestoneList() {
    this.searchInput
      .pipe(
        filter(search => search && search.trim().length >= 3), 
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(search =>
          //this.getMilestone(this.whereObj, search)
          this.getMilestonesRecursively(this.prepareWhereClause(), search)
        )
      ).subscribe(
        data => {
          this.isLoading = false;
          if (data && data.length) {
            this.milestoneOptionList = data;
          } else {
            this.milestoneOptionList = [];
          }
          // this.setProgramData(data);
        },
        err => {
          this.isLoading = false;
          console.log(err);
          this.milestoneOptionList = [];
        }

      );
  }

  private getMilestonesRecursively(where, search) {
    this.isLoading = true;
    let whereNew = JSON.parse(JSON.stringify(where));
    if(whereNew && whereNew['PgMO_Projects__c'] && whereNew['PgMO_Projects__c'].inq && whereNew['PgMO_Projects__c'].inq.length){
      const array_chunks = (array, chunk_size) => Array(Math.ceil(array.length / chunk_size)).fill('').map((_, index) => index * chunk_size).map(begin => array.slice(begin, begin + chunk_size));
      var project_chunks = [];
      if(whereNew['PgMO_Projects__c'] && whereNew['PgMO_Projects__c'].inq && whereNew['PgMO_Projects__c'].inq.length){        
        project_chunks = array_chunks(whereNew['PgMO_Projects__c'].inq, 200);
      }
      var totalChunks = project_chunks.length;
      var counter = 1;
      whereNew['PgMO_Projects__c'].inq = project_chunks[counter - 1];
      return this.getMilestone(whereNew, search).expand((obj) => {
        if(counter < totalChunks){
          counter++;
          whereNew['PgMO_Projects__c'].inq = project_chunks[counter - 1];          
          return this.getMilestone(whereNew, search);          
        }else{
          return EMPTY;
        }
      }).map(obj => obj).flatMap(array => array).reduce((acc, x) => acc.concat(x), []);
    }else{
      return this.getMilestone(whereNew, search);
    }
  }

  getMilestone(whereObj, search) {
    let andObj = [];
    if (whereObj['sfdcId']) {
      delete whereObj['sfdcId'];
    }
    if (Object.keys(whereObj).length) {
      andObj = Object.keys(whereObj).map(function (itemIndexed) { const item = whereObj[itemIndexed]; return {[itemIndexed]: item}; })
      andObj.push({
        or: [
          { Name: { like: '%' + search.trim() + '%', options: 'i' } },
          { Milestone_No__c: { like: '%' + search.trim() + '%', options: 'i' } }
        ]
      });
    } else {
      andObj = [{
        or: [
          { Name: { like: '%' + search.trim() + '%', options: 'i' } },
          { Milestone_No__c: { like: '%' + search.trim() + '%', options: 'i' } }
        ]
      }];
    }
    const filterObj = {
      fields: ['id', 'sfdcId', 'Name', 'Milestone_No__c', 'PgMO_Projects__c'],
      order: 'createdAt desc',
      limit: 200
    }
    if (Object.keys(andObj).length) {
      filterObj['where'] = {and: andObj};
    }

    return this._milestoneApi.find(filterObj);
  }

  getMilestoneById() {
    this._preloaderService.showPreloader();
    const filter = {
      fields: ['id', 'sfdcId', 'Name', 'Milestone_No__c', 'PgMO_Projects__c'],
      where: this._setWhereCondition()
    }
    this._milestoneApi.findOne(filter).subscribe(
      milestone => {
        this._preloaderService.hidePreloader();
        if (milestone) {
          this.milestoneOptionList = []
          this.milestoneOptionList.push(milestone);
          this.setInitialMilestone.emit(this.milestoneSelectedList);
        } else {
          this.milestoneOptionList = [];
        }
      },
      err => {
        this._preloaderService.hidePreloader();
        console.log(err);
        this.milestoneOptionList = [];
      })
  }

  private _setWhereCondition() {
    return this.selectedMilestone && this.selectedMilestone.id ?
      { id: this.selectedMilestone.id } : { sfdcId: this.selectedMilestone.sfdcId };
  }

  onMilestoneChange() {
    this.getMilestoneObj.emit(this.milestoneSelectedList);
  }

  clearMilestone() {
    this.milestoneSelectedList = [];
    this.getMilestoneObj.emit([]);
  }
  clear(val) {
    this.clearMilestone()
  }
}
