import { Component, EventEmitter, OnInit, Input, Output, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ModalService } from '../../services/modal.service';
import { AlertService } from '../../services/alert.service';
import { PreloaderService } from '../../services/preloader.service';
import { WorkerApi, JobApi, ApvpWorkersVmsApi } from '../../sdk';
import { Observable, Subscription } from 'rxjs';
import { WorkflowService } from './../../services/workflow.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-select-worker',
    templateUrl: './select-worker.component.html',
    styleUrls: ['./select-worker.component.css']
})
export class SelectWorkerComponent implements OnInit {
    // , OnDestroy {
    @Input() modelId: string;
    // @Input() getWorkers: Observable<any>;
    @Output() assignedWorker: EventEmitter<any> = new EventEmitter();
    @Output() addWorker: EventEmitter<any> = new EventEmitter();


    @Input() workflowStatusId = null;
    @Input() workflowId = null;
    @Input() job;

    workersTypeahead = new EventEmitter<string>();
    workers: any = [];
    projectProfileSfdcId: any = '';
    // workersLoaded: Boolean;
    error: any;
    formAssignWorker: FormGroup;
    jobData: any;
    isSelected = false;
    Dispatch_Worker_Phone = '';
    Dispatch_Worker_Name_Text__c : any ;
    workerEmail: string = '';
    isFBWorker: boolean = false;
    facebookAccount: boolean = false;
    accessType: string = '';
    // workerSubscription: Subscription;

    constructor(
        private _modalService: ModalService,
        private _preloaderService: PreloaderService,
        private _workerApi: WorkerApi,
        private _apvpWorkersVmsApi: ApvpWorkersVmsApi,
        private _jobApi: JobApi,
        private _fb: FormBuilder,
        private _alertService: AlertService,
        private _workflowService: WorkflowService,
        private _router: Router
    ) {
    }

    ngOnInit() {
        // this.workersLoaded = false;
        const appData = JSON.parse(localStorage.getItem('appData'));
        this.accessType = (appData.user && appData.user.accessType) ? appData.user.accessType : '';
        if (this.modelId) {
            this.getJobData(this.modelId);
        }
        this.buildForm();
    }

    getJobData(jobId) {
        this._jobApi.findById(jobId, {
            fields: ['id', 'sfdcId', 'Dispatch_Worker_Name__c', 'Dispatch_Worker_Name_Text__c', 'Project__c', 'Vendor__c',
                'Dispatch_Worker_Phone__c', 'Vendorsite__c', 'CKSW_BASE__Account__c', 'Project_SOP__c'],
                'include': [
                {
                    'relation': 'worker',
                    'scope': {
                        'fields': ['sfdcId', 'Name', 'Dispatch_Worker_num__c', 'Work_Phone_Number__c', 'Vendorsite__c','Email__c']
                    }
                },
                {
                    'relation': 'partner',
                    'scope': {
                        where: { 'Name': { like: '%facebook%', options: 'i' } },
                        'fields': ['sfdcId', 'Name']
                    }
                },
                {
                    'relation': 'project',
                    'scope': {
                        'fields': ['sfdcId', 'Project__c', 'Name', 'Project_Profile__c', 'Project_Routing_Profile__c']
                    }
                },
                {
                    'relation': 'program',
                    'scope': {
                        'fields': ['sfdcId', 'Program_Requires_Registered_Workers__c']
                    }
                },
            ]
        }).subscribe(data => {
            if (data) {
                this.jobData = data;
                this.projectProfileSfdcId = this.jobData && this.jobData.project && this.jobData.project.Project_Routing_Profile__c ? this.jobData.project.Project_Routing_Profile__c:'';
                this.searchWorkerName();
                // this.facebookAccount = (this.jobData && this.jobData.partner) ? true : false;
                // if (this.jobData && this.jobData.Dispatch_Worker_Name__c && this.jobData.Vendorsite__c) {
                //     if (this.jobData.worker && this.jobData.worker.Vendorsite__c &&
                //         this.jobData.worker.Vendorsite__c === this.jobData.Vendorsite__c) {
                //         this.isSelected = true;
                //         this.formAssignWorker.controls['Dispatch_Worker_Name__c'].setValue(this.jobData.Dispatch_Worker_Name__c);
                //         this.Dispatch_Worker_Name_Text__c = this.jobData.Dispatch_Worker_Name_Text__c;
                //         this.Dispatch_Worker_Phone = this.jobData.Dispatch_Worker_Phone__c;
                //         this.workerEmail = this.jobData.worker['Email__c'] || '';
                //         // this.formAssignWorker.controls['Dispatch_Worker_Phone__c']
                //         // .setValue(this.getWorkerPhoneNumber(this.jobData.Dispatch_Worker_Name__c));
                //     }
                // }
            }
        },
        err => {
            console.log(err);
            this.error = err;
        });
    }

    buildForm() {
        const selectedWorker = this.jobData && this.jobData.Dispatch_Worker_Name__c ? this.jobData.Dispatch_Worker_Name__c : null;
        this.formAssignWorker = this._fb.group({
            Dispatch_Worker_Name__c: [selectedWorker, Validators.required]
            // Dispatch_Worker_Name__c: new FormControl({
            //     value: null
            // }, [Validators.required]),
            // Dispatch_Worker_Phone__c: new FormControl({
            //     value: null,
            //     disabled: true
            // })
        });
    }

    searchWorkerName() {
        this.workers = [];
        this._preloaderService.showPreloader();
        if(this.projectProfileSfdcId && this.projectProfileSfdcId.length){
            this._apvpWorkersVmsApi.getAllApvpWorkersList({
                where: { 
                    Project_Routing_Profile__c: this.projectProfileSfdcId,  
                    Status_Worker__c: 'Approved', 
                    Vendor_Account_Id: this.job.Vendor__c,
                    Requires_Registered_Workers__c: this.job.program && this.job.program.Program_Requires_Registered_Workers__c ? 
                        this.job.program.Program_Requires_Registered_Workers__c: false,
                    Onboarding_Status__c: 'Completed'
                }
            }).subscribe(
                res => {
                    this._preloaderService.hidePreloader();
                    let workerList = []
                    for (const [key, value] of Object.entries(res)) {
                        let w = {
                            Contact__c: value['workerContactId'],
                            Email__c: value['workerEmail'],
                            Name: value['ServiceoUserId'] && value['ServiceoUserIsActive'] ?  value['workerName'] :  value['workerName'] + '(Not Serviceo User)',
                            Work_Phone_Number__c: value['workerPhone'],
                            is_Facebook__c: value['workerIsFacebook'],
                            sfdcId: value['workerSfdcId']
                        }
                        workerList.push(w)   
                    }
                    console.log(workerList)
                    this.workers = workerList;
                    this.facebookAccount = (this.jobData && this.jobData.partner) ? true : false;
                    if (this.jobData && this.jobData.Dispatch_Worker_Name__c) {
                        this.isSelected = true;
                        this.formAssignWorker.controls['Dispatch_Worker_Name__c'].setValue(this.jobData.Dispatch_Worker_Name__c);
                        this.Dispatch_Worker_Name_Text__c = this.jobData.Dispatch_Worker_Name_Text__c;
                        this.Dispatch_Worker_Phone = this.jobData.Dispatch_Worker_Phone__c;
                        this.workerEmail = this.jobData.worker['Email__c'] || '';
                        // this.formAssignWorker.controls['Dispatch_Worker_Phone__c']
                        // .setValue(this.getWorkerPhoneNumber(this.jobData.Dispatch_Worker_Name__c));
                    }
                },
                err => {
                    this._preloaderService.hidePreloader();
                    console.log(err);
                }            
            );
        }
    }

    // getWorkerPhoneNumber(sfdcId: string) {
    //     const selectedWorker = this.workers.find(i => i.sfdcId === sfdcId);
    //     return  selectedWorker && selectedWorker.Work_Phone_Number__c;
    // }

    assignWorker(phoneField) {
        if (this.formAssignWorker.valid && this.formAssignWorker.value &&
            this.formAssignWorker.value.Dispatch_Worker_Name__c &&
            this.formAssignWorker.value.Dispatch_Worker_Name__c !== null &&
            this.Dispatch_Worker_Phone && phoneField.valid) {
            this.error = '';
            const values = this.formAssignWorker.value;
            values['Dispatch_Worker_Phone__c'] = this.Dispatch_Worker_Phone;
            values['Dispatch_Worker_Name_Text__c'] = this.Dispatch_Worker_Name_Text__c;
            if(this.jobData.sfdcId) {
                values['jobId'] = this.jobData.sfdcId;
            }

            if (values["Dispatch_Worker_Name__c"] && this.workflowStatusId === 9 && this.workflowId === 1) {
                this._workflowService.setWorkFlowStatusId(12);
            }

            const dataObj = { values, id: this.modelId, updateWorkflow: true };
            this._preloaderService.showPreloader();
            this._jobApi.assignWorker(dataObj).subscribe(
                result => {
                    this._preloaderService.hidePreloader();
                    if(result.error){
                        this._alertService.error(result.error.message);
                    } else {
                        this._alertService.success('Worker has been assigned successfully');
                        if(this._router.url.slice(0,28) == '/pms/service-manager/details') {
                            this.assignedWorker.emit({sfdcId: values["Dispatch_Worker_Name__c"], Name:values['Dispatch_Worker_Name_Text__c']})
                        } else {
                            this.assignedWorker.emit('all');
                        }
                    }                    
                    
                    this._modalService.closed();
                },
                err => {
                    this._preloaderService.hidePreloader();
                    this._alertService.error(err.message);
                    this._modalService.closed();
                    window.scrollTo(0,0);
                }
            );
        } else {
            this.error = 'Please fill complete form.';
            return;
        }
    }

    getPhone(data, workerForm) {
        let selectedWorker = '';
        workerForm['submitted'] = false;
        this.error = '';
        if (data) {
            const worker = data.target.value;
            this.workers.filter(item => {
                if (item.sfdcId === worker) {
                    selectedWorker = item;
                }
            });
            this.Dispatch_Worker_Phone = selectedWorker['Work_Phone_Number__c'];
            this.Dispatch_Worker_Name_Text__c = selectedWorker['Name'];
            this.workerEmail = selectedWorker['Email__c'] || '';
            this.isFBWorker = selectedWorker['is_Facebook__c'] || false;
            // this.formAssignWorker.controls['Dispatch_Worker_Phone__c'].setValue(this.getWorkerPhoneNumber(worker));
        } else {
            this.isSelected = false;
            this.Dispatch_Worker_Phone = selectedWorker;
            this.workerEmail = '';
            this.isFBWorker = false;
            // this.formAssignWorker.controls['Dispatch_Worker_Phone__c'].setValue(selectedWorker);
        }
    }
    getWorkerStatus() {
        let isWorkerServiceGlobal = false;
        const serviceGlobalEmail = (this.workerEmail && this.workerEmail.toLowerCase().includes('serviceglobal')) ? true : false;
        return (this.facebookAccount && !serviceGlobalEmail && this.isFBWorker) ? true : false;
    }
    openAddWorkerModal(){
        this.addWorker.emit(true);
        this._modalService.closed();
    }
    // ngOnDestroy() {
    //     if (this.workerSubscription) {
    //         this.workerSubscription.unsubscribe();
    //     }
    // }  

}
