import { Component, OnInit, ViewChild, TemplateRef, Input} from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { AlertService } from 'shared/services/alert.service';
import { CommonService } from 'shared/services/common.service';
import { ExportToExcelService } from 'shared/services/export-to-excel.service';
import { PreloaderService } from 'shared/services/preloader.service';
import { UtilityService } from 'shared/services/utility.service';

import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ModalService } from 'shared/services/modal.service';
import { NotificationService } from 'shared/services/notification.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TwilioErrorCodeValues } from 'shared/models/twillio-error-codes.service';
import { CommunityDetailsList } from 'shared/models/static-list-data.service';
import * as moment from 'moment-timezone';
@Component({
  selector: 'app-scheduled-sms-list',
  templateUrl: './scheduled-sms-list.component.html',
  styleUrls: ['./scheduled-sms-list.component.css']
})
export class ScheduledSmsListComponent implements OnInit {

  @Input()
  set messageListType(e) {
    if (e) {
      this._messageListType = e
      this.pageTitle = `Schedule SMS List (${e[0].toUpperCase() + e.slice(1)})`
    }
  }
  
  @Input()
  set getFieldData(e) {
    if (e) {
      this.initColumn();
    }
  }

  @Input()
  set jobNumber(e) {
    if (e) {
      this._jobNum = e;
      this.fromJobDetail =true;
      this.pageTitle = `Schedule SMS List (Related to Job : ${e})`
      this.initColumn();
    }
  }
  _jobNum = '';
  fromJobDetail =false;
  enableFilters = [
    'dateOptions',
    'jobs',
    'workerNames',
    'messageScheduledDate',
    'smsType',
    'smsStatus',
    'messageSentTo',
    'messageSentFrom',
    'messageCommunity'
  ];

  tableColumns: any[];
  tableData: any[];
  filteredItems = [];
  loadingIndicator = false;
  itemsPerPage = 10
  totalCount = 0
  localFilters = {};
  @ViewChild('myTable') table: any;
  itemsPerBatch = 200;
  noRecords = false;
  dataList = [];
  filterRelation: {};
  isLoadMore = false;
  datePipe: DatePipe;
  pageTitle = 'Schedule SMS List (All)'

  currentMenuOption = '';
  dateFormat = 'MM/DD/yyyy HH:mm A';
  globalFilterData = undefined;
  scheduleSMSApiUrl = environment.notificationUrl + '/schedule-sms';
  _messageListType = 'all';
  @ViewChild('scheduledSmsdetail') scheduledSmsdetail: TemplateRef<any>;
  rowdata:any;
  sendSmsAccess = false;
  constructor(
    public http: HttpClient,
    private _utilityService: UtilityService,
    private _loader: PreloaderService,
    private _commonService: CommonService,
    private _alertService: AlertService,
    private _exportToExcelService: ExportToExcelService,
    private _modalService: ModalService,
    private _notificationService: NotificationService,
    private _sanitizer: DomSanitizer,

  ) { }

  ngOnInit() {
    const app = JSON.parse(localStorage.getItem('appData'));
    let allowedOperation = (app && app['user'] && app['user']['workers'] && app['user']['workers'].length) ? app['user']['workers'][0]['Operation_Project_Management_Assignment__c'] : '';
    allowedOperation = allowedOperation && allowedOperation.length ?  allowedOperation.split(';') : [];
    if (allowedOperation.includes('TNM Console (Send SMS)')) {
      this.sendSmsAccess = true;
    } else {
      this.sendSmsAccess = false;
    }
  }

  // This function is to set coloumns Objects that is to be provided in ngxDataTable in html file.
  initColumn() {
    this.tableColumns = [
      { name: 'Action' , prop : '__', width: 120 , visible: true, sortable: false},
      { name: 'Message ID', prop: 'MessageCommunity', width: 150, visible: true, sortable: false, showMore: true, type: 'text' },
      { name: 'SID', prop: 'sid', width: 200, visible: true, sortable: false, showMore: true, type: 'text' },
      { name: 'Message Sent From', prop: 'MesageSendFrom', width: 180, visible: true, sortable: true, showMore: false, type: 'text' },
      { name: 'Message Sent To', prop: 'MessageSendTo', width: 180, visible: true, sortable: true, showMore: false, type: 'text' },
      { name: 'Message Sent At', prop: 'MessageSentDateTime', width: 180, visible: true, sortable: true, showMore: true, type: 'date' },      
      { name: 'Message Delivery Method', prop: 'MessageDeliveryMethod', width: 220, visible: true, sortable: true, showMore: true, type: 'text' },
      { name: 'Message Type', prop: 'messageName', width: 180, visible: true, sortable: true, showMore: true, type: 'text' },
      { name: 'Message Delivery Type', prop: 'MessageType', width: 200, visible: true, sortable: true, showMore: true, type: 'text' },
      { name: 'Message Scheduled At', prop: 'messageScheduledDateTime', width: 200, visible: true, sortable: true, showMore: false, type: 'date' },
      { name: 'Message Schedule DateTime (Jobsite local)', prop: 'messageScheduledDateTimeLocal', width: 220, visible: true, sortable: true, showMore: false, type: 'date' }, 
      { name: 'Message Delivery Status', prop: 'MessageStatus', width: 200, visible: true, sortable: true, showMore: true, type: 'text' },
      { name: 'Message Reply (DateTime)', prop: 'MessageReplyDateTime', width: 220, visible: true, sortable: true, showMore: true, type: 'text' },
      { name: 'Message Reply From', prop: 'MessageReplyFrom', width: 180, visible: true, sortable: true, showMore: true, type: 'text' },
      { name: 'Message Reply To', prop: 'MessageReplyTo', width: 180, visible: true, sortable: true, showMore: true, type: 'text' },
      { name: 'Message Reply Text', prop: 'MessageReplyText', width: 200, visible: true, sortable: true, showMore: true, type: 'text' },
      { name: 'Error Code', prop: 'ErrorCode', width: 120, visible: true, sortable: true, showMore: false, type: 'text' },
      { name: 'Country', prop: 'Country', width: 150, visible: true, sortable: true, showMore: false, type: 'text' },
      { name: 'Job Number', prop: 'JobNumber', width: 150, visible: true, sortable: true, showMore: false, type: 'text' },
      { name: 'Priority', prop: 'Priority', width: 120, visible: true, sortable: true, showMore: false, type: 'text' },
      { name: 'Worker Name', prop: 'WorkerName', width: 200, visible: true, sortable: true, showMore: true, type: 'text' },
      { name: 'Region', prop: 'Region', width: 120, visible: true, sortable: true, showMore: false, type: 'text' },
      { name: 'Created At', prop: 'createdAt', width: 200, visible: true, sortable: true, showMore: false, type: 'date' }
    ]
    this.hideColumn();
    this.setFilters();
  }
  
  hideColumn(){
    if (this._jobNum && this.fromJobDetail) {
      const hiddenColumn = ['JobNumber', 'Region', 'Country','Priority'];
      this.tableColumns = this.tableColumns.filter(col => !hiddenColumn.includes(col.prop));
    }
  }

  setFilters(){
    this.globalFilterData = {};
    const localGlobalFilterData = this._commonService.getGlobalFilter(this.enableFilters);    
    if (localGlobalFilterData && Object.keys(localGlobalFilterData).length) {
      this.globalFilterData = localGlobalFilterData;
      if (this.globalFilterData['Iron_Job_num__c']) {
        this.globalFilterData['JobNumber'] = {...this.globalFilterData['Iron_Job_num__c']};
        delete this.globalFilterData['Iron_Job_num__c'];
      }
      if (this.globalFilterData['Dispatch_Worker_Name__c']) {
        this.globalFilterData['WorkerId'] = {...this.globalFilterData['Dispatch_Worker_Name__c']};
        delete this.globalFilterData['Dispatch_Worker_Name__c'];
      }
    }
    this.loadScheduledSMSListData(1, false)
  }

  onRefresh() {
    this.loadScheduledSMSListData(1);
  }

  loadScheduledSMSListData(offset, exportToExcel?) {
    let filter = {}
    if (!exportToExcel) {
      this.loadingIndicator = true;
    } else {
      // this.filterRelation = { ...this.filterRelation, ...{ exportToExcel: true } }
    }
// to be implement
    if (this.globalFilterData && Object.keys(this.globalFilterData).length) {
      filter = { ...filter, ...this.globalFilterData };
    }
    
    if(this._messageListType && this._messageListType.length && this._messageListType != 'all'){
      if(this._messageListType == "failed" || this._messageListType == "undelivered"){
        filter['MessageStatus']= { inq : [this._messageListType]};
      } else if(this._messageListType == "success") {
        filter['MessageStatus']= { inq : ['delivered']};
      }
    }
    if(this._jobNum && this._jobNum.length && this.fromJobDetail){
      filter = {};
      filter ['JobNumber'] = {
        inq: [this._jobNum]
      }
    }
    let limit = this.itemsPerBatch;
    // let listData = [];
    this._loader.showPreloader();

    this._notificationService.getScheduleSMS(offset, filter, limit, 'id').then(async result => {
      const data = result && result['data'] ? result['data'] : [];
        this.totalCount = (result && result['total']) ? +result['total'] : 0;
        this.noRecords = (data && data.length < this.itemsPerBatch) ? true : false;
        if (this.isLoadMore) {
          this.dataList = [...this.dataList, ...this.prepareActivityListData(data)];
        } else {
          this.dataList = [...this.prepareActivityListData(data)];
        }
        this.dataList = [...this.dataList];
        this.filteredItems = this.dataList.slice();
        if (Object.keys(this.localFilters).length > 0 && this.localFilters.constructor === Object) {
          this.tableData = this.filteredItems.filter(item => {
            const notMatchingField = Object.keys(this.localFilters).find(key =>
              this._utilityService.dataTableSearch(item, this.localFilters, key));
            return !notMatchingField;
          });
        } else {
          this.tableData = [...this.dataList]
          if (!this.isLoadMore) {
            if (this.table) {
              this.table['offset'] = 0
            }
          }
        }
        this.loadingIndicator = false;
        this.setEmptyMessage();
        this.isLoadMore = false;
        this._loader.hidePreloader();
      }).catch(err => {
        this._alertService.error((err.error && err.error.message) ||err.message || 'Server Error');
        this.setEmptyMessage();
        this.loadingIndicator = false;
        this._loader.hidePreloader();
      });
  }

  prepareActivityListData(data) {
    data.forEach((item) => {
      let payload = {}
      if(item.schedulerPayload && item.schedulerPayload.payload){
        payload = item.schedulerPayload.payload;
        item['Country'] = payload['job'] && payload['job'].CKSW_BASE__Country__c || '' ;
        item['Region'] = payload['jobsite'] && payload['jobsite'].Region__c || '' ;
        item['timezone'] = payload['jobsite'] && payload['jobsite'].Time_Zone__c || '' ;
        item['Priority'] = payload['job'] && payload['job'].Service_Dispatch_SLA_Priority__c || '' ;
        item['messageScheduledDateTimeLocal'] = item.messageScheduledDateTime ? this._commonService.convertTimeZone(item.messageScheduledDateTime, item['timezone'], 'L, LT z') : ''
      }
      item['JobNumber'] = (item && item.JobNumber) ? item.JobNumber : '';
      item['MesageSendFrom'] = (item && item.MesageSendFrom) ? item.MesageSendFrom : '';
      item['MessageDeliveryMethod'] = (item && item.MessageDeliveryMethod) ? item.MessageDeliveryMethod[0].toUpperCase() + item.MessageDeliveryMethod.slice(1).toLowerCase(): '';
      item['WorkerName'] = (item && item.WorkerName) ? item.WorkerName : '';
      item['MessageSendTo'] = (item && item.MessageSendTo) ? item.MessageSendTo : '';
      item['messageScheduledDateTime'] = (item && item.messageScheduledDateTime) ? this.transform(item.messageScheduledDateTime ,'MM/dd/yyyy, h:mm a') : '';
      item['MessageStatus'] = (item && item.MessageStatus) ? item.MessageStatus[0].toUpperCase() + item.MessageStatus.slice(1) : '';
      item['MessageDeliveryMethod'] = (item && item.MessageDeliveryMethod) ? item.MessageDeliveryMethod : '';
      item['MessageSentDateTime'] = (item && item.MessageSentDateTime) ? this.transform(item.MessageSentDateTime,'MM/dd/yyyy, h:mm a') : '';
      item['createdAt'] = (item && item.created_at) ?  this.localTimezone(item.created_at) : '';
      item['MessageReplyDateTime'] = (item && item.MessageReplyDateTime) ? this.transform(item.MessageReplyDateTime,'MM/dd/yyyy, h:mm a') : '';
      item['msgBody'] = this._sanitizer.bypassSecurityTrustHtml(item.MessageBody) || '';
      item['sid'] = item.SID || '';   
      item['jobId'] = item.jobId || '';
      item['ErrorCode'] = item.ErrorCode || '';
      item['ErrorCodeMessage'] = item.ErrorCode ? TwilioErrorCodeValues[String(item.ErrorCode)].message : '';
      item['ErrorCodeType'] = item.ErrorCode ? TwilioErrorCodeValues[String(item.ErrorCode)].log_level : '';
      item['CommunityDetail'] = item.MessageCommunity ? CommunityDetailsList[item.MessageCommunity] : '';
      item['id']= item.id ? item.id :'';
      item['senderName'] = item.SenderContact && item.SenderContact.name || '';
    });
    return data;
  }

  // To set data table Empty message
  setEmptyMessage() {
    const msg = 'No data to display.';
    if (!this.tableData.length) {
      this.tableData = [{
        'message': msg
      }];
      this.tableData[0][this.tableColumns[0]['prop']] = msg;
      // this.tableColumns[0]['width'] = 160;
    } else {
      if (this.tableData[0].hasOwnProperty('message')) {
        this.tableData.shift();
      }
      // this.tableColumns[0]['width'] = 300;
    }
  }

  transform(value: string, type?) {
    let dateformate: string;
    type ? dateformate = type : dateformate = 'short';
    this.datePipe = new DatePipe('en-US');
    const formateddate = (value ? this.datePipe.transform(value, dateformate) : value);
    return formateddate;
  }

  localTimezone(value:string , format = 'MM/DD/YYYY, h:mm A'){
    return moment.utc(value).local().format(format);
  }

  applyLocalFilters(event, type){
    if (type === 'date') {
      if (event.value === '') {
          if (this.localFilters[event.input.id + '_temp']) {
              delete this.localFilters[event.input.id];
          }
      } else {
          const date = this.transform(event.value).split(',');
          this.localFilters[event.input.id] = date[0];
      }
  } else {
      if (event.target.value === '') {
          delete this.localFilters[event.currentTarget.id];
      } else {
          this.localFilters[event.currentTarget.id] = event.target.value;
      }
  }

  this.tableData = this.filteredItems.filter(item => {
      const notMatchingField = Object.keys(this.localFilters).find(key =>
          this._utilityService.dataTableSearch(item, this.localFilters, key));
      return !notMatchingField;
  });

  this.noRecords = (this.tableData.length < this.itemsPerBatch) ? true : false;
  if (this.table) {
      this.table['offset'] = 0
  }
  this.setEmptyMessage();
  }

  clearLocalFilters(col) {
    this.localFilters[col] = ''
    delete this.localFilters[col];
    this.tableData = this.filteredItems.filter(item => {
        const notMatchingField = Object.keys(this.localFilters).find(key =>
            this._utilityService.dataTableSearch(item, this.localFilters, key));
        return !notMatchingField;
    });

    this.noRecords = (this.tableData.length < this.itemsPerBatch) ? true : false;
    if (this.table) {
        this.table['offset'] = 0
    }
    this.setEmptyMessage();
  }

  openModel(modal, ) {
  this._modalService.open(modal,'sm');
  }

  closeModal() {
    this._modalService.closed();
    this.initColumn()
  }

  viewPopup(raw) {
    this.rowdata=raw;
    this._modalService.open(this.scheduledSmsdetail, 'lg');
  }
  close() {
    this._modalService.closed();
  }
  exportToExcel(){

  }

  loadMoreRecords() {
    this.isLoadMore = true;
    this.loadingIndicator = true;
    this.loadScheduledSMSListData((this.dataList.length/this.itemsPerBatch)+1);
  }

  sendNow(row){
    let payload={
      id :  (row && row.id) ? row.id : ''
    };
    this.loadingIndicator = true;
    this._loader.showPreloader();
    this._notificationService.sendNow(payload).then( async result =>{
      if(result){
        const message = result['message'] ? result['message'] : '';
        this._alertService.success(message);
      }
      this.loadingIndicator = false;
      this._loader.hidePreloader();
    }).catch(error => {
        this._alertService.error((error.error && error.error.message) ||error.message || 'Server Error');
        this.loadingIndicator = false;
        this._loader.hidePreloader();
    });
  }

  resend(row){
    let payload={
      id :  (row && row.id) ? row.id : '',
      resend : true
    };
    this.loadingIndicator = true;
    this._loader.showPreloader();
    this._notificationService.sendNow(payload).then( async result =>{
      if(result){
        const message = result['message'] ? result['message'] : '';
        this._alertService.success(message);
      }
      this.loadingIndicator = false;
      this._loader.hidePreloader();
    }).catch(error => {
        this._alertService.error((error.error && error.error.message) ||error.message || 'Server Error');
        this.loadingIndicator = false;
        this._loader.hidePreloader();
    });
  }

  messageReplyTextInfo(row){
    let info = '';
    if(row && row.MessageReplyText){
      const index= row.MessageReplyText.indexOf("-") + 1;
      const IsStartWithVMS= row.MessageReplyText.slice(0,3) == 'VMS' ? true : false;
      info = (IsStartWithVMS && row.MessageReplyText.slice(index) == '01') ? 'Yes' :  (IsStartWithVMS && row.MessageReplyText.slice(index) == '02') ? 'No' : 'Not Defined' ;
    }
   return info;
  }
}
