import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PreloaderService } from 'shared/services/preloader.service';
import { CommonService } from 'shared/services/common.service';
import { WorkerApi, PricelistItemApi } from 'shared/sdk';
import { PaginationInstance } from 'ngx-pagination';
import { environment } from 'environments/environment';
import { AppStateService } from 'shared/services/app-state.service';
import * as moment from 'moment';
@Component({
  selector: 'app-maketplace-catalog',
  templateUrl: './maketplace-catalog.component.html',
  styleUrls: ['./maketplace-catalog.component.css']
})
export class MaketplaceCatalogComponent implements OnInit {
  accountId: string;
  accessType: string;
  @Input() activeTab: any;
  @Input() parentTab: any;
  @Input() enableFilters: any;
  @Input() fromJobdetails;
  @Input() set gloablFilterData(e) {
    this.isLoadMore = false;
    this.setGlobalFilterObject();
    this._preloader.showPreloader();
    this.getWorkerList(0);
    this.config.currentPage = 1;
  }
  @Input() set worker(e) {
    if (e) {
      this.workerSfdcId = { sfdcId: e };
      this.getWorkerList(0);
      this.showPagination = true;
    } else {
      this.workerList = [];
      this.workerDetails = {};
      this.showPagination = true;
      this.infoMsg = 'Worker Not Assigned';
    }
  }
  @Output() childFilters: EventEmitter<any> = new EventEmitter<any>();
  enableFiltersB = ['regions', 'countries', 'states', 'geoCode', 'workerType', 'workerNames', 'jobSites'];
  globalFilters: any;
  workerList: any[];
  currPage: number;

  maxSize = 7;
  directionLinks = true;
  autoHide = false;
  responsive = false;
  config: PaginationInstance = {
    id: 'advanced',
    itemsPerPage: 10,
    currentPage: 1
  };
  labels: any = {
    previousLabel: '',
    nextLabel: '',
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'page',
    screenReaderCurrentLabel: `You're on page`
  };
  isLoadMore = false;
  itemsPerBatch = 50;
  totalCount = 0;
  recordTypes = ['MSP User', 'PMS User', 'VMS User'];
  workerDetails: any;
  whereCondition: any;
  pricelistIds = [];
  partnerAccountInfo: any;
  partnerPriceList: any;
  currentUser: any;
  @Input() sideBarMenuOpened = false;
  infoMsg = 'Page Loading...';
  viewMsg = true;
  workerSfdcId = {};
  showPagination = false;
  isAdminPartner = false;
  sidebarstate = true;
  constructor(
    private _commonService: CommonService,
    private _preloader: PreloaderService,
    private _workerApi: WorkerApi,
    private _pricelistItemApi: PricelistItemApi,
    private _appState: AppStateService) {
    this.accessType = this._appState.getAccessType();
    this.accountId = this._appState.getSelectedAccount();
    this._commonService.getServiceCatalogInfo().subscribe(res => {
      // To check if view as PMS mode is activated.
      if (localStorage.getItem('viewAsInfo')) {
        const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
        this.isAdminPartner = (viewAsInfo && viewAsInfo.viewAsType === 'Partner' && viewAsInfo.isAdmin);
      }
      if (res && Object.keys(res).length) {
        this.partnerAccountInfo = res;
        // this.getPartnerPriceBook();
      } else {
        const appData = JSON.parse(localStorage.getItem('appData'));
        this.currentUser = appData && appData.user && appData.user;
        // this.getPartnerPriceBook();
      }
      this._commonService.getRightSidebarStateState().subscribe(res => {
          this.sidebarstate = res;
      });
    });
  }

  ngOnInit() {
    this.workerList = [];
    this.workerDetails = {};
    this.childFilters.emit(this.enableFilters);

  }


  setGlobalFilterObject() {
    // if (this.parentTab && this.parentTab === 'allJobsites') {
    //   this.enableFilters = [...this.enableFilters].slice(0, this.enableFilters.length - 1);
    // } else if (this.parentTab && this.parentTab === 'all') {
    //   this.enableFilters = ['workerType', 'workerNames'];
    // }
    const filter = this._commonService.getGlobalFilter(this.enableFilters);
    if (filter && Object.keys(filter).length) {
      this.whereCondition = { ...filter };
      console.log('globalFilterObj>>', this.whereCondition);
    } else {
      this.whereCondition = {}
    }
  }

  onPageChange(number: number) {
    this.config.currentPage = number;
  }

  onPageBoundsCorrection(number: number) {
    this.config.currentPage = number;
  }

  /**
   * Prepair worker img url
   * @param payload In this `workerPickUrl` | `profileImage`
   */
  setProfileImageUrl(payload) {
    let imgpath = environment.baseUrl + '/' + environment.apiVersion;
    let url: string;
    if (imgpath.indexOf('localhost') !== -1) {
      imgpath = 'https://den.serviceo.me/api/v1';
    }
    if (payload && payload.profileImage && payload.url) {
      const imageUrl = `${imgpath}/Containers${payload.url}${payload.profileImage}`;
      url = imageUrl
    } else {
      url = `assets/images/male-circle.png`;
    }
    return url;
  }

  prepareData(res: any[]) {
    res.forEach(obj => {
      obj['isCollapsed'] = true;
      obj['profileImage'] = this.setProfileImageUrl(obj.user);
      if ((obj['serviceContract'] && obj['serviceContract'].length)) {
        obj['CurrencyIsoCode'] = obj.serviceContract[0].CurrencyIsoCode;
        obj['dedicatedCost_perHour'] = (obj['serviceContract'][0]['Vendor_FTE_Hourly_Rate_Overtime__c'])
          ? (obj['serviceContract'][0]['Vendor_FTE_Hourly_Rate_Overtime__c']).toFixed(2) : null;
        obj['dedicatedCost_perMonth'] = (obj['serviceContract'][0]['FTE_Monthly_Rate_Vendor__c'])
          ? (obj['serviceContract'][0]['FTE_Monthly_Rate_Vendor__c']).toFixed(2) : null;
      }
      // account prielist
      if (obj.account && obj.account.Default_Pricelist__c) {
        this.pricelistIds.push(obj.account.Default_Pricelist__c);
      }
      // serviceContract Vendor Pricelist
      if (obj.serviceContract && obj.serviceContract.length) {
        let Vendor_Pricelist__c = obj.serviceContract.map(sc => sc.Vendor_Pricelist__c);
        Vendor_Pricelist__c = Array.from(new Set(Vendor_Pricelist__c));
        this.pricelistIds.push(Vendor_Pricelist__c);
      }
      // worker Pricelist
      if (obj.Worker_Pricelist__c) {
        this.pricelistIds.push(obj.Worker_Pricelist__c);
      }
      // this.prepareFsePriceAndCost(obj);
      this.prepareAvailabilityData(obj);
    });
    return res;
  }


  prepareFsePriceAndCost(worker) {
    if (worker && worker.account && worker.account.pricelist) {
      const pricelist = worker.account.pricelist;
      if (pricelist.PricelistItems && pricelist.PricelistItems.length) {
        worker['CurrencyIsoCode'] = pricelist.PricelistItems[0].CurrencyIsoCode;
        pricelist.PricelistItems.forEach(item => {
          switch (item.SLA__c) {
            case 'NBD': {
              worker['fsePriceNbd'] = (item.PPE_1HR_Standard_Price__c) ? (item.PPE_1HR_Standard_Price__c).toFixed(2) : null;
              break;
            };
            case 'SBD2H': {
              worker['fsePriceSb2d'] = (item.PPE_1HR_Standard_Price__c) ? (item.PPE_1HR_Standard_Price__c).toFixed(2) : null;
              break;
            };
          };

          if (worker.is_FTE_Worker__c && !(worker.dedicatedCost_perHour && worker.dedicatedCost_perMonth)) {
            worker['dedicatedCost_perHour'] = item.UnitPrice ? item.UnitPrice.toFixed(2) : null;
            worker['dedicatedCost_perMonth'] = item.FTE_Monthly_Rate__c ? item.FTE_Monthly_Rate__c.toFixed(2) : null;
          }
        });
      }
    }
  }

  prepareAvailabilityData(worker) {
    const weekStartDate = moment().startOf('week');
    const weekArray = [];
    const naStr = '&nbsp;&nbsp;&nbsp;&nbsp;N&nbsp;/&nbsp;A&nbsp;&nbsp;&nbsp;';
    for (let i = 0; i <= 6; i++) {
      const obj = {};
      const availabilityRecord = (worker.availability && worker.availability.length) ? worker.availability.find((avail) => {
        const d1 = moment(avail.Start_Date__c).format('YYYY-MM-DD');
        const d2 = moment().startOf('week').add(i, 'days').format('YYYY-MM-DD');
        return d1 === d2;
      }) : null;
      if (availabilityRecord) {
        obj['date'] = availabilityRecord.Start_Date__c;
        obj['day'] = moment(availabilityRecord.Start_Date__c).format('ddd');
        obj['availability_start_time'] = availabilityRecord.Available__c ? this._commonService.dateFormate(availabilityRecord.Start_Date_Time__c, 'America/Los_Angeles', 'hh:mm A') : naStr;
        obj['availability_end_time'] = availabilityRecord.Available__c ? this._commonService.dateFormate(availabilityRecord.Worker_Availability_End__c, 'America/Los_Angeles', 'hh:mm A') : naStr;
        obj['isAvailable'] = availabilityRecord.Available__c;
      } else {
        obj['date'] = moment().startOf('week').add(i, 'days');
        obj['day'] = moment().startOf('week').add(i, 'days').format('ddd');
        obj['availability_start_time'] = naStr;
        obj['availability_end_time'] = naStr;
        obj['isAvailable'] = false;
      }
      weekArray.push(obj);
    }
    worker['availability'] = weekArray.reverse();
  }

  getWorkerList(offset) {

    const reqObject = {
      skip: offset,
      limit: this.itemsPerBatch,
      globalFilter: this.whereCondition,
      listType: 'MARKETPLACE-CATALOG'
    }
    if (this.isAdminPartner) {
      Object.assign(reqObject, { isViewAsPMSA: true });
    }
    if (this.workerSfdcId) {
      Object.assign(reqObject, { where: this.workerSfdcId, })
    }
    if (this.workerSfdcId) {
      Object.assign(reqObject, { where: this.workerSfdcId, })
    }
    if (this.partnerAccountInfo && this.partnerAccountInfo.programId) {
      reqObject['masterProjectId'] = this.partnerAccountInfo.programId;
    }
    if (this.partnerAccountInfo && this.partnerAccountInfo.jobsiteId && (this.activeTab === 'workerListId')) {
      reqObject['jobsiteId'] = this.partnerAccountInfo.jobsiteId;
    }
    this.countRecord();
    this._workerApi.getServiceLocator(reqObject).subscribe(res => {
      if (!(res && res.length) && !this.isLoadMore) {
        this.infoMsg = 'No data to display';
      } else {
        this.viewMsg = false;
      }
      this._preloader.hidePreloader();
      if (this.isLoadMore) {
        this.workerList = [...this.workerList, ...this.prepareData(res)]
      } else {
        this.workerList = [...this.prepareData(res)];
      }
      if (this.workerList.length) {
        this.getPartnerPriceBook();
        this.setVendorPriceList();
      }
      // if (this.partnerPriceList && this.workerList.length) {
      //   this.setPartnerPriceList();
      // }
    }, err => {
      this._preloader.hidePreloader();
      console.log('Error: Worker data API not responding');
    });
  }

  /**
   * Get All Price list related to partner account and project
   */
  getPartnerPriceBook() {
    let whereObj;
    if (this.partnerAccountInfo && this.partnerAccountInfo.priceList && this.partnerAccountInfo.priceList.sfdcId && this.partnerAccountInfo.Product2Id) {
      whereObj = { Pricebook2Id: this.partnerAccountInfo.priceList.sfdcId, Product2Id: this.partnerAccountInfo.Product2Id };
    } else if (this.currentUser && this.currentUser.account && this.currentUser.account.Default_Pricelist__c) {
      whereObj = { Pricebook2Id: this.currentUser.account.Default_Pricelist__c };
    }
    if (whereObj) {
      const filter = {
        where: whereObj,
        fields: ['sfdcId', 'Coverage_Hours__c', 'CurrencyIsoCode', 'SLA__c', 'PPE_1HR_Standard_Price__c', 'Product2Id', 'UnitPrice', 'FTE_Monthly_Rate__c'],
        include: [
          {
            relation: 'Product',
            scope: {
              where: { Talent_Type__c: { neq: null }, SLA__c: { inq: ['NBD', 'SBD2H', 'BKFL'] } },
              fields: ['sfdcId,', 'SLA__c', 'Talent_Type__c']
            }
          }
        ]
      }
      this._pricelistItemApi.find(filter).subscribe(res => {
        if (res && res.length) {
          this.partnerPriceList = res;
          if (this.partnerPriceList && this.workerList.length) {
            this.setPartnerPriceList();
          }
        }
      });
    }
  }

  /**
   * fill partner pricelist in worker list
   */
  setPartnerPriceList() {
    let workerTalentTypes = [];
    this.workerList.map(worker => {
      workerTalentTypes = worker.talentInformations.map(w => w.Talent_Type__c);
      if (workerTalentTypes && workerTalentTypes.length) {
        worker['CurrencyIsoCode'] = this.partnerPriceList[0].CurrencyIsoCode;
        this.partnerPriceList.forEach(item => {
          if (item.Product && item.Product.SLA__c && item.Product.Talent_Type__c && workerTalentTypes.includes(item.Product.Talent_Type__c)) {
            switch (item.Product.SLA__c) {
              case 'NBD': {
                worker['fsePriceNbd'] = (item.PPE_1HR_Standard_Price__c) ? (item.PPE_1HR_Standard_Price__c).toFixed(2) : null;
                break;
              };
              case 'SBD2H': {
                worker['fsePriceSbd2h'] = (item.PPE_1HR_Standard_Price__c) ? (item.PPE_1HR_Standard_Price__c).toFixed(2) : null;
                break;
              };
            }
            if (worker.is_FTE_Worker__c && !(worker.dedicatedCost_perHour && worker.dedicatedCost_perMonth)) {
              worker['dedicatedCost_perHour'] = item.UnitPrice ? item.UnitPrice.toFixed(2) : null;
              worker['dedicatedCost_perMonth'] = item.FTE_Monthly_Rate__c ? item.FTE_Monthly_Rate__c.toFixed(2) : null;
            }
          }
        })
      }
    });
  }

  /**
   * fill vendor pricelist in worker list
   */
  setVendorPriceList() {
    let workerTalentTypes = [];
    this.workerList.map(worker => {
      workerTalentTypes = worker.talentInformations.map(w => w.Talent_Type__c);
      if (workerTalentTypes && workerTalentTypes.length && worker.account && worker.account.pricelist && worker.account.pricelist.PricelistItems) {
        // worker['vCurrencyIsoCode'] = this.partnerPriceList[0].CurrencyIsoCode;
        const pricelistItems = worker.account.pricelist.PricelistItems;
        pricelistItems.forEach(item => {
          if (item.Product && item.Product.SLA__c && item.Product.Talent_Type__c && workerTalentTypes.includes(item.Product.Talent_Type__c)) {
            switch (item.Product.SLA__c) {
              case 'NBD': {
                worker['fseCostNbd'] = (item.PPE_1HR_Standard_Price__c) ? (item.PPE_1HR_Standard_Price__c).toFixed(2) : null;
                break;
              };
              case 'SBD2H': {
                worker['fseCostSbd2h'] = (item.PPE_1HR_Standard_Price__c) ? (item.PPE_1HR_Standard_Price__c).toFixed(2) : null;
                break;
              };
            }
            if (worker.is_FTE_Worker__c && !(worker.dedicatedCost_perHour && worker.dedicatedCost_perMonth)) {
              worker['dedicatedCost_perHour'] = item.UnitPrice ? item.UnitPrice.toFixed(2) : null;
              worker['dedicatedCost_perMonth'] = item.FTE_Monthly_Rate__c ? item.FTE_Monthly_Rate__c.toFixed(2) : null;
            }
          }
        })
      }
    });
  }

  countRecord() {
    const findObj = {
      globalFilter: this.whereCondition,
      listType: 'MARKETPLACE-CATALOG-COUNT'
    };
    if (this.isAdminPartner) {
      Object.assign(findObj, { isViewAsPMSA: true });
    }
    if (this.partnerAccountInfo && this.partnerAccountInfo.programId) {
      findObj['masterProjectId'] = this.partnerAccountInfo.programId;
    }
    if (this.partnerAccountInfo && this.partnerAccountInfo.jobsiteId && (this.activeTab === 'workerListId')) {
      findObj['jobsiteId'] = this.partnerAccountInfo.jobsiteId;
    }
    this._workerApi.getServiceLocator(findObj).subscribe(res => {
      if (typeof res === 'number') {
        this.totalCount = res;
      } else {
        this.totalCount = 0;
      }
    }, err => {
      console.log('Error: Worker count API not responding');
    })
  }


  // on load more click
  loadMoreRecords() {
    this.isLoadMore = true;
    this._preloader.showPreloader();
    this.getWorkerList(this.workerList.length);
  }

}
