import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/Rx';
import { io, Socket } from 'socket.io-client-latest';
import { Conversation, ConversationAdaptor } from '../personal-conversation-adapters/conversation';
import { MessageAdaptor } from '../personal-conversation-adapters/message';
import { MemberAdaptor } from '../personal-conversation-adapters/member';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PersonalConversationSocketService {

  socket: Socket;
  lastConversation;
  interval;
  isStarted: boolean = false;
  private chatEvents = new Subject<any>();
  private typingEvents = new Subject<any>();
  private conversationMessages = new Subject<any>();
  private socketconnection = new Subject<any>();
  private readMessage = new Subject<any>();
  private changeConversation = new Subject<any>();
  private seachStatus = new Subject<boolean>();

  private isChannelLeave = new Subject<boolean>();
  constructor(
    private _conversationAdaptor: ConversationAdaptor,
    private _messageAdaptor: MessageAdaptor,
    private _MemberAdaptor:MemberAdaptor
  ) { }

  initializeSocket(){
      if(!this.isStarted) {
          this.isStarted = true;
          this.connectSocket();
      }else{
          console.log('Personal Socket connected already....');
      }
  }
  
  connectSocket() {
    const token = localStorage.getItem('accessToken');
    const url = environment.messengerApiUrl;

    const socket:Socket = io(url, {
      transports: ["websocket", "polling"],
      reconnection: true,
      auth: {Authorization: token} 
    });
    this.socket = socket;

    socket.on("reconnect_attempt", (attempt) => {
      console.log('Trying to reconnect: Attempt ', attempt);
    });

    socket.on("reconnect_error", (error) => {
      console.error('Error occured while reconnecting ', error);
    });

    socket.on("connect_error", (error) => { 
      console.error("An error occured while connecting to socket. ", error)
    });

   
 
    socket.on('message', (msgData) => {
      //console.log('socket message >>> ', msgData);
      if(msgData && msgData.data && msgData.data.data && msgData.data.actionType =="DOCUMENT"){
        msgData.data.data['isFile'] = true;
        msgData.data.data.channelId = parseInt(msgData.data.data.channelId);
        msgData.data.data.document['url'] = msgData.data.data.message;
      }
      if(msgData && msgData.data && msgData.data.data && msgData.data.actionType =="FORWARD" && msgData.data.data.parentDocument !== null){
        msgData.data.data['isFile'] = true;
        msgData.data.data.channelId = parseInt(msgData.data.data.channelId);
        msgData.data.data.document = msgData.data.data.parentDocument;
      }
      let finalData = this._messageAdaptor.adapt(JSON.parse(JSON.stringify(msgData.data.data)));
      msgData.data.data = finalData;

      if (msgData && msgData.data && msgData.data.data && msgData.data.data.channel && msgData.data.data.channel.type !== 'TASK') {
        this.conversationMessages.next(msgData.data);
      } else {
        if (!this._hideAutoMessages() || (this._hideAutoMessages() && !msgData.data.data.isAutoMessage)) {
          this.conversationMessages.next(msgData.data);
        }
      }
    });

    // client-side
    socket.on("connect", () => {
      this.socketconnection.next(false);
      console.log("socket connectd automatically", socket.id);
    });

    socket.on('disconnect', reason => {
      console.log('messenger socket | disconnect socket=> ', reason);
      this.socketconnection.next(true);
      socket.connect();
    });

    socket.on('markRead',(data) => {
      this.readMessage.next(data);
    });
    socket.on('chatRoom', (socketData) => {
      if(socketData && socketData.data && socketData.data.data){
        //socketData.data['relatedType'] = socketData.data.actionType;
        //socketData.data.data['type'] = socketData.data.data.channelType;
        socketData.data.data['title'] = socketData.data.data.channelName;
        socketData.data.data['conversation_id'] = socketData.data.data.channelId;
      }
      if (socketData && socketData.data && socketData.data.data && socketData.data.data.channel && socketData.data.data.channel.type && socketData.data.data.channel.type !== 'TASK') {
        this.chatEvents.next(socketData.data);
      } else {
        if (!this._hideAutoMessages() || (this._hideAutoMessages() && socketData.data.data.hasManualMessage)) {
          console.log("socket chatRoom event", socketData.data);
          this.chatEvents.next(socketData.data);
        }
      }
    });
    socket.on('userTyping', (socketData) => {
      if(socketData && socketData.data && socketData.data.data && socketData.data.data.user){
        let typingObject ={};
        typingObject['user'] = this._MemberAdaptor.adapt(JSON.parse(JSON.stringify(socketData.data.data.user)));
        typingObject['conversation_id'] =socketData.data.data.channelId;

        //console.log("socket typing data final 111", typingObject)
      }
    });
  }

  registerConversation(conversation) {
    clearTimeout(this.interval);
    if (this.socket && this.socket.connected) {
      if(this.lastConversation) {
        this.socket.emit('unsubscribe_conversation', this.lastConversation);
      }
      this.socket.emit('subscribe_conversation', conversation);
      this.lastConversation = conversation;
    } else {
      this.connectSocket();
      this.interval = setTimeout(() => {
        this.registerConversation(conversation);
      }, 1000);
    }
  }

  CheckSocketConnection(): Observable<any> {
    return this.socketconnection.asObservable();
  }

  getConversationMessages(): Observable<any> {
    return this.conversationMessages.asObservable();
  }

  getMessageRead(): Observable<any> {
    return this.readMessage.asObservable();
  }

  getChatEvents(): Observable<any> {
    return this.chatEvents.asObservable();
  }

  getTypingEvents(): Observable<any> {
    return this.typingEvents.asObservable();
  }
  notifyOnLeave(status){
    this.isChannelLeave.next(status);
  }
  getChannelLeaveAlert(): Observable<any> {
    return this.isChannelLeave.asObservable();
  }

  onChangeConversation(): Observable<any> {
    return this.changeConversation.asObservable();
  }

  postMessage(message) {
    //this.socket.emit('conv_message_event', message);
  }

  markAsRead(data){
    this.socket.emit('markMessageRead', data);
  }

  typeEventEmit(data){
    //console.log("user typing socket event emit");
    this.socket.emit('typing', data);
  }
  onChangeConversationList(data){
    this.changeConversation.next(data);
  }

  searchingStatus(status) {
    this.seachStatus.next(status);
  }
  getsearchingStatus(): Observable<any> {
    return this.seachStatus.asObservable();
  }
  
  socketDisconnect() {
    this.socket.disconnect();
  }

  private _hideAutoMessages() {
    return localStorage.hasOwnProperty('hideAutoMessages') ? JSON.parse(localStorage.getItem('hideAutoMessages')) : true;
  }

}
