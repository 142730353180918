export const TwilioErrorCodeValues = {
  '400': {
    message: 'Bad Request',
    log_level: 'ERROR',
  },
  '404': {
    message: 'Not Found',
    log_level: 'ERROR',
  },
  '410': {
    message: 'Unknown Error Code',
    log_level: 'ERROR',
  },
  '503': {
    message: 'Internal Error',
    log_level: 'ERROR',
  },
  '10001': {
    message: 'Account is not active',
    log_level: 'ERROR',
  },
  '10002': {
    message: 'Trial accounts do not support the feature you tried to use',
    log_level: 'ERROR',
  },
  '10003': {
    message: 'Incoming call rejected due to inactive account',
    log_level: 'ERROR',
  },
  '10004': {
    message: 'Call concurrency limit exceeded',
    log_level: 'ERROR',
  },
  '11100': {
    message: 'Invalid URL format',
    log_level: 'ERROR',
  },
  '11200': {
    message: 'HTTP retrieval failure',
    log_level: 'ERROR',
  },
  '11201': {
    message: 'TCP connection timed out',
    log_level: 'ERROR',
  },
  '11202': {
    message: 'TCP connection refused',
    log_level: 'ERROR',
  },
  '11203': {
    message: 'HTTP communication total time out triggered',
    log_level: 'ERROR',
  },
  '11205': {
    message: 'HTTP connection failure',
    log_level: 'ERROR',
  },
  '11206': {
    message: 'HTTP protocol violation',
    log_level: 'ERROR',
  },
  '11210': {
    message: 'HTTP bad host name',
    log_level: 'ERROR',
  },
  '11215': {
    message: 'HTTP too many redirects',
    log_level: 'ERROR',
  },
  '11220': {
    message: 'SSL/TLS Handshake Error',
    log_level: 'ERROR',
  },
  '11235': {
    message: 'Certificate Invalid - Domain Mismatch',
    log_level: 'ERROR',
  },
  '11236': {
    message: 'Certificate Invalid - Certificate Expired',
    log_level: 'ERROR',
  },
  '11237': {
    message: 'Certificate Invalid - Could not find path to certificate',
    log_level: 'ERROR',
  },
  '11240': {
    message: 'HTTP connection edge location is invalid',
    log_level: 'ERROR',
  },
  '11241': {
    message: 'HTTP connection edge location is not supported',
    log_level: 'ERROR',
  },
  '11242': {
    message: 'HTTP connection over Twilio Interconnect is not allowed',
    log_level: 'ERROR',
  },
  '11243': {
    message: 'HTTP retry policy is invalid',
    log_level: 'ERROR',
  },
  '11251': {
    message: 'Fatal protocol violation',
    log_level: 'ERROR',
  },
  '11300': {
    message: 'Invalid template URL',
    log_level: 'ERROR',
  },
  '11310': {
    message: 'Invalid template token',
    log_level: 'ERROR',
  },
  '11320': {
    message: 'Invalid template unclosed brackets',
    log_level: 'ERROR',
  },
  '11750': {
    message: 'TwiML response body too large',
    log_level: 'ERROR',
  },
  '11751': {
    message: 'Media Message - Media exceeds messaging provider size limit',
    log_level: 'ERROR',
  },
  '11770': {
    message: 'Empty response body',
    log_level: 'ERROR',
  },
  '12100': {
    message: 'Document parse failure',
    log_level: 'ERROR',
  },
  '12101': {
    message: 'Invalid Twilio Markup XML version',
    log_level: 'ERROR',
  },
  '12102': {
    message: 'The root element must be Response',
    log_level: 'ERROR',
  },
  '12200': {
    message: 'Schema validation warning',
    log_level: 'WARNING',
  },
  '12300': {
    message: 'Invalid Content-Type',
    log_level: 'ERROR',
  },
  '12301': {
    message: 'Invalid Upload Content-Type',
    log_level: 'ERROR',
  },
  '12400': {
    message: 'Internal Failure',
    log_level: 'ERROR',
  },
  '13000': {
    message: 'Conference Noun cannot be mixed with Number nouns',
    log_level: 'WARNING',
  },
  '13110': {
    message: 'Annotate: Annotate must contain one valid nested element',
    log_level: 'ERROR',
  },
  '13111': {
    message: 'Annotate: Annotate must contain only one of element X',
    log_level: 'ERROR',
  },
  '13112': {
    message: 'Annotate: Invalid nested element',
    log_level: 'WARNING',
  },
  '13120': {
    message: 'Annotate->BillingReferenceTag cannot be over 128 characters',
    log_level: 'ERROR',
  },
  '13201': {
    message: 'Dial: Cannot Dial out from a Dial Call Segment',
    log_level: 'ERROR',
  },
  '13210': {
    message: 'Dial: Invalid method value',
    log_level: 'WARNING',
  },
  '13211': {
    message: 'Dial: Invalid ifMachine value',
    log_level: 'WARNING',
  },
  '13212': {
    message: 'Dial: Invalid timeout value',
    log_level: 'WARNING',
  },
  '13213': {
    message: 'Dial: Invalid hangupOnStar value',
    log_level: 'WARNING',
  },
  '13214': {
    message: 'Dial: Invalid callerId value',
    log_level: 'WARNING',
  },
  '13215': {
    message: 'Dial: Invalid nested element',
    log_level: 'WARNING',
  },
  '13216': {
    message: 'Invalid timeLimit value',
    log_level: 'WARNING',
  },
  '13217': {
    message: 'Dial: Invalid record value',
    log_level: 'WARNING',
  },
  '13218': {
    message: 'Dial: Invalid sequential value',
    log_level: 'WARNING',
  },
  '13219': {
    message: 'Dial: Invalid answerOnBridge value',
    log_level: 'WARNING',
  },
  '13220': {
    message: 'Dial: Invalid ringTone value',
    log_level: 'WARNING',
  },
  '13221': {
    message: 'Dial->Number: Invalid method value',
    log_level: 'WARNING',
  },
  '13222': {
    message: 'Dial->Number: Invalid sendDigits value',
    log_level: 'WARNING',
  },
  '13223': {
    message: 'Dial: Invalid phone number format',
    log_level: 'WARNING',
  },
  '13224': {
    message:
      'Dial: Twilio does not support calling this number or the number is invalid',
    log_level: 'WARNING',
  },
  '13225': {
    message: 'Dial: Call blocked by Twilio block list',
    log_level: 'WARNING',
  },
  '13226': {
    message: 'Dial: Invalid country code',
    log_level: 'WARNING',
  },
  '13227': {
    message: 'Geo Permission configuration is not permitting call',
    log_level: 'WARNING',
  },
  '13230': {
    message: 'Dial->Conference: Invalid muted value',
    log_level: 'WARNING',
  },
  '13231': {
    message: 'Dial->Conference: Invalid endConferenceOnExit value',
    log_level: 'ERROR',
  },
  '13232': {
    message: 'Dial->Conference: Invalid startConferenceOnEnter value',
    log_level: 'WARNING',
  },
  '13233': {
    message: 'Dial->Conference: Invalid waitUrl',
    log_level: 'WARNING',
  },
  '13234': {
    message: 'Dial->Conference: Invalid waitMethod',
    log_level: 'WARNING',
  },
  '13235': {
    message: 'Dial->Conference: Invalid beep value',
    log_level: 'WARNING',
  },
  '13236': {
    message: 'Dial->Conference: Invalid Conference Sid',
    log_level: 'WARNING',
  },
  '13237': {
    message: 'Dial->Conference: Invalid Conference Name',
    log_level: 'WARNING',
  },
  '13238': {
    message:
      'Dial->Conference: Invalid Verb used in waitUrl, holdUrl, or announceUrl TwiML',
    log_level: 'ERROR',
  },
  '13239': {
    message: 'Dial->Conference: Invalid Trim Value',
    log_level: 'ERROR',
  },
  '13240': {
    message: 'Dial->Conference: Invalid Whisper SID',
    log_level: 'ERROR',
  },
  '13241': {
    message: 'Dial->SIP: Invalid method value',
    log_level: 'WARNING',
  },
  '13242': {
    message: 'Dial->SIP: Invalid sendDigits value',
    log_level: 'WARNING',
  },
  '13243': {
    message: 'Dial->SIP: Invalid SIP URI',
    log_level: 'WARNING',
  },
  '13244': {
    message: 'Dial: No SIP Authorization',
    log_level: 'WARNING',
  },
  '13245': {
    message: 'Dial: Not allowed in this API version',
    log_level: 'WARNING',
  },
  '13246': {
    message: 'Dial: SIP dialing not enabled for this account',
    log_level: 'WARNING',
  },
  '13247': {
    message: 'Dial: Invalid From number (caller ID)',
    log_level: 'ERROR',
  },
  '13248': {
    message: 'Dial: Invalid callerID, too long',
    log_level: 'WARNING',
  },
  '13249': {
    message: 'Dial: Invalid username or password attribute',
    log_level: 'WARNING',
  },
  '13250': {
    message: 'Dial: Too many URIs passed',
    log_level: 'WARNING',
  },
  '13251': {
    message: 'Dial: Too many headers passed',
    log_level: 'WARNING',
  },
  '13252': {
    message: 'Dial: Invalid header name',
    log_level: 'WARNING',
  },
  '13253': {
    message: 'Dial: Header is too long',
    log_level: 'WARNING',
  },
  '13254': {
    message:
      'Dial->Sip: SIP URI DNS does not resolve or resolves to an non-public IP address',
    log_level: 'WARNING',
  },
  '13255': {
    message: 'Dialing .sip.twilio.com addresses is not currently allowed',
    log_level: 'WARNING',
  },
  '13256': {
    message: 'Invalid recordingStatusCallback URL',
    log_level: 'WARNING',
  },
  '13257': {
    message: 'Invalid transcribeCallback URL',
    log_level: 'ERROR',
  },
  '13258': {
    message: 'Dial->Sim not supported in this realm',
    log_level: 'ERROR',
  },
  '13299': {
    message: '2010 Conference API feature requested using 2008 API',
    log_level: 'ERROR',
  },
  '13310': {
    message: 'Gather: Invalid finishOnKey value',
    log_level: 'WARNING',
  },
  '13311': {
    message: 'Gather: Invalid finishOnKey value',
    log_level: 'WARNING',
  },
  '13312': {
    message: 'Gather: Invalid method value',
    log_level: 'WARNING',
  },
  '13313': {
    message: 'Gather: Invalid timeout value',
    log_level: 'WARNING',
  },
  '13314': {
    message: 'Gather: Invalid numDigits value',
    log_level: 'WARNING',
  },
  '13320': {
    message: 'Gather: Invalid nested verb',
    log_level: 'WARNING',
  },
  '13321': {
    message: 'Gather->Say: Invalid voice value',
    log_level: 'WARNING',
  },
  '13322': {
    message: 'Gather->Say: Invalid loop value',
    log_level: 'WARNING',
  },
  '13325': {
    message: 'Gather->Play: Invalid Content-Type',
    log_level: 'ERROR',
  },
  '13326': {
    message: 'Gather: Invalid input value',
    log_level: 'WARNING',
  },
  '13327': {
    message: 'Gather: Invalid speechTimeout value',
    log_level: 'WARNING',
  },
  '13328': {
    message: 'Gather: Invalid maxSpeechTime value',
    log_level: 'WARNING',
  },
  '13329': {
    message: 'Gather: Invalid partialResultCallbackMethod value',
    log_level: 'WARNING',
  },
  '13330': {
    message: 'Gather: Invalid hints value',
    log_level: 'WARNING',
  },
  '13331': {
    message: 'Gather: Invalid language value',
    log_level: 'WARNING',
  },
  '13332': {
    message: 'Gather: Invalid bargeIn value',
    log_level: 'WARNING',
  },
  '13333': {
    message: 'Gather: Invalid profanityFilter value',
    log_level: 'WARNING',
  },
  '13334': {
    message: 'Gather: Invalid model value',
    log_level: 'WARNING',
  },
  '13335': {
    message: 'Gather: speechTimeout auto cannot be used with model default',
    log_level: 'WARNING',
  },
  '13337': {
    message:
      'Gather: callback must be over HTTPS when using gather with PCI compliance',
    log_level: 'ERROR',
  },
  '13338': {
    message: 'Gather: Invalid actionOnEmptyResult value',
    log_level: 'ERROR',
  },
  '13340': {
    message: 'Gather: Degraded Speech ',
    log_level: 'ERROR',
  },
  '13410': {
    message: 'Play: Invalid loop value',
    log_level: 'WARNING',
  },
  '13420': {
    message: 'Play: Invalid Content-Type',
    log_level: 'ERROR',
  },
  '13430': {
    message: 'Play: Invalid DTMF String',
    log_level: 'WARNING',
  },
  '13510': {
    message: 'Say: Invalid loop value',
    log_level: 'WARNING',
  },
  '13511': {
    message: 'Say: Invalid voice value',
    log_level: 'WARNING',
  },
  '13512': {
    message: 'Gather element has an invalid "language" attribute value',
    log_level: 'WARNING',
  },
  '13513': {
    message: 'Say: Invalid rate value',
    log_level: 'WARNING',
  },
  '13520': {
    message: 'Say: Invalid text',
    log_level: 'WARNING',
  },
  '13521': {
    message: '`<Say>` element character limits exceeded',
    log_level: 'WARNING',
  },
  '13610': {
    message: 'Record: Invalid method value',
    log_level: 'WARNING',
  },
  '13611': {
    message: 'Record: Invalid timeout value',
    log_level: 'WARNING',
  },
  '13612': {
    message: 'Record: Invalid maxLength value',
    log_level: 'WARNING',
  },
  '13613': {
    message: 'Record: Invalid finishOnKey value',
    log_level: 'WARNING',
  },
  '13614': {
    message: 'Record: Invalid transcribe value',
    log_level: 'WARNING',
  },
  '13615': {
    message: 'Record: maxLength too high for transcription',
    log_level: 'WARNING',
  },
  '13616': {
    message: 'Record: playBeep must be true or false',
    log_level: 'WARNING',
  },
  '13617': {
    message: 'Record: Recording length is out of range for transcription',
    log_level: 'WARNING',
  },
  '13618': {
    message: 'Record: Recording not available for transcription',
    log_level: 'WARNING',
  },
  '13619': {
    message:
      'Record: Transcription feature not available for this type of recording.',
    log_level: 'WARNING',
  },
  '13620': {
    message: 'Record: Transcription not available for this recording',
    log_level: 'WARNING',
  },
  '13621': {
    message: "Invalid 'recordingStatusCallbackEvent'",
    log_level: 'ERROR',
  },
  '13699': {
    message: 'Record: Invalid trim value',
    log_level: 'WARNING',
  },
  '13710': {
    message: 'Redirect: Invalid method value',
    log_level: 'WARNING',
  },
  '13750': {
    message: 'Twiml verb not supported by this API version.',
    log_level: 'ERROR',
  },
  '13801': {
    message: 'Refer not allowed on non-SIP call legs',
    log_level: 'ERROR',
  },
  '13802': {
    message: 'Dial: No referUrl attribute specified',
    log_level: 'ERROR',
  },
  '13803': {
    message: 'SMS verb not supported in this realm',
    log_level: 'ERROR',
  },
  '13804': {
    message: 'AddOns are not supported in this realm',
    log_level: 'ERROR',
  },
  '13805': {
    message: 'Trial account call duration exceeded 10 minute limit',
    log_level: 'WARNING',
  },
  '13810': {
    message: 'Reject: Invalid cause',
    log_level: 'WARNING',
  },
  '13910': {
    message: 'Pause: Invalid length value',
    log_level: 'WARNING',
  },
  '14101': {
    message: 'Invalid "To" attribute',
    log_level: 'WARNING',
  },
  '14102': {
    message: 'Invalid "From" attribute',
    log_level: 'WARNING',
  },
  '14103': {
    message: 'Invalid Body',
    log_level: 'WARNING',
  },
  '14104': {
    message: 'Invalid Method attribute',
    log_level: 'WARNING',
  },
  '14105': {
    message: 'Invalid statusCallback attribute',
    log_level: 'WARNING',
  },
  '14106': {
    message: 'Document retrieval limit reached',
    log_level: 'WARNING',
  },
  '14107': {
    message: 'SMS send rate limit exceeded',
    log_level: 'ERROR',
  },
  '14108': {
    message: 'From phone number not SMS capable',
    log_level: 'WARNING',
  },
  '14109': {
    message: 'TwiML Reply message limit exceeded',
    log_level: 'WARNING',
  },
  '14110': {
    message: 'Invalid Verb for SMS Reply',
    log_level: 'WARNING',
  },
  '14111': {
    message: 'Invalid To phone number for Trial mode',
    log_level: 'WARNING',
  },
  '14201': {
    message: 'Enqueue: Invalid method value',
    log_level: 'ERROR',
  },
  '14202': {
    message: 'Enqueue: Invalid waitUrl',
    log_level: 'ERROR',
  },
  '14203': {
    message: 'Enqueue: Invalid Enqueue action url',
    log_level: 'ERROR',
  },
  '14204': {
    message: 'Enqueue: Queue name too short',
    log_level: 'ERROR',
  },
  '14205': {
    message: 'Enqueue: Queue name too long',
    log_level: 'ERROR',
  },
  '14206': {
    message: 'Enqueue: Invalid waitUrlMethod value',
    log_level: 'ERROR',
  },
  '14207': {
    message: ' Enqueue: The targeted queue reached max queue size',
    log_level: 'WARNING',
  },
  '14210': {
    message: 'Dial->Queue: Invalid whisper method',
    log_level: 'ERROR',
  },
  '14211': {
    message: 'Dial->Queue: Invalid whisper url',
    log_level: 'ERROR',
  },
  '14212': {
    message: 'Dial->Queue: queue name too short',
    log_level: 'ERROR',
  },
  '14213': {
    message: 'Dial->Queue: queue name too long',
    log_level: 'ERROR',
  },
  '14215': {
    message: 'Dial->Queue: Invalid ReservationSid. Unable to dequeue',
    log_level: 'ERROR',
  },
  '14217': {
    message: 'Dial->Queue: Could not find or accept provided reservationSid',
    log_level: 'ERROR',
  },
  '14218': {
    message: 'Dial->Queue: Could not update worker to provided activity',
    log_level: 'ERROR',
  },
  '14219': {
    message: 'TaskRouter Dial Queue not supported in this realm',
    log_level: 'ERROR',
  },
  '14220': {
    message: 'Enqueue: Provided Workflow was not a valid sid',
    log_level: 'ERROR',
  },
  '14221': {
    message: 'Enqueue: Provided Attributes JSON was not valid',
    log_level: 'ERROR',
  },
  '14222': {
    message: 'Enqueue: Could not create Task',
    log_level: 'ERROR',
  },
  '14223': {
    message: 'Enqueue: Unable to cleanup task',
    log_level: 'ERROR',
  },
  '14226': {
    message: 'TaskRouter Enqueue not supported in this realm',
    log_level: 'ERROR',
  },
  '14230': {
    message: 'Dial->Conference: Invalid WorkflowSid',
    log_level: 'ERROR',
  },
  '14231': {
    message: 'Dial->Conference: Provided Attributes was not valid JSON ',
    log_level: 'ERROR',
  },
  '14232': {
    message: 'Dial->Conference: Invalid Priority',
    log_level: 'ERROR',
  },
  '14233': {
    message: 'Dial->Conference: Invalid Timeout',
    log_level: 'ERROR',
  },
  '14234': {
    message: 'Dial->Conference: Unable to create task',
    log_level: 'ERROR',
  },
  '14235': {
    message: 'Dial->Conference: Unable to cleanup task',
    log_level: 'ERROR',
  },
  '14236': {
    message: 'Dial->Conference: Invalid ReservationSid',
    log_level: 'ERROR',
  },
  '14237': {
    message: 'Dial->Conference: Invalid PostWorkActivitySid',
    log_level: 'ERROR',
  },
  '14238': {
    message: 'Dial->Conference: Unable to accept Reservation',
    log_level: 'ERROR',
  },
  '14239': {
    message: 'Dial->Conference: Unable to update Worker',
    log_level: 'ERROR',
  },
  '14240': {
    message: 'Max concurrent Workers exceeded',
    log_level: 'ERROR',
  },
  '14241': {
    message:
      'start_date passed to TaskRouter statistics is older than 30 days.',
    log_level: 'WARNING',
  },
  '14300': {
    message: 'Start: Invalid nested noun value',
    log_level: 'ERROR',
  },
  '15000': {
    message: 'Call Progress: Internal Twilio Error',
    log_level: 'ERROR',
  },
  '15002': {
    message: 'Call Progress: Queue Timeout',
    log_level: 'ERROR',
  },
  '15003': {
    message: 'Call Progress: Warning Response to Callback URL',
    log_level: 'WARNING',
  },
  '15004': {
    message:
      'Action Callback URL must be an absolute URL when using TwiML to update in-progress calls',
    log_level: 'ERROR',
  },
  '15009': {
    message: 'Internal Server Error',
    log_level: 'ERROR',
  },
  '16000': {
    message: 'Whisper Not Available on Twilio Conference',
    log_level: 'ERROR',
  },
  '16001': {
    message: 'Conference is not bridged',
    log_level: 'ERROR',
  },
  '16002': {
    message: 'Failed to validate conference attributes',
    log_level: 'ERROR',
  },
  '16003': {
    message: 'Could not recognize conference sid or friendly name',
    log_level: 'ERROR',
  },
  '16010': {
    message: 'Conference Event: Internal Twilio Error',
    log_level: 'ERROR',
  },
  '16011': {
    message: 'Conference Event: Error Response to Callback URL',
    log_level: 'ERROR',
  },
  '16020': {
    message: 'Conference is full',
    log_level: 'ERROR',
  },
  '16021': {
    message:
      'Failed to join conference due to account concurrency limit exceeded',
    log_level: 'ERROR',
  },
  '16022': {
    message: 'Conference does not exist or is completed',
    log_level: 'ERROR',
  },
  '16023': {
    message:
      "Dial->Conference: Invalid participant label, must not exceed 128 characters, must not be a CallSid, must not contain '/'",
    log_level: 'ERROR',
  },
  '16024': {
    message:
      "Invalid participant label, must not exceed 128 characters, must not be a CallSid, must not contain '/'",
    log_level: 'ERROR',
  },
  '16025': {
    message:
      'Dial->Conference: Participant label is in use by another participant',
    log_level: 'ERROR',
  },
  '16026': {
    message: 'Participant label is in use by another participant',
    log_level: 'ERROR',
  },
  '16027': {
    message: 'Participant to be whispered is on hold',
    log_level: 'ERROR',
  },
  '16028': {
    message: 'Participant to be whispered is not present in the conference',
    log_level: 'ERROR',
  },
  '16099': {
    message: 'Unexpected conference status',
    log_level: 'ERROR',
  },
  '16101': {
    message: 'Voice Recording : Unavailable because duration is too short',
    log_level: 'WARNING',
  },
  '16102': {
    message: 'Voice Recording: Unavailable because recording is silent',
    log_level: 'WARNING',
  },
  '16104': {
    message: 'Voice Recording: Unavailable due to encryption failure',
    log_level: 'ERROR',
  },
  '16105': {
    message: 'Voice Recording: Unavailable due to no valid public keys',
    log_level: 'ERROR',
  },
  '16106': {
    message: 'Voice Recording: Unavailable due to internal encryption error',
    log_level: 'ERROR',
  },
  '16107': {
    message: 'Voice Recording: Encrypted with alternate public key',
    log_level: 'ERROR',
  },
  '16108': {
    message: 'Voice Recording: Request failed due to concurrent recordings',
    log_level: 'ERROR',
  },
  '16109': {
    message: 'Voice Recording: Cannot fetch .mp3 encrypted recording',
    log_level: 'ERROR',
  },
  '16110': {
    message: 'Internal failure when bulk deleting recordings from your account',
    log_level: 'ERROR',
  },
  '16111': {
    message:
      'Voice Recording: Upload file to external AWS S3 bucket failed (Invalid Configuration)',
    log_level: 'ERROR',
  },
  '16112': {
    message:
      'Voice Recording: Upload file to external AWS S3 bucket failed (Access Denied)',
    log_level: 'ERROR',
  },
  '16113': {
    message:
      'Voice Recording: Cannot download a dual-channel presentation of a mono recording',
    log_level: 'WARNING',
  },
  '17000': {
    message: 'Forbidden to access data',
    log_level: 'ERROR',
  },
  '17001': {
    message: "Completed summary for this call wasn't found",
    log_level: 'ERROR',
  },
  '17002': {
    message: 'This call ended more than 30 days ago',
    log_level: 'ERROR',
  },
  '17007': {
    message: 'Voice Insights Advanced Features not enabled',
    log_level: 'ERROR',
  },
  '17008': {
    message: 'Internal Server Error - Query Timeout',
    log_level: 'ERROR',
  },
  '17009': {
    message: 'Internal Server Error',
    log_level: 'ERROR',
  },
  '17400': {
    message: 'Invalid query parameter',
    log_level: 'ERROR',
  },
  '19003': {
    message:
      'Contact with the unique_customer_provided_id provided already exists',
    log_level: 'ERROR',
  },
  '19004': {
    message: 'Invalid or missing Contact input',
    log_level: 'ERROR',
  },
  '19005': {
    message: 'Contact validation error',
    log_level: 'ERROR',
  },
  '19010': {
    message: 'Invalid contact search request',
    log_level: 'ERROR',
  },
  '19011': {
    message:
      'When updating a contact, invalid JSON syntax or invalid field that cannot be updated by this endpoint',
    log_level: 'ERROR',
  },
  '19012': {
    message: 'When updating a contact at least one field should be updated',
    log_level: 'ERROR',
  },
  '19013': {
    message:
      'At least one of the following fields is required for a contact: first_name, middle_name, last_name, legal_name, preferred_name, unique_customer_provided_id or channel',
    log_level: 'ERROR',
  },
  '19014': {
    message:
      'Can fetch contact either by unique_customer_provided_id or channel ',
    log_level: 'ERROR',
  },
  '19020': {
    message: 'Contact with the provided channel value already exists',
    log_level: 'ERROR',
  },
  '19021': {
    message: 'Only one channel can be set as primary',
    log_level: 'ERROR',
  },
  '19022': {
    message: 'Invalid channel',
    log_level: 'ERROR',
  },
  '19023': {
    message: 'Invalid channel type',
    log_level: 'ERROR',
  },
  '19024': {
    message: 'Invalid channel input',
    log_level: 'ERROR',
  },
  '19025': {
    message: 'Channel validation error',
    log_level: 'ERROR',
  },
  '19026': {
    message: 'Maximum number of channels allowed reached',
    log_level: 'ERROR',
  },
  '19027': {
    message: 'Invalid Channel Description',
    log_level: 'ERROR',
  },
  '19028': {
    message: 'Channel value can not be updated',
    log_level: 'ERROR',
  },
  '19029': {
    message: 'When updating a channel at least one field should be updated',
    log_level: 'ERROR',
  },
  '19030': {
    message: 'Invalid location type',
    log_level: 'ERROR',
  },
  '19031': {
    message: 'Maximum number of locations allowed reached ',
    log_level: 'ERROR',
  },
  '19032': {
    message: 'Invalid location input',
    log_level: 'ERROR',
  },
  '19033': {
    message: 'Location validation error',
    log_level: 'ERROR',
  },
  '19034': {
    message: 'Invalid country code',
    log_level: 'ERROR',
  },
  '19035': {
    message: 'Invalid page size, it must be between 1 and 25 if specified',
    log_level: 'ERROR',
  },
  '19036': {
    message: 'Invalid page token',
    log_level: 'ERROR',
  },
  '19037': {
    message: 'When updating a location at least one field should be updated',
    log_level: 'ERROR',
  },
  '19038': {
    message: 'Page token must be bigger than or equal to 0',
    log_level: 'ERROR',
  },
  '19040': {
    message: 'Custom Field provided is not defined',
    log_level: 'ERROR',
  },
  '19041': {
    message: 'Invalid or missing Custom Field input',
    log_level: 'ERROR',
  },
  '19042': {
    message: 'Custom Field validation error',
    log_level: 'ERROR',
  },
  '19043': {
    message: 'Field definition name already exists',
    log_level: 'ERROR',
  },
  '19044': {
    message: 'Field definition name exceeded maximum length',
    log_level: 'ERROR',
  },
  '19045': {
    message:
      'Field definition type is invalid; data types that are supported are text, date, and number',
    log_level: 'ERROR',
  },
  '19046': {
    message: 'Number of custom field definitions exceeded limit',
    log_level: 'ERROR',
  },
  '19047': {
    message: 'Field definition name cannot be empty',
    log_level: 'ERROR',
  },
  '19048': {
    message: 'Input request body is not properly json formatted',
    log_level: 'ERROR',
  },
  '19049': {
    message: 'Custom field definition provided is not defined',
    log_level: 'ERROR',
  },
  '19050': {
    message: 'Internal Server Error',
    log_level: 'ERROR',
  },
  '19052': {
    message: 'Invalid page size for custom field definition',
    log_level: 'ERROR',
  },
  '19053': {
    message:
      'Field definition name cannot be a duplicate of an existing Twilio-defined field',
    log_level: 'ERROR',
  },
  '19054': {
    message: 'Expected Unique form key in input request is missing',
    log_level: 'ERROR',
  },
  '19055': {
    message:
      'When updating a channel, invalid JSON syntax or invalid field that cannot be updated by this endpoint',
    log_level: 'ERROR',
  },
  '19056': {
    message: 'Input request content type is invalid',
    log_level: 'ERROR',
  },
  '19057': {
    message: 'Server unavailable or busy',
    log_level: 'ERROR',
  },
  '20001': {
    message: 'Unknown parameters',
    log_level: 'WARNING',
  },
  '20002': {
    message: 'Invalid FriendlyName',
    log_level: 'ERROR',
  },
  '20003': {
    message: 'Permission Denied',
    log_level: 'ERROR',
  },
  '20004': {
    message: 'Method not allowed',
    log_level: 'ERROR',
  },
  '20005': {
    message: 'Account not active',
    log_level: 'ERROR',
  },
  '20006': {
    message: 'Access Denied',
    log_level: 'ERROR',
  },
  '20007': {
    message: 'Page size too large',
    log_level: 'ERROR',
  },
  '20008': {
    message: 'Test Credentials',
    log_level: 'ERROR',
  },
  '20009': {
    message: 'Cannot delete this resource before it is complete',
    log_level: 'ERROR',
  },
  '20010': {
    message: 'Action disabled for account',
    log_level: 'ERROR',
  },
  '20011': {
    message: 'Invalid TLS version',
    log_level: 'ERROR',
  },
  '20012': {
    message: 'Invalid TLSv1.2 Cipher Suite',
    log_level: 'ERROR',
  },
  '20020': {
    message: 'No update/state change is observed for the data entered',
    log_level: 'ERROR',
  },
  '20021': {
    message: 'Phone number rejected by T-Mobile SDG Service Provisioning API',
    log_level: 'ERROR',
  },
  '20023': {
    message:
      'Phone number is not correct: it cannot be null or have non-decimal symbols',
    log_level: 'ERROR',
  },
  '20101': {
    message: 'Invalid Access Token',
    log_level: 'ERROR',
  },
  '20102': {
    message: 'Invalid Access Token header',
    log_level: 'ERROR',
  },
  '20103': {
    message: 'Invalid Access Token issuer/subject',
    log_level: 'ERROR',
  },
  '20104': {
    message: 'Access Token expired or expiration date invalid',
    log_level: 'WARNING',
  },
  '20105': {
    message: 'Access Token not yet valid',
    log_level: 'ERROR',
  },
  '20106': {
    message: 'Invalid Access Token grants',
    log_level: 'ERROR',
  },
  '20107': {
    message: 'Invalid Access Token signature',
    log_level: 'ERROR',
  },
  '20151': {
    message: 'Authentication Failed',
    log_level: 'ERROR',
  },
  '20152': {
    message: 'Invalid Header',
    log_level: 'ERROR',
  },
  '20153': {
    message: 'Invalid Issuer Or Subject',
    log_level: 'ERROR',
  },
  '20154': {
    message: 'Invalid Claim Set',
    log_level: 'ERROR',
  },
  '20155': {
    message: 'Expiration Time In The Future',
    log_level: 'ERROR',
  },
  '20156': {
    message: 'Expired or Invalid Expiration in Token',
    log_level: 'ERROR',
  },
  '20157': {
    message: 'Expiration Time Exceeds Maximum Time Allowed',
    log_level: 'ERROR',
  },
  '20159': {
    message: 'Invalid Signature',
    log_level: 'ERROR',
  },
  '20160': {
    message: 'Invalid Token',
    log_level: 'ERROR',
  },
  '20161': {
    message:
      'Programmable Chat: Parameters are not specified for update request',
    log_level: 'ERROR',
  },
  '20162': {
    message: 'A conflicting resource update is in progress',
    log_level: 'ERROR',
  },
  '20403': {
    message: '403 Forbidden',
    log_level: 'ERROR',
  },
  '20404': {
    message: 'Not Found',
    log_level: 'ERROR',
  },
  '20409': {
    message: 'Conflict',
    log_level: 'ERROR',
  },
  '20413': {
    message: 'Request Entity Too Large',
    log_level: 'ERROR',
  },
  '20422': {
    message: 'Invalid Parameter',
    log_level: 'ERROR',
  },
  '20423': {
    message: 'Invalid SID',
    log_level: 'ERROR',
  },
  '20426': {
    message: 'Upgrade Required',
    log_level: 'ERROR',
  },
  '20429': {
    message: 'Too Many Requests',
    log_level: 'ERROR',
  },
  '20500': {
    message: 'Internal Server Error',
    log_level: 'ERROR',
  },
  '20503': {
    message: 'Service unavailable',
    log_level: 'ERROR',
  },
  '21100': {
    message: 'Accounts Resource',
    log_level: 'ERROR',
  },
  '21101': {
    message: 'Subaccounts cannot contain subaccounts',
    log_level: 'ERROR',
  },
  '21102': {
    message: 'Reached maximum number of Services',
    log_level: 'ERROR',
  },
  '21200': {
    message: 'Calls Resource',
    log_level: 'ERROR',
  },
  '21201': {
    message: 'No Called number specified',
    log_level: 'ERROR',
  },
  '21202': {
    message: 'Called number is a premium number',
    log_level: 'ERROR',
  },
  '21203': {
    message: 'International calling not enabled',
    log_level: 'ERROR',
  },
  '21204': {
    message: 'Call already initiated',
    log_level: 'ERROR',
  },
  '21205': {
    message: 'Invalid URL',
    log_level: 'ERROR',
  },
  '21206': {
    message: 'Invalid SendDigits',
    log_level: 'ERROR',
  },
  '21207': {
    message: 'Invalid IfMachine',
    log_level: 'ERROR',
  },
  '21208': {
    message: 'Invalid Timeout',
    log_level: 'ERROR',
  },
  '21209': {
    message: 'Invalid Method',
    log_level: 'ERROR',
  },
  '21210': {
    message: "'From' phone number not verified",
    log_level: 'ERROR',
  },
  '21211': {
    message: "Invalid 'To' Phone Number",
    log_level: 'ERROR',
  },
  '21212': {
    message: 'Invalid From Number (caller ID)',
    log_level: 'ERROR',
  },
  '21213': {
    message: 'Caller phone number is required',
    log_level: 'ERROR',
  },
  '21214': {
    message: "'To' phone number cannot be reached",
    log_level: 'ERROR',
  },
  '21215': {
    message: 'Geo Permission configuration is not permitting call',
    log_level: 'ERROR',
  },
  '21216': {
    message: 'API: Call blocked by Twilio blocklist',
    log_level: 'ERROR',
  },
  '21217': {
    message: 'Phone number does not appear to be valid',
    log_level: 'ERROR',
  },
  '21218': {
    message: 'Invalid ApplicationSid',
    log_level: 'ERROR',
  },
  '21219': {
    message: "'To' phone number not verified",
    log_level: 'ERROR',
  },
  '21220': {
    message: 'Invalid call state',
    log_level: 'ERROR',
  },
  '21221': {
    message: 'Invalid SipAuthUsername. Must be fewer than 256 chars',
    log_level: 'WARNING',
  },
  '21222': {
    message: 'Invalid SipAuthUsername. Illegal chars',
    log_level: 'WARNING',
  },
  '21223': {
    message: 'Invalid SipAuthPassword. Must be fewer than 256 chars',
    log_level: 'WARNING',
  },
  '21224': {
    message: 'Invalid SipAuthPassword. Illegal chars',
    log_level: 'WARNING',
  },
  '21225': {
    message: 'SipAuthPassword is required when providing SipAuthUsername',
    log_level: 'WARNING',
  },
  '21226': {
    message: 'SIP calling not enabled for this account',
    log_level: 'WARNING',
  },
  '21227': {
    message: 'Headers portion of SIP URI must be fewer than 1024 chars',
    log_level: 'WARNING',
  },
  '21228': {
    message: 'Invalid SIP Header. Illegal chars in header name',
    log_level: 'WARNING',
  },
  '21229': {
    message: 'Invalid SIP Header. Illegal chars in header value',
    log_level: 'WARNING',
  },
  '21230': {
    message: 'Maximum Domains Reached',
    log_level: 'ERROR',
  },
  '21231': {
    message: 'Domain Validation Error',
    log_level: 'ERROR',
  },
  '21232': {
    message: 'Invalid Domain',
    log_level: 'ERROR',
  },
  '21233': {
    message: 'Domain still has subdomains',
    log_level: 'ERROR',
  },
  '21234': {
    message: 'Invalid Machine Detection configuration value',
    log_level: 'ERROR',
  },
  '21235': {
    message: 'IP Access Control List Validation Error',
    log_level: 'ERROR',
  },
  '21236': {
    message: 'IP Access Control List Dependencies Violation',
    log_level: 'ERROR',
  },
  '21237': {
    message: 'Maximum IP Addresses Reached for List',
    log_level: 'ERROR',
  },
  '21238': {
    message: 'Address Validation Error',
    log_level: 'ERROR',
  },
  '21239': {
    message: 'Maximum Credential Lists Reached',
    log_level: 'ERROR',
  },
  '21240': {
    message: 'Credential List Validation Error',
    log_level: 'ERROR',
  },
  '21241': {
    message: 'Credential List Dependencies Violation',
    log_level: 'ERROR',
  },
  '21242': {
    message: 'Maximum Credentials Reached for List',
    log_level: 'ERROR',
  },
  '21243': {
    message: 'Credential Validation Error',
    log_level: 'ERROR',
  },
  '21244': {
    message: 'Maximum Number of Trunks reached',
    log_level: 'ERROR',
  },
  '21245': {
    message: 'Trunk Validation Error',
    log_level: 'ERROR',
  },
  '21247': {
    message: 'Trunk Dependencies',
    log_level: 'ERROR',
  },
  '21248': {
    message: 'Trunk Domain already taken',
    log_level: 'ERROR',
  },
  '21249': {
    message: 'Maximum Origination URIs reached  ',
    log_level: 'ERROR',
  },
  '21251': {
    message: 'Trunking CPS change not allowed',
    log_level: 'ERROR',
  },
  '21252': {
    message: 'Invalid Region',
    log_level: 'ERROR',
  },
  '21253': {
    message: 'Max Connection Policies Reached',
    log_level: 'ERROR',
  },
  '21254': {
    message: 'Max Connection Policy Entries Reached',
    log_level: 'ERROR',
  },
  '21255': {
    message: 'Maximum IP Access Control Lists reached',
    log_level: 'ERROR',
  },
  '21256': {
    message: 'Invalid ruleset',
    log_level: 'ERROR',
  },
  '21257': {
    message: 'Invalid SIP Manipulation Policy SID',
    log_level: 'ERROR',
  },
  '21258': {
    message: 'Invalid SIP Manipulation Policy',
    log_level: 'ERROR',
  },
  '21259': {
    message: 'Maximum number of SIP Manipulation Polies per account reached',
    log_level: 'ERROR',
  },
  '21260': {
    message: 'Maximum number of actions per rule reached',
    log_level: 'ERROR',
  },
  '21261': {
    message: 'Maximum number of conditions per rule reached',
    log_level: 'ERROR',
  },
  '21262': {
    message: 'No AMD status callback URL provided',
    log_level: 'WARNING',
  },
  '21263': {
    message: 'Invalid Answering Machine Detection Parameters',
    log_level: 'WARNING',
  },
  '21300': {
    message: 'Invalid BYOC trunk SID',
    log_level: 'ERROR',
  },
  '21301': {
    message: 'Cannot create application: application limit exceeded',
    log_level: 'ERROR',
  },
  '21302': {
    message: 'Approaching application creation limit ',
    log_level: 'WARNING',
  },
  '21401': {
    message: 'Invalid Phone Number',
    log_level: 'ERROR',
  },
  '21402': {
    message: 'Invalid Url',
    log_level: 'ERROR',
  },
  '21403': {
    message: 'Invalid Method',
    log_level: 'ERROR',
  },
  '21404': {
    message: 'Inbound Phone number not available to trial account',
    log_level: 'ERROR',
  },
  '21405': {
    message: 'Cannot set VoiceFallbackUrl without setting Url',
    log_level: 'ERROR',
  },
  '21406': {
    message: 'Cannot set SmsFallbackUrl without setting SmsUrl',
    log_level: 'ERROR',
  },
  '21407': {
    message: 'This Phone Number type does not support SMS',
    log_level: 'ERROR',
  },
  '21408': {
    message:
      "Permission to send an SMS has not been enabled for the region indicated by the 'To' number",
    log_level: 'ERROR',
  },
  '21409': {
    message: 'VoiceCallerIdLookup cannot be set for this phone number',
    log_level: 'ERROR',
  },
  '21420': {
    message: 'ApplicationSid is not accessible',
    log_level: 'ERROR',
  },
  '21421': {
    message: 'Phone Number is invalid',
    log_level: 'ERROR',
  },
  '21422': {
    message: 'PhoneNumber is not available',
    log_level: 'ERROR',
  },
  '21449': {
    message: 'Number already can be used for outgoing calls and messages',
    log_level: 'ERROR',
  },
  '21450': {
    message: 'Phone number already validated on your account',
    log_level: 'ERROR',
  },
  '21451': {
    message: 'Invalid area code',
    log_level: 'ERROR',
  },
  '21452': {
    message: 'No phone numbers found in area code',
    log_level: 'ERROR',
  },
  '21453': {
    message: 'Phone number already validated on another account',
    log_level: 'ERROR',
  },
  '21454': {
    message: 'Invalid CallDelay',
    log_level: 'ERROR',
  },
  '21455': {
    message: 'Invalid PlayUrl',
    log_level: 'ERROR',
  },
  '21456': {
    message: 'Invalid CallbackUrl',
    log_level: 'ERROR',
  },
  '21457': {
    message: 'AreaCode Parameter not Supported',
    log_level: 'ERROR',
  },
  '21458': {
    message: 'PhoneNumber Provisioning Type Mismatch',
    log_level: 'ERROR',
  },
  '21470': {
    message: 'Invalid AccountSid',
    log_level: 'ERROR',
  },
  '21471': {
    message: 'Account does not exist',
    log_level: 'ERROR',
  },
  '21472': {
    message: 'Account is not active',
    log_level: 'ERROR',
  },
  '21473': {
    message:
      'AccountSid you are transferring to is not related to the originating owner of the phone number',
    log_level: 'ERROR',
  },
  '21474': {
    message: 'API User must be the parent account to transfer phone numbers.',
    log_level: 'ERROR',
  },
  '21475': {
    message: 'Unable to update Status, invalid Status.',
    log_level: 'ERROR',
  },
  '21476': {
    message:
      'Unable to update Status for subaccount, parent account is suspended.',
    log_level: 'ERROR',
  },
  '21477': {
    message: 'Unable to update Status for parent accounts',
    log_level: 'ERROR',
  },
  '21478': {
    message:
      'Unable to update Status for subaccount, subaccount has been suspended by Twilio',
    log_level: 'ERROR',
  },
  '21479': {
    message:
      'Unable to update Status for subaccount, subaccount has been closed.',
    log_level: 'ERROR',
  },
  '21480': {
    message: 'Reached maximum number of subaccounts',
    log_level: 'ERROR',
  },
  '21481': {
    message: 'Invalid PageToken',
    log_level: 'ERROR',
  },
  '21501': {
    message: 'Resource not available',
    log_level: 'ERROR',
  },
  '21502': {
    message: 'Invalid callback url',
    log_level: 'ERROR',
  },
  '21503': {
    message: 'Invalid transcription type',
    log_level: 'ERROR',
  },
  '21504': {
    message: 'RecordingSid is required.',
    log_level: 'ERROR',
  },
  '21601': {
    message: 'Phone number is not a valid SMS-capable inbound phone number',
    log_level: 'ERROR',
  },
  '21602': {
    message: 'Message body is required',
    log_level: 'ERROR',
  },
  '21603': {
    message:
      "A 'From' or 'MessagingServiceSid' parameter is required to send a message",
    log_level: 'ERROR',
  },
  '21604': {
    message: "The destination 'To' phone number is required to send an SMS",
    log_level: 'ERROR',
  },
  '21605': {
    message: 'Maximum body length is 160 characters (old API endpoint)',
    log_level: 'ERROR',
  },
  '21606': {
    message:
      "The 'From' phone number provided is not a valid message-capable Twilio phone number for this destination.",
    log_level: 'ERROR',
  },
  '21607': {
    message:
      "The 'from' phone number must be the sandbox phone number for trial accounts.",
    log_level: 'ERROR',
  },
  '21608': {
    message:
      "The 'to' phone number provided is not yet verified for this account.",
    log_level: 'ERROR',
  },
  '21609': {
    message: "Invalid 'StatusCallback'",
    log_level: 'ERROR',
  },
  '21610': {
    message: 'Attempt to send to unsubscribed recipient',
    log_level: 'ERROR',
  },
  '21611': {
    message:
      "This 'From' number has exceeded the maximum number of queued messages",
    log_level: 'ERROR',
  },
  '21612': {
    message: "The 'To' phone number is not currently reachable via SMS",
    log_level: 'ERROR',
  },
  '21613': {
    message: 'PhoneNumber Requires Certification',
    log_level: 'ERROR',
  },
  '21614': {
    message: "'To' number is not a valid mobile number",
    log_level: 'ERROR',
  },
  '21615': {
    message: 'Phone Number Requires a Local Address',
    log_level: 'ERROR',
  },
  '21616': {
    message: "The 'From' number matches multiple numbers for your account",
    log_level: 'ERROR',
  },
  '21617': {
    message: 'The concatenated message body exceeds the 1600 character limit',
    log_level: 'ERROR',
  },
  '21618': {
    message: 'The message body cannot be sent',
    log_level: 'ERROR',
  },
  '21619': {
    message: 'Unable to create record',
    log_level: 'ERROR',
  },
  '21620': {
    message: 'Invalid media URL(s)',
    log_level: 'ERROR',
  },
  '21621': {
    message: "The 'From' number has not been enabled for MMS",
    log_level: 'ERROR',
  },
  '21622': {
    message: 'MMS has not been enabled for your account',
    log_level: 'ERROR',
  },
  '21623': {
    message: 'Number of media files exceeds allowed limit',
    log_level: 'ERROR',
  },
  '21624': {
    message: 'Invalid validity period value',
    log_level: 'ERROR',
  },
  '21626': {
    message: "Invalid 'StatusCallbackEvent'",
    log_level: 'ERROR',
  },
  '21627': {
    message: 'Max Price must be a valid float',
    log_level: 'ERROR',
  },
  '21628': {
    message: 'Address Validation Error',
    log_level: 'ERROR',
  },
  '21629': {
    message: 'Address Validation Error - Check Suggested Address',
    log_level: 'ERROR',
  },
  '21630': {
    message: 'Cannot mutate Address that is linked to a verified Document.',
    log_level: 'ERROR',
  },
  '21631': {
    message: 'Phone Number Requires an Address',
    log_level: 'ERROR',
  },
  '21634': {
    message: 'SIP Trunk is in use for emergency calling',
    log_level: 'ERROR',
  },
  '21644': {
    message:
      'End-User cannot be deleted due to an active assignment to a Bundle',
    log_level: 'ERROR',
  },
  '21645': {
    message:
      'Supporting Document cannot be deleted due to active Regulatory Bundle assignment',
    log_level: 'ERROR',
  },
  '21646': {
    message: 'Supporting Document is not eligible for deletion',
    log_level: 'ERROR',
  },
  '21647': {
    message: 'Regulatory Bundle is not eligible for deletion',
    log_level: 'ERROR',
  },
  '21648': {
    message:
      'Regulatory Bundle cannot be deleted due to active number assignment',
    log_level: 'ERROR',
  },
  '21649': {
    message: 'Phone Number Requires a Bundle',
    log_level: 'ERROR',
  },
  '21650': {
    message: 'Phone Number Requires a Verified Identity Document',
    log_level: 'ERROR',
  },
  '21651': {
    message: 'Document does not satisfy regulatory requirement',
    log_level: 'ERROR',
  },
  '21652': {
    message: 'Maximum subject length is 40 characters',
    log_level: 'ERROR',
  },
  '21653': {
    message: 'There are more recipient addresses than allowed',
    log_level: 'ERROR',
  },
  '21654': {
    message: 'ContentSid Required',
    log_level: 'ERROR',
  },
  '21655': {
    message: 'The ContentSid is Invalid',
    log_level: 'ERROR',
  },
  '21656': {
    message: 'The ContentVariables Parameter is invalid',
    log_level: 'ERROR',
  },
  '21701': {
    message: 'The Messaging Service does not exist',
    log_level: 'ERROR',
  },
  '21702': {
    message: 'The Messaging Service is not available to send new messages',
    log_level: 'ERROR',
  },
  '21703': {
    message:
      'The Messaging Service does not have a phone number available to send a message',
    log_level: 'ERROR',
  },
  '21704': {
    message: 'The Messaging Service contains no phone numbers',
    log_level: 'ERROR',
  },
  '21705': {
    message: 'The Messaging Service is invalid',
    log_level: 'ERROR',
  },
  '21708': {
    message: 'Alpha Sender ID Missing from the request',
    log_level: 'WARNING',
  },
  '21709': {
    message:
      'Alpha Sender ID is Invalid or Not Authorized for this Messaging Service',
    log_level: 'ERROR',
  },
  '21710': {
    message: 'Phone Number Already Exists in Messaging Service',
    log_level: 'WARNING',
  },
  '21711': {
    message:
      'Phone Number, Shortcode, or AlphaSender is not associated to the specified Messaging Service.',
    log_level: 'WARNING',
  },
  '21712': {
    message:
      'Phone Number or Short Code is associated with another Messaging Service.',
    log_level: 'ERROR',
  },
  '21713': {
    message: 'Messaging Service Use Case is Invalid',
    log_level: 'ERROR',
  },
  '21714': {
    message: 'Messaging Service Number Pool size limit reached',
    log_level: 'ERROR',
  },
  '21715': {
    message:
      'Phone Number Does Not Have Correct Messaging Service Capabilities',
    log_level: 'ERROR',
  },
  '21717': {
    message:
      'Brand Registration SID for US A2P Campaign Use Case is Not Registered or Not Valid',
    log_level: 'ERROR',
  },
  '21719': {
    message: 'Incompatible Messaging Service/A2P Use Cases',
    log_level: 'ERROR',
  },
  '21720': {
    message: 'A2P Use Case is Invalid',
    log_level: 'ERROR',
  },
  '21721': {
    message:
      'Cannot import Campaign Verify token due to incompatible A2P brand',
    log_level: 'ERROR',
  },
  '21722': {
    message: 'Invalid Campaign Verify token',
    log_level: 'ERROR',
  },
  '21723': {
    message: 'Campaign Verify token import already in progress',
    log_level: 'ERROR',
  },
  '21724': {
    message: 'Brand update count exceeded',
    log_level: 'ERROR',
  },
  '21725': {
    message: 'Brand can only be updated when in FAILED state',
    log_level: 'ERROR',
  },
  '21726': {
    message: 'Starter brand registrations and updates are temporarily disabled',
    log_level: 'ERROR',
  },
  '21727': {
    message: 'Campaign registration failed due to missing parameter(s)',
    log_level: 'ERROR',
  },
  '21728': {
    message: 'Campaign registration failed due to length validation failures',
    log_level: 'ERROR',
  },
  '21729': {
    message: 'Cannot perform operation on suspended campaign',
    log_level: 'ERROR',
  },
  '21900': {
    message: 'DltPEId is invalid',
    log_level: 'ERROR',
  },
  '21901': {
    message: 'DltTemplateId is invalid',
    log_level: 'ERROR',
  },
  '21902': {
    message: 'InvoiceTag length must be between 0 and 32',
    log_level: 'ERROR',
  },
  '21910': {
    message:
      'Invalid From and To pair. From and To should be of the same channel.',
    log_level: 'ERROR',
  },
  '22001': {
    message: 'Call timed out',
    log_level: 'ERROR',
  },
  '22005': {
    message: 'Call Queue Full',
    log_level: 'ERROR',
  },
  '22100': {
    message: 'Reached Maximum Verification Attempts',
    log_level: 'ERROR',
  },
  '22101': {
    message: 'Invalid Hosted Number Order SIDs',
    log_level: 'ERROR',
  },
  '22102': {
    message: 'Invalid Phone Number',
    log_level: 'ERROR',
  },
  '22103': {
    message: 'Unsupported Iso Country',
    log_level: 'ERROR',
  },
  '22104': {
    message: 'Invalid Email Format',
    log_level: 'ERROR',
  },
  '22105': {
    message: 'Invalid URL format',
    log_level: 'ERROR',
  },
  '22106': {
    message: 'Invalid Method',
    log_level: 'ERROR',
  },
  '22107': {
    message: 'Unable to Update Authorization Document',
    log_level: 'ERROR',
  },
  '22108': {
    message: 'Invalid Application SID',
    log_level: 'ERROR',
  },
  '22109': {
    message: 'Invalid Address SID',
    log_level: 'ERROR',
  },
  '22110': {
    message: 'Phone Number Not Hostable',
    log_level: 'ERROR',
  },
  '22111': {
    message: 'Invalid Hosted Number Order Status',
    log_level: 'ERROR',
  },
  '22112': {
    message: 'Unable to Update Hosted Number Order Status',
    log_level: 'ERROR',
  },
  '22113': {
    message: 'Phone Verification Incorrect',
    log_level: 'ERROR',
  },
  '22114': {
    message: 'Unable to Verify Code',
    log_level: 'ERROR',
  },
  '22115': {
    message: 'Invalid Unique Name',
    log_level: 'ERROR',
  },
  '22116': {
    message: 'Invalid Friendly Name',
    log_level: 'ERROR',
  },
  '22117': {
    message: 'Invalid Extension',
    log_level: 'ERROR',
  },
  '22118': {
    message: 'Invalid Verification Document SID',
    log_level: 'ERROR',
  },
  '22119': {
    message: 'Invalid Capabilities',
    log_level: 'ERROR',
  },
  '22120': {
    message: 'Invalid Verification Type',
    log_level: 'ERROR',
  },
  '22121': {
    message: 'Unable to Transfer Hosted Number',
    log_level: 'ERROR',
  },
  '22122': {
    message: 'Invalid Authorization Document Status',
    log_level: 'ERROR',
  },
  '22123': {
    message: 'Unable to Initiate Verification Call',
    log_level: 'ERROR',
  },
  '22199': {
    message: 'Configuration Retrieval Error',
    log_level: 'ERROR',
  },
  '22200': {
    message: 'Invalid End-User Type or Number Type',
    log_level: 'ERROR',
  },
  '22201': {
    message: 'No regulation sid found for the given number group',
    log_level: 'ERROR',
  },
  '22202': {
    message: 'No regulation sid or phone number country and type was provided',
    log_level: 'ERROR',
  },
  '22203': {
    message: 'Unable to parse bundle status',
    log_level: 'ERROR',
  },
  '22204': {
    message:
      'Bundle status and properties cannot be updated in the same request',
    log_level: 'ERROR',
  },
  '22205': {
    message: 'Attempting to assign invalid object_sid to Bundle',
    log_level: 'ERROR',
  },
  '22206': {
    message: 'Attempting to add invalid object type to bundle',
    log_level: 'ERROR',
  },
  '22207': {
    message: 'Unable to parse attributes JSON',
    log_level: 'ERROR',
  },
  '22208': {
    message:
      'Supporting Document status and attributes cannot be updated in the same request',
    log_level: 'ERROR',
  },
  '22209': {
    message: 'Invalid status enum in Supporting Document update request',
    log_level: 'ERROR',
  },
  '22210': {
    message: 'Cannot create a Supporting Document with no Type',
    log_level: 'ERROR',
  },
  '22211': {
    message: 'Cannot create a Supporting Document with no FriendlyName',
    log_level: 'ERROR',
  },
  '22212': {
    message: 'Invalid End-User Type in request',
    log_level: 'ERROR',
  },
  '22213': {
    message: 'Invalid Number Type in request',
    log_level: 'ERROR',
  },
  '22214': {
    message: 'Missing End User',
    log_level: 'ERROR',
  },
  '22215': {
    message: 'Missing End-User field',
    log_level: 'ERROR',
  },
  '22216': {
    message: 'Missing Supporting Document',
    log_level: 'ERROR',
  },
  '22217': {
    message: 'Missing Supporting Document field',
    log_level: 'ERROR',
  },
  '22218': {
    message:
      'The Supporting Document field does not match the field in the End-User',
    log_level: 'ERROR',
  },
  '22219': {
    message: 'An Address is missing',
    log_level: 'ERROR',
  },
  '22221': {
    message: 'Emergency address is not registered',
    log_level: 'WARNING',
  },
  '22222': {
    message: 'Emergency Status cannot be updated',
    log_level: 'WARNING',
  },
  '22223': {
    message: 'Regulatory Bundle is not eligible to be Copied',
    log_level: 'ERROR',
  },
  '22224': {
    message: 'Regulatory Bundle cannot transfer Item Assignments',
    log_level: 'ERROR',
  },
  '22225': {
    message: 'From Bundle to Replace Items does not exist',
    log_level: 'ERROR',
  },
  '22226': {
    message: 'Cannot replace Items from Bundle to same Bundle',
    log_level: 'ERROR',
  },
  '22228': {
    message:
      'From Bundle does not have latest Regulation requirements that matches destination Bundle',
    log_level: 'ERROR',
  },
  '22229': {
    message: 'Supporting Document Bundle Assignment cannot be removed',
    log_level: 'ERROR',
  },
  '22300': {
    message:
      'This account is restricted from provisioning new long code phone numbers',
    log_level: 'ERROR',
  },
  '22400': {
    message: 'Phone Number linked to Active Route Configuration',
    log_level: 'ERROR',
  },
  '22401': {
    message: 'Phone Number Instance fields are not supported within region',
    log_level: 'ERROR',
  },
  '22402': {
    message: 'Phone Number Operation not permitted within Region',
    log_level: 'ERROR',
  },
  '22403': {
    message: 'Phone Number Operation not permitted within Region',
    log_level: 'ERROR',
  },
  '22404': {
    message: 'Starter profile creation and updates are temporarily disabled',
    log_level: 'ERROR',
  },
  '23001': {
    message:
      'Message Redaction Incompatible Configuration: Long code STOP filtering',
    log_level: 'WARNING',
  },
  '23002': {
    message:
      'Message Redaction Incompatible Configuration: Short code "STOP" filtering',
    log_level: 'WARNING',
  },
  '23003': {
    message: 'Message Redaction Incompatible Configuration: Sticky Sender',
    log_level: 'WARNING',
  },
  '23004': {
    message: 'Message Redaction Incompatible Configuration: Advanced Opt-Out',
    log_level: 'WARNING',
  },
  '23005': {
    message:
      'Phone Number Redaction Incompatible Configuration: Fallback to Long Code',
    log_level: 'WARNING',
  },
  '23006': {
    message:
      'Message Redaction Incompatible Configuration: Inbound Webhook GET Requests',
    log_level: 'WARNING',
  },
  '30001': {
    message: 'Queue overflow',
    log_level: 'ERROR',
  },
  '30002': {
    message: 'Account suspended',
    log_level: 'ERROR',
  },
  '30003': {
    message: 'Unreachable destination handset',
    log_level: 'ERROR',
  },
  '30004': {
    message: 'Message blocked',
    log_level: 'ERROR',
  },
  '30005': {
    message: 'Unknown destination handset',
    log_level: 'ERROR',
  },
  '30006': {
    message: 'Landline or unreachable carrier',
    log_level: 'ERROR',
  },
  '30007': {
    message: 'Message filtered',
    log_level: 'ERROR',
  },
  '30008': {
    message: 'Unknown error',
    log_level: 'ERROR',
  },
  '30009': {
    message: 'Missing inbound segment',
    log_level: 'ERROR',
  },
  '30010': {
    message: 'Message price exceeds max price',
    log_level: 'ERROR',
  },
  '30011': {
    message: 'MMS not supported by the receiving phone number in this region',
    log_level: 'ERROR',
  },
  '30012': {
    message: 'TTL is too small',
    log_level: 'ERROR',
  },
  '30013': {
    message: 'TTL is too big',
    log_level: 'ERROR',
  },
  '30014': {
    message: "'To' attributes are Invalid",
    log_level: 'ERROR',
  },
  '30015': {
    message: 'Non-supported channel type is used',
    log_level: 'ERROR',
  },
  '30016': {
    message: "'To' and 'From' channel types are incompatible",
    log_level: 'ERROR',
  },
  '30017': {
    message: 'Carrier network congestion',
    log_level: 'ERROR',
  },
  '30018': {
    message: 'Destination carrier requires sender ID pre-registration',
    log_level: 'WARNING',
  },
  '30019': {
    message: 'Content size exceeds carrier limit',
    log_level: 'ERROR',
  },
  '30020': {
    message: 'Internal Failure with Message Scheduling',
    log_level: 'ERROR',
  },
  '30021': {
    message: 'Internal Failure with messaging service orchestrator',
    log_level: 'ERROR',
  },
  '30022': {
    message: 'US A2P 10DLC - Rate Limits Exceeded',
    log_level: 'ERROR',
  },
  '30023': {
    message: 'US A2P 10DLC - Daily Message Cap Reached',
    log_level: 'ERROR',
  },
  '30024': {
    message: 'Numeric Sender ID Not Provisioned on Carrier',
    log_level: 'ERROR',
  },
  '30025': {
    message: 'US A2P 10DLC - 50% T-Mobile Daily Message Limit Consumed',
    log_level: 'WARNING',
  },
  '30026': {
    message: 'US A2P 10DLC - 70% T-Mobile Daily Message Limit Consumed',
    log_level: 'WARNING',
  },
  '30027': {
    message: 'US A2P 10DLC - T-Mobile Daily Message Limit Reached',
    log_level: 'ERROR',
  },
  '30028': {
    message: 'Invalid API version',
    log_level: 'ERROR',
  },
  '30029': {
    message: 'Invalid ContentRetention',
    log_level: 'ERROR',
  },
  '30030': {
    message: 'Invalid AddressRetention',
    log_level: 'ERROR',
  },
  '30031': {
    message: 'Invalid MaxRate',
    log_level: 'ERROR',
  },
  '30032': {
    message: 'Toll-Free Number Has Not Been Verified',
    log_level: 'ERROR',
  },
  '30100': {
    message: 'Domain SID is invalid',
    log_level: 'ERROR',
  },
  '30101': {
    message: 'Domain is unverified',
    log_level: 'ERROR',
  },
  '30102': {
    message: "Domain's certificate has expired",
    log_level: 'ERROR',
  },
  '30103': {
    message: 'Links not shortened due to shortener application failure',
    log_level: 'ERROR',
  },
  '30104': {
    message: 'Shortened link not found. Click redirected to fallback Url',
    log_level: 'WARNING',
  },
  '30105': {
    message: 'Shortened link not found and no fallback URL found',
    log_level: 'WARNING',
  },
  '30106': {
    message: 'Domain has not been set up for this account',
    log_level: 'ERROR',
  },
  '30107': {
    message: 'Domain private certificate has not been uploaded',
    log_level: 'ERROR',
  },
  '30108': {
    message: 'Twilio account does not belong to an organization',
    log_level: 'ERROR',
  },
  '30109': {
    message: 'Callback URL is invalid',
    log_level: 'ERROR',
  },
  '30110': {
    message: 'Domain is blocked',
    log_level: 'ERROR',
  },
  '30111': {
    message: 'Url is blacklisted',
    log_level: 'ERROR',
  },
  '30112': {
    message: 'Account is not found',
    log_level: 'ERROR',
  },
  '30113': {
    message: 'Specified date is too old',
    log_level: 'ERROR',
  },
  '30114': {
    message: 'Specified date is not available yet',
    log_level: 'ERROR',
  },
  '30115': {
    message: 'Date format is incorrect',
    log_level: 'ERROR',
  },
  '30116': {
    message: 'Certificate or private key or both are missing',
    log_level: 'ERROR',
  },
  '30117': {
    message: 'Certificate cannot be parsed',
    log_level: 'ERROR',
  },
  '30118': {
    message: 'Private key is invalid',
    log_level: 'ERROR',
  },
  '30119': {
    message: 'Certificate and private key pair is invalid',
    log_level: 'ERROR',
  },
  '30120': {
    message: ' Domain certificate and private key are not uploaded',
    log_level: 'ERROR',
  },
  '30121': {
    message: 'Fallback URL is missing',
    log_level: 'ERROR',
  },
  '30122': {
    message: 'Fallback URL is invalid',
    log_level: 'ERROR',
  },
  '30123': {
    message: 'Callback URL is missing',
    log_level: 'ERROR',
  },
  '30124': {
    message: 'MessagingServiceSID cannot be empty or null',
    log_level: 'ERROR',
  },
  '30125': {
    message: 'Your phone number could not be registered with US A2P 10DLC',
    log_level: 'WARNING',
  },
  '30127': {
    message: 'MessagingServiceSID is invalid.',
    log_level: 'ERROR',
  },
  '30128': {
    message: 'MessagingServiceSidsAction is invalid',
    log_level: 'ERROR',
  },
  '30129': {
    message: 'Certificate is self signed',
    log_level: 'ERROR',
  },
  '30130': {
    message:
      'Messaging Service SID already belongs in another domain configuration.',
    log_level: 'ERROR',
  },
  '30131': {
    message: "Domain's certificate will expire soon",
    log_level: 'WARNING',
  },
  '30132': {
    message: 'Certificate cannot be validated. ',
    log_level: 'ERROR',
  },
  '30133': {
    message: 'The certificate could not be uploaded.',
    log_level: 'ERROR',
  },
  '30400': {
    message: 'Parameters are not valid ',
    log_level: 'ERROR',
  },
  '30404': {
    message: 'Not Found',
    log_level: 'ERROR',
  },
  '30409': {
    message: 'This message cannot be canceled',
    log_level: 'ERROR',
  },
  '30410': {
    message: 'Provider Timeout Error',
    log_level: 'ERROR',
  },
  '30440': {
    message: 'Toll Free verification rejection - Unknown Error',
    log_level: 'ERROR',
  },
  '30441': {
    message:
      'Toll-Free phone number verification rejection - Content Violation',
    log_level: 'ERROR',
  },
  '30442': {
    message:
      'Toll-Free phone number verification rejection - Campaign Violation',
    log_level: 'ERROR',
  },
  '30443': {
    message: 'Toll Free verification rejection - Disallowed Content',
    log_level: 'ERROR',
  },
  '30444': {
    message: 'Toll Free verification rejection - High Risk',
    log_level: 'ERROR',
  },
  '30445': {
    message: 'Toll Free verification rejection - Invalid Information',
    log_level: 'ERROR',
  },
  '30446': {
    message: 'Toll Free verification rejection - Opt-In Error',
    log_level: 'ERROR',
  },
  '30447': {
    message: 'Toll Free verification rejection - Phone Number Error',
    log_level: 'ERROR',
  },
  '30448': {
    message: 'Toll-Free phone number verification rejection - Age Gate',
    log_level: 'ERROR',
  },
  '30449': {
    message:
      'Toll Free verification rejection - Sample message URL issues in sample message',
    log_level: 'ERROR',
  },
  '31000': {
    message: 'Generic error',
    log_level: 'ERROR',
  },
  '31001': {
    message: 'Application not found.',
    log_level: 'ERROR',
  },
  '31002': {
    message: 'Connection declined.',
    log_level: 'ERROR',
  },
  '31003': {
    message: 'Connection timeout',
    log_level: 'ERROR',
  },
  '31005': {
    message: 'Connection error',
    log_level: 'ERROR',
  },
  '31006': {
    message: 'Audio device error',
    log_level: 'ERROR',
  },
  '31007': {
    message: 'Twilio Client: Client version not supported',
    log_level: 'ERROR',
  },
  '31008': {
    message: 'Call cancelled',
    log_level: 'ERROR',
  },
  '31009': {
    message: 'Transport error',
    log_level: 'ERROR',
  },
  '31100': {
    message: 'Malformed request',
    log_level: 'ERROR',
  },
  '31101': {
    message: 'Missing parameter array in request.',
    log_level: 'ERROR',
  },
  '31102': {
    message: 'Authorization token missing in request.',
    log_level: 'ERROR',
  },
  '31103': {
    message: 'Length of parameters cannot exceed MAX_PARAM_LENGTH.',
    log_level: 'ERROR',
  },
  '31104': {
    message: 'Invalid bridge token.',
    log_level: 'ERROR',
  },
  '31105': {
    message: 'Invalid client name',
    log_level: 'ERROR',
  },
  '31106': {
    message: 'Invalid data',
    log_level: 'ERROR',
  },
  '31201': {
    message: 'Twilio Client: Error occurred while accessing microphone',
    log_level: 'ERROR',
  },
  '31202': {
    message: 'Signature validation failed.',
    log_level: 'ERROR',
  },
  '31203': {
    message: 'No valid account.',
    log_level: 'ERROR',
  },
  '31204': {
    message: 'Invalid JWT token.',
    log_level: 'ERROR',
  },
  '31205': {
    message: 'JWT token expired.',
    log_level: 'ERROR',
  },
  '31206': {
    message: 'Rate exceeded authorized limit.',
    log_level: 'ERROR',
  },
  '31207': {
    message: 'JWT token expiration too long.',
    log_level: 'ERROR',
  },
  '31208': {
    message: 'User denied access to microphone.',
    log_level: 'ERROR',
  },
  '31209': {
    message: 'Call Message Event Payload size exceeded authorized limit.',
    log_level: 'ERROR',
  },
  '31301': {
    message: 'Registration error',
    log_level: 'ERROR',
  },
  '31302': {
    message: 'Unsupported Cancel Message Error',
    log_level: 'ERROR',
  },
  '31400': {
    message: 'Bad Request',
    log_level: 'ERROR',
  },
  '31403': {
    message: 'Forbidden',
    log_level: 'ERROR',
  },
  '31404': {
    message: 'Not Found',
    log_level: 'ERROR',
  },
  '31408': {
    message: 'Request Timeout',
    log_level: 'ERROR',
  },
  '31409': {
    message: 'Conflict',
    log_level: 'ERROR',
  },
  '31426': {
    message: 'Upgrade Required',
    log_level: 'ERROR',
  },
  '31429': {
    message: 'Too Many Requests',
    log_level: 'ERROR',
  },
  '31480': {
    message: 'Temporarily Unavailable',
    log_level: 'ERROR',
  },
  '31481': {
    message: 'Call/Transaction Does Not Exist',
    log_level: 'ERROR',
  },
  '31484': {
    message: 'Address Incomplete',
    log_level: 'ERROR',
  },
  '31486': {
    message: 'Busy Here',
    log_level: 'ERROR',
  },
  '31487': {
    message: 'Request Terminated',
    log_level: 'ERROR',
  },
  '31500': {
    message: 'Internal Server Error',
    log_level: 'ERROR',
  },
  '31502': {
    message: 'Bad Gateway',
    log_level: 'ERROR',
  },
  '31503': {
    message: 'Service Unavailable',
    log_level: 'ERROR',
  },
  '31504': {
    message: 'Gateway Timeout',
    log_level: 'ERROR',
  },
  '31530': {
    message: 'DNS Resolution Error',
    log_level: 'ERROR',
  },
  '31600': {
    message: 'Busy Everywhere',
    log_level: 'ERROR',
  },
  '31603': {
    message: 'Decline',
    log_level: 'ERROR',
  },
  '31604': {
    message: 'Does Not Exist Anywhere',
    log_level: 'ERROR',
  },
  '31900': {
    message: 'Stream - Unknown Error',
    log_level: 'ERROR',
  },
  '31901': {
    message: 'Stream - WebSocket - Connection Timeout',
    log_level: 'ERROR',
  },
  '31902': {
    message: 'Stream - WebSocket - Connection Refused',
    log_level: 'ERROR',
  },
  '31903': {
    message: 'Stream - WebSocket - Connection Broken Pipe',
    log_level: 'ERROR',
  },
  '31904': {
    message: 'Stream - WebSocket - Host Unreachable',
    log_level: 'ERROR',
  },
  '31910': {
    message: 'Stream - WebSocket - SSL Protocol Error',
    log_level: 'ERROR',
  },
  '31920': {
    message: 'Stream - WebSocket - Handshake Error',
    log_level: 'ERROR',
  },
  '31921': {
    message: 'Stream - WebSocket - Close Error',
    log_level: 'ERROR',
  },
  '31922': {
    message: 'Stream - WebSocket - URL Schema Not Supported',
    log_level: 'ERROR',
  },
  '31923': {
    message: 'Stream - WebSocket - Malformed URL',
    log_level: 'ERROR',
  },
  '31924': {
    message: 'Stream - Websocket - Protocol Error',
    log_level: 'ERROR',
  },
  '31930': {
    message: 'Stream - Media - Buffer Overflow',
    log_level: 'WARNING',
  },
  '31931': {
    message: 'Stream - Media - Media Discarded',
    log_level: 'WARNING',
  },
  '31940': {
    message: 'Stream - Invalid connectorName attribute in TwiML.',
    log_level: 'ERROR',
  },
  '31941': {
    message: 'Stream - Invalid Track configuration',
    log_level: 'ERROR',
  },
  '31942': {
    message: 'Stream - Invalid connector configuration',
    log_level: 'ERROR',
  },
  '31950': {
    message: 'Stream - Protocol - Malformed Message',
    log_level: 'WARNING',
  },
  '31951': {
    message: 'Stream - Protocol - Invalid Message',
    log_level: 'WARNING',
  },
  '31952': {
    message: 'Stream Extension not found: ',
    log_level: 'ERROR',
  },
  '31960': {
    message: 'Stream - Quota exceeded',
    log_level: 'ERROR',
  },
  '32001': {
    message: 'SIP: Trunk CPS limit exceeded',
    log_level: 'ERROR',
  },
  '32002': {
    message: 'SIP: Dial failure - Twilio SIP Domain not found',
    log_level: 'WARNING',
  },
  '32006': {
    message: 'SIP: Too many hops',
    log_level: 'WARNING',
  },
  '32007': {
    message: 'SIP: Too many endpoints/bindings for the Address-of-record (AOR)',
    log_level: 'WARNING',
  },
  '32008': {
    message: 'SIP: Registration per second limit reached',
    log_level: 'WARNING',
  },
  '32009': {
    message:
      'The user you tried to dial is not registered with the corresponding SIP Domain',
    log_level: 'WARNING',
  },
  '32010': {
    message: 'SIP: No valid Origination URIs configured',
    log_level: 'ERROR',
  },
  '32011': {
    message: 'Error communicating with your SIP communications infrastructure',
    log_level: 'WARNING',
  },
  '32012': {
    message: 'SIP: Master account pooled Trunking CPS limit exceeded',
    log_level: 'WARNING',
  },
  '32013': {
    message: 'SIP: Master account SIP Interface CPS limit exceeded',
    log_level: 'ERROR',
  },
  '32014': {
    message: 'Call is terminated because of no audio received',
    log_level: 'WARNING',
  },
  '32015': {
    message: 'Call is terminated due to exceeding maximum call duration',
    log_level: 'WARNING',
  },
  '32016': {
    message: 'PSTN PDD timeout',
    log_level: 'ERROR',
  },
  '32017': {
    message: 'PSTN: Carrier blocked call due to calling number (caller ID)',
    log_level: 'ERROR',
  },
  '32018': {
    message: 'Twiml size exceeded maximum allowed value',
    log_level: 'ERROR',
  },
  '32019': {
    message: 'Twiml and Voice URL are both set. Using Voice URL.',
    log_level: 'WARNING',
  },
  '32021': {
    message: 'SHAKEN/STIR call verification failed',
    log_level: 'WARNING',
  },
  '32022': {
    message: 'ACK not received from your SIP endpoint',
    log_level: 'ERROR',
  },
  '32100': {
    message: 'SIP: Trial accounts can only call verified caller IDs',
    log_level: 'WARNING',
  },
  '32101': {
    message: 'SIP: Invalid phone number',
    log_level: 'WARNING',
  },
  '32102': {
    message: 'SIP: Bad SDP',
    log_level: 'WARNING',
  },
  '32103': {
    message: 'SIP: Empty body',
    log_level: 'WARNING',
  },
  '32105': {
    message: 'SIP: Invalid contact header',
    log_level: 'WARNING',
  },
  '32106': {
    message: 'SIP: Authentication Error',
    log_level: 'ERROR',
  },
  '32110': {
    message: 'SIP: URI is formatted incorrectly',
    log_level: 'WARNING',
  },
  '32111': {
    message: 'SIP: Invalid header name',
    log_level: 'WARNING',
  },
  '32112': {
    message: 'SIP: Invalid header value',
    log_level: 'WARNING',
  },
  '32113': {
    message: 'SIP: Header name is not allowed',
    log_level: 'WARNING',
  },
  '32114': {
    message: 'SIP: Unsupported parameter value',
    log_level: 'WARNING',
  },
  '32115': {
    message: 'X-Branded-CallReason header contains an invalid value.',
    log_level: 'ERROR',
  },
  '32200': {
    message: 'SIP: Insufficient permissions',
    log_level: 'ERROR',
  },
  '32201': {
    message: 'SIP: Source IP address not in ACL',
    log_level: 'ERROR',
  },
  '32202': {
    message: 'SIP: Bad user credentials',
    log_level: 'ERROR',
  },
  '32203': {
    message: 'SIP: Call blocked by Twilio',
    log_level: 'ERROR',
  },
  '32204': {
    message: "SIP: 'From' phone number not verified",
    log_level: 'ERROR',
  },
  '32205': {
    message:
      'SIP Trunking: Geo Permission configuration is not permitting call',
    log_level: 'ERROR',
  },
  '32206': {
    message: 'SIP: Invalid From number (caller ID)',
    log_level: 'ERROR',
  },
  '32207': {
    message: 'SIP: Secure media not accepted',
    log_level: 'ERROR',
  },
  '32208': {
    message: 'SIP: Secure media required',
    log_level: 'ERROR',
  },
  '32209': {
    message: 'SIP: Secure transport required',
    log_level: 'ERROR',
  },
  '32210': {
    message: 'SIP: Register not supported',
    log_level: 'ERROR',
  },
  '32212': {
    message: 'SIP: Registration Authentication problem',
    log_level: 'WARNING',
  },
  '32214': {
    message: 'SIP: Invalid <Dial><Sip> ',
    log_level: 'WARNING',
  },
  '32215': {
    message: 'Dial failure calling a SIP Domain without specifying a region',
    log_level: 'WARNING',
  },
  '32216': {
    message: 'SIP: Address blacklisted',
    log_level: 'ERROR',
  },
  '32218': {
    message: 'SIP: Transfer not allowed',
    log_level: 'ERROR',
  },
  '32219': {
    message: 'SIP: Redirect failed',
    log_level: 'ERROR',
  },
  '32220': {
    message:
      'Specifying an edge is not allowed when dialing SIP registered endpoints',
    log_level: 'ERROR',
  },
  '32221': {
    message:
      'Dialing SIP Endpoint failure - No devices registered in specified edge',
    log_level: 'ERROR',
  },
  '32222': {
    message: 'TLS version not allowed',
    log_level: 'ERROR',
  },
  '32223': {
    message:
      'There is no username in the SIP URI when calling a SIP registered endpoint',
    log_level: 'ERROR',
  },
  '32301': {
    message: 'Interconnect: Invalid Connection (TNX) SID',
    log_level: 'WARNING',
  },
  '32302': {
    message: 'Interconnect: Connection (TNX) SID not found',
    log_level: 'WARNING',
  },
  '32303': {
    message:
      'Interconnect: Multiple SIP Dials with Interconnect Connection (TNX) SID',
    log_level: 'WARNING',
  },
  '32304': {
    message: 'Interconnect: Connection (TNX) SID is not Active',
    log_level: 'WARNING',
  },
  '32400': {
    message: 'BYOC Trunk routing failure - failover routing disabled.',
    log_level: 'ERROR',
  },
  '32401': {
    message: 'BYOC Trunk routing failure - failover to Twilio default routing.',
    log_level: 'WARNING',
  },
  '32500': {
    message: 'Voice Conversation: Generic error.',
    log_level: 'ERROR',
  },
  '32501': {
    message: 'Voice Conversation: TwiML attributes validation error.',
    log_level: 'ERROR',
  },
  '32502': {
    message: 'Voice Conversation: Callback event internal error.',
    log_level: 'WARNING',
  },
  '32503': {
    message: 'Voice Conversation: Callback event response error.',
    log_level: 'WARNING',
  },
  '32504': {
    message: 'Voice Conversation: Incomplete Conversation.',
    log_level: 'ERROR',
  },
  '32505': {
    message: 'Voice Conversation: Invalid data inside existing conversation.',
    log_level: 'ERROR',
  },
  '32506': {
    message:
      'Voice Conversation: Failed to get worker assigned to a newly created Conversation.',
    log_level: 'ERROR',
  },
  '32600': {
    message: 'Virtual Agent: Configuration Error',
    log_level: 'ERROR',
  },
  '32601': {
    message: 'Virtual Agent: Provider Error',
    log_level: 'ERROR',
  },
  '32602': {
    message: 'Virtual Agent: Invalid Connector',
    log_level: 'ERROR',
  },
  '32603': {
    message: 'Virtual Agent: Unsupported <Config> attribute(s) in TwiML',
    log_level: 'WARNING',
  },
  '32604': {
    message: 'Virtual Agent: Internal Error',
    log_level: 'ERROR',
  },
  '32700': {
    message: 'Voice User-Defined Message: Internal Twilio Error',
    log_level: 'ERROR',
  },
  '32701': {
    message: 'Voice User-Defined Message: Invalid Content-Type',
    log_level: 'WARNING',
  },
  '32702': {
    message: 'Voice User-Defined Message: Invalid Content',
    log_level: 'WARNING',
  },
  '32703': {
    message: 'Voice User-Defined Message: Content body exceeded max length',
    log_level: 'WARNING',
  },
  '32710': {
    message: 'Voice User-Defined Message: Subscription Callback Internal Error',
    log_level: 'WARNING',
  },
  '32711': {
    message:
      'Voice User-Defined Message: Request to subscription callback URL encountered error ',
    log_level: 'WARNING',
  },
  '33000': {
    message: 'Generic Error',
    log_level: 'ERROR',
  },
  '33004': {
    message: 'Service is unavailable\t',
    log_level: 'ERROR',
  },
  '33010': {
    message: 'Conflicting update',
    log_level: 'ERROR',
  },
  '33101': {
    message: 'Invalid Parameter Value\t',
    log_level: 'ERROR',
  },
  '33102': {
    message: 'Parameter missing',
    log_level: 'ERROR',
  },
  '33103': {
    message: 'Paging information invalid',
    log_level: 'ERROR',
  },
  '33104': {
    message: 'Configuration incomplete',
    log_level: 'ERROR',
  },
  '33105': {
    message: 'Transition invalid',
    log_level: 'ERROR',
  },
  '33107': {
    message: 'SIM not found',
    log_level: 'ERROR',
  },
  '33108': {
    message: 'Rate Plan not found',
    log_level: 'ERROR',
  },
  '33111': {
    message: 'Command exceeded max length',
    log_level: 'ERROR',
  },
  '33118': {
    message: 'Number of Commands exceeded',
    log_level: 'ERROR',
  },
  '33119': {
    message: 'SIM connectivity reset not allowed',
    log_level: 'ERROR',
  },
  '33120': {
    message: 'SIM connectivity reset in progress',
    log_level: 'ERROR',
  },
  '33121': {
    message: 'Invalid Parameter Combination',
    log_level: 'ERROR',
  },
  '33122': {
    message: 'Rate Plan Is Not Allowed',
    log_level: 'ERROR',
  },
  '33201': {
    message: 'Unauthorized',
    log_level: 'ERROR',
  },
  '33203': {
    message: 'Messaging not allowed',
    log_level: 'ERROR',
  },
  '34002': {
    message: 'Callee Busy',
    log_level: 'ERROR',
  },
  '34003': {
    message: 'Callee did not answer',
    log_level: 'ERROR',
  },
  '34004': {
    message: 'Error during fax transmission',
    log_level: 'ERROR',
  },
  '34005': {
    message: 'Programmable Fax is no longer available',
    log_level: 'ERROR',
  },
  '34106': {
    message: 'No Fax TwiML action specified',
    log_level: 'ERROR',
  },
  '34108': {
    message: 'Other End Incompatible',
    log_level: 'ERROR',
  },
  '35111': {
    message: 'SendAt timestamp is missing',
    log_level: 'ERROR',
  },
  '35112': {
    message: 'OptimizeStartAt timestamp is missing',
    log_level: 'ERROR',
  },
  '35113': {
    message: 'OptimizeEndAt timestamp is missing',
    log_level: 'ERROR',
  },
  '35114': {
    message: 'Scheduling does not support this timestamp',
    log_level: 'ERROR',
  },
  '35115': {
    message: 'Scheduling does not support this timestamp',
    log_level: 'ERROR',
  },
  '35116': {
    message: 'Scheduling does not support this timestamp',
    log_level: 'ERROR',
  },
  '35118': {
    message: 'MessagingServiceSid is required to schedule a message',
    log_level: 'ERROR',
  },
  '35119': {
    message: "SendAt can only be used when ScheduleType is 'fixed'",
    log_level: 'ERROR',
  },
  '35120': {
    message: "OptimizeStartAt can only be used when ScheduleType is 'optimize'",
    log_level: 'ERROR',
  },
  '35121': {
    message: "OptimizeEndAt can only be used when ScheduleType is 'optimize'",
    log_level: 'ERROR',
  },
  '35125': {
    message: 'Maximum limit reached in the account for scheduling messages',
    log_level: 'ERROR',
  },
  '35126': {
    message:
      'The ScheduleType value provided is not supported for this channel',
    log_level: 'ERROR',
  },
  '40000': {
    message: "Content-Type of 'application/json' not set",
    log_level: 'ERROR',
  },
  '40001': {
    message:
      'Could not parse Assignment Instruction response as JSON. Ensure your JSON is not escaped',
    log_level: 'WARNING',
  },
  '40005': {
    message: 'Assignment Callback response does not contain Instruction',
    log_level: 'WARNING',
  },
  '40100': {
    message: 'TaskRouter->Reject',
    log_level: 'ERROR',
  },
  '40110': {
    message: 'TaskRouter->Call',
    log_level: 'ERROR',
  },
  '40111': {
    message: 'TaskRouter->Call',
    log_level: 'ERROR',
  },
  '40112': {
    message: 'TaskRouter->Call',
    log_level: 'ERROR',
  },
  '40113': {
    message: 'TaskRouter->Call',
    log_level: 'ERROR',
  },
  '40114': {
    message: 'TaskRouter->Call',
    log_level: 'ERROR',
  },
  '40120': {
    message: 'TaskRouter->Direct',
    log_level: 'ERROR',
  },
  '40121': {
    message: 'TaskRouter->Direct',
    log_level: 'ERROR',
  },
  '40122': {
    message: 'TaskRouter->Direct',
    log_level: 'ERROR',
  },
  '40123': {
    message: 'TaskRouter->Redirect',
    log_level: 'ERROR',
  },
  '40130': {
    message: 'TaskRouter->Dequeue',
    log_level: 'ERROR',
  },
  '40131': {
    message: 'TaskRouter->Dequeue',
    log_level: 'ERROR',
  },
  '40132': {
    message: 'TaskRouter->Dequeue',
    log_level: 'ERROR',
  },
  '40133': {
    message: 'TaskRouter->Dequeue',
    log_level: 'ERROR',
  },
  '40134': {
    message:
      "Dequeue Instruction does not have a valid 'status_callback_events' parameter",
    log_level: 'ERROR',
  },
  '40135': {
    message: 'TaskRouter->Dequeue',
    log_level: 'ERROR',
  },
  '40136': {
    message: 'TaskRouter->Conference',
    log_level: 'ERROR',
  },
  '40137': {
    message: "Missing 'to' parameter when issuing Conference instruction",
    log_level: 'ERROR',
  },
  '40138': {
    message: "Missing 'from' parameter when issuing Conference instruction",
    log_level: 'ERROR',
  },
  '40139': {
    message:
      'Failed to issue Conference Instruction due to invalid ActivitySid',
    log_level: 'ERROR',
  },
  '40140': {
    message:
      "Failed to issue Dequeue instruction due to missing 'call_sid' property",
    log_level: 'ERROR',
  },
  '40141': {
    message: 'TaskRouter->Conference',
    log_level: 'ERROR',
  },
  '40142': {
    message:
      "Failed to issue Conference instruction due to missing 'call_sid' or 'outbound_to' properties",
    log_level: 'ERROR',
  },
  '40143': {
    message: 'TaskRouter->Conference',
    log_level: 'ERROR',
  },
  '40144': {
    message: 'TaskRouter->Conference',
    log_level: 'ERROR',
  },
  '40145': {
    message: 'TaskRouter->Conference',
    log_level: 'ERROR',
  },
  '40146': {
    message:
      'Failed to issue Supervise Instruction due to invalid Reservation state',
    log_level: 'ERROR',
  },
  '40147': {
    message:
      "Failed to issue Supervise instruction due to missing or invalid 'contact_uri' property",
    log_level: 'ERROR',
  },
  '40148': {
    message:
      'Failed to issue Supervise instruction due to missing ‘from’ property',
    log_level: 'ERROR',
  },
  '40149': {
    message:
      'Failed to issue Conference instruction due to missing ‘conference_sid’',
    log_level: 'ERROR',
  },
  '40151': {
    message: 'TaskRouter->Invalid instruction',
    log_level: 'ERROR',
  },
  '40152': {
    message: 'Invalid Queue for Known Worker',
    log_level: 'ERROR',
  },
  '40153': {
    message: 'Absolute Paging used when iterating TaskRouter resources',
    log_level: 'WARNING',
  },
  '40154': {
    message: 'Invalid Known Worker information',
    log_level: 'ERROR',
  },
  '40155': {
    message: 'Invalid Workspace for Known Worker',
    log_level: 'ERROR',
  },
  '40157': {
    message:
      "Dequeue Instruction does not have a valid 'status_callback_url' parameter",
    log_level: 'ERROR',
  },
  '45001': {
    message: 'General Service Error',
    log_level: 'ERROR',
  },
  '45002': {
    message: 'Authentication Error',
    log_level: 'ERROR',
  },
  '45003': {
    message: 'Authorization Error',
    log_level: 'ERROR',
  },
  '45004': {
    message: 'Validation Error',
    log_level: 'ERROR',
  },
  '45005': {
    message: 'Service Unavailable',
    log_level: 'ERROR',
  },
  '45006': {
    message: 'Resource Not Found',
    log_level: 'ERROR',
  },
  '45007': {
    message: 'Resource Conflict Error',
    log_level: 'ERROR',
  },
  '45008': {
    message: 'Unprocessable Request',
    log_level: 'ERROR',
  },
  '45009': {
    message: 'Configuration Error',
    log_level: 'ERROR',
  },
  '45010': {
    message: 'Rate Limit Exceeded',
    log_level: 'ERROR',
  },
  '45101': {
    message: 'Configuration Not Found',
    log_level: 'ERROR',
  },
  '45102': {
    message: 'Collision On Configuration Change',
    log_level: 'ERROR',
  },
  '45103': {
    message: 'Workspace Not Configured',
    log_level: 'ERROR',
  },
  '45109': {
    message: 'Skills Limit Exceeded',
    log_level: 'ERROR',
  },
  '45201': {
    message: 'Resource Not Found',
    log_level: 'ERROR',
  },
  '45202': {
    message: 'Resource Already Exists',
    log_level: 'ERROR',
  },
  '45203': {
    message: 'Requested Chat User not found',
    log_level: 'ERROR',
  },
  '45204': {
    message: 'Requested Flex Chat Channel is not found',
    log_level: 'WARNING',
  },
  '45205': {
    message: 'Requested Flex Flow is not found',
    log_level: 'ERROR',
  },
  '45206': {
    message: 'Chat User is already a member of the Chat Channel',
    log_level: 'WARNING',
  },
  '45207': {
    message:
      'Chat User per Chat Channel limit reached. User is part of too many Chat Channels.',
    log_level: 'ERROR',
  },
  '45208': {
    message: 'Chat Channel with this unique name already exists',
    log_level: 'WARNING',
  },
  '45209': {
    message:
      'Adding a user to the Chat Channel has timed out waiting for a response from Twilio Chat.',
    log_level: 'ERROR',
  },
  '45210': {
    message: 'Flex Flow creation failed',
    log_level: 'ERROR',
  },
  '45211': {
    message: 'Provided identity is reserved by system',
    log_level: 'ERROR',
  },
  '45212': {
    message: 'Flex Flow creation failed',
    log_level: 'ERROR',
  },
  '45301': {
    message: 'Error occurred when connecting to a Meeting Participant',
    log_level: 'ERROR',
  },
  '45302': {
    message: 'Participant was disconnected before setting up Meeting',
    log_level: 'WARNING',
  },
  '45303': {
    message: 'Participant was busy',
    log_level: 'WARNING',
  },
  '45304': {
    message: 'Internal timeout error',
    log_level: 'ERROR',
  },
  '45305': {
    message: ' No answer from Participant',
    log_level: 'WARNING',
  },
  '45306': {
    message: 'Meeting was canceled',
    log_level: 'WARNING',
  },
  '45307': {
    message: 'Add Participant Not Allowed',
    log_level: 'WARNING',
  },
  '45308': {
    message: 'Add Participant Not Allowed',
    log_level: 'WARNING',
  },
  '45309': {
    message: 'Modify Participant Not Allowed',
    log_level: 'ERROR',
  },
  '45310': {
    message: 'Participant Not Found',
    log_level: 'ERROR',
  },
  '45312': {
    message: 'Remove Participant Request Failed',
    log_level: 'ERROR',
  },
  '45313': {
    message: 'Transfer Failed',
    log_level: 'ERROR',
  },
  '45350': {
    message: 'An unexpected error occurred.',
    log_level: 'ERROR',
  },
  '45351': {
    message: 'Invalid Channel Participant properties.',
    log_level: 'ERROR',
  },
  '45352': {
    message: 'Failed to create an outbound Channel.',
    log_level: 'ERROR',
  },
  '45353': {
    message: 'A duplicate create Channel request was dropped.',
    log_level: 'WARNING',
  },
  '45354': {
    message: 'Cannot create a Channel for a closed Conversation.',
    log_level: 'ERROR',
  },
  '45355': {
    message: 'Failed to create a channel.  No Participants found.',
    log_level: 'ERROR',
  },
  '45356': {
    message: 'Failed to create a channel. Downstream error.',
    log_level: 'ERROR',
  },
  '45357': {
    message: 'Downstream error configuring Channel.',
    log_level: 'ERROR',
  },
  '45358': {
    message: 'Failed to close Channel.  Missing status parameter.',
    log_level: 'ERROR',
  },
  '45359': {
    message: 'Failed to close Channel.  Invalid status parameter.',
    log_level: 'ERROR',
  },
  '45360': {
    message: 'Failed to close or delete Conversation.  Downstream error.',
    log_level: 'ERROR',
  },
  '45361': {
    message: 'Failed to add Participant. Downstream error.',
    log_level: 'ERROR',
  },
  '45362': {
    message: 'Failed to remove Participant.  Not found.',
    log_level: 'WARNING',
  },
  '45363': {
    message: 'Failed to remove Participant.  Downstream error.',
    log_level: 'ERROR',
  },
  '45366': {
    message: 'Failed to create Channel.  Invalid request parameters.',
    log_level: 'ERROR',
  },
  '45367': {
    message: 'Failed to add Participant.  Invalid request parameters.',
    log_level: 'ERROR',
  },
  '45368': {
    message: 'Failed to delete Channel.  Not found.',
    log_level: 'WARNING',
  },
  '45369': {
    message: 'Failed to create inbound Channel.  Internal error.',
    log_level: 'ERROR',
  },
  '45370': {
    message: 'Failed to create outbound Channel.  Internal error.',
    log_level: 'ERROR',
  },
  '45371': {
    message: 'Failed to close Channel.  Internal error.',
    log_level: 'ERROR',
  },
  '45372': {
    message: 'Failed to add Participant.  Internal error.',
    log_level: 'ERROR',
  },
  '45373': {
    message: 'Failed to remove Participant.  Internal error.',
    log_level: 'ERROR',
  },
  '45374': {
    message: 'Failed to delete Channel.  Internal error.',
    log_level: 'ERROR',
  },
  '45375': {
    message: 'Failed to delete Channel.  Invalid Account sid.',
    log_level: 'WARNING',
  },
  '45376': {
    message:
      'Failed to add a participant.  Conversation was closed or not found.',
    log_level: 'WARNING',
  },
  '45377': {
    message: 'Account SID not authorized. ',
    log_level: 'ERROR',
  },
  '45401': {
    message: 'RTA feed callback returned not successful response code',
    log_level: 'WARNING',
  },
  '45402': {
    message: 'RTA feed callback response time exceeded the threshold',
    log_level: 'WARNING',
  },
  '45600': {
    message: 'Flex UI error',
    log_level: 'ERROR',
  },
  '45601': {
    message: 'Custom Flex UI error',
    log_level: 'ERROR',
  },
  '45700': {
    message: 'Unexpected error occurred. Unable to process WebChat request.',
    log_level: 'ERROR',
  },
  '45701': {
    message: 'Unexpected error occurred. Service unavailable. ',
    log_level: 'ERROR',
  },
  '45711': {
    message: 'Failed to create webchat participant. Unauthorized ',
    log_level: 'WARNING',
  },
  '45715': {
    message:
      'Failed to create webchat participant. Invalid request parameters. ',
    log_level: 'WARNING',
  },
  '45719': {
    message: 'Failed to create webchat participant. Too many requests ',
    log_level: 'WARNING',
  },
  '45731': {
    message: 'Pre-engagement data too large. ',
    log_level: 'ERROR',
  },
  '45733': {
    message: 'Pre-engagement data is not in valid JSON format.',
    log_level: 'ERROR',
  },
  '45741': {
    message: 'Failed to create webchat conversation. Account not authorized ',
    log_level: 'ERROR',
  },
  '45744': {
    message:
      'Failed to create webchat conversation. A resource provided could not be found. ',
    log_level: 'ERROR',
  },
  '45745': {
    message:
      'Invalid or missing parameters in the create conversation request.',
    log_level: 'ERROR',
  },
  '45760': {
    message:
      'Unable to validate address configuration. Auto create data missing.  ',
    log_level: 'ERROR',
  },
  '45761': {
    message:
      'Failed to validate address configuration. Auto create not enabled.',
    log_level: 'ERROR',
  },
  '45762': {
    message:
      'Failed to validate address configuration. Auto create type is empty or invalid. ',
    log_level: 'ERROR',
  },
  '45763': {
    message:
      'Unable to validate address configuration. Auto create studio flow SID missing. ',
    log_level: 'ERROR',
  },
  '45764': {
    message:
      'Unable to validate address configuration. Auto create webhook URL missing. ',
    log_level: 'ERROR',
  },
  '45765': {
    message: 'Address configuration data not found',
    log_level: 'ERROR',
  },
  '48000': {
    message: 'Invalid request payload',
    log_level: 'ERROR',
  },
  '48001': {
    message: 'Callback URL is not set',
    log_level: 'WARNING',
  },
  '48002': {
    message: 'Callback URL is invalid',
    log_level: 'ERROR',
  },
  '48003': {
    message: 'Callback returned an invalid response',
    log_level: 'ERROR',
  },
  '48004': {
    message: 'Callback returned an error',
    log_level: 'ERROR',
  },
  '48005': {
    message: 'Callback failed due to timeout',
    log_level: 'ERROR',
  },
  '48010': {
    message: 'Custom Routing Callback failed to execute successfully',
    log_level: 'ERROR',
  },
  '48011': {
    message: 'Custom Routing Callback failed due to an internal error',
    log_level: 'ERROR',
  },
  '48023': {
    message: 'Frontline user conversation limit exceeded',
    log_level: 'ERROR',
  },
  '48024': {
    message: 'Contact conversation limit exceeded',
    log_level: 'ERROR',
  },
  '48025': {
    message: 'Outgoing conversation: Invalid contact address',
    log_level: 'ERROR',
  },
  '48026': {
    message: 'Outgoing conversation: Invalid proxy address',
    log_level: 'ERROR',
  },
  '48027': {
    message: 'Outgoing conversation: Proxy address equals contact address',
    log_level: 'ERROR',
  },
  '48028': {
    message: 'Outgoing conversation: Unauthorized use of the proxy address',
    log_level: 'ERROR',
  },
  '48029': {
    message:
      'Outgoing conversation: Contact address type does not match proxy address type',
    log_level: 'ERROR',
  },
  '48030': {
    message:
      'Outgoing conversation: Proxy address is not WhatsApp-enabled sender',
    log_level: 'ERROR',
  },
  '48031': {
    message:
      'Outgoing conversation: Conversation with this contact and proxy address already exists',
    log_level: 'ERROR',
  },
  '48032': {
    message: 'Outgoing conversation: Missing Messaging service',
    log_level: 'ERROR',
  },
  '48033': {
    message: 'Outgoing conversation: Invalid contact identity',
    log_level: 'ERROR',
  },
  '48050': {
    message: 'Internal service error',
    log_level: 'ERROR',
  },
  '50000': {
    message: 'Programmable Chat: FriendlyName not provided',
    log_level: 'ERROR',
  },
  '50001': {
    message: 'Programmable Chat: Account SID not provided',
    log_level: 'ERROR',
  },
  '50002': {
    message: 'Programmable Chat: Resource is being deleted',
    log_level: 'ERROR',
  },
  '50003': {
    message: 'Programmable Chat: Friendly name too long',
    log_level: 'ERROR',
  },
  '50004': {
    message: 'Programmable Chat: Unique Name too long',
    log_level: 'ERROR',
  },
  '50006': {
    message: 'Programmable Chat: Invalid Account SID',
    log_level: 'ERROR',
  },
  '50050': {
    message: 'Programmable Chat: Service Instance not found',
    log_level: 'ERROR',
  },
  '50051': {
    message: 'Programmable Chat: Service SID not provided',
    log_level: 'ERROR',
  },
  '50052': {
    message: 'Programmable Chat: Invalid consumption interval format',
    log_level: 'ERROR',
  },
  '50053': {
    message: 'Programmable Chat: Invalid typing indicator format',
    log_level: 'ERROR',
  },
  '50054': {
    message: 'Programmable Chat: Invalid webhook format',
    log_level: 'ERROR',
  },
  '50055': {
    message: 'Programmable Chat: Invalid webhook method',
    log_level: 'ERROR',
  },
  '50056': {
    message: 'Programmable Chat: Webhook disabled processing of command',
    log_level: 'ERROR',
  },
  '50057': {
    message: 'Programmable Chat: Webhook call failed to execute successfully',
    log_level: 'ERROR',
  },
  '50058': {
    message: 'Programmable Chat: Notification template too long',
    log_level: 'ERROR',
  },
  '50059': {
    message: 'Programmable Chat: Notification sound name too long',
    log_level: 'ERROR',
  },
  '50060': {
    message: 'Programmable Chat: Invalid user channels limit format',
    log_level: 'ERROR',
  },
  '50061': {
    message: 'Programmable Chat: Invalid channel members limit format',
    log_level: 'ERROR',
  },
  '50063': {
    message: 'Programmable Chat: Actions per second limit exceeded',
    log_level: 'ERROR',
  },
  '50065': {
    message: 'Programmable Chat: Invalid webhook retry count',
    log_level: 'ERROR',
  },
  '50068': {
    message: 'Programmable Chat: Service instance unique name invalid',
    log_level: 'ERROR',
  },
  '50069': {
    message:
      'Programmable Chat: Service instance with provided unique name already exists',
    log_level: 'ERROR',
  },
  '50074': {
    message: 'Global actions per second limit exceeded',
    log_level: 'ERROR',
  },
  '50076': {
    message: 'Webhook failed to execute successfully due to timeout',
    log_level: 'ERROR',
  },
  '50100': {
    message: 'Programmable Chat: Role not found',
    log_level: 'ERROR',
  },
  '50101': {
    message: 'Programmable Chat: Channel role not found',
    log_level: 'ERROR',
  },
  '50102': {
    message: 'Programmable Chat: Deployment role not found',
    log_level: 'ERROR',
  },
  '50103': {
    message: 'Programmable Chat: Role SID not provided',
    log_level: 'ERROR',
  },
  '50104': {
    message: 'Programmable Chat: Permission not found',
    log_level: 'ERROR',
  },
  '50105': {
    message: 'Programmable Chat: Invalid role type',
    log_level: 'ERROR',
  },
  '50106': {
    message: 'Programmable Chat: Channel creator role not found',
    log_level: 'ERROR',
  },
  '50107': {
    message: 'Programmable Chat: User not authorized for command',
    log_level: 'ERROR',
  },
  '50109': {
    message: 'Conversation role not found',
    log_level: 'ERROR',
  },
  '50200': {
    message: 'Programmable Chat: User not found',
    log_level: 'ERROR',
  },
  '50201': {
    message: 'Programmable Chat: User already exists',
    log_level: 'ERROR',
  },
  '50202': {
    message: 'Programmable Chat: User SID not provided',
    log_level: 'ERROR',
  },
  '50203': {
    message: 'Programmable Chat: Identity reserved',
    log_level: 'ERROR',
  },
  '50204': {
    message: 'Programmable Chat: Identity not provided',
    log_level: 'ERROR',
  },
  '50205': {
    message: 'Programmable Chat: User unauthorized to set role',
    log_level: 'ERROR',
  },
  '50206': {
    message: 'Programmable Chat: Identity should not match user SID pattern',
    log_level: 'ERROR',
  },
  '50207': {
    message: 'Programmable Chat: Identity too long',
    log_level: 'ERROR',
  },
  '50208': {
    message: 'Programmable Chat: User channel limit exceeded',
    log_level: 'ERROR',
  },
  '50209': {
    message: 'Programmable Chat: Invalid binding type',
    log_level: 'ERROR',
  },
  '50210': {
    message: 'Programmable Chat: Invalid notification level',
    log_level: 'ERROR',
  },
  '50211': {
    message: 'Identity parameter not acceptable for this Participant',
    log_level: 'ERROR',
  },
  '50212': {
    message: 'Programmable Chat: User already invited',
    log_level: 'ERROR',
  },
  '50213': {
    message: 'Conflicting user modification',
    log_level: 'ERROR',
  },
  '50214': {
    message: 'User conversation limit exceeded',
    log_level: 'ERROR',
  },
  '50300': {
    message: 'Programmable Chat: Channel not found',
    log_level: 'ERROR',
  },
  '50301': {
    message: 'Programmable Chat: Channel key not provided',
    log_level: 'ERROR',
  },
  '50302': {
    message: 'Programmable Chat: Unknown channel command',
    log_level: 'ERROR',
  },
  '50303': {
    message: 'Programmable Chat: Attributes too long',
    log_level: 'ERROR',
  },
  '50304': {
    message: 'Programmable Chat: Attributes not valid JSON',
    log_level: 'ERROR',
  },
  '50305': {
    message: 'Programmable Chat: Channel SID not provided',
    log_level: 'ERROR',
  },
  '50306': {
    message:
      'Programmable Chat: Unique name should not match channel SID pattern',
    log_level: 'ERROR',
  },
  '50307': {
    message:
      'Programmable Chat: Channel with provided unique name already exists',
    log_level: 'ERROR',
  },
  '50308': {
    message: 'Programmable Chat: Invalid Date Created parameter',
    log_level: 'ERROR',
  },
  '50309': {
    message: 'Programmable Chat: Invalid Date Updated parameter',
    log_level: 'ERROR',
  },
  '50310': {
    message: 'Programmable Chat: Author parameter is too long',
    log_level: 'ERROR',
  },
  '50320': {
    message: 'Programmable Chat: Channel webhook not found',
    log_level: 'ERROR',
  },
  '50321': {
    message: 'Programmable Chat: Too many channel webhooks',
    log_level: 'ERROR',
  },
  '50322': {
    message: 'Programmable Chat: Too many channel webhook triggers',
    log_level: 'ERROR',
  },
  '50323': {
    message: 'Programmable Chat: Invalid channel webhook sid',
    log_level: 'ERROR',
  },
  '50324': {
    message: 'Invalid channel webhook type',
    log_level: 'ERROR',
  },
  '50325': {
    message: 'Programmable Chat: Invalid channel webhook filter',
    log_level: 'ERROR',
  },
  '50326': {
    message: 'Programmable Chat: Invalid channel webhook trigger',
    log_level: 'ERROR',
  },
  '50327': {
    message: 'Programmable Chat: Invalid channel webhook flow sid',
    log_level: 'ERROR',
  },
  '50328': {
    message: 'Programmable Chat: Channel webhook type not provided',
    log_level: 'ERROR',
  },
  '50329': {
    message: 'Programmable Chat: Channel webhook url not provided',
    log_level: 'ERROR',
  },
  '50330': {
    message: 'Programmable Chat: Channel webhook filter not provided',
    log_level: 'ERROR',
  },
  '50331': {
    message: 'Programmable Chat: Channel webhook trigger not provided',
    log_level: 'ERROR',
  },
  '50332': {
    message: 'Programmable Chat: Channel webhook url too long',
    log_level: 'ERROR',
  },
  '50334': {
    message: 'Channel deletion operation is in progress',
    log_level: 'ERROR',
  },
  '50340': {
    message: 'Messaging service SID not provided',
    log_level: 'ERROR',
  },
  '50341': {
    message: 'Invalid messaging service SID',
    log_level: 'ERROR',
  },
  '50342': {
    message: 'Messaging service does not belong to account',
    log_level: 'ERROR',
  },
  '50347': {
    message: 'Parameters are missing for channel update request',
    log_level: 'ERROR',
  },
  '50349': {
    message: 'Conflicting channel modification',
    log_level: 'ERROR',
  },
  '50350': {
    message: 'Conversation not found',
    log_level: 'ERROR',
  },
  '50351': {
    message: 'Conversation SID not provided',
    log_level: 'ERROR',
  },
  '50353': {
    message: 'Conversation with provided unique name already exists',
    log_level: 'ERROR',
  },
  '50360': {
    message: 'Conversation webhook not found',
    log_level: 'ERROR',
  },
  '50361': {
    message: 'Too many conversation webhooks',
    log_level: 'ERROR',
  },
  '50362': {
    message: 'Too many conversation webhook triggers',
    log_level: 'ERROR',
  },
  '50363': {
    message: 'Invalid conversation webhook SID',
    log_level: 'ERROR',
  },
  '50364': {
    message: 'Invalid conversation webhook type',
    log_level: 'ERROR',
  },
  '50365': {
    message: 'Invalid conversation webhook filter',
    log_level: 'ERROR',
  },
  '50366': {
    message: 'Invalid conversation webhook trigger',
    log_level: 'ERROR',
  },
  '50367': {
    message: 'Invalid conversation webhook flow SID',
    log_level: 'ERROR',
  },
  '50368': {
    message: 'Conversation webhook type not provided',
    log_level: 'ERROR',
  },
  '50369': {
    message: 'Conversation webhook URL not provided',
    log_level: 'ERROR',
  },
  '50370': {
    message: 'Conversation webhook filter not provided',
    log_level: 'ERROR',
  },
  '50371': {
    message: 'Conversation webhook trigger not provided',
    log_level: 'ERROR',
  },
  '50372': {
    message: 'Conversation webhook URL is too long',
    log_level: 'ERROR',
  },
  '50373': {
    message: 'No Messaging Service assigned to Conversation',
    log_level: 'ERROR',
  },
  '50374': {
    message: "'State' field can't be empty",
    log_level: 'ERROR',
  },
  '50375': {
    message: 'TimeToInactive should be greater or equal to 1 minute',
    log_level: 'ERROR',
  },
  '50376': {
    message: 'TimeToClosed should be greater or equal to 10 minutes',
    log_level: 'ERROR',
  },
  '50377': {
    message: "Can't update conversation as it's in final closed state",
    log_level: 'ERROR',
  },
  '50378': {
    message: "'State' parameter value is invalid",
    log_level: 'ERROR',
  },
  '50379': {
    message: 'TimeToInactive format is invalid',
    log_level: 'ERROR',
  },
  '50380': {
    message: 'TimeToClosed format is invalid',
    log_level: 'ERROR',
  },
  '50382': {
    message: "Timer can't be set without state",
    log_level: 'ERROR',
  },
  '50384': {
    message: "Initial state can't be 'closed",
    log_level: 'ERROR',
  },
  '50385': {
    message: 'Conversations are disabled in this region',
    log_level: 'ERROR',
  },
  '50390': {
    message: 'Unique name should not match conversation sid pattern',
    log_level: 'ERROR',
  },
  '50391': {
    message: 'Conflicting conversation modification',
    log_level: 'ERROR',
  },
  '50392': {
    message: 'PreWebhookTimeout should be greater than 0ms',
    log_level: 'ERROR',
  },
  '50393': {
    message: "Type value should be 'private'.",
    log_level: 'ERROR',
  },
  '50400': {
    message: 'Programmable Chat: User not member of channel',
    log_level: 'ERROR',
  },
  '50401': {
    message: 'Programmable Chat: Member SID not provided',
    log_level: 'ERROR',
  },
  '50402': {
    message: 'Programmable Chat: Member not found',
    log_level: 'ERROR',
  },
  '50403': {
    message: 'Programmable Chat: Channel member limit exceeded',
    log_level: 'ERROR',
  },
  '50404': {
    message: 'Programmable Chat: Member already exists',
    log_level: 'ERROR',
  },
  '50405': {
    message: 'Programmable Chat: Invalid last consumption timestamp format',
    log_level: 'ERROR',
  },
  '50406': {
    message: 'Programmable Chat: Invalid last consumed message index format',
    log_level: 'ERROR',
  },
  '50407': {
    message: 'Invalid messaging binding address',
    log_level: 'ERROR',
  },
  '50408': {
    message: 'Invalid participant proxy address',
    log_level: 'ERROR',
  },
  '50409': {
    message: 'Participant address equals proxy address',
    log_level: 'ERROR',
  },
  '50411': {
    message: 'Participant address is empty',
    log_level: 'ERROR',
  },
  '50412': {
    message: 'Participant proxy address is empty',
    log_level: 'ERROR',
  },
  '50413': {
    message: 'Account is not authorized to use proxy address',
    log_level: 'ERROR',
  },
  '50414': {
    message: 'Participant address type does not match proxy address type',
    log_level: 'ERROR',
  },
  '50415': {
    message: 'Proxy address is not WhatsApp enabled sender',
    log_level: 'ERROR',
  },
  '50416': {
    message: 'Participant and proxy address pair is already in use',
    log_level: 'ERROR',
  },
  '50417': {
    message: 'Participants limit exceeded',
    log_level: 'ERROR',
  },
  '50418': {
    message: 'Non-Chat participants limit exceeded',
    log_level: 'ERROR',
  },
  '50419': {
    message: 'Conflicting member modification',
    log_level: 'ERROR',
  },
  '50420': {
    message:
      'Participant Messaging Binding type does not support all of the provided Messaging Binding parameters',
    log_level: 'ERROR',
  },
  '50421': {
    message: 'Invalid last read message index format',
    log_level: 'ERROR',
  },
  '50422': {
    message: 'Non-chat conversation participants limit exceeded',
    log_level: 'ERROR',
  },
  '50430': {
    message: 'Participant is not a member of conversation',
    log_level: 'ERROR',
  },
  '50431': {
    message: 'Participant SID not provided',
    log_level: 'ERROR',
  },
  '50432': {
    message: 'Participant not found',
    log_level: 'ERROR',
  },
  '50433': {
    message: 'Participant already exists',
    log_level: 'ERROR',
  },
  '50434': {
    message: 'Participant projected address not provided',
    log_level: 'ERROR',
  },
  '50435': {
    message: 'Group MMS activation failed.',
    log_level: 'ERROR',
  },
  '50436': {
    message: 'Participant limit exceeded for group conversation',
    log_level: 'ERROR',
  },
  '50437': {
    message: 'Group Participant already exists',
    log_level: 'ERROR',
  },
  '50438': {
    message: 'Group conversation with given participant list already exists',
    log_level: 'ERROR',
  },
  '50439': {
    message: 'Account is not authorized to use the projected address',
    log_level: 'ERROR',
  },
  '50440': {
    message: 'Failed to remove the proxy address of a participant.',
    log_level: 'ERROR',
  },
  '50441': {
    message: 'Failed to add a proxy address to a participant.',
    log_level: 'ERROR',
  },
  '50442': {
    message: 'Failed to remove the projected address of a participant.',
    log_level: 'ERROR',
  },
  '50443': {
    message: 'Failed to add a projected address to a participant.',
    log_level: 'ERROR',
  },
  '50444': {
    message: 'Failed to add an identity to a participant.',
    log_level: 'ERROR',
  },
  '50449': {
    message: 'Conflicting channel modification',
    log_level: 'ERROR',
  },
  '50451': {
    message:
      'One user identifier parameter for lookup at a time is allowed. Please, use either Identity or Address.',
    log_level: 'ERROR',
  },
  '50452': {
    message: 'Group MMS is not enabled for this Account',
    log_level: 'ERROR',
  },
  '50453': {
    message: 'Proxy Address of participant is not supported for this channel',
    log_level: 'ERROR',
  },
  '50500': {
    message: 'Programmable Chat: Message not found',
    log_level: 'ERROR',
  },
  '50501': {
    message: 'Programmable Chat: Message SID not provided',
    log_level: 'ERROR',
  },
  '50502': {
    message: 'Programmable Chat: Message index not provided',
    log_level: 'ERROR',
  },
  '50503': {
    message: 'Programmable Chat: Message body not provided',
    log_level: 'ERROR',
  },
  '50504': {
    message: 'Programmable Chat: Message body parameter is too long',
    log_level: 'ERROR',
  },
  '50505': {
    message: 'Programmable Chat: Last Updated By parameter is too long',
    log_level: 'ERROR',
  },
  '50506': {
    message: 'Programmable Chat: Media SID not provided',
    log_level: 'ERROR',
  },
  '50507': {
    message: 'Programmable Chat: Media not found',
    log_level: 'ERROR',
  },
  '50508': {
    message: 'Programmable Chat: Media already sent with another message',
    log_level: 'ERROR',
  },
  '50509': {
    message: 'Programmable Chat: Media message body cannot be updated',
    log_level: 'ERROR',
  },
  '50510': {
    message: 'Invalid message media SID',
    log_level: 'ERROR',
  },
  '50511': {
    message: 'Invalid message media content type',
    log_level: 'ERROR',
  },
  '50512': {
    message: 'Message media size is too large',
    log_level: 'ERROR',
  },
  '50513': {
    message: ' Message author should be among Group MMS participants.',
    log_level: 'ERROR',
  },
  '50516': {
    message: 'Programmable Chat: Message index is not a number',
    log_level: 'ERROR',
  },
  '50526': {
    message: 'Invalid content SID',
    log_level: 'ERROR',
  },
  '50527': {
    message: 'Invalid content variables format',
    log_level: 'ERROR',
  },
  '50528': {
    message: 'Content SID is missing',
    log_level: 'ERROR',
  },
  '50600': {
    message: 'Programmable Chat: Invite SID not provided',
    log_level: 'ERROR',
  },
  '50601': {
    message: 'Programmable Chat: Invite not found',
    log_level: 'ERROR',
  },
  '50602': {
    message:
      'Programmable Chat: Cannot decline invite when already channel member.',
    log_level: 'ERROR',
  },
  '50610': {
    message: 'Address configuration deleted',
    log_level: 'WARNING',
  },
  '51001': {
    message: 'Client Connection: Connections resource limit exceeded',
    log_level: 'ERROR',
  },
  '51002': {
    message: 'Client Connection: Request rate limit exceeded',
    log_level: 'ERROR',
  },
  '51003': {
    message: 'Client Connection: identity too long',
    log_level: 'ERROR',
  },
  '51004': {
    message: 'Client Connection: endpoint_id too long',
    log_level: 'ERROR',
  },
  '51005': {
    message:
      'Client Connection: Command or keepalive acknowledgement not received',
    log_level: 'ERROR',
  },
  '51006': {
    message: 'Client Connection: Connection expired',
    log_level: 'ERROR',
  },
  '51007': {
    message: 'Client Connection: Token authentication is rejected',
    log_level: 'ERROR',
  },
  '51101': {
    message: 'Twilsock: Service instance not found',
    log_level: 'ERROR',
  },
  '51102': {
    message: 'Twilsock: Service instance SID not specified',
    log_level: 'ERROR',
  },
  '51103': {
    message: "Twilsock: Token doesn't contain required grants section",
    log_level: 'ERROR',
  },
  '51104': {
    message: "Twilsock: Token doesn't contain service instance",
    log_level: 'ERROR',
  },
  '51105': {
    message: "Twilsock: Token doesn't contain identity",
    log_level: 'ERROR',
  },
  '51106': {
    message:
      "Twilsock: Active product doesn't match with service instance product",
    log_level: 'ERROR',
  },
  '51107': {
    message: "Twilsock: Service can't be used",
    log_level: 'ERROR',
  },
  '51108': {
    message: 'Twilsock: Service instance disabled',
    log_level: 'ERROR',
  },
  '51109': {
    message: 'Twilsock: Service instance is under legal hold',
    log_level: 'ERROR',
  },
  '51110': {
    message: 'Twilsock: Token contains multiple grants of same product',
    log_level: 'ERROR',
  },
  '51111': {
    message: 'Twilsock: Service instance limit reached',
    log_level: 'ERROR',
  },
  '51112': {
    message: 'Twilsock: Product usage disabled',
    log_level: 'ERROR',
  },
  '51113': {
    message: 'Twilsock: Product usage is not enabled',
    log_level: 'ERROR',
  },
  '51114': {
    message: 'Twilsock: Invalid access token header',
    log_level: 'ERROR',
  },
  '51115': {
    message: 'Twilsock: Invalid access token subject',
    log_level: 'ERROR',
  },
  '51116': {
    message: 'Twilsock: Invalid access token grants',
    log_level: 'ERROR',
  },
  '51117': {
    message: 'Twilsock: Invalid access token signature',
    log_level: 'ERROR',
  },
  '51118': {
    message: 'Twilsock: Invalid claim set',
    log_level: 'ERROR',
  },
  '51119': {
    message: 'Twilsock: Token expired',
    log_level: 'ERROR',
  },
  '51120': {
    message: 'Twilsock: Token is not valid yet',
    log_level: 'ERROR',
  },
  '51121': {
    message: 'Twilsock: Token expiration time exceeds maximum',
    log_level: 'ERROR',
  },
  '51122': {
    message: 'Twilsock: Authentication failed',
    log_level: 'ERROR',
  },
  '51123': {
    message: 'Twilsock: Upstream not resolved',
    log_level: 'ERROR',
  },
  '51124': {
    message: 'Twilsock: Too many connections',
    log_level: 'ERROR',
  },
  '51125': {
    message: 'Twilsock: Too many updates',
    log_level: 'ERROR',
  },
  '51126': {
    message: 'Twilsock: DNC limit has been reached',
    log_level: 'ERROR',
  },
  '51127': {
    message: 'Twilsock: PNC limit has been',
    log_level: 'ERROR',
  },
  '51128': {
    message: 'Twilsock: Too many messages per connection',
    log_level: 'ERROR',
  },
  '51129': {
    message: 'Twilsock: Too many messages per account',
    log_level: 'ERROR',
  },
  '51130': {
    message: 'Twilsock: Token is invalid!',
    log_level: 'ERROR',
  },
  '51131': {
    message: 'Twilsock: Authentication failed',
    log_level: 'ERROR',
  },
  '51201': {
    message: 'Twilsock : CPS, Init per Account',
    log_level: 'ERROR',
  },
  '51202': {
    message: 'Twilsock : CPS, Too many requests',
    log_level: 'ERROR',
  },
  '51215': {
    message: 'Twilsock: Unauthorized',
    log_level: 'ERROR',
  },
  '52000': {
    message: 'Internal notification error',
    log_level: 'ERROR',
  },
  '52001': {
    message: 'Invalid destination binding',
    log_level: 'ERROR',
  },
  '52002': {
    message: 'Invalid Credential Sid',
    log_level: 'ERROR',
  },
  '52003': {
    message: 'Invalid Credential Type',
    log_level: 'ERROR',
  },
  '52004': {
    message: 'Credential SID not specified',
    log_level: 'ERROR',
  },
  '52051': {
    message: 'Internal error when sending notification via client connection',
    log_level: 'ERROR',
  },
  '52052': {
    message: 'Client connection not created or closed',
    log_level: 'WARNING',
  },
  '52071': {
    message:
      'Internal error when sending notification via mqtt client connection',
    log_level: 'ERROR',
  },
  '52072': {
    message: 'Client mqtt connection not created or closed',
    log_level: 'WARNING',
  },
  '52101': {
    message: 'Invalid GCM Api Key or FCM Secret',
    log_level: 'ERROR',
  },
  '52102': {
    message: 'Invalid GCM/FCM registration token',
    log_level: 'ERROR',
  },
  '52103': {
    message: 'GCM/FCM client uninstalled or turned off notifications',
    log_level: 'ERROR',
  },
  '52104': {
    message: 'Mismatched GCM/FCM sender ID',
    log_level: 'ERROR',
  },
  '52105': {
    message: 'Invalid GCM/FCM package name',
    log_level: 'ERROR',
  },
  '52106': {
    message: 'Notification too large for GCM/FCM',
    log_level: 'ERROR',
  },
  '52107': {
    message: 'Invalid custom key for GCM/FCM',
    log_level: 'ERROR',
  },
  '52108': {
    message: 'GCM/FCM device message rate exceeded',
    log_level: 'ERROR',
  },
  '52109': {
    message: 'GCM/FCM unauthorized error',
    log_level: 'ERROR',
  },
  '52110': {
    message: 'GCM/FCM service unavailable',
    log_level: 'ERROR',
  },
  '52111': {
    message: 'GCM/FCM unknown error',
    log_level: 'ERROR',
  },
  '52131': {
    message: 'Invalid APNs credentials',
    log_level: 'ERROR',
  },
  '52133': {
    message: 'Invalid APNs device token size',
    log_level: 'ERROR',
  },
  '52134': {
    message: 'Invalid APNs device token',
    log_level: 'ERROR',
  },
  '52135': {
    message: 'Missing subject in APNs certificate',
    log_level: 'ERROR',
  },
  '52136': {
    message: 'Missing payload for APNs delivery',
    log_level: 'ERROR',
  },
  '52137': {
    message: 'Invalid size of subject in APNs certificate',
    log_level: 'ERROR',
  },
  '52138': {
    message: 'APNs payload too large',
    log_level: 'ERROR',
  },
  '52139': {
    message: 'APN service shutdown',
    log_level: 'ERROR',
  },
  '52140': {
    message: 'Unknown APNs error',
    log_level: 'ERROR',
  },
  '52141': {
    message: 'The provided APNs device token has been unregistered',
    log_level: 'ERROR',
  },
  '52142': {
    message: 'The provided APNs device token is not correct',
    log_level: 'ERROR',
  },
  '52143': {
    message: 'The push notification was rejected by APNs',
    log_level: 'ERROR',
  },
  '52144': {
    message: 'APNs experienced an internal error',
    log_level: 'ERROR',
  },
  '52145': {
    message: 'Failed to authenticate with APNs',
    log_level: 'ERROR',
  },
  '52147': {
    message: 'Too many APNs provider token updates',
    log_level: 'WARNING',
  },
  '52148': {
    message: 'Expired APNs provider token used',
    log_level: 'ERROR',
  },
  '52149': {
    message: 'Invalid APNs provider token used',
    log_level: 'ERROR',
  },
  '52161': {
    message: 'Empty Credentials',
    log_level: 'ERROR',
  },
  '52162': {
    message: 'Credentials do not belong to used account',
    log_level: 'ERROR',
  },
  '52163': {
    message: 'Incorrect URL used to retrieve Webhook Credentials',
    log_level: 'ERROR',
  },
  '52164': {
    message: 'No Credentials found for the supplied Binding Type',
    log_level: 'ERROR',
  },
  '52165': {
    message: 'No Credentials found for the supplied Identifier',
    log_level: 'ERROR',
  },
  '52166': {
    message: 'Webhook Credentials endpoint responded with Internal Error',
    log_level: 'ERROR',
  },
  '52167': {
    message: 'Invalid Webhook Credentials response',
    log_level: 'ERROR',
  },
  '52168': {
    message: 'Webhook Credentials request timed out',
    log_level: 'ERROR',
  },
  '52170': {
    message: 'Too many Webhook Credential requests',
    log_level: 'ERROR',
  },
  '52171': {
    message: 'Webhook Credentials request signature was not verified',
    log_level: 'ERROR',
  },
  '52172': {
    message: 'Unexpected Binding Type used for Webhook Credentials request',
    log_level: 'WARNING',
  },
  '52173': {
    message: 'Unexpected Identifier used for Webhook Credentials request',
    log_level: 'WARNING',
  },
  '52174': {
    message:
      'Unexpected error response received for Webhook Credentials request',
    log_level: 'WARNING',
  },
  '52181': {
    message: 'Too many SMS notification requests',
    log_level: 'ERROR',
  },
  '52182': {
    message: 'Messaging Service not specified',
    log_level: 'ERROR',
  },
  '52201': {
    message: 'Too many Facebook messenger notification requests',
    log_level: 'ERROR',
  },
  '52202': {
    message: 'Facebook page is not connected to Twilio',
    log_level: 'ERROR',
  },
  '52203': {
    message: 'Missing body for Facebook Messenger delivery attempt',
    log_level: 'ERROR',
  },
  '52211': {
    message: 'Too many Alexa notifications',
    log_level: 'ERROR',
  },
  '52212': {
    message: 'Missing Alexa skill ID',
    log_level: 'ERROR',
  },
  '52213': {
    message: 'Invalid Alexa user ID',
    log_level: 'ERROR',
  },
  '52214': {
    message: 'Alexa skill is not connected to Twilio',
    log_level: 'ERROR',
  },
  '52215': {
    message: 'Missing Title parameter for Alexa',
    log_level: 'ERROR',
  },
  '52301': {
    message: 'Resulted destination list for requested parameters is empty',
    log_level: 'WARNING',
  },
  '52302': {
    message: 'Too many recipients',
    log_level: 'ERROR',
  },
  '52303': {
    message: 'Concurrent User update',
    log_level: 'WARNING',
  },
  '52304': {
    message: 'User already exists',
    log_level: 'WARNING',
  },
  '52305': {
    message: 'User already belongs to the segment',
    log_level: 'WARNING',
  },
  '52306': {
    message: 'Cannot delete User resource with Bindings',
    log_level: 'ERROR',
  },
  '52307': {
    message: 'No users with provided segments',
    log_level: 'WARNING',
  },
  '52309': {
    message: 'Channel provider replied with an error',
    log_level: 'ERROR',
  },
  '52310': {
    message: 'Notification TTL has expired',
    log_level: 'ERROR',
  },
  '52311': {
    message: 'Delivery callback invocation failed',
    log_level: 'ERROR',
  },
  '52401': {
    message: 'Too many notification log events ',
    log_level: 'WARNING',
  },
  '53000': {
    message: 'Signaling connection error',
    log_level: 'ERROR',
  },
  '53001': {
    message: 'Signaling connection disconnected',
    log_level: 'ERROR',
  },
  '53002': {
    message: 'Signaling connection timed out',
    log_level: 'ERROR',
  },
  '53003': {
    message: 'Client received an invalid signaling message',
    log_level: 'ERROR',
  },
  '53004': {
    message: 'Client sent an invalid signaling message',
    log_level: 'ERROR',
  },
  '53006': {
    message: 'Video server is busy',
    log_level: 'ERROR',
  },
  '53100': {
    message: 'Room name is invalid',
    log_level: 'ERROR',
  },
  '53101': {
    message: 'Room name is too long',
    log_level: 'ERROR',
  },
  '53102': {
    message: 'Room name contains invalid characters',
    log_level: 'ERROR',
  },
  '53103': {
    message: ' Unable to create Room',
    log_level: 'ERROR',
  },
  '53104': {
    message: 'Unable to connect to Room',
    log_level: 'ERROR',
  },
  '53105': {
    message: 'Room contains too many Participants',
    log_level: 'ERROR',
  },
  '53106': {
    message: 'Room not found',
    log_level: 'ERROR',
  },
  '53107': {
    message: 'MaxParticipants is out of range',
    log_level: 'ERROR',
  },
  '53108': {
    message: 'RoomType is not valid',
    log_level: 'ERROR',
  },
  '53109': {
    message: 'Timeout is out of range',
    log_level: 'ERROR',
  },
  '53110': {
    message: 'StatusCallbackMethod is invalid',
    log_level: 'ERROR',
  },
  '53111': {
    message: 'StatusCallback is invalid',
    log_level: 'ERROR',
  },
  '53112': {
    message: 'Status is invalid',
    log_level: 'ERROR',
  },
  '53113': {
    message: 'Room creation failed',
    log_level: 'ERROR',
  },
  '53118': {
    message: 'Room Completed Error',
    log_level: 'ERROR',
  },
  '53119': {
    message: 'The concurrent Rooms quota was exceeded',
    log_level: 'ERROR',
  },
  '53120': {
    message: ' Invalid Recording Rule(s)',
    log_level: 'ERROR',
  },
  '53121': {
    message: 'Approaching room or participant concurrency quota',
    log_level: 'WARNING',
  },
  '53122': {
    message:
      'The recording operation requested is not supported for the Room type',
    log_level: 'ERROR',
  },
  '53123': {
    message: 'MaxParticipantDuration is out of range',
    log_level: 'ERROR',
  },
  '53124': {
    message: 'The AudioOnly flag is not supported for the Room type',
    log_level: 'ERROR',
  },
  '53125': {
    message: 'The track kind is not supported by the Room',
    log_level: 'ERROR',
  },
  '53200': {
    message: 'Participant identity is invalid',
    log_level: 'ERROR',
  },
  '53201': {
    message: 'Participant identity is too long',
    log_level: 'ERROR',
  },
  '53202': {
    message: 'Participant identity contains invalid characters',
    log_level: 'ERROR',
  },
  '53203': {
    message:
      'The maximum number of published tracks allowed in the Room at the same time has been reached',
    log_level: 'ERROR',
  },
  '53204': {
    message: 'Participant not found',
    log_level: 'ERROR',
  },
  '53205': {
    message: 'Participant disconnected because of duplicate identity',
    log_level: 'ERROR',
  },
  '53206': {
    message: 'The Participant concurrency quota was exceeded',
    log_level: 'ERROR',
  },
  '53207': {
    message: 'MaxPublishedTracks is out of range',
    log_level: 'ERROR',
  },
  '53208': {
    message: "Participant's bandwidth profile configuration is invalid",
    log_level: 'ERROR',
  },
  '53209': {
    message: 'Participant status is invalid',
    log_level: 'ERROR',
  },
  '53215': {
    message: 'Invalid Subscribe Rule(s)',
    log_level: 'ERROR',
  },
  '53216': {
    message: 'Participant session length exceeded',
    log_level: 'ERROR',
  },
  '53217': {
    message: 'maxAudioTracks or maxVideoTracks configuration is out of range',
    log_level: 'ERROR',
  },
  '53300': {
    message: 'Track is invalid',
    log_level: 'ERROR',
  },
  '53301': {
    message: 'Track name is invalid',
    log_level: 'ERROR',
  },
  '53302': {
    message: 'Track name is too long',
    log_level: 'ERROR',
  },
  '53303': {
    message: 'Track name contains invalid characters',
    log_level: 'ERROR',
  },
  '53304': {
    message: 'Track name is duplicated',
    log_level: 'ERROR',
  },
  '53400': {
    message: 'Client is unable to create or apply a local media description',
    log_level: 'ERROR',
  },
  '53401': {
    message: 'Server is unable to create or apply a local media description',
    log_level: 'ERROR',
  },
  '53402': {
    message: 'Client is unable to apply a remote media description',
    log_level: 'ERROR',
  },
  '53403': {
    message: 'Server is unable to apply a remote media description',
    log_level: 'ERROR',
  },
  '53404': {
    message: 'No supported codec',
    log_level: 'ERROR',
  },
  '53405': {
    message: 'Media connection failed or Media activity ceased',
    log_level: 'ERROR',
  },
  '53406': {
    message: 'The data channel used by the Data Track had a problem',
    log_level: 'ERROR',
  },
  '53407': {
    message: 'Media connection failed due to DTLS handshake failure',
    log_level: 'ERROR',
  },
  '53408': {
    message: 'ICE connection restart was attempted, but it is not allowed',
    log_level: 'ERROR',
  },
  '53500': {
    message: 'Unable to acquire configuration',
    log_level: 'ERROR',
  },
  '53501': {
    message: 'Unable to acquire TURN credentials',
    log_level: 'ERROR',
  },
  '53600': {
    message: 'S3 URL for recording upload is invalid',
    log_level: 'ERROR',
  },
  '53601': {
    message: 'AWS credentials for recording upload are invalid',
    log_level: 'ERROR',
  },
  '53602': {
    message: 'AWS encryption key for recording upload is invalid',
    log_level: 'ERROR',
  },
  '53603': {
    message: 'Internal failure while processing a recording',
    log_level: 'ERROR',
  },
  '53604': {
    message: 'Failed to upload the recording to S3',
    log_level: 'WARNING',
  },
  '53605': {
    message:
      "Internal failure when retrieving your account's recording settings",
    log_level: 'WARNING',
  },
  '53606': {
    message: 'Internal failure when creating the recording resource',
    log_level: 'WARNING',
  },
  '53607': {
    message: 'Internal failure when updating the recording resource',
    log_level: 'WARNING',
  },
  '53610': {
    message: 'Invalid URL for external S3 bucket in recording settings',
    log_level: 'WARNING',
  },
  '53611': {
    message:
      'Invalid AWS credentials to access external S3 bucket in recording settings',
    log_level: 'WARNING',
  },
  '53612': {
    message:
      'Invalid public key for media tracks encryption in recording settings',
    log_level: 'WARNING',
  },
  '53613': {
    message: 'AWS credentials to access external S3 bucket could not be loaded',
    log_level: 'WARNING',
  },
  '53614': {
    message:
      'Public key credentials for media tracks encryption could not be loaded',
    log_level: 'WARNING',
  },
  '53615': {
    message:
      'Access denied to external S3 bucket configured in recording settings',
    log_level: 'WARNING',
  },
  '53616': {
    message:
      'Deleted a recording with custom configuration as time for retries was depleted',
    log_level: 'WARNING',
  },
  '53617': {
    message:
      'Internal failure when bulk deleting compositions from your account',
    log_level: 'ERROR',
  },
  '53620': {
    message: 'Invalid URL for external S3 bucket in composition settings',
    log_level: 'WARNING',
  },
  '53621': {
    message:
      'Invalid AWS credentials to access external S3 bucket in composition settings',
    log_level: 'WARNING',
  },
  '53622': {
    message:
      'Invalid public key for media tracks encryption in composition settings',
    log_level: 'WARNING',
  },
  '53623': {
    message: 'AWS credentials to access external S3 bucket could not be loaded',
    log_level: 'WARNING',
  },
  '53624': {
    message:
      'Public key credentials for media tracks encryption could not be loaded',
    log_level: 'WARNING',
  },
  '53625': {
    message:
      'Access denied to external S3 bucket configured in composition settings',
    log_level: 'WARNING',
  },
  '53626': {
    message: 'Internal failure while processing media composition',
    log_level: 'ERROR',
  },
  '53627': {
    message: 'Internal failure when updating the composition resource',
    log_level: 'ERROR',
  },
  '53628': {
    message: 'Room recordings deleted',
    log_level: 'ERROR',
  },
  '53630': {
    message: 'Empty track list for composition',
    log_level: 'ERROR',
  },
  '53631': {
    message:
      'Failed to enqueue a room composition from a configured composition hook',
    log_level: 'WARNING',
  },
  '53632': {
    message: 'Failed to enqueue a room composition',
    log_level: 'WARNING',
  },
  '53633': {
    message: 'Composition is too large',
    log_level: 'ERROR',
  },
  '53660': {
    message: 'Status Callback response timed out',
    log_level: 'WARNING',
  },
  '53661': {
    message: 'StatusCallbackMethod is invalid',
    log_level: 'WARNING',
  },
  '53662': {
    message: 'StatusCallback is invalid',
    log_level: 'WARNING',
  },
  '53663': {
    message: 'Internal failure when bulk deleting recordings from your account',
    log_level: 'ERROR',
  },
  '53664': {
    message: 'Invalid URL for external S3 bucket in composition settings',
    log_level: 'ERROR',
  },
  '53665': {
    message:
      'Invalid AWS credentials to access external S3 bucket in composition settings',
    log_level: 'ERROR',
  },
  '53666': {
    message:
      'Invalid public key for media tracks encryption in composition settings',
    log_level: 'ERROR',
  },
  '53667': {
    message: 'AWS credentials to access external S3 bucket could not be loaded',
    log_level: 'ERROR',
  },
  '53668': {
    message:
      'Public key credentials for media tracks encryption could not be loaded',
    log_level: 'ERROR',
  },
  '53669': {
    message:
      'Access denied to external S3 bucket configured in composition settings',
    log_level: 'ERROR',
  },
  '54003': {
    message: 'Invalid If-Match header',
    log_level: 'ERROR',
  },
  '54006': {
    message: 'Request entity too large',
    log_level: 'ERROR',
  },
  '54007': {
    message: 'Access forbidden for identity',
    log_level: 'ERROR',
  },
  '54008': {
    message: 'Invalid JSON',
    log_level: 'ERROR',
  },
  '54009': {
    message: 'Rate limit exceeded',
    log_level: 'ERROR',
  },
  '54010': {
    message: 'No parameters specified',
    log_level: 'ERROR',
  },
  '54011': {
    message: 'Invalid TTL',
    log_level: 'ERROR',
  },
  '54050': {
    message: 'Service Instance not found',
    log_level: 'ERROR',
  },
  '54051': {
    message: 'Invalid webhook URL',
    log_level: 'ERROR',
  },
  '54053': {
    message: 'Invalid friendly name',
    log_level: 'ERROR',
  },
  '54056': {
    message: 'Account cannot access requested Service Instance',
    log_level: 'ERROR',
  },
  '54100': {
    message: 'Document not found',
    log_level: 'ERROR',
  },
  '54101': {
    message: 'Invalid Document data',
    log_level: 'ERROR',
  },
  '54103': {
    message: 'Document revision mismatch',
    log_level: 'ERROR',
  },
  '54150': {
    message: 'List not found',
    log_level: 'ERROR',
  },
  '54151': {
    message: 'List Item not found',
    log_level: 'ERROR',
  },
  '54155': {
    message: 'List Item revision mismatch',
    log_level: 'ERROR',
  },
  '54156': {
    message: 'Invalid List Item data',
    log_level: 'ERROR',
  },
  '54200': {
    message: 'Map not found',
    log_level: 'ERROR',
  },
  '54201': {
    message: 'Map Item not found',
    log_level: 'ERROR',
  },
  '54205': {
    message: 'Map Item revision mismatch',
    log_level: 'ERROR',
  },
  '54206': {
    message: 'Invalid Map Item data',
    log_level: 'ERROR',
  },
  '54208': {
    message: 'Map Item already exists',
    log_level: 'ERROR',
  },
  '54209': {
    message: 'Invalid Map Item key',
    log_level: 'ERROR',
  },
  '54250': {
    message: 'Message Stream not found',
    log_level: 'ERROR',
  },
  '54251': {
    message: 'Invalid Message Stream Message data',
    log_level: 'ERROR',
  },
  '54300': {
    message: 'Unique name not found',
    log_level: 'ERROR',
  },
  '54301': {
    message: 'Unique name already exists',
    log_level: 'ERROR',
  },
  '54302': {
    message: 'Invalid unique name',
    log_level: 'ERROR',
  },
  '54351': {
    message: 'Invalid identity',
    log_level: 'ERROR',
  },
  '54354': {
    message: 'Permission not found',
    log_level: 'ERROR',
  },
  '54419': {
    message: 'Number of subscriptions per connection is over the limit',
    log_level: 'ERROR',
  },
  '54450': {
    message: "Invalid 'Direction' query parameter",
    log_level: 'ERROR',
  },
  '54451': {
    message: "Invalid 'Order' query parameter",
    log_level: 'ERROR',
  },
  '54452': {
    message: "Invalid 'Bounds' query parameter",
    log_level: 'ERROR',
  },
  '54453': {
    message: "Invalid 'PageToken' query parameter",
    log_level: 'ERROR',
  },
  '54454': {
    message: "Sync: Invalid 'PageSize' query parameter",
    log_level: 'ERROR',
  },
  '54458': {
    message: 'Invalid List Item index',
    log_level: 'ERROR',
  },
  '54502': {
    message: 'Invalid index name',
    log_level: 'ERROR',
  },
  '54507': {
    message: 'Invalid query',
    log_level: 'ERROR',
  },
  '54509': {
    message: 'Query expression contains too many operators',
    log_level: 'ERROR',
  },
  '54510': {
    message: 'Query expression contains an array with too many items',
    log_level: 'ERROR',
  },
  '55555': {
    message: 'Invalid Instruction passed to TaskRouter',
    log_level: 'ERROR',
  },
  '57001': {
    message: "'Secret id' is empty",
    log_level: 'ERROR',
  },
  '57002': {
    message: "'Secret id' is too long",
    log_level: 'ERROR',
  },
  '57003': {
    message: "'Secret id' is invalid for this Partner",
    log_level: 'ERROR',
  },
  '57004': {
    message: "'Category' is empty",
    log_level: 'ERROR',
  },
  '57005': {
    message: "'Category' is too long",
    log_level: 'ERROR',
  },
  '57006': {
    message: "'EventType' is empty",
    log_level: 'ERROR',
  },
  '57007': {
    message: "'EventType' is absent",
    log_level: 'ERROR',
  },
  '57008': {
    message: "'EventType' format must be String",
    log_level: 'ERROR',
  },
  '57009': {
    message: "'EventType' is too long",
    log_level: 'ERROR',
  },
  '57010': {
    message: "'PartnerName' is absent",
    log_level: 'ERROR',
  },
  '57011': {
    message: 'Unsupported Partner name',
    log_level: 'ERROR',
  },
  '57012': {
    message: 'Signature invalid',
    log_level: 'ERROR',
  },
  '57013': {
    message: "'Topic' is absent",
    log_level: 'ERROR',
  },
  '57014': {
    message: "'Event' data in payload is absent",
    log_level: 'ERROR',
  },
  '57016': {
    message: "'Topic' is empty",
    log_level: 'ERROR',
  },
  '57017': {
    message: "'Topic' is too long",
    log_level: 'ERROR',
  },
  '57018': {
    message: "'Event' value type must be Map",
    log_level: 'ERROR',
  },
  '57019': {
    message: "'Authorization' header is missing or is invalid",
    log_level: 'ERROR',
  },
  '57020': {
    message: 'Authorization failed',
    log_level: 'ERROR',
  },
  '58000': {
    message: 'Twilio Live congestion',
    log_level: 'ERROR',
  },
  '58001': {
    message: 'Video-enabled PlayerStreamer concurrency quota was exceeded',
    log_level: 'ERROR',
  },
  '58002': {
    message: 'The MediaProcessor concurrency quota was exceeded',
    log_level: 'ERROR',
  },
  '58003': {
    message: 'Audio-only PlayerStreamer concurrency quota was exceeded',
    log_level: 'ERROR',
  },
  '60001': {
    message: 'Downstream Authentication Failed',
    log_level: 'ERROR',
  },
  '60002': {
    message: 'End User Identification Timeout',
    log_level: 'ERROR',
  },
  '60003': {
    message: 'End User Data is Not Available',
    log_level: 'ERROR',
  },
  '60004': {
    message: 'Invalid Configuration ',
    log_level: 'ERROR',
  },
  '60005': {
    message: 'Downstream Carrier Error',
    log_level: 'ERROR',
  },
  '60006': {
    message: 'Invalid Phone Number',
    log_level: 'ERROR',
  },
  '60008': {
    message: 'Unsupported Carrier',
    log_level: 'ERROR',
  },
  '60200': {
    message: 'Invalid parameter',
    log_level: 'ERROR',
  },
  '60201': {
    message: 'Selected template translation is not approved',
    log_level: 'ERROR',
  },
  '60202': {
    message: 'Max check attempts reached',
    log_level: 'ERROR',
  },
  '60203': {
    message: 'Max send attempts reached.',
    log_level: 'ERROR',
  },
  '60204': {
    message: 'Service does not support this feature',
    log_level: 'ERROR',
  },
  '60205': {
    message: 'SMS is not supported by landline phone number',
    log_level: 'ERROR',
  },
  '60206': {
    message: "'Amount' & 'Payee' params are required",
    log_level: 'ERROR',
  },
  '60207': {
    message: 'Max rate limits per service reached ',
    log_level: 'ERROR',
  },
  '60208': {
    message: 'Rate limit with that UniqueName already exists',
    log_level: 'ERROR',
  },
  '60209': {
    message: 'UniqueName format is invalid',
    log_level: 'ERROR',
  },
  '60210': {
    message: 'Max Buckets per Rate limit reached',
    log_level: 'ERROR',
  },
  '60211': {
    message: 'Bucket with the given Interval already exists',
    log_level: 'ERROR',
  },
  '60212': {
    message: 'Too many concurrent requests for phone number',
    log_level: 'ERROR',
  },
  '60213': {
    message: 'A Messaging Configuration already exists for the given country',
    log_level: 'ERROR',
  },
  '60214': {
    message: 'Call channel is not supported when using PSD2',
    log_level: 'ERROR',
  },
  '60215': {
    message: 'Max number of mailers per account reached',
    log_level: 'ERROR',
  },
  '60217': {
    message: 'Invalid Service configuration',
    log_level: 'ERROR',
  },
  '60218': {
    message: 'SendGrid Template is not active',
    log_level: 'ERROR',
  },
  '60219': {
    message: 'SendGrid Template does not contain required placeholders',
    log_level: 'ERROR',
  },
  '60220': {
    message: 'Messages to China require use case vetting',
    log_level: 'ERROR',
  },
  '60221': {
    message: 'No target verification specified',
    log_level: 'ERROR',
  },
  '60222': {
    message:
      'SendGrid The from address does not match a verified Sender Identity',
    log_level: 'ERROR',
  },
  '60223': {
    message: 'Delivery channel disabled',
    log_level: 'ERROR',
  },
  '60224': {
    message: 'Missing substitutions for selected template',
    log_level: 'ERROR',
  },
  '60225': {
    message: 'Translation already exists for the provided template',
    log_level: 'ERROR',
  },
  '60226': {
    message: 'Messages sent to china require friendly_name',
    log_level: 'ERROR',
  },
  '60227': {
    message: 'The selected channel for template is not supported',
    log_level: 'ERROR',
  },
  '60228': {
    message: 'Template was not found',
    log_level: 'ERROR',
  },
  '60229': {
    message: 'Template translation was not found',
    log_level: 'ERROR',
  },
  '60300': {
    message: 'Invalid Param',
    log_level: 'ERROR',
  },
  '60301': {
    message: 'Entity already exists',
    log_level: 'ERROR',
  },
  '60302': {
    message: 'FactorType already exists',
    log_level: 'ERROR',
  },
  '60305': {
    message: 'Access Token parameters are invalid',
    log_level: 'ERROR',
  },
  '60306': {
    message: 'Invalid Request',
    log_level: 'ERROR',
  },
  '60307': {
    message: "Cannot resend push notifications to 'none' notification platform",
    log_level: 'ERROR',
  },
  '60308': {
    message: 'Challenge verification attempts limit reached',
    log_level: 'ERROR',
  },
  '60309': {
    message: 'Push notifications limit reached for a Challenge',
    log_level: 'ERROR',
  },
  '60310': {
    message: 'Factor verification attempts reached',
    log_level: 'ERROR',
  },
  '60311': {
    message: 'Factor verification failed',
    log_level: 'ERROR',
  },
  '60312': {
    message: 'Challenge creation limit reached',
    log_level: 'ERROR',
  },
  '60313': {
    message: 'Unauthorized factor creation',
    log_level: 'ERROR',
  },
  '60314': {
    message: 'Factors binding format is invalid',
    log_level: 'ERROR',
  },
  '60315': {
    message: 'Reached max limit of 20 push Factors associated per Entity',
    log_level: 'ERROR',
  },
  '60317': {
    message: 'Factor already exists',
    log_level: 'ERROR',
  },
  '60318': {
    message: 'Factor is unverified',
    log_level: 'ERROR',
  },
  '60322': {
    message: 'Challenge already responded',
    log_level: 'ERROR',
  },
  '60323': {
    message: 'Challenge expired',
    log_level: 'ERROR',
  },
  '60324': {
    message: 'Challenge verification failed',
    log_level: 'ERROR',
  },
  '60325': {
    message: 'Translation for locale not found, using default',
    log_level: 'WARNING',
  },
  '60326': {
    message: 'Too many requests to create factors for the entity',
    log_level: 'ERROR',
  },
  '60329': {
    message: 'Verify SNA does not work with `psd2_enabled`',
    log_level: 'ERROR',
  },
  '60330': {
    message: 'Failed to invoke the webhook',
    log_level: 'ERROR',
  },
  '60331': {
    message:
      'Locale requested is not supported by Verify Text-To-Speech conversion',
    log_level: 'ERROR',
  },
  '60401': {
    message: 'Network Error',
    log_level: 'ERROR',
  },
  '60402': {
    message: 'Mapper Error',
    log_level: 'ERROR',
  },
  '60403': {
    message: 'Storage Error',
    log_level: 'ERROR',
  },
  '60404': {
    message: 'Input Error',
    log_level: 'ERROR',
  },
  '60405': {
    message: 'Key Storage Error',
    log_level: 'ERROR',
  },
  '60406': {
    message: 'Initialization Error',
    log_level: 'ERROR',
  },
  '60407': {
    message: 'Authentication Token Error',
    log_level: 'ERROR',
  },
  '60408': {
    message: 'TemplateSid is only supported for the SMS channel',
    log_level: 'ERROR',
  },
  '60409': {
    message: 'Custom message did not match any template',
    log_level: 'WARNING',
  },
  '60410': {
    message: 'Verification delivery attempt blocked',
    log_level: 'ERROR',
  },
  '60411': {
    message: 'Phone Number already exists',
    log_level: 'ERROR',
  },
  '60500': {
    message: 'SNA Phone Number Mismatch',
    log_level: 'ERROR',
  },
  '60510': {
    message: 'SNA Error',
    log_level: 'ERROR',
  },
  '60511': {
    message: 'SNA Downstream Error',
    log_level: 'ERROR',
  },
  '60517': {
    message: 'SNA User-Agent Mismatch Error',
    log_level: 'ERROR',
  },
  '60519': {
    message: 'SNA Verification Result Pending',
    log_level: 'ERROR',
  },
  '60520': {
    message: 'SNA URL Failed',
    log_level: 'ERROR',
  },
  '60531': {
    message: 'SNA Carrier Not Detected',
    log_level: 'ERROR',
  },
  '60532': {
    message: 'SNA Potential Dual SIM Detected',
    log_level: 'ERROR',
  },
  '60533': {
    message: 'SNA Carrier Header Error',
    log_level: 'ERROR',
  },
  '60534': {
    message: 'SNA Downstream Carrier Error',
    log_level: 'ERROR',
  },
  '60540': {
    message: 'SNA Carrier Identified Invalid Phone Number',
    log_level: 'ERROR',
  },
  '60600': {
    message: 'Unprovisioned or out of coverage',
    log_level: 'ERROR',
  },
  '60601': {
    message: 'Authorization required for Canada lookups',
    log_level: 'ERROR',
  },
  '60602': {
    message: 'App hash can only be used with SMS channel',
    log_level: 'ERROR',
  },
  '60603': {
    message: 'SendGrid maximum credits exceeded',
    log_level: 'ERROR',
  },
  '60604': {
    message: 'SendGrid Authenticated user is not authorized to send mail',
    log_level: 'ERROR',
  },
  '60605': {
    message: 'Verification delivery attempt blocked',
    log_level: 'ERROR',
  },
  '60606': {
    message: 'Package not enabled',
    log_level: 'ERROR',
  },
  '60607': {
    message: 'Provider not found',
    log_level: 'ERROR',
  },
  '60608': {
    message: 'Lookup provider error',
    log_level: 'ERROR',
  },
  '60609': {
    message: 'Live activity not enabled',
    log_level: 'ERROR',
  },
  '60610': {
    message: 'Phone number outside of coverage',
    log_level: 'ERROR',
  },
  '60611': {
    message: 'Lookup quota reached',
    log_level: 'ERROR',
  },
  '60612': {
    message: 'Requested phone number not mobile',
    log_level: 'ERROR',
  },
  '60613': {
    message: 'Lookup Provider Degradation',
    log_level: 'ERROR',
  },
  '60614': {
    message: 'Lookup package not supported in region',
    log_level: 'ERROR',
  },
  '60616': {
    message: 'Lookup rate limit exceeded',
    log_level: 'ERROR',
  },
  '60617': {
    message: 'Not enough arguments',
    log_level: 'ERROR',
  },
  '60618': {
    message: 'Malformed request',
    log_level: 'ERROR',
  },
  '60619': {
    message: 'Lookup Request Cannot be Completed in Twilio Region',
    log_level: 'ERROR',
  },
  '60620': {
    message: 'Lookup SIM Swap Information is Not Available From Carrier',
    log_level: 'ERROR',
  },
  '60621': {
    message: 'Lookup SIM Swap Information is Incomplete From Carrier',
    log_level: 'ERROR',
  },
  '60699': {
    message: 'Lookup usage disabled',
    log_level: 'ERROR',
  },
  '60700': {
    message: 'Something went wrong. Try again later',
    log_level: 'ERROR',
  },
  '60701': {
    message: 'Invalid request',
    log_level: 'ERROR',
  },
  '60702': {
    message: 'Business Profile not found',
    log_level: 'WARNING',
  },
  '60703': {
    message: 'Invalid phone numbers format',
    log_level: 'ERROR',
  },
  '60704': {
    message: 'Phone number not branded by Twilio',
    log_level: 'ERROR',
  },
  '60706': {
    message: 'Invalid Push Token',
    log_level: 'ERROR',
  },
  '60707': {
    message: 'Branded Channel not found',
    log_level: 'WARNING',
  },
  '60708': {
    message: 'Phone record number not found',
    log_level: 'WARNING',
  },
  '60709': {
    message: 'Business Profile already exists',
    log_level: 'WARNING',
  },
  '60710': {
    message: 'Phone number CPS not found',
    log_level: 'WARNING',
  },
  '60711': {
    message: 'Business status does not allow updates',
    log_level: 'ERROR',
  },
  '60712': {
    message: 'Error communicating with Regulatory Compliance API',
    log_level: 'ERROR',
  },
  '60713': {
    message: 'Error communicating with Regulatory Identification API',
    log_level: 'ERROR',
  },
  '60714': {
    message: 'Brand not found',
    log_level: 'WARNING',
  },
  '60715': {
    message: 'Error reading logo file',
    log_level: 'ERROR',
  },
  '60716': {
    message: 'Selected logo is not a valid PNG file',
    log_level: 'ERROR',
  },
  '60717': {
    message: 'Error uploading logo to the storage',
    log_level: 'ERROR',
  },
  '60719': {
    message: 'Branded Call not found',
    log_level: 'WARNING',
  },
  '60721': {
    message: 'Phone Number(s) already used in a Branded Channel',
    log_level: 'WARNING',
  },
  '60722': {
    message: 'Business status does not allow dismissal',
    log_level: 'WARNING',
  },
  '60723': {
    message: 'Brand status does not allow updates',
    log_level: 'WARNING',
  },
  '60724': {
    message: 'Brand status does not allow dismissal',
    log_level: 'WARNING',
  },
  '60725': {
    message: 'Brand status does not allow to have branded channels',
    log_level: 'WARNING',
  },
  '60726': {
    message: 'Business status does not allow to have brands',
    log_level: 'WARNING',
  },
  '60727': {
    message: 'Channel not found',
    log_level: 'WARNING',
  },
  '61000': {
    message: 'Add-ons: Internal server error',
    log_level: 'ERROR',
  },
  '61001': {
    message: 'Add-ons: Request timed out',
    log_level: 'ERROR',
  },
  '61002': {
    message: 'Add-ons: Provider could not complete request',
    log_level: 'ERROR',
  },
  '61003': {
    message: 'Add-ons: Requirements to invoke AddOns have not been met',
    log_level: 'ERROR',
  },
  '61004': {
    message: 'Add-ons: No results',
    log_level: 'WARNING',
  },
  '61005': {
    message: 'Add-ons: Bad request',
    log_level: 'ERROR',
  },
  '61006': {
    message: 'Add-ons: Add-ons unavailable for GET callbacks',
    log_level: 'WARNING',
  },
  '61007': {
    message: 'Add-ons: Response body too large',
    log_level: 'ERROR',
  },
  '61008': {
    message: 'Add-ons:  HTTP too many redirects',
    log_level: 'ERROR',
  },
  '61009': {
    message: 'Add-ons: Could not fulfill request with available data',
    log_level: 'ERROR',
  },
  '62000': {
    message: 'Failed to write to the database.',
    log_level: 'ERROR',
  },
  '62001': {
    message: 'Invalid SID',
    log_level: 'ERROR',
  },
  '62002': {
    message: 'Exchange not found',
    log_level: 'ERROR',
  },
  '62003': {
    message: 'MPLS carrier not found.',
    log_level: 'ERROR',
  },
  '62004': {
    message: 'Direct connect not found.',
    log_level: 'ERROR',
  },
  '62005': {
    message: ' Bandwidth reserve not found.',
    log_level: 'ERROR',
  },
  '62006': {
    message: ' MPLS carrier is not associated with the exchange.',
    log_level: 'ERROR',
  },
  '62007': {
    message: 'Direct connect is not associated with the exchange.',
    log_level: 'ERROR',
  },
  '62008': {
    message: 'Bandwidth reserve is not associated with the exchange.',
    log_level: 'ERROR',
  },
  '62009': {
    message: 'Account SID was not found.',
    log_level: 'ERROR',
  },
  '62010': {
    message: 'No authentication was provided.',
    log_level: 'ERROR',
  },
  '62011': {
    message: 'Unauthorized',
    log_level: 'ERROR',
  },
  '62012': {
    message: 'Connection not found.',
    log_level: 'ERROR',
  },
  '62013': {
    message: 'Unable to identify account owner of connection.',
    log_level: 'ERROR',
  },
  '62014': {
    message: 'Connection not ready',
    log_level: 'ERROR',
  },
  '62015': {
    message: 'Connection in transition',
    log_level: 'ERROR',
  },
  '62016': {
    message: 'Connection not active',
    log_level: 'ERROR',
  },
  '62017': {
    message: 'No IP route specified',
    log_level: 'ERROR',
  },
  '62018': {
    message: 'Invalid IP route',
    log_level: 'ERROR',
  },
  '62019': {
    message: 'IP route exceeds permitted IP range',
    log_level: 'ERROR',
  },
  '62020': {
    message: 'Connection pending deletion.',
    log_level: 'ERROR',
  },
  '62021': {
    message: 'IP Gateway Invalid',
    log_level: 'ERROR',
  },
  '62022': {
    message: 'Missing account SID',
    log_level: 'ERROR',
  },
  '62023': {
    message: 'Missing exchange',
    log_level: 'ERROR',
  },
  '62024': {
    message: 'Missing connection type',
    log_level: 'ERROR',
  },
  '62025': {
    message: 'Interconnect: Invalid connection type',
    log_level: 'ERROR',
  },
  '62026': {
    message: 'Interconnect: Missing MPLS carrier SID',
    log_level: 'ERROR',
  },
  '62027': {
    message: 'Interconnect: Extra MPLS parameter',
    log_level: 'ERROR',
  },
  '62028': {
    message: 'Interconnect: Connection expired.',
    log_level: 'ERROR',
  },
  '62034': {
    message: 'Interconnect: No useful parameters provided.',
    log_level: 'ERROR',
  },
  '62035': {
    message: 'Interconnect: No bandwidth was specified in the request',
    log_level: 'ERROR',
  },
  '62052': {
    message:
      "Interconnect: This account is not a subaccount of the Interconnect Connection owner's account.",
    log_level: 'ERROR',
  },
  '62053': {
    message:
      'Interconnect: The subaccount is not authorized to access this connection.  ',
    log_level: 'ERROR',
  },
  '62100': {
    message: 'IP address(es) already linked to another connection',
    log_level: 'ERROR',
  },
  '62200': {
    message: 'Provisioning failure - Network-API is unavailable!',
    log_level: 'ERROR',
  },
  '62220': {
    message:
      'Provisioning failure - Requested bandwidth not available on the network device.',
    log_level: 'ERROR',
  },
  '63001': {
    message:
      'Channel could not authenticate the request. Please see Channel specific error message for more information',
    log_level: 'ERROR',
  },
  '63002': {
    message: 'Channel could not find the From address',
    log_level: 'ERROR',
  },
  '63003': {
    message: 'Channel could not find To address',
    log_level: 'ERROR',
  },
  '63005': {
    message:
      'Channel did not accept given content. Please see Channel specific error message for more information',
    log_level: 'ERROR',
  },
  '63006': {
    message:
      'Could not format given content for the channel. Please see Channel specific error message for more information',
    log_level: 'ERROR',
  },
  '63007': {
    message: 'Twilio could not find a Channel with the specified From address',
    log_level: 'ERROR',
  },
  '63008': {
    message:
      'Could not execute the request because the channel module has been misconfigured. Please check the Channel configuration in Twilio',
    log_level: 'ERROR',
  },
  '63009': {
    message:
      'Channel provider returned an internal service error (HTTP 5xx). Please see Channel specific error message for more information',
    log_level: 'ERROR',
  },
  '63010': {
    message:
      "Twilio's platform encountered an internal error processing this message",
    log_level: 'ERROR',
  },
  '63011': {
    message:
      'Invalid Request: Twilio encountered an error while processing your request',
    log_level: 'ERROR',
  },
  '63012': {
    message: 'Channel provider returned an internal service error',
    log_level: 'ERROR',
  },
  '63013': {
    message: 'Channel policy violation',
    log_level: 'ERROR',
  },
  '63014': {
    message: 'Channel message blocked by user action',
    log_level: 'ERROR',
  },
  '63015': {
    message:
      'Channel Sandbox can only send messages to phone numbers that have joined the Sandbox',
    log_level: 'ERROR',
  },
  '63016': {
    message:
      'Failed to send freeform message because you are outside the allowed window. If you are using WhatsApp, please use a Message Template.',
    log_level: 'ERROR',
  },
  '63017': {
    message: 'Rate limit exceeded',
    log_level: 'ERROR',
  },
  '63018': {
    message: 'Rate limit exceeded for Channel',
    log_level: 'ERROR',
  },
  '63019': {
    message: 'Media failed to download',
    log_level: 'ERROR',
  },
  '63020': {
    message: 'Twilio encountered a Business Manager account error',
    log_level: 'ERROR',
  },
  '63021': {
    message: 'Channel invalid content error',
    log_level: 'ERROR',
  },
  '63022': {
    message: 'Invalid vname certificate',
    log_level: 'ERROR',
  },
  '63023': {
    message: 'Channel generic error',
    log_level: 'ERROR',
  },
  '63024': {
    message: 'Invalid message recipient',
    log_level: 'ERROR',
  },
  '63025': {
    message: 'Media already exists',
    log_level: 'ERROR',
  },
  '63026': {
    message: 'Channel sender content flagged as spam',
    log_level: 'ERROR',
  },
  '63027': {
    message: 'Template does not exist for a language and locale',
    log_level: 'ERROR',
  },
  '63028': {
    message:
      'Number of parameters provided does not match the expected number of parameters',
    log_level: 'ERROR',
  },
  '63029': {
    message: 'The receiver failed to download the template',
    log_level: 'ERROR',
  },
  '63030': {
    message: 'Unsupported parameter for type of channels message',
    log_level: 'ERROR',
  },
  '63031': {
    message: 'Channels message cannot have same From and To',
    log_level: 'ERROR',
  },
  '63032': {
    message:
      'We cannot send this message to this user because of a WhatsApp limitation. ',
    log_level: 'ERROR',
  },
  '63033': {
    message: 'Recipient blocked to receive message',
    log_level: 'ERROR',
  },
  '63034': {
    message: 'Media exceeds size limit',
    log_level: 'ERROR',
  },
  '63035': {
    message:
      'This operation is blocked because the RBM agent has not launched and the recipient has not been invited and accepted the invitation to become a tester.',
    log_level: 'ERROR',
  },
  '63036': {
    message:
      'The specified phone number cannot be reached by RBM at this time.',
    log_level: 'ERROR',
  },
  '63037': {
    message: 'Channel Media Upload Error',
    log_level: 'WARNING',
  },
  '63038': {
    message: 'Account exceeded the daily messages limit',
    log_level: 'ERROR',
  },
  '63039': {
    message:
      'Warning! Facebook says your page is engaging in behavior that may be considered bothersome or abusive by users. To avoid messaging restrictions being placed on your Page, Facebook requires you to immediately decrease the rate at which you are sending messages outside the 24-hour window to this person.',
    log_level: 'ERROR',
  },
  '63040': {
    message: 'Template Rejected',
    log_level: 'ERROR',
  },
  '63041': {
    message: 'Template paused',
    log_level: 'ERROR',
  },
  '63042': {
    message: 'Template disabled',
    log_level: 'ERROR',
  },
  '63051': {
    message: 'WhatsApp Business Account is Locked',
    log_level: 'ERROR',
  },
  '64001': {
    message: 'Pay: Configuration Error',
    log_level: 'ERROR',
  },
  '64002': {
    message: 'Pay: Service unavailable.',
    log_level: 'ERROR',
  },
  '64003': {
    message: 'Pay: Invalid charge amount.',
    log_level: 'ERROR',
  },
  '64004': {
    message: 'Pay: Invalid paymentConnector attribute in TwiML.',
    log_level: 'ERROR',
  },
  '64005': {
    message: 'Pay: Connector does not support tokenization.',
    log_level: 'ERROR',
  },
  '64006': {
    message: 'Pay: Connector does not support token type.',
    log_level: 'ERROR',
  },
  '64007': {
    message: 'Pay: Connector does not support creating charge.',
    log_level: 'ERROR',
  },
  '64008': {
    message: 'Pay: Payment Gateway rejected charge creation.',
    log_level: 'ERROR',
  },
  '64009': {
    message:
      'Pay: Twilio is no longer authorized to initiate transactions on your behalf.',
    log_level: 'ERROR',
  },
  '64010': {
    message: 'Pay: Payment Gateway rejected token creation.',
    log_level: 'ERROR',
  },
  '64011': {
    message: 'Pay: Connector does not support the requested currency.',
    log_level: 'ERROR',
  },
  '64012': {
    message: 'Pay: Payment Gateway rejected the card.',
    log_level: 'ERROR',
  },
  '64013': {
    message:
      'Pay: Connector does not support supplied paymentMethod attribute.',
    log_level: 'ERROR',
  },
  '64014': {
    message: 'Pay: ECP/ACH requires AVSName Parameter in the <Pay> verb.',
    log_level: 'ERROR',
  },
  '64015': {
    message: 'Pay: `<Pay>` verb is missing a needed Parameter',
    log_level: 'ERROR',
  },
  '64016': {
    message: 'Pay: Invalid Action URL',
    log_level: 'ERROR',
  },
  '64017': {
    message:
      'Pay: BankAccountType Parameter not supported with PaymentMethod = "credit-card"',
    log_level: 'ERROR',
  },
  '64018': {
    message: 'Pay: Value needed for either Capture or Status parameters',
    log_level: 'ERROR',
  },
  '64019': {
    message: 'Pay: Required payment information incomplete',
    log_level: 'ERROR',
  },
  '64020': {
    message: 'Pay: Invalid Parameter Value',
    log_level: 'ERROR',
  },
  '64021': {
    message: 'Pay: Invalid Operation',
    log_level: 'ERROR',
  },
  '68001': {
    message: 'Network Error',
    log_level: 'ERROR',
  },
  '68002': {
    message: 'Mapper Error',
    log_level: 'ERROR',
  },
  '68003': {
    message: 'Storage Error',
    log_level: 'ERROR',
  },
  '68004': {
    message: 'Input Error',
    log_level: 'ERROR',
  },
  '68005': {
    message: 'Key Storage Error',
    log_level: 'ERROR',
  },
  '68006': {
    message: 'Initialization Error',
    log_level: 'ERROR',
  },
  '68007': {
    message: 'Authentication Token Error',
    log_level: 'ERROR',
  },
  '70001': {
    message: 'Validation Failed',
    log_level: 'ERROR',
  },
  '70002': {
    message: 'Bad request',
    log_level: 'ERROR',
  },
  '70003': {
    message: 'Outdated Entity',
    log_level: 'ERROR',
  },
  '70004': {
    message: 'Unauthorized',
    log_level: 'ERROR',
  },
  '70005': {
    message: 'Failure Threshold Exceeded',
    log_level: 'ERROR',
  },
  '70051': {
    message: 'Authorization Failed',
    log_level: 'ERROR',
  },
  '70052': {
    message: 'Public Key Client Validation Required For Account',
    log_level: 'ERROR',
  },
  '70053': {
    message: 'Public Key Client Validation Not Enabled For Account',
    log_level: 'ERROR',
  },
  '70101': {
    message: 'Unsupported Public Key Algorithm',
    log_level: 'ERROR',
  },
  '70102': {
    message: 'Unsupported Public Key Length',
    log_level: 'ERROR',
  },
  '70103': {
    message: 'Unsupported Public Key Exponent',
    log_level: 'ERROR',
  },
  '70104': {
    message: 'Invalid Public Key',
    log_level: 'ERROR',
  },
  '70105': {
    message: 'Invalid Type Specified in the Request',
    log_level: 'ERROR',
  },
  '70106': {
    message: 'Invalid AWS credentials',
    log_level: 'ERROR',
  },
  '70151': {
    message: 'Maximum Number Of API Keys Exceeded',
    log_level: 'ERROR',
  },
  '70152': {
    message: 'Request Contains Invalid Flags',
    log_level: 'ERROR',
  },
  '70153': {
    message: 'Public Key Specified Does Not Exist',
    log_level: 'ERROR',
  },
  '70154': {
    message: 'Public Key Is Invalid',
    log_level: 'ERROR',
  },
  '70155': {
    message: 'Request Is Missing Required HTTP Headers',
    log_level: 'ERROR',
  },
  '70156': {
    message: 'Request Hash Is Invalid',
    log_level: 'ERROR',
  },
  '70251': {
    message: 'Bad SSO Settings',
    log_level: 'ERROR',
  },
  '70252': {
    message: 'Bad Saml Response',
    log_level: 'ERROR',
  },
  '70253': {
    message: 'Invalid User Grants',
    log_level: 'ERROR',
  },
  '80101': {
    message: 'Number Already Added to Another Service',
    log_level: 'ERROR',
  },
  '80102': {
    message: 'Participant Already In Interaction',
    log_level: 'WARNING',
  },
  '80103': {
    message: 'Participant Already In Session',
    log_level: 'ERROR',
  },
  '80104': {
    message: 'Phone Number Already In Service',
    log_level: 'WARNING',
  },
  '80105': {
    message: 'Short Code Already In Service',
    log_level: 'WARNING',
  },
  '80201': {
    message: 'No Available Voice Proxy',
    log_level: 'WARNING',
  },
  '80202': {
    message: 'No Available Message Proxy',
    log_level: 'WARNING',
  },
  '80203': {
    message: 'No Available Proxy For Country',
    log_level: 'ERROR',
  },
  '80205': {
    message: 'No Proxies For Service',
    log_level: 'WARNING',
  },
  '80206': {
    message: 'No Available Proxy',
    log_level: 'WARNING',
  },
  '80207': {
    message: ' No unreserved numbers in proxy pool.',
    log_level: 'ERROR',
  },
  '80208': {
    message: 'No Available Unused Proxy',
    log_level: 'ERROR',
  },
  '80301': {
    message: 'Not Found Phone Number SID',
    log_level: 'ERROR',
  },
  '80303': {
    message: 'Not Found Short Code Sid',
    log_level: 'WARNING',
  },
  '80304': {
    message: 'Not Found Unmanaged Identifier',
    log_level: 'WARNING',
  },
  '80305': {
    message: 'Not Found Unmanaged Identifier Sid',
    log_level: 'ERROR',
  },
  '80306': {
    message: 'Not Found Chat Service',
    log_level: 'ERROR',
  },
  '80307': {
    message: 'Record to be updated was not found in database.',
    log_level: 'WARNING',
  },
  '80308': {
    message: 'Session with the unique name not found.',
    log_level: 'ERROR',
  },
  '80401': {
    message: 'Account Sid Invalid',
    log_level: 'WARNING',
  },
  '80402': {
    message: 'Identifier Proxy Pair Invalid',
    log_level: 'WARNING',
  },
  '80403': {
    message: 'Interaction Sid Invalid',
    log_level: 'WARNING',
  },
  '80404': {
    message: 'Participant Identifier Invalid',
    log_level: 'ERROR',
  },
  '80405': {
    message: 'Participant Sid Invalid',
    log_level: 'WARNING',
  },
  '80406': {
    message: 'Phone Number Did Invalid',
    log_level: 'WARNING',
  },
  '80407': {
    message: 'Phone Number Sid Invalid',
    log_level: 'WARNING',
  },
  '80408': {
    message: 'Service Sid Invalid',
    log_level: 'WARNING',
  },
  '80409': {
    message: 'Session Sid Invalid',
    log_level: 'WARNING',
  },
  '80501': {
    message: 'Storage Operation Failed',
    log_level: 'ERROR',
  },
  '80502': {
    message: 'Internal Server Error from Downstream',
    log_level: 'ERROR',
  },
  '80503': {
    message: 'No Records Updated',
    log_level: 'WARNING',
  },
  '80504': {
    message: 'An internal server error has occurred.',
    log_level: 'ERROR',
  },
  '80505': {
    message: 'Flex Configuration Error',
    log_level: 'ERROR',
  },
  '80506': {
    message: 'Service Creation is restricted for new customers',
    log_level: 'ERROR',
  },
  '80601': {
    message: 'Phone Number Not Available',
    log_level: 'WARNING',
  },
  '80602': {
    message: 'Non Unique Service Name',
    log_level: 'WARNING',
  },
  '80603': {
    message: 'Non Unique Session Name',
    log_level: 'ERROR',
  },
  '80604': {
    message: 'Proxy Identifier In Use',
    log_level: 'WARNING',
  },
  '80605': {
    message: 'Proxy Identifier Not In Service',
    log_level: 'WARNING',
  },
  '80606': {
    message: 'Proxy Identifier Not Owned By Account',
    log_level: 'WARNING',
  },
  '80607': {
    message: 'Session Closed',
    log_level: 'WARNING',
  },
  '80608': {
    message: 'Session Status Invalid',
    log_level: 'ERROR',
  },
  '80609': {
    message: 'Too Many Added Participants',
    log_level: 'WARNING',
  },
  '80610': {
    message: 'Unauthorized Operation',
    log_level: 'WARNING',
  },
  '80611': {
    message: 'Proxy Number In Active Sessions',
    log_level: 'ERROR',
  },
  '80612': {
    message: 'Duplicate Entry',
    log_level: 'ERROR',
  },
  '80613': {
    message: 'Downstream Request Rejected',
    log_level: 'ERROR',
  },
  '80614': {
    message: ' No Partner Participant Found',
    log_level: 'WARNING',
  },
  '80615': {
    message: 'Account Sid on Legal Hold',
    log_level: 'ERROR',
  },
  '80616': {
    message: 'Unsupported Identifier Type For Session Mode',
    log_level: 'ERROR',
  },
  '80617': {
    message: 'Flex Configuration Error',
    log_level: 'ERROR',
  },
  '80618': {
    message: 'Chat Integration Error',
    log_level: 'ERROR',
  },
  '80619': {
    message: 'Chat Channel Attribute Error',
    log_level: 'ERROR',
  },
  '80620': {
    message: 'Chat Configured Proxy Identifier Not Found',
    log_level: 'ERROR',
  },
  '80622': {
    message: 'Maximum Pool Size Error',
    log_level: 'ERROR',
  },
  '80623': {
    message: 'Duplicate Participant Request',
    log_level: 'ERROR',
  },
  '80624': {
    message: 'Approaching Maximium Number Pool Size',
    log_level: 'WARNING',
  },
  '80701': {
    message: 'Parameter Validation Failed',
    log_level: 'WARNING',
  },
  '80801': {
    message: 'Invalid attempt to Re-Open a Session',
    log_level: 'ERROR',
  },
  '80802': {
    message:
      'Simultaneous requests to create the same Identifier in one or more Sessions',
    log_level: 'ERROR',
  },
  '80901': {
    message: 'Message Matched Stop Word',
    log_level: 'WARNING',
  },
  '80902': {
    message: 'No Active Session',
    log_level: 'ERROR',
  },
  '80903': {
    message: 'Unknown Participant',
    log_level: 'WARNING',
  },
  '80904': {
    message: 'Expired Proxy Session',
    log_level: 'WARNING',
  },
  '80905': {
    message: 'Unknown Scenario',
    log_level: 'WARNING',
  },
  '80906': {
    message: 'Interaction Not Open',
    log_level: 'WARNING',
  },
  '80907': {
    message: 'Open Interaction Not Found',
    log_level: 'WARNING',
  },
  '80908': {
    message: 'Callback Error',
    log_level: 'WARNING',
  },
  '80909': {
    message: 'Inbound Contact Rejected',
    log_level: 'WARNING',
  },
  '80910': {
    message: 'Message To Voice Only Session Rejected',
    log_level: 'WARNING',
  },
  '80911': {
    message: 'Call To Message Only Session Rejected',
    log_level: 'WARNING',
  },
  '80913': {
    message: 'Out-Of-Session Callback Error',
    log_level: 'ERROR',
  },
  '81000': {
    message: 'The Execution has exceeded max steps allowed for a flow',
    log_level: 'WARNING',
  },
  '81001': {
    message: 'The Widget has exceeded max steps in a loop',
    log_level: 'WARNING',
  },
  '81002': {
    message: 'Unexpected event while processing Widget',
    log_level: 'WARNING',
  },
  '81004': {
    message: 'Failed to add member to Chat Channel',
    log_level: 'ERROR',
  },
  '81005': {
    message: 'Failed to transition because no match was found',
    log_level: 'WARNING',
  },
  '81006': {
    message: 'Failed to create Chat Channel',
    log_level: 'ERROR',
  },
  '81007': {
    message: 'Connecting to a Call timed out',
    log_level: 'ERROR',
  },
  '81008': {
    message: 'Failed to connect to outgoing Call',
    log_level: 'ERROR',
  },
  '81009': {
    message: 'Timed out enqueueing Call',
    log_level: 'ERROR',
  },
  '81010': {
    message: 'There was an internal error while processing a Function',
    log_level: 'ERROR',
  },
  '81011': {
    message: 'Failed to send Message',
    log_level: 'ERROR',
  },
  '81012': {
    message: 'Failed to update Sync service',
    log_level: 'ERROR',
  },
  '81013': {
    message: 'Failed to invoke Understand API',
    log_level: 'ERROR',
  },
  '81014': {
    message: 'There was an internal error while processing an HTTP request',
    log_level: 'ERROR',
  },
  '81015': {
    message: 'Failed to Create Task',
    log_level: 'ERROR',
  },
  '81016': {
    message: 'Outbound HTTP Request Failed',
    log_level: 'ERROR',
  },
  '81017': {
    message: 'Error in Twilio Function Response',
    log_level: 'ERROR',
  },
  '81018': {
    message: 'Template evaluation error in Studio widget',
    log_level: 'WARNING',
  },
  '81019': {
    message: 'Twilio phone number using deprecated API version',
    log_level: 'ERROR',
  },
  '81020': {
    message: 'Unsupported Trigger Type',
    log_level: 'WARNING',
  },
  '81021': {
    message:
      'Flow revision must be an integer or enum(LatestPublished, LatestRevision)',
    log_level: 'ERROR',
  },
  '81022': {
    message: 'Flow definition validation failed',
    log_level: 'ERROR',
  },
  '81023': {
    message:
      'Creating an Execution via REST API failed due to malformed contact parameters',
    log_level: 'ERROR',
  },
  '81024': {
    message: 'Subflow Error',
    log_level: 'ERROR',
  },
  '81025': {
    message: 'Studio Flow exceeds maximum allowed widgets',
    log_level: 'WARNING',
  },
  '81026': {
    message:
      'Studio Execution failed because Flow exceeds maximum allowed widgets',
    log_level: 'ERROR',
  },
  '82001': {
    message: 'Function invocation resulted in StatusCode 4xx',
    log_level: 'WARNING',
  },
  '82002': {
    message: 'Error on Twilio Function response',
    log_level: 'ERROR',
  },
  '82003': {
    message: 'Deployment Installation Failure',
    log_level: 'ERROR',
  },
  '82004': {
    message: 'Function execution resulted in a warning log',
    log_level: 'WARNING',
  },
  '82005': {
    message: 'Function execution resulted in an error log',
    log_level: 'ERROR',
  },
  '82006': {
    message: 'Environment Context too large',
    log_level: 'ERROR',
  },
  '82007': {
    message: 'Unsupported Runtime',
    log_level: 'ERROR',
  },
  '82008': {
    message: 'Headers or cookies too large',
    log_level: 'ERROR',
  },
  '82009': {
    message: 'Multi-valued headers not supported',
    log_level: 'ERROR',
  },
  '83000': {
    message: 'Super SIM registration failed due to Internal Error',
    log_level: 'ERROR',
  },
  '83001': {
    message: 'Parameter missing while registering a Super SIM',
    log_level: 'ERROR',
  },
  '83002': {
    message: 'Super SIM cannot be registered',
    log_level: 'ERROR',
  },
  '83003': {
    message: 'The Super SIM already belongs to the requesting Account.',
    log_level: 'ERROR',
  },
  '83004': {
    message: 'Super SIM update operation failed due to Internal Error',
    log_level: 'ERROR',
  },
  '83005': {
    message: 'Super SIM not found',
    log_level: 'ERROR',
  },
  '83006': {
    message: 'Super SIM’s Target Fleet not found',
    log_level: 'ERROR',
  },
  '83007': {
    message:
      'Unable to activate your Super SIM as it does not belong to a Fleet',
    log_level: 'ERROR',
  },
  '83008': {
    message: 'Unable to remove your Super SIM from its Fleet',
    log_level: 'ERROR',
  },
  '83009': {
    message:
      'Unable to update your Super SIM’s Fleet while it is in status scheduled',
    log_level: 'ERROR',
  },
  '83010': {
    message: 'Unable to update your Super SIM to the desired status',
    log_level: 'ERROR',
  },
  '83011': {
    message: 'A Super SIM with the specified Unique Name exists already',
    log_level: 'ERROR',
  },
  '83400': {
    message: 'IP Commands error',
    log_level: 'ERROR',
  },
  '83401': {
    message: 'The device was not attached to a cellular network',
    log_level: 'ERROR',
  },
  '83402': {
    message: 'Received error response to IP Command callback request',
    log_level: 'ERROR',
  },
  '83500': {
    message: 'No eSIM Profiles are available',
    log_level: 'ERROR',
  },
  '83600': {
    message: ' An invalid parameter value was passed to the API',
    log_level: 'ERROR',
  },
  '83601': {
    message:
      'Request StartTime and/or EndTime must be aligned to UTC day boundaries',
    log_level: 'ERROR',
  },
  '83602': {
    message:
      'Request StartTime and/or EndTime must be aligned to UTC hour boundaries.',
    log_level: 'ERROR',
  },
  '83603': {
    message:
      'The maximum allowed query period is 31 days for group by sim queries',
    log_level: 'ERROR',
  },
  '83604': {
    message:
      'The requested query period exceeds the maximum allowed period for the requested Granularity',
    log_level: 'ERROR',
  },
  '83605': {
    message:
      'StartTime parameter is too far in the past. It must be within the last 18 months.',
    log_level: 'ERROR',
  },
  '83700': {
    message: 'Attachment Failed Due To Internal Error',
    log_level: 'ERROR',
  },
  '83701': {
    message: 'Data Session Establishment Failed Due To Internal Error',
    log_level: 'ERROR',
  },
  '83702': {
    message: 'Attachment Rejected Due To Network Not Allowed',
    log_level: 'ERROR',
  },
  '83703': {
    message: 'Attachment Rejected Due To Rate Limiting',
    log_level: 'ERROR',
  },
  '83704': {
    message: 'Attachment Rejected Due To SIM In New State',
    log_level: 'ERROR',
  },
  '83705': {
    message: 'Attachment Rejected Due To SIM In Inactive State',
    log_level: 'ERROR',
  },
  '90000': {
    message: 'Uncaught Flex JavaScript Exception',
    log_level: 'ERROR',
  },
  '90001': {
    message: 'Message SID is invalid',
    log_level: 'ERROR',
  },
  '90002': {
    message: 'Too Many Errors',
    log_level: 'ERROR',
  },
  '90003': {
    message: 'Unable to fetch plugins from the Custom Plugins URL',
    log_level: 'ERROR',
  },
  '90004': {
    message: 'Message Truncated',
    log_level: 'ERROR',
  },
  '90006': {
    message: 'Invalid direction',
    log_level: 'ERROR',
  },
  '90007': {
    message: 'Invalid validity period value',
    log_level: 'ERROR',
  },
  '90009': {
    message: 'The message SID already exists.',
    log_level: 'ERROR',
  },
  '90010': {
    message: 'Account is not active',
    log_level: 'ERROR',
  },
  '90011': {
    message: 'MessageSid is invalid',
    log_level: 'ERROR',
  },
  '90012': {
    message: "'Recipients' list has too many items",
    log_level: 'ERROR',
  },
  '90013': {
    message:
      "'Template' or 'TemplateSid'&'TemplateLanguage' or 'MediaUrls' is required",
    log_level: 'ERROR',
  },
  '90014': {
    message: 'Validity Period should be positive integer',
    log_level: 'ERROR',
  },
  '90015': {
    message: 'Body and Template (Body, Sid, Language, Args) are provided',
    log_level: 'ERROR',
  },
  '90016': {
    message:
      "'Template' or 'TemplateSid'/'TemplateLanguage' is required to send a Template Message",
    log_level: 'ERROR',
  },
  '90017': {
    message:
      "'Template' and 'TemplateSid'/'TemplateLanguage' must not be specified together",
    log_level: 'ERROR',
  },
  '90018': {
    message: "'Template' field is too long",
    log_level: 'ERROR',
  },
  '90019': {
    message: "'TemplateArgs' dictionary size is too large",
    log_level: 'ERROR',
  },
  '90020': {
    message: "One of 'TemplateArgs' dictionary key is blank",
    log_level: 'ERROR',
  },
  '90021': {
    message: "One of 'TemplateArgs' dictionary key is too long",
    log_level: 'ERROR',
  },
  '90022': {
    message: "One of 'TemplateArgs' dictionary value is too long",
    log_level: 'ERROR',
  },
  '90023': {
    message: "One of 'TemplateArgs' dictionary value is null",
    log_level: 'ERROR',
  },
  '90024': {
    message: "Template body has tag which is not provided in 'TemplateArgs'",
    log_level: 'ERROR',
  },
  '90025': {
    message: 'Template body has unsupported tag type',
    log_level: 'ERROR',
  },
  '90026': {
    message: 'Template body could not be parsed',
    log_level: 'ERROR',
  },
  '90027': {
    message: "Broadcast 'FriendlyName' is too long",
    log_level: 'ERROR',
  },
  '90028': {
    message: "Broadcast 'IdempotencyToken' is too long",
    log_level: 'ERROR',
  },
  '90029': {
    message: "Broadcast 'CorrelationId' is empty",
    log_level: 'ERROR',
  },
  '90030': {
    message: "Broadcast 'CorrelationId' is too long",
    log_level: 'ERROR',
  },
  '90031': {
    message: "Broadcast 'Recipients' list is empty",
    log_level: 'ERROR',
  },
  '90032': {
    message: "Broadcast recipient's 'to' is invalid",
    log_level: 'ERROR',
  },
  '90033': {
    message: "Broadcast recipient's 'to' is too long",
    log_level: 'ERROR',
  },
  '90034': {
    message: "'BroadcastStatusCallbackUrl' is too long",
    log_level: 'ERROR',
  },
  '90035': {
    message: "Broadcast 'MessageStatusCallbackUrl' is too long",
    log_level: 'ERROR',
  },
  '90036': {
    message: "Broadcast recipient's 'body' is too long",
    log_level: 'ERROR',
  },
  '90037': {
    message: "Broadcast has too many 'CorrelationId' items",
    log_level: 'ERROR',
  },
  '90038': {
    message: "'BroadcastStatusCallbackUrl' is invalid",
    log_level: 'ERROR',
  },
  '90039': {
    message: "Broadcast 'MessageStatusCallbackUrl' is invalid",
    log_level: 'ERROR',
  },
  '90040': {
    message: "Broadcast 'MediaUrls' list has too many items",
    log_level: 'ERROR',
  },
  '90041': {
    message: "Broadcast 'MediaUrl' field is too long",
    log_level: 'ERROR',
  },
  '90100': {
    message: 'Invalid Autopilot Actions JSON',
    log_level: 'ERROR',
  },
  '90101': {
    message: 'Unique Name Already Exists',
    log_level: 'ERROR',
  },
  '90102': {
    message: 'Assistant failure to start collection',
    log_level: 'ERROR',
  },
  '90103': {
    message: 'Error processing answer during collection',
    log_level: 'ERROR',
  },
  '90104': {
    message: 'Invalid Collect Field Type',
    log_level: 'ERROR',
  },
  '90403': {
    message: '[Autopilot] Signature validation failed',
    log_level: 'ERROR',
  },
  '91000': {
    message: 'Sink could not be created',
    log_level: 'ERROR',
  },
  '91001': {
    message: 'Sink could not be found',
    log_level: 'ERROR',
  },
  '91002': {
    message: 'Incorrect values for pagination',
    log_level: 'ERROR',
  },
  '91003': {
    message: 'Account reached the max sink limit',
    log_level: 'ERROR',
  },
  '91004': {
    message: 'Test event cannot be found',
    log_level: 'ERROR',
  },
  '91005': {
    message: 'Test ID is invalid',
    log_level: 'ERROR',
  },
  '91006': {
    message: 'The sink sid is in an invalid format',
    log_level: 'ERROR',
  },
  '91007': {
    message: 'Sink still in use',
    log_level: 'ERROR',
  },
  '91100': {
    message: 'Subscription could not be created',
    log_level: 'ERROR',
  },
  '91101': {
    message: 'Subscription could not be created',
    log_level: 'ERROR',
  },
  '91102': {
    message: 'Subscription could not be found',
    log_level: 'ERROR',
  },
  '91103': {
    message: 'Event type list is empty',
    log_level: 'ERROR',
  },
  '91104': {
    message: 'Event type not found',
    log_level: 'ERROR',
  },
  '91201': {
    message: 'Bad Request',
    log_level: 'ERROR',
  },
  '91202': {
    message: 'Not Found',
    log_level: 'ERROR',
  },
  '91203': {
    message: 'Method Not Allowed',
    log_level: 'ERROR',
  },
  '92001': {
    message: "'Types' Parameter Required",
    log_level: 'ERROR',
  },
  '92002': {
    message: "The 'variables' parameter exceeds the allowed limit",
    log_level: 'ERROR',
  },
  '92003': {
    message: "'language' Parameter Required",
    log_level: 'ERROR',
  },
  '92004': {
    message: 'Invalid language code',
    log_level: 'ERROR',
  },
  '92005': {
    message: 'ContentSid Required',
    log_level: 'ERROR',
  },
  '92006': {
    message: 'The Content Sid is Invalid',
    log_level: 'ERROR',
  },
  '92007': {
    message: 'The Content Variables Parameter is invalid',
    log_level: 'ERROR',
  },
  '92008': {
    message: 'Unsupported Content Type',
    log_level: 'ERROR',
  },
  '93101': {
    message: 'Unable to deliver events to sink',
    log_level: 'ERROR',
  },
  '93102': {
    message: 'There was an error with your AWS role',
    log_level: 'ERROR',
  },
  '93103': {
    message: 'There was an error with your Kinesis stream',
    log_level: 'ERROR',
  },
  '93104': {
    message: 'There is an issue with the Kinesis Stream Name or Region',
    log_level: 'ERROR',
  },
  '93105': {
    message: 'Consent has already been provided for this account and vendor',
    log_level: 'ERROR',
  },
  '94000': {
    message: 'Transcriptions: request to transcribe audio error',
    log_level: 'ERROR',
  },
  '94001': {
    message: 'Transcriptions: Invalid transcribe value',
    log_level: 'ERROR',
  },
  '94002': {
    message: 'Transcriptions: configuration not found',
    log_level: 'ERROR',
  },
  '94100': {
    message: 'Transcriptions: status callback response error',
    log_level: 'WARNING',
  },
  '94101': {
    message: 'Transcriptions: status callback response timed out',
    log_level: 'WARNING',
  },
  '94200': {
    message: 'Transcriptions Settings: Invalid encryptionEnabled value',
    log_level: 'ERROR',
  },
  '94201': {
    message: 'Transcriptions Settings: encryptionKeySid invalid or not found',
    log_level: 'ERROR',
  },
  '94300': {
    message: 'Transcriptions Configurations: Invalid callback configuration',
    log_level: 'ERROR',
  },
  '94301': {
    message: 'Transcriptions Configurations: UniqueName is required',
    log_level: 'ERROR',
  },
  '94302': {
    message: 'Transcriptions Configurations: UniqueName is invalid',
    log_level: 'ERROR',
  },
  '94303': {
    message: 'Transcriptions Configurations: Language is required',
    log_level: 'ERROR',
  },
  '94304': {
    message: 'Transcriptions Configurations: Language is invalid',
    log_level: 'ERROR',
  },
  '94400': {
    message: 'Transcriptions: transcription internal error',
    log_level: 'ERROR',
  },
  '94401': {
    message: 'Transcriptions: audio processing error',
    log_level: 'ERROR',
  },
  '94402': {
    message:
      'Transcriptions: Encryption failed and transcription result files deleted',
    log_level: 'ERROR',
  },
  '94403': {
    message: 'Transcriptions: Invalid encryption credentials',
    log_level: 'WARNING',
  },
  '94500': {
    message: 'Transcriptions: sourceSid invalid',
    log_level: 'ERROR',
  },
  '94501': {
    message: 'Transcriptions: Status invalid',
    log_level: 'ERROR',
  },
  '94600': {
    message: 'Filter limit exceeded',
    log_level: 'ERROR',
  },
  '94601': {
    message: 'Date range is not valid',
    log_level: 'ERROR',
  },
  '94602': {
    message: 'Invalid FromDate',
    log_level: 'ERROR',
  },
  '94603': {
    message: 'Ttl is out of range',
    log_level: 'ERROR',
  },
  '94604': {
    message: 'Account Sid on Legal Hold.',
    log_level: 'ERROR',
  },
  '456001': {
    message: 'Approaching Flex Plugin creation limit',
    log_level: 'WARNING',
  },
  '456002': {
    message: 'Approaching Flex Plugin Version creation limit',
    log_level: 'WARNING',
  },
};
