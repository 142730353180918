import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { CommentApi, ConversationRelationApi } from 'shared/sdk';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalChatService {

  commonChatFilters = [
    'dateOptions',
    'accounts',
    'customers',
    'programs',
    'jobSites',
    'priority',
    'pmsCaseStatus',
    'pmsCaseNumbers'
  ];

  constructor(
    private _conversationRelationApi: ConversationRelationApi,
    private _commentApi: CommentApi,
    private _messageService: MessageService,
  ) { }

  getTaskConversations(filterObj: { [key: string]: any }): Observable<any> {
    return this._conversationRelationApi.getDistinctByType({...filterObj, isTesting: this.isTesting()}).pipe(take(1));
  }

  getTaskConversationsContinuous(filterObj: { [key: string]: any }): Observable<any> {
    const filter = { ...filterObj };
    if (filter && filter.conversationType === 'mwp') {
      // TODO :: Once will stable with new task conversation we can use timer method here
      return this._conversationRelationApi.getDistinctByType({...filterObj, isTesting: this.isTesting()}).map(data => {
        filter.where['updatedAt'] = { gte: new Date() };
        return data;
      })
      .catch((err) => {
        console.error(err);
        return of([]);
      })
    } else {
      return Observable.timer(0, 60000)
      .flatMap(() => {
        return this._conversationRelationApi.getDistinctByType({...filterObj, isTesting: this.isTesting()}).map(data => {
          filter.where['updatedAt'] = { gte: new Date() };
          return data;
        })
        .catch((err) => {
          console.error(err);
          return of([]);
        })
      });
    }
  }

  getCaseComments(filterObj: { [key: string]: any }): Observable<any> {
    return this._commentApi.getDistinctByType(filterObj).pipe(take(1));
  }

  isTesting() {
    return !!(localStorage.getItem('isTesting'));
  }

  getCaseCommentsContinuous(): Observable<any> {
    return this._messageService.getNewMessageJob();
  }

  checkifDataFilterMatch({ filterCondition, data }) {
    let filterMatched = true;
    const messageFilter = data.filters;
    // console.log(filterCondition);
    if (Object.keys(filterCondition).length) {
      // CreatedAt Filter
      if (typeof filterCondition.CreatedDate !== 'undefined') {
        filterMatched = this.checkDateInRange(filterCondition.CreatedDate.between, data.createdAt)
      }
      // Partner Account filter
      if (typeof filterCondition.CKSW_BASE__Account__c !== 'undefined') {
        filterMatched = filterCondition.CKSW_BASE__Account__c.inq.includes(messageFilter.partnerAccount);
      }
      // Program filter
      if (typeof filterCondition.Project_SOP__c !== 'undefined') {
        filterMatched = filterCondition.Project_SOP__c.inq.includes(messageFilter.projectId);
      }
      // Jobsite Location filter
      if (typeof filterCondition.Jobsite__c !== 'undefined') {
        filterMatched = filterCondition.Jobsite__c.inq.includes(data.jobSiteId);
      }
      // Priority
      if (typeof filterCondition.Service_Dispatch_SLA_Priority__c !== 'undefined') {
        filterMatched = filterCondition.Service_Dispatch_SLA_Priority__c.inq.includes(messageFilter.Service_Dispatch_SLA_Priority__c);
      }
      // PMS Case status
      if (typeof filterCondition.PMS_Case_Status !== 'undefined') {
        filterMatched = filterCondition.PMS_Case_Status.inq.includes(messageFilter.PMSCaseStatus);
      }
      // PMS Case number
      if (typeof filterCondition.PMS_Case_Number !== 'undefined') {
        filterMatched = filterCondition.PMS_Case_Number.inq.includes(messageFilter.PMS_Case_Number);
      }
      // PMC Case number
      if (typeof filterCondition.PMC_Case_Number !== 'undefined') {
        filterMatched = filterCondition.PMC_Case_Number.inq.includes(messageFilter.PMS_Case_Number);
      }
      // JOB filters

      // Vendor filter
      if (typeof filterCondition.Vendor__c !== 'undefined') {
        filterMatched = filterCondition.Vendor__c.inq.includes(messageFilter.Vendor__c);
      }
      // Stage status
      if (typeof filterCondition.Dispatch_Service_Resolution_Status__c !== 'undefined') {
        filterMatched = filterCondition.Dispatch_Service_Resolution_Status__c.inq.includes(messageFilter.Dispatch_Service_Resolution_Status__c);
      }
      // Talent_type
      if (typeof filterCondition.Talent_Type__c !== 'undefined') {
        filterMatched = filterCondition.Talent_Type__c.inq.includes(messageFilter.Talent_Type__c);
      }
      // JOB number
      if (typeof filterCondition.Iron_Job_num__c !== 'undefined') {
        filterMatched = filterCondition.Iron_Job_num__c.inq.includes(messageFilter.Iron_Job_num__c);
      }
      // job status
      if (typeof filterCondition.Job_Status_Internal__c !== 'undefined') {
        filterMatched = filterCondition.Job_Status_Internal__c.inq.includes(messageFilter.Job_Status_Internal__c);
      }
      // Vendor site
      if (typeof filterCondition.Vendorsite__c !== 'undefined') {
        filterMatched = filterCondition.Vendorsite__c.inq.includes(messageFilter.Vendorsite__c);
      }
      // Priority JOB
      if (typeof filterCondition.Service_Dispatch_SLA_Priority__c !== 'undefined') {
        filterMatched = filterCondition.Service_Dispatch_SLA_Priority__c.inq.includes(messageFilter.Service_Dispatch_SLA_Priority__c);
      }
      // Geo metro
      if (typeof filterCondition.Jobsite__c !== 'undefined') {
        filterMatched = filterCondition.Jobsite__c.inq.includes(messageFilter.Jobsite__c);
      }
      // Worker
      if (typeof filterCondition.Dispatch_Worker_Name__c !== 'undefined') {
        filterMatched = filterCondition.Dispatch_Worker_Name__c.inq.includes(messageFilter.Dispatch_Worker_Name__c);
      }
      // pmc window
      if (typeof filterCondition.commentType !== 'undefined' && filterCondition.commentType === 'pmc') {
        filterMatched = data.commentType === 'pmc' ? true : false;
      }
      // billing window
      if (typeof filterCondition.commentType !== 'undefined' && filterCondition.commentType === 'billing') {
        filterMatched = data.commentType === 'billing' ? true : false;
      }

    }
    // console.log('Has filter matched ', filterMatched);
    return filterMatched;
  }

  setChatCommonEnableFilters() {
    return this.commonChatFilters;
  }

  setProgramPMCConsoleFilter() {
    return [
      'dateOptions',
      'accounts',
      'customers',
      'programs',
      'jobSites',
      'caseTypes',
      'statgeStatus',
      'priority',
      'pmsCaseStatus',
      'pmsCaseNumbers',
      'pmcCaseNumbers'
    ]
  }

  setBillingSOPOConsoleFilter() {
    return [
      'dateOptions',
          'accounts',
          'customers',
          'vendorAccounts',
          'programs',
          'jobSites',
          'statgeStatus',
          'talentTypes',
          'jobs',
          'jobStatus',
          'jobTypes',
          'vendorSites',
          'caseCategory',
          'priority',
          'geoMetro',
          'workerNames',
          'pmsCaseStatus',
          'pmsCaseNumbers'
    ]
  }

  setInternalAccessEnableFilters(accessType?: string, userType?: string) {
    let enableFilters = this.commonChatFilters;
    enableFilters = enableFilters.concat([
      'caseTypes',
      'pmcCaseNumbers',
      'messageType',
      'employeeTypeList'
      // 'workerNames'
    ]);
    return enableFilters;
  }

  setPartnerAccessEnableFilters(accessType?: string, userType?: string) {
    let enableFilters = this.commonChatFilters;
    enableFilters = enableFilters.concat([
      'statgeStatus',
      'talentTypes',
      'caseCategory',
      'geoMetro'
    ]);
    return enableFilters;
  }

  setOtherAccessEnableFilters(accessType?: string, userType?: string) {
    // console.log(accessType, userType)
    let enableFilters = this.commonChatFilters;
    enableFilters = enableFilters.concat([
      'vendorAccounts',
      'statgeStatus',
      'talentTypes',
      'jobs',
      'jobStatus',
      'jobTypes',
      'vendorSites',
      'caseCategory',
      'geoMetro'
    ]);
    if (accessType === 'vendor' && userType !== 'standard') {
      enableFilters.concat([
        'workerNames'
      ]);
    }
    if (accessType === 'internal') {
      enableFilters = enableFilters.concat([
        'employeeTypeList'
      ]);
    }
    return enableFilters;
  }

  setChatEnableFilters(accessType: string, userType: string) {
    switch (accessType) {
      case 'internal':
        return this.setInternalAccessEnableFilters();
      case 'partner':
        return this.setPartnerAccessEnableFilters();
      default:
        return this.setOtherAccessEnableFilters(accessType, userType);
    }
  }

  setGC3EnableFilters(type: string, accessType?: string, userType?: string) {
    switch (type) {
      case 'caseList':
        return this.setChatEnableFilters(accessType, userType);
      case 'taskList':
        return [
          'dateOptions', 'taskAccounts', 'projectTypes', 'projects', 'milestones', 'tasks', 'taskPriorities',
          'taskStatuses', 'taskRequestors', 'taskTypes', 'users', 'teams', 'startFromDateOptions',
          'accountCommunities', 'pmsCaseNumbers'
        ];
        case 'escalationTask':
          return [
            'dateOptions', 'taskAccounts', 'projectTypes', 'projects', 'milestones', 'tasks', 'taskPriorities',
            'taskStatuses', 'taskRequestors', 'taskTypes', 'users', 'teams', 'startFromDateOptions',
            'accountCommunities', 'pmsCaseNumbers', 'escalationType'
          ];
    }
  }
  checkDateInRange(range, date) {
    const minDate = new Date(range[0]);
    const maxDate = new Date(range[1]);
    date = new Date(date);
    if (date > minDate && date < maxDate) {
      return true;
    }
    return true;
  }

  // check if list has the card for incoming message.
  checkIfCardExists(list, incomingMessage) {
    let indexOfCard = null;
    list.filter(function (e, i) {
      if (typeof e.context !== 'undefined' && e.context !== null && typeof incomingMessage.context !== 'undefined') {
        if (typeof incomingMessage.context.jobId !== 'undefined' && incomingMessage.context.jobId !== null && e.context.jobId === incomingMessage.context.jobId) {
          indexOfCard = i;
        } else if (e.context.workOrderId === incomingMessage.context.workOrderId) {
          indexOfCard = i;
        } else if (e.context.caseId === incomingMessage.context.caseId) {
          indexOfCard = i;
        } else if (typeof incomingMessage.filters !== 'undefined' && typeof incomingMessage.filters.jobSfdcId !== 'undefined' && e.Job__c === incomingMessage.filters.jobSfdcId) {
          indexOfCard = i;
        } else if (typeof incomingMessage.filters !== 'undefined' && typeof e.job !== 'undefined' && typeof e.job.Iron_Job_num__c !== 'undefined' && typeof incomingMessage.filters.Iron_Job_num__c !== 'undefined' && e.job.Iron_Job_num__c == incomingMessage.filters.Iron_Job_num__c) {
          indexOfCard = i;
        }
      }
      if (e.modelName == incomingMessage.modelName && e.modelId == incomingMessage.modelId) {
        indexOfCard = i;
      }
    });
    return indexOfCard;
  }

}
