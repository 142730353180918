import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';
import { AccountApi, TaskApi, RecordTypeApi } from 'shared/sdk';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from 'shared/services/common.service';


@Component({
  selector: 'app-task-account-lookup',
  templateUrl: './task-account-lookup.component.html',
  styleUrls: ['./task-account-lookup.component.css']
})
export class TaskAccountLookupComponent implements OnInit, OnDestroy {
  viewId: any;
  selectedAccCommunity: any;
  // RecordTypeIds = ['0121a000000QaUdAAK', '0121a000000QamvAAC']
  RecordTypeIds = ['0121a000000QaUdAAK', '0121a000000QamvAAC', '0121a000000QaUYAA0'];
  isLoading = false;
  @Input() boxedLayout = false;

  @Input() set resetData(isClear) {
    // this.pAccountList = [];
    this.pAccountsSelected = [];
  }
  @Input() isMultipleSelected = true;
  @Input() selectedDefaultAccount;
  @Input() from;
  @Input() isdisabled;
  @Input() set selectedConversatonAccount(e) {
    if (e) {
      this.selectedTask = e;
      this.getSelectedAccount(this.selectedTask);
    } else {
      this.selectedTask = '';
    }
  }
  @Input() set setAccCommunityObj(params) {
    if (!this.removeRecordTypeIds) {
      this.clearPAccount();
      if (params) {
        this.selectedAccCommunity = [];
        this.selectedAccCommunity = params;
      } else {
        this.selectedAccCommunity = [];
      }
      if (this.selectedAccCommunity.length > 0) {
        this.findRecordTypeId(this.selectedAccCommunity)
      } else {
        this.RecordTypeIds = ['0121a000000QaUdAAK', '0121a000000QamvAAC', '0121a000000QaUYAA0']
        this.getAccounts();
      }
    }
  }
  @Output() getPartnerAccountObj: EventEmitter<any> = new EventEmitter;
  @Output() getInitialPartnerAccountObj: EventEmitter<any> = new EventEmitter;
  selectedTask: any;
  noPAccount = 'No account found.';
  pAccountsTypeahead = new EventEmitter<string>();
  pAccounts: Array<any>;
  pAccountList = [];
  pAccountsSelected = [];
  removeRecordTypeIds = false;
  removeRecordTypeSubscription: Subscription;
  constructor(
    private _cd: ChangeDetectorRef,
    private _accountApi: AccountApi,
    private _taskAPI: TaskApi,
    private _recordType: RecordTypeApi,
    private _activatedRoute: ActivatedRoute,
    private _commonService: CommonService,
  ) {
    this._activatedRoute.queryParams.subscribe(params => {
      this.viewId = params.consoleType;
    });
  }

  ngOnInit() {
    this.removeRecordTypeSubscription = this._commonService.removeRecordTypeIds.subscribe(remove => {
      if (remove) {
        this.removeRecordTypeIds = true;
      }
    })
    // to show selected partner account
    if (this.from && this.from === 'analyticsDashboard') {
      if (this.selectedDefaultAccount && this.selectedDefaultAccount.length) {
        this.pAccountsSelected = this.selectedDefaultAccount;
      } else {
        this.pAccountsSelected = [];
      }
    } else {
      const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
      this.pAccountsSelected = (preselected && preselected['taskAccounts']) ? preselected['taskAccounts'] : '';
    }
    if (this.pAccountsSelected && this.pAccountsSelected.length && this.selectedAccCommunity && this.selectedAccCommunity.length > 0) {
      this.isLoading = true;
      this._recordType.find({ where: { Name: this.selectedAccCommunity[0] } }).subscribe(result => {
        if (result[0]['sfdcId']) {
          this.RecordTypeIds = result[0]['sfdcId'].split();
        }
      })
      this.getAccountList({ sfdcId: { inq: this.pAccountsSelected }, RecordTypeId: { inq: this.RecordTypeIds } }).subscribe(
        x => {
          this.isLoading = false;
          this.pAccountList = x;
          this.noPAccount = (this.pAccountList.length === 0) ? 'No account found.' : '';
        },
        err => {
          this.isLoading = false;
          console.log(err);
          this.pAccountList = [];
          this.noPAccount = '';
        }
      );
      this.onAccountChange();
    } else {
      this.getAccounts();
      if (this.pAccountsSelected && this.pAccountsSelected.length) {
        this.onAccountChange();
        this.isLoading = true;
        this.getAccountList({ sfdcId: { inq: this.pAccountsSelected }, RecordTypeId: { inq: this.RecordTypeIds } }).subscribe(
          x => {
            this.isLoading = false;
            this.pAccountList = x;
            this.noPAccount = (this.pAccountList.length === 0) ? 'No account found.' : '';
          },
          err => {
            this.isLoading = false;
            console.log(err);
            this.pAccountList = [];
            this.noPAccount = '';
          }
        );
      }

    }
    if (this.selectedTask) {
      this.getSelectedAccount(this.selectedTask);
    }
    this.getPartnerAccountObj.emit(this.pAccountsSelected);
  }

  findRecordTypeId(recordTypeVal) {
    this._recordType.find({ where: { Name: recordTypeVal } }).subscribe(result => {
      if (result[0]['sfdcId']) {
        this.RecordTypeIds = result[0]['sfdcId'].split();
        this.getAccounts();
      }
    })
  }

  // get partner's accounts list by name
  getAccounts() {
    this.pAccountList = [];
    this.pAccountsTypeahead
      .pipe(
        filter(term => term && term.trim().length > 2),
        distinctUntilChanged(),
        debounceTime(300),
        switchMap(term => {
          const searchPattern = { like: '%' + term.trim() + '%', options: 'i' };
          const whereObj = {
            Name: searchPattern,
            RecordTypeId: { inq: this.RecordTypeIds }
          };
          return this.getAccountList(whereObj);
        })
      )
      .subscribe(
        x => {
          this.isLoading = false;
          this._cd.markForCheck();
          this.pAccountList = x;
          this.noPAccount = (this.pAccountList.length === 0) ? 'No account found.' : '';
        },
        err => {
          this.isLoading = false;
          console.log(err);
          this.pAccountList = [];
          this.noPAccount = '';
        }
      );
  }

  onAccountChange() {
    this.getPartnerAccountObj.emit(this.pAccountsSelected);
  }

  clearPAccount() {
    this.pAccountsSelected = [];
    this.getPartnerAccountObj.emit([]);
  }

  /**
   * 
   * @param whereObj 
   */

  getAccountList(whereObj) {
    this.isLoading = true;
    if (this.removeRecordTypeIds) {
      if (whereObj && whereObj.RecordTypeId) {
        delete whereObj.RecordTypeId
      }
    }
    return this._accountApi.find({
      where: whereObj,
      fields: ['Name', 'id', 'sfdcId'],
      order: 'CreatedDate DESC',
    }).map(res => res);
  }

  // METHOD TO GET THE SELECTED ACCOUNT
  getSelectedAccount(task) {
    if (task) {
      this._taskAPI.find({
        where: this._setWhereCondition(task),
        fields: ['id', 'sfdcId', 'Account__c'],
        include: [
          {
            relation: 'account',
            scope: {
              fields: ['id', 'sfdcId', 'Name']
            }
          }]
      }).subscribe(
        data => {
          if (data && data.length) {
            // this.pAccountsSelected = [];
            this.pAccountList = [];
            // this.pAccountsSelected.push(data[0] && data[0]['account'] && data[0]['account'].sfdcId);
            // this.pAccountList.push(data[0]['account']);
            this.pAccountsSelected = data[0] && data[0]['account'] && data[0]['account'].sfdcId;
            this.pAccountList.push(data[0]['account']);
            this.getInitialPartnerAccountObj.emit(this.pAccountsSelected);
          }
        },
        err => {
          console.log('Error fetching Task', err.message);
        }
      );
    }
  }

  private _setWhereCondition(task) {
    return (task && task.id) ? { id : task.id } : { sfdcId: task.sfdcId };
  }

  ngOnDestroy() {
    this.removeRecordTypeSubscription && this.removeRecordTypeSubscription.unsubscribe()
  }

}