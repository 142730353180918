import { Component, EventEmitter, OnInit, Output ,OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApvpWorkersVmsApi, JobApi, WorkerApi } from 'shared/sdk';
import { debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';
import { AlertService } from 'shared/services/alert.service';
import { NotificationService } from 'shared/services/notification.service';

@Component({
  selector: 'app-send-sms',
  templateUrl: './send-sms.component.html',
  styleUrls: ['./send-sms.component.css'],
})
export class SendSmsComponent implements OnInit {
  @Output() isSmsSent:EventEmitter<any>= new EventEmitter()
  sendSmsForm: FormGroup;
  phonePattern = /^\+[0-9]{1,3}(\-|\s)?[0-9]{8,14}$/;
  isValidPhone = false;
  loggedInData = {};
  maxCharacters = 450;
  public searchInput = new EventEmitter<string>();
  jobIds: any;
  notFoundText: string;
  workers: any[];
  workerTypeahead = new EventEmitter<string>();
  istotalMobileLimitExceed= false;
  maxPhoneCharacters = 100;
  maxWorkerSelect = 5;
  maxPhoneNumbers = 5; 
  constructor(
    private fb: FormBuilder,
    private _notificationService: NotificationService,
    private _alertService: AlertService,
    private _jobApi: JobApi,
    private _apvpWorkersVmsApi: ApvpWorkersVmsApi,
    private _workerApi: WorkerApi,
    ) {}

  ngOnInit() {
    this.formBuilder();
    const app = JSON.parse(localStorage.getItem('appData'));
    if(app && app.user){
      this.loggedInData = {
        sfdcId: app.user.sfdcId,
        email: app.user.email,
        name: app.user.firstname + ' ' + app.user.lastname
      }
    }
    this.getJobIds();
    this.getWorkers();
  }

  formBuilder() {
    this.sendSmsForm = this.fb.group({
      job: '',
      workers: '',
      phoneNumber: ['', [Validators.required]],
      message: ['', [Validators.required,Validators.maxLength(450)]],
    });
  }

  getJobIds() {
    this.searchInput
      .pipe(
        filter(search => search && search.length > 2),
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(search => this.loadJobIds(search))
      ).subscribe( x => {
        this.jobIds = x;
        this.notFoundText = (this.jobIds.length === 0) ? 'No job id found.' : '';
      },
      err => {
        console.log(err);
        this.jobIds = [];
      }
    );
   }

  loadJobIds(search?) {
    let whereFilter={}
    if (search) {
      const searchPattern = { like: '%' + search.trim() + '%', options: 'i' };
      whereFilter = {...whereFilter, Iron_Job_num__c: searchPattern, sfdcId: undefined };
    }
    const reqObj = {
      'fields': ['sfdcId', 'id', 'Iron_Job_num__c', 'RecordTypeId', 'Vendor__c', 'Dispatch_Service_Resolution_Status__c',
          'Customer_Service_Type_From_Program__c', 'Service_Dispatch_SLA_Priority__c', 'Technical_Level__c',
          'CKSW_BASE__Account__c', 'Name','Project__c','Jobsite__c','Case__c','CKSW_BASE__Country__c','CKSW_BASE__State__c','CKSW_BASE__City__c','CKSW_BASE__Street__c','Work_Order__c','Job_Status_Internal__c','Jobsite_Name__c','Vendorsite__c','Project_SOP__c'],
      'where': whereFilter,
      'include': [
        {
          'relation': 'vendor',
          'scope': {
            'fields': ['sfdcId', 'Name']
          }
        },
        {
          'relation': 'partner',
          'scope': {
            'fields': ['sfdcId', 'Name']
          }
        },
        {
          'relation': 'project',
          'scope': {
            'fields': ['sfdcId', 'Project__c', 'Name', 'Project_Profile__c', 'Project_Routing_Profile__c']
          }
        },
        {
          'relation': 'jobsite',
          'scope': {
            fields: ['sfdcId', 'Name', 'Region__c', 'Time_Zone__c','GEO_Metro__c'],
            include: [
              {
                relation : 'GeoMetro',
                scope: {
                  fields: ['sfdcId','Region__c']
                }
              }
            ]
          }
        }
      ],
      limit: '20',
      order: 'id DESC'
    };
    return this._jobApi.find(reqObj);
  }
  
  getWorkers() {
      this.workerTypeahead.pipe(
        filter(search => search && search.length > 2),
        debounceTime(200),
        switchMap(search => {
        let whereFilter={}
        const searchPattern = { like: '%' + search.trim() + '%', options: 'i' };
          whereFilter = {
            and: [
              {
                or:
                [
                  { Dispatch_Worker_num__c: searchPattern },
                    { Name: searchPattern }
                  ]
                }
            ]
          };
        whereFilter = { ...whereFilter, Name: searchPattern };
        const selectedJob= this.getFormControlValue('job');
        if(!selectedJob){
          return this.findWorkerApi(whereFilter);
        }else {
          return [];
        }
      })
    ).subscribe( 
      x => {
        this.workers = x;
      },
      err => {
        console.log(err);
        this.workers = [];
      }
      );
  }

  findWorkerApi(whereFilter) {
      return this._workerApi.find({
        where: whereFilter,
        fields: ['Name', 'id', 'sfdcId', 'Contact__c', 'Account_Name__c', 'Dispatch_Worker_num__c','Work_Phone_Number__c'],
        // limit: this.itemsPerBatch
      })
  }
  
  getApvpWorkers() {
    const selectedJobObj = this.getFormControlValue('job')[0]
    this.workers = [];
    if(selectedJobObj && selectedJobObj.project && selectedJobObj.project.Project_Routing_Profile__c ){ 
      this._apvpWorkersVmsApi.getAllApvpWorkersList({
        where: { 
          Project_Routing_Profile__c: selectedJobObj.project.Project_Routing_Profile__c, 
          Vendor_Account_Id: selectedJobObj.Vendor__c,
          Requires_Registered_Workers__c: selectedJobObj.program && selectedJobObj.program.Program_Requires_Registered_Workers__c ? selectedJobObj.program.Program_Requires_Registered_Workers__c: false,
          Onboarding_Status__c: 'Completed',
          Status_Worker__c: 'Approved',
        },
      }).subscribe( res => {
        let workerList = [];
        for (const [key, value] of Object.entries(res)) {
          let w = {
            Contact__c: value['workerContactId'],
            Email__c: value['workerEmail'],
            Name: value['ServiceoUserId'] && value['ServiceoUserIsActive'] ?  value['workerName'] :  value['workerName'] + '(Not Serviceo User)',
            Work_Phone_Number__c: value['workerPhone'],
            is_Facebook__c: value['workerIsFacebook'],
            sfdcId: value['workerSfdcId'],
            Dispatch_Worker_num__c: value['workerNum']
          }
          workerList.push(w)
        }
        this.workers = workerList;
      },
      err => {
        console.log(err);
        this.workers = [];
      });
    }
  }

  updatesendSmsForm() {
    let payload={}
    const phoneNumberList = this.sendSmsForm.value.phoneNumber.split(',');
    const message = this.sendSmsForm.value.message.trim();
    const selectedJobObj = this.getFormControlValue('job')[0];
    if(selectedJobObj && selectedJobObj.jobsite){
      if(selectedJobObj.jobsite.GeoMetro && selectedJobObj.jobsite.GeoMetro.Region__c) {
        selectedJobObj.jobsite.Region__c = selectedJobObj.jobsite.Region__c ? selectedJobObj.jobsite.Region__c : selectedJobObj.jobsite.GeoMetro.Region__c;
        delete selectedJobObj.jobsite.GeoMetro;
      }
    }
    const selectedWorkerList = this.getFormControlValue('workers');
    payload= {
      job: selectedJobObj || {},
      jobsite: selectedJobObj && selectedJobObj.jobsite || {},
      worker: selectedWorkerList,
      phoneNum: phoneNumberList,
      message: message,
      sender: this.loggedInData
    }
    this._notificationService.sendSMS(payload).then(async result => {
      if(result){
        this._alertService.success("Message Sent successfully.");
        this.isSmsSent.emit(true);
      }
    }).catch(error => {
        this._alertService.error((error.error && error.error.message) ||error.message || 'Server Error');
    });

  }

  validatePhone() {
    let validation = true;
    const phoneNumText = this.getFormControlValue('phoneNumber')
    const phoneNumbers = phoneNumText.split(',');
    phoneNumbers.forEach((el) => {
      if (this.phonePattern.test(el.trim()) === false) {
        validation = false;
      }
    });
    this.isValidPhone = validation;
    this.istotalMobileLimitExceed = phoneNumbers.length > this.maxPhoneNumbers ? true : false;
    if( this.istotalMobileLimitExceed ) {
      this.maxPhoneCharacters = phoneNumbers.slice(0 ,this.maxPhoneNumbers).join(',').length + 1;
      const text = phoneNumText.slice(0,this.maxPhoneCharacters)
      this.setFormControlValue( text ,'phoneNumber');
    } else {
      this.maxPhoneCharacters = 100 ;
    }
  }

  getFormControlValue(controlName){
    return this.sendSmsForm.controls[controlName].value;
  }

  setFormControlValue(value,controlName){
    this.sendSmsForm.controls[controlName].setValue(value);
    this.sendSmsForm.controls[controlName].markAsDirty();
    this.sendSmsForm.controls[controlName].markAsTouched();
    if(controlName == 'phoneNumber'){
      this.validatePhone();
    }
  }

  resetFormControl(controlName){
    this.sendSmsForm.controls[controlName].reset();
  }

  onPaste(e:ClipboardEvent){
    const pastedText=e.clipboardData.getData('text');
    let texts = pastedText.split(',');
    texts=texts.map((el) =>el.trim());
    const text = texts.join(',');
    e.preventDefault();
    this.setFormControlValue(text,'phoneNumber');
  }
  
  formatWorkersNumber(numbers){
    numbers = numbers.map( item => {
     let number = item.replaceAll('(','').replaceAll(')','').
                       replaceAll(',','').replaceAll('-','');
     number = number.replace(/\s/g, '');
     return number;
    })
    let text = numbers.join(',');
    return text;
  }

  onJobIdChange() {
    this.resetFormControl('workers');
    this.resetFormControl('phoneNumber');
    this.getApvpWorkers();
  }
  
  workerChange() {
    const selectWorkers = this.getFormControlValue('workers');
    const phoneNumbers = selectWorkers.map( item => {
      return item.Work_Phone_Number__c ? item.Work_Phone_Number__c : '';
    });
    const numberText = this.formatWorkersNumber(phoneNumbers);
    this.setFormControlValue(numberText,'phoneNumber');
  }

  clearJobIds() {
    this.notFoundText = 'Type to search job';
    this.resetFormControl('phoneNumber');
    this.resetFormControl('workers');
  }

  clearWorkers(){
    this.resetFormControl('workers');
  }

  resetForm() {
    this.jobIds=[];
    this.workers=[];
    this.clearJobIds();
    this.formBuilder();
  }
}
