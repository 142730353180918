import { ServiceContractLineItemApi } from './../../../../../sdk/services/custom/ServiceContractLineItem';
import { Component, OnInit, Input, Pipe, Sanitizer } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { ApprovedProjectVendorPoolApi, ProjectWorkerApi, ServiceContractApi, ProjectApi, WorkerApi } from 'shared/sdk';
import { PreloaderService } from 'shared/services/preloader.service';
import { forkJoin, of } from 'rxjs';
import { ApvpWorkersVmsApi } from 'shared/sdk/services/custom/ApvpWorkersVms';
import { DatePipe } from '@angular/common';
import { ApvpDistributionListWorkersApi } from 'shared/sdk/services/custom/ApvpDistributionListWorkers';
import { CommonService } from '../../../../../services/common.service';
import * as moment from 'moment';
import { AlertService } from 'shared/services/alert.service';
import { NotificationService } from 'shared/services/notification.service';

@Component({
  selector: 'app-worker-list-apvp',
  templateUrl: './worker-list-apvp.component.html',
  styleUrls: ['./worker-list-apvp.component.css']
})
export class WorkerListApvpComponent implements OnInit {
  @Input() projectProfileSfdcId;
  @Input() jobDetails;
  @Input() fromServiceDetailPage = false;
  activeTabs = ['projectProfileHealthSummary', 'vendorAssignment', 'workerAssignment', 'apvpWorkersSummary', 'apvpVMSWorkers',
    'apvpVFMSVendors', 'apvpVFMSWorkers', 'mvvpWorkers', 'vendorJobPreference', 'apvpVendors', 'fteActive', 'fteInActive', 'fteExpired'];
  tableData = {
    vendorAssignment: [], workerAssignment: [], apvpWorkersSummary: [], apvpVMSWorkers: [], apvpVFMSVendors: [],
    apvpVFMSWorkers: [], mvvpWorkers: [], vendorJobPreference: [], apvpVendors: [], fteActive: [], fteInActive: [], fteExpired: []
  };
  filterObj = {};
  itemsPerPage = 10;
  columns = {
    vendorAssignment: [], workerAssignment: [], apvpWorkersSummary: [], apvpVMSWorkers: [], apvpVFMSVendors: [],
    apvpVFMSWorkers: [], mvvpWorkers: [], vendorJobPreference: [], apvpVendors: [], fteActive: [], fteInActive: [], fteExpired: []
  };
  isLoading: boolean;
  timesLoadedMore = {
    vendorAssignment: 0, workerAssignment: 0, apvpWorkersSummary: 0, apvpVMSWorkers: 0, apvpVFMSVendors: 0,
    apvpVFMSWorkers: 0, mvvpWorkers: 0, vendorJobPreference: 0, apvpVendors: 0, fteActive: 0, fteInActive: 0, fteExpired: 0
  };
  totalCount = {
    vendorAssignment: 0, workerAssignment: 0, apvpWorkersSummary: 0, apvpVMSWorkers: 0, apvpVFMSVendors: 0,
    apvpVFMSWorkers: 0, mvvpWorkers: 0, vendorJobPreference: 0, apvpVendors: 0, fteActive: 0, fteInActive: 0, fteExpired: 0
  };
  noRecords = {
    vendorAssignment: false, workerAssignment: false, apvpWorkersSummary: false, apvpVMSWorkers: false,
    apvpVFMSVendors: false, apvpVFMSWorkers: false, mvvpWorkers: false, vendorJobPreference: false, apvpVendors: false,
    fteActive: false, fteInActive: false, fteExpired: false
  };
  allItems = {
    vendorAssignment: [], workerAssignment: [], apvpWorkersSummary: [], apvpVMSWorkers: [], apvpVFMSVendors: [],
    apvpVFMSWorkers: [], mvvpWorkers: [], vendorJobPreference: [], apvpVendors: [], fteActive: [], fteInActive: [], fteExpired: []
  };
  itemsPerBatch = 50;
  datePipe: any;
  healthCheckParam = [
    { number: '(A)', label: 'Number of Vendors > 3', value: 'success', field: 'Health_Jobsite_Status__c' },
    { number: '(B)', label: 'Atleast one VFMS Worker', value: 'success', field: 'Health_Project_status__c' },
    { number: '(C)', label: 'Approved Vendor Available under APVP', value: 'success', field: 'Health_OrderItem_Status__c' },
    { number: '(D)', label: 'At least one VFMS Vendors', value: 'success', field: 'At_least_one_VFMS_Vendor__c' }
  ];
  approvedApvpVendorCount = 0;
  additionalFindObjForApvpVMSWorkers: any;
  _healthData: any;
  healthCheckData = [];
  postHealthCheckParam = [
    { number: '(A)', label: 'Number of Vendors > 3', value: 'success', field: 'Number_of_Vendors_3__c' },
    { number: '(B)', label: 'Atleast one VFMS Worker', value: 'success', field: 'At_least_one_VFMS_Worker__c' },
    { number: '(C)', label: 'Approved Vendor Available under APVP', value: 'success', field: 'Health_Vendor_Status__c' },
    { number: '(D)', label: 'At least one VFMS Vendors', value: 'success', field: 'At_least_one_VFMS_Vendor__c' }
  ];

  Worker_Arrival_DateTime_Cust_Requested__c: any;
  Worker_Arrival_Date_Customer_Req_End__c: any;
  selectedWorkersList = []
  constructor(private _approvedProjectVendorPoolApi: ApprovedProjectVendorPoolApi,
    private _apvpWorkersVmsApi: ApvpWorkersVmsApi,
    private _apvpDistributionListWorkersApi: ApvpDistributionListWorkersApi,
    private _projectWorkersApi: ProjectWorkerApi,
    private _workerApi: WorkerApi,
    private _commonService: CommonService,
    private _loader: PreloaderService,
    private _serviceContractApi: ServiceContractApi,
    private _serviceContractLineItemApi: ServiceContractLineItemApi,
    private sanitizer: DomSanitizer,
    private _projectApi: ProjectApi,
    private _notificationService: NotificationService,
    private _alertService: AlertService) {
  }
  ngOnInit() {
    this.Worker_Arrival_DateTime_Cust_Requested__c = (this.jobDetails && this.jobDetails.appointment &&
      this.jobDetails.appointment.Worker_Arrival_DateTime_Cust_Requested__c) ? this.jobDetails.appointment.Worker_Arrival_DateTime_Cust_Requested__c : '';
    this.Worker_Arrival_Date_Customer_Req_End__c =
      this.Worker_Arrival_DateTime_Cust_Requested__c ?
        this._commonService.addTime(
          this.Worker_Arrival_DateTime_Cust_Requested__c, this.jobDetails.case.PPE_Hours__c, 'h') : '';
    this.Worker_Arrival_Date_Customer_Req_End__c = this.Worker_Arrival_Date_Customer_Req_End__c ? new Date(this.Worker_Arrival_Date_Customer_Req_End__c).toISOString() : '';
    this._commonService.saveCurrentPageAnalytics('Job Manager Console - Details', 'Worker List (APVP)')
    this.columnInitialize();
    if (this.fromServiceDetailPage) {
      this.getDataFromServiceManager();
    } else {
      this.getData();
    }
  }

  columnInitialize() {
    this.columns.vendorAssignment = [
      //  {
      //   prop: '##Action',
      //   name: 'Action',
      //   visible: this.fromServiceDetailPage ,
      //   width: 60,
      //   type: 'static',
      //   frozenLeft: true
      // },
      { prop: 'number', name: 'No.', visible: true, width: 60, type: 'static', frozenLeft: true },
      { name: 'Vendor Type', visible: true, width: 120, type: 'static', value: 'APVP', frozenLeft: true },
      { prop: 'accountName', name: 'Account Name', visible: true, width: 190, type: 'string', sortable: true, frozenLeft: true },
      { prop: 'vendorSubCommunityType', name: 'Vendor Sub-community Type', visible: true, width: 150, type: 'string', sortable: true, frozenLeft: true },
      { prop: 'status', name: 'Status', visible: true, width: 120, type: 'string', sortable: true, frozenLeft: true },
      { prop: 'metroVirtualVendorPool', name: 'Metro Virtual Vendor Pool', visible: true, width: 150, type: 'string', sortable: true },
      { prop: 'Project_Priority_Rating__c', name: 'Project Priority Rating', width: 180, visible: this.fromServiceDetailPage, type: 'string', sortable: true },
      { prop: 'routingRating', name: 'Routing Rating', width: 150, visible: true, type: 'string', sortable: true },
      { prop: 'ratingWeightedAverageAuto', name: 'Rating Weighted Average (Auto)', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'poolHealthRating', name: 'Pool General Health Rating', width: 180, type: 'string', visible: this.fromServiceDetailPage, sortable: true },
      { prop: 'vendorProgramPerformanceHealthRating', name: 'Vendor Program Performance Health Rating', width: 230, type: 'string', visible: true, sortable: true }
    ];

    this.columns.apvpVendors = [
      { prop: 'seqNum', name: 'Seq No.', visible: true, width: 100, type: 'string', sortable: true, frozenLeft: true },
      { prop: 'accountName', name: 'Account Name', visible: true, width: 210, type: 'string', sortable: true, frozenLeft: true },
      { prop: 'vendorSubCommunityType', name: 'Vendor Sub-community Type', visible: true, width: 150, type: 'string', sortable: true, frozenLeft: true },
      { prop: 'status', name: 'Status', visible: true, width: 120, type: 'string', sortable: true, frozenLeft: true },
      { prop: 'metroVirtualVendorPool', name: 'Metro Virtual Vendor Pool', visible: true, width: 150, type: 'string', sortable: true, },
      { prop: 'projectPriorityRating', name: 'Project Priority Rating', visible: true, width: 150, type: 'string', },
      { prop: 'routingRating', name: 'Routing Rating', width: 150, visible: true, type: 'string', sortable: true },
      { prop: 'ratingWeightedAverageAuto', name: 'Rating Weighted Average (Auto)', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'poolHealthRating', name: 'Pool General Health Rating', width: 180, type: 'string', visible: this.fromServiceDetailPage, sortable: true },
      { prop: 'vendorProgramPerformanceHealthRating', name: 'Vendor Program Performance Health Rating', width: 200, type: 'string', visible: true, sortable: true }
    ];

    this.columns.workerAssignment = [
      // {
      //   prop: '##Action',
      //   name: 'Action',
      //   visible: true,
      //   width: 200,
      //   type: 'static',
      //   frozenLeft: true
      // },
      { prop: 'number', name: 'No.', visible: true, width: 60, type: 'static', frozenLeft: true },
      { prop: 'vendorName', name: 'Vendor Name', visible: true, width: 150, type: 'string', frozenLeft: true },
      { prop: 'workerNum', name: 'Worker #', visible: true, width: 120, type: 'string', sortable: true, frozenLeft: true },
      { prop: 'wmId', name: 'WM Id', visible: true, width: 120, type: 'string', sortable: true, frozenLeft: true },
      { prop: 'workerName', name: 'Worker Name', visible: true, width: 150, type: 'string', sortable: true },
      { prop: 'workerPhoneNumber', name: 'Worker Phone', visible: true, width: 170, type: 'string', sortable: true },
      { prop: 'blacklisted', name: 'Blacklisted', visible: true, width: 150, type: 'string', sortable: true },
      { prop: 'tempBlocked', name: 'Temporary Blocked (Yes/No)', visible: true, width: 150, type: 'string', sortable: true },
      { prop: 'hasISGEmail', name: 'Has ISG Email Address', visible: true, width: 150, type: 'string', sortable: false },
      { prop: 'enabledServiceo', name: 'Enable Serviceo App Login', visible: true, width: 150, type: 'html', sortable: true },
      { prop: 'workerHasJob', name: 'Worker has Job for this slot ?', visible: true, width: 200, type: 'string', sortable: true },
      { prop: 'workerAvailability', name: 'Worker MyAvailability General (WMAG)', visible: this.fromServiceDetailPage, width: 200, type: 'workerAvailability', sortable: true },
      { prop: 'workerMySchedule', name: 'Worker MySchedule (WMS)', visible: true, width: 180, type: 'string', sortable: true },
      { prop: 'workOfferRequest', name: 'Work Offer Request (WOR)', visible: true, width: 180, type: 'string', sortable: true },
      { prop: 'workerRating', name: 'Worker Rating', visible: true, width: 140, type: 'starRating', sortable: true },
      { prop: 'seqNum', name: 'Seq No.', visible: true, width: 150, type: 'string', sortable: true },
      { prop: 'travelDistance', name: 'Travel Distance (Miles/KMs)', width: 180, visible: this.fromServiceDetailPage, type: 'string', sortable: true },
      { prop: 'travelTime', name: 'Travelling Time (Min/Max Avg*)', width: 180, visible: this.fromServiceDetailPage, type: 'string', sortable: true },
      { prop: 'workerTravelRisk', name: 'Worker Travel Risk Category (Auto)', width: 180, visible: true, type: 'string', sortable: true },
      { prop: 'region', name: 'Region', width: 150, visible: true, type: 'string', sortable: true },
      { prop: 'country', name: 'Country', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'state', name: 'State', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'city', name: 'City', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'projectWorkerRating', name: 'Project Worker Rating', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'ratingWeightedAverageAuto', name: 'Rating Weighted Average (Auto)', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'jobsiteName', name: 'Jobsite Name', width: 200, type: 'string', visible: true, sortable: true, showMore: true },
      { prop: 'vendorSubCommunityType', name: 'Vendor Sub-community Type', visible: true, width: 150, type: 'string', sortable: true, },
      { prop: 'status', name: 'Status (Vendor)', width: 220, type: 'string', visible: true, sortable: true },
      { prop: 'poolGeneralHealthRating', name: 'Pool General Health Rating', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'vendorProgramPerformanceHealthRating', name: 'Vendor Program Performance Health Rating', width: 200, type: 'string', visible: true, sortable: true },
      { prop: 'createdDate', name: 'Created Date', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'jobsiteWorkerJobs_7D', name: 'Jobsite  Worker Jobs (Last 7 Days)', width: 200, type: 'string', visible: this.fromServiceDetailPage, sortable: true },
      { prop: 'jobsiteCurrentMonth', name: 'Jobsite Worker Jobs (Current Month)', width: 200, type: 'string', visible: this.fromServiceDetailPage, sortable: true },
      { prop: 'JobsiteWorkerJobsTotal', name: 'Jobsite Worker Jobs (Total)', width: 200, type: 'string', visible: this.fromServiceDetailPage, sortable: true },
      { prop: 'WorkerJobsLastSevenDays', name: 'All Jobsite Worker Jobs  (Last 7 Days)', width: 200, type: 'string', visible: this.fromServiceDetailPage, sortable: true },
      { prop: 'WorkerJobsCurrentMonth', name: 'All Jobsite Worker Jobs (Current Month)', width: 180, type: 'string', visible: this.fromServiceDetailPage, sortable: true },
      { prop: 'WorkerJobsTotal', name: 'All Jobsite Worker Jobs (Total)', width: 180, type: 'string', visible: this.fromServiceDetailPage, sortable: true },
      { prop: 'checkAvailability', name: 'Check Worker Availability', width: 200, visible: true, sortable: false, type: 'string' },
      { prop: 'MessageSendTo', name: 'Message Sent To', width: 180, visible: true, sortable: false, type: 'string' },
      { prop: 'MessageSentDateTime', name: 'Message Sent At', width: 180, visible: true, sortable: false, type: 'string' },
      { prop: 'MessageReplyDateTime', name: 'Message Reply (Datetime)', width: 200, visible: true, sortable: false, type: 'string' },
      { prop: 'MessageReplyFrom', name: 'Message Reply From', width: 180, visible: true, sortable: false, type: 'string' },
      { prop: 'MessageReplyText', name: 'Message Reply Text', width: 200, visible: true, sortable: false, type: 'string' },
    ];

    this.hideColumnForAPVPWorkerSummaryList();


    this.columns.apvpVFMSVendors = [
      { prop: 'seqNum', name: 'Seq No', visible: true, width: 150, type: 'string', frozenLeft: true },
      { prop: 'accountName', name: 'Account Name', visible: true, width: 150, type: 'string', frozenLeft: true },
      { prop: 'vendorSubCommunityType', name: 'Vendor Sub-community Type', visible: true, width: 150, type: 'string', frozenLeft: true },
      { prop: 'status', name: 'Status', visible: true, width: 150, type: 'string', frozenLeft: true },
      { prop: 'metroVirtualVendorPool', name: 'Metro Virtual Vendor Pool', visible: true, width: 150, type: 'string', sortable: true },
      { prop: 'projectPriorityRating', name: 'Project Priority Rating', visible: true, width: 150, type: 'string' },
      { prop: 'routingRating', name: 'Routing Rating', visible: true, width: 150, type: 'string' },
      { prop: 'ratingWeightedAverageAuto', name: 'Rating Weighted Average (Auto)', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'poolGeneralHealthRating', name: 'Pool General Health Rating', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'vendorProgramPerformanceHealthRating', name: 'Vendor Program Performance Health Rating', width: 200, type: 'string', visible: true, sortable: true }
    ];

    this.columns.apvpVFMSWorkers = [
      { prop: 'name', name: 'Row #', visible: true, width: 150, type: 'string', frozenLeft: true },
      { prop: 'workerAccount', name: 'Worker Account', visible: true, width: 150, type: 'string', frozenLeft: true },
      { prop: 'workerNum', name: 'Worker#', visible: true, width: 150, type: 'string', frozenLeft: true },
      { prop: 'wmId', name: 'WM Id', visible: true, width: 150, type: 'string', frozenLeft: true },
      { prop: 'workerName', name: 'Worker Name', visible: true, width: 150, type: 'string', sortable: true },
      { prop: 'enabledServiceo', name: 'Enable Serviceo App login', visible: true, width: 180, type: 'html' },
      { prop: 'workerContactType', name: 'Worker Contact Type', visible: true, width: 150, type: 'string' },
      { prop: 'projectWorkerRating', name: 'Project Worker Rating', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'ratingWeightedAverageAuto', name: 'Rating Weighted Average (Auto)', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'createdDate', name: 'Created Date', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'jobRatingCount', name: 'Job Rating (Count)', width: 140, type: 'string', visible: true, sortable: true },
      { prop: 'professionalismRatingAvg', name: 'Professionalism Rating (Average)', width: 200, type: 'string', visible: true, sortable: true },
      { prop: 'satisfactionRatingAvg', name: 'Satisfaction Rating (Average)', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'onTimeRatingAvg', name: 'On-Time Rating (Average)', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'deliverablesRatingAvg', name: 'Deliverables Rating (Average)', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'communicationRatingAvg', name: 'Communication Rating (Average)', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'qualityRatingAvg', name: 'Quality Rating (Average)', width: 180, type: 'string', visible: true, sortable: true }
    ];

    this.columns.mvvpWorkers = [
      { prop: 'vendorSiteName', name: 'Vendorsite Name', visible: true, width: 200, type: 'string', frozenLeft: true },
      { prop: 'accountName', name: 'Account Name', visible: true, width: 150, type: 'string', frozenLeft: true },
      { prop: 'workerNum', name: 'Worker #', visible: true, width: 120, type: 'string', sortable: true, frozenLeft: true },
      { prop: 'wmId', name: 'WM Id', visible: true, width: 150, type: 'string', frozenLeft: true },
      { prop: 'workerName', name: 'Worker Name', visible: true, width: 150, type: 'string', sortable: true },
      { prop: 'enabledServiceo', name: 'Enable Serviceo App login', visible: true, width: 180, type: 'html' },
      { prop: 'region', name: 'Region', width: 150, visible: true, type: 'string', sortable: true },
      { prop: 'country', name: 'Country', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'state', name: 'State', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'city', name: 'City', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'geoMetro', name: 'GeoMetro', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'workerRegistrationStatus', name: 'Worker Registration Status', width: 180, type: 'string', visible: true, sortable: true }
    ];

    this.columns.vendorJobPreference = [
      { prop: 'region', name: 'Region', width: 100, visible: true, type: 'string', sortable: true, frozenLeft: true },
      { prop: 'country', name: 'Country', width: 130, type: 'string', visible: true, sortable: true, frozenLeft: true },
      { prop: 'state', name: 'State', width: 130, type: 'string', visible: true, sortable: true, frozenLeft: true },
      { prop: 'city', name: 'City', width: 130, type: 'string', visible: true, sortable: true, frozenLeft: true },
      { prop: 'program', name: 'Program', width: 200, type: 'string', visible: true, sortable: true },
      { prop: 'vendorName', name: 'Vendor Name', visible: true, width: 150, type: 'string' },
      { prop: 'workerNum', name: 'Worker #', visible: true, width: 120, type: 'string', sortable: true },
      { prop: 'wmId', name: 'WM Id', visible: true, width: 150, type: 'string' },
      { prop: 'workerName', name: 'Worker Name', visible: true, width: 150, type: 'string', sortable: true },
      { prop: 'enabledServiceo', name: 'Enable Serviceo App login', visible: true, width: 180, type: 'html' },
      { prop: 'workerRating', name: 'Worker Rating', visible: true, width: 140, type: 'starRating', sortable: true },
      { prop: 'vendorSubCommunityType', name: 'Vendor Sub-community Type', visible: true, width: 150, type: 'string', sortable: true },
      { prop: 'vendorMinimumHoursCommited', name: 'Vendor minimum Hours (Committed)', visible: true, width: 150, type: 'string', sortable: true },
      { prop: 'overallVendorMinimumHours', name: 'Overall Vendor minimum Hours (Geo Metro Allocated)', visible: true, width: 200, type: 'string', sortable: true },
      { prop: 'remainingHours', name: 'Remaining Hours (Geo Metro Unallocated)', visible: true, width: 200, type: 'string', sortable: true },
      { prop: 'vendorMinimumHours', name: 'Vendor minimum Hours (Geo Metro Allocated)', visible: true, width: 200, type: 'string', sortable: true },
      { prop: 'totalBilledHoursCurrentMonth', name: 'Total Billed Hours: Geo Metro (Current Month)', visible: true, width: 250, type: 'string', sortable: true },
      { prop: 'totalScheduledHoursCurrentMonth', name: 'Total Scheduled Hours: Geo Metro (Current Month)', visible: true, width: 250, type: 'string', sortable: true },
      { prop: 'vendorRemainingHoursCurrentMonth', name: 'Vendor Remaining Hours (Current Month)', visible: true, width: 250, type: 'string', sortable: true },
      { prop: 'vendorAssignmentPriority', name: 'Vendor Assignment Priority', visible: true, width: 250, type: 'string', sortable: true },
      { prop: 'overallBilledHoursLast3Months', name: 'Overall Billed Hours: Geo Metro (Last 3 Months)', visible: true, width: 250, type: 'string', sortable: true },
      { prop: 'overallHoursLastCalendarMonth', name: 'Overall Hours: Geo Metro (Last Calendar Month)', visible: true, width: 250, type: 'string', sortable: true },
    ];

    this.columns.apvpVMSWorkers = [
      { prop: 'vendorSiteName', name: 'Vendorsite Name', visible: true, width: 200, type: 'string', frozenLeft: true },
      { prop: 'accountName', name: 'Account Name', visible: true, width: 150, type: 'string', frozenLeft: true },
      { prop: 'workerNum', name: 'Worker #', visible: true, width: 120, type: 'string', sortable: true, frozenLeft: true },
      { prop: 'wmId', name: 'WM Id', visible: true, width: 150, type: 'string', frozenLeft: true },
      { prop: 'workerName', name: 'Worker Name', visible: true, width: 150, type: 'string', sortable: true },
      { prop: 'enabledServiceo', name: 'Enable Serviceo App login', visible: true, width: 180, type: 'html' },
      { prop: 'region', name: 'Region', width: 150, visible: true, type: 'string', sortable: true },
      { prop: 'country', name: 'Country', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'state', name: 'State', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'city', name: 'City', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'geoMetro', name: 'GeoMetro', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'workerRegistrationStatus', name: 'Worker Registration Status', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'jobRatingCount', name: 'Job Rating (Count)', width: 140, type: 'string', visible: true, sortable: true },
      { prop: 'professionalismRatingAvg', name: 'Professionalism Rating (Average)', width: 200, type: 'string', visible: true, sortable: true },
      { prop: 'satisfactionRatingAvg', name: 'Satisfaction Rating (Average)', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'onTimeRatingAvg', name: 'On-Time Rating (Average)', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'deliverablesRatingAvg', name: 'Deliverables Rating (Average)', width: 180, type: 'string', visible: true, sortable: true },
      { prop: 'communicationRatingAvg', name: 'Communication Rating (Average)', width: 180, type: 'string', visible: this.fromServiceDetailPage, sortable: true },
      { prop: 'qualityRatingAvg', name: 'Quality Rating (Average)', width: 180, type: 'string', visible: this.fromServiceDetailPage, sortable: true }
    ];

    this.columns.fteActive = [...this.columns.apvpVMSWorkers];

    this.columns.fteInActive = [...this.columns.apvpVMSWorkers];

    this.columns.fteExpired = [...this.columns.apvpVMSWorkers];
  }

  hideColumnForAPVPWorkerSummaryList() {
    if (this.fromServiceDetailPage) {
      const hiddenColumn = ['workerHasJobSlot', 'workerAvailability', 'workerMySchedule', 'workOfferRequest'];
      this.columns.apvpWorkersSummary = this.columns.workerAssignment.filter(col => !hiddenColumn.includes(col.prop));
    } else {
      const hiddenColumn = ['MessageSendTo', 'MessageSentDateTime', 'MessageReplyDateTime', 'MessageReplyFrom', 'MessageReplyText', 'checkAvailability'];
      this.columns.apvpWorkersSummary = this.columns.workerAssignment.filter(col => !hiddenColumn.includes(col.prop));
    }

  }

  transform(value: string, type?) {
    let dateformate: string;
    type ? dateformate = type : dateformate = 'short';
    this.datePipe = new DatePipe('en-US');
    const formateddate = (value ? this.datePipe.transform(value, dateformate) : value);
    return formateddate;
  }

  prepareData(tabName, data) {
    if (tabName === 'vendorAssignment') {
      return data.map(i => ({
        accountName: i.account && i.account.Name,
        vendorSubCommunityType: i.account && i.account.Community_Type__c,
        status: i.Status__c,
        metroVirtualVendorPool: i.MetroVirtualVendorPool && i.MetroVirtualVendorPool.Name,
        routingRating: i.Metro_Rating__c,
        ratingWeightedAverageAuto: i.Rating_Weighted_Average_Auto__c,
        vendorProgramPerformanceHealthRating: i.Vendor_Program_Performance_Health_Rating__c,
        seqNum: i.Rank__c,
        Project_Priority_Rating__c: i.Project_Priority_Rating__c,
        projectPriorityRating: i.Project_Priority_Rating__c,
        poolHealthRating: i.project && i.project.Pool_General_Health_Rating__c
      }));
    } else if (tabName === 'workerAssignment') {
      return data.map(i => ({
        workerSfdcId: i.W__sfdcId || '',
        vendorName: i.APVPAccount__Name || '',
        workerNum: i.W__DispatchWorkerNum || '',
        hasISGEmail: i.W__hasISGEmail ? 'Yes' : 'No',
        blacklisted: i.W__Blacklisted ? 'Yes' : 'No',
        wmId: i.W__WMWorkerId,
        workerHasJob: (i.worker && i.worker.appointment && i.worker.appointment.length && i.worker.appointment[0].Job && i.worker.appointment[0].Job.Iron_Job_num__c && (i.worker.appointment[0].Job.Job_Status_Internal__c != 'Cancelled'))
          ? i.worker.appointment[0].Job.Iron_Job_num__c + ' - ' + i.worker.appointment[0].Job.Jobsite_Name__c + ' - '
          + (i.worker.appointment[0]['Worker_Arrival_DateTime_Cust_Requested__c'] ? this._commonService.dateFormate(i.worker.appointment[0]['Worker_Arrival_DateTime_Cust_Requested__c'], i.worker.appointment[0].Job.jobsite.Time_Zone__c, 'L LT') : '')
          + (i.worker.appointment[0]['Worker_Arrival_Date_Customer_Req_End__c'] ? " - " + this._commonService.dateFormate(i.worker.appointment[0]['Worker_Arrival_Date_Customer_Req_End__c'], i.worker.appointment[0].Job.jobsite.Time_Zone__c, 'L LT') : '') : '',
        enabledServiceo: i.Enable_App_Login__c ? this.getEnableLoginIcon(i.Enable_App_Login__c) : this.getEnableLoginIcon(false),
        tempBlocked: i.W__tempBlocked && i.W__tempBlocked > 0 ? 'Yes' : 'No',
        workerName: i.W__Name || '',
        workerRating: i.W__WorkerRating,
        seqNum: i.APVP__Rank,
        region: i.GM__Region,
        country: i.GM__Country,
        state: i.GM__State,
        city: i.GM__City,
        projectWorkerRating: i.AWV__ProjectWorkerRating,
        ratingWeightedAverageAuto: i.APVP__RatingWeightedAverageAuto,
        jobsiteName: this.jobDetails && this.jobDetails.jobsite && this.jobDetails.jobsite.Name,
        vendorSubCommunityType: i.APVPAccount__CommunityType,
        status: i.APVP__Status,
        poolGeneralHealthRating: i.P__PoolGeneralHealthRating,
        vendorProgramPerformanceHealthRating: i.APVP__VendorProgramPerformanceHealthRating,
        createdDate: i.CreatedDate && this.transform(i.AWV__CreatedDate),
        travelDistance: '', // Pending
        travelTime: '',  // Pending
        workerTravelRisk: '',  // Pending
        workerHasJobSlot: '',  // Pending
        workerAvailability: 'noAvailabilityData',
        workerMySchedule: 'noAvailabilityData',
        workOfferRequest: '',  // Pending
        jobsiteWorkerJobs_7D: '',
        jobsiteCurrentMonth: '',
        JobsiteWorkerJobsTotal: '',
        WorkerJobsLastSevenDays: '',
        WorkerJobsCurrentMonth: '',
        WorkerJobsTotal: '',
        workerPhoneNumber: i.W__WorkPhoneNumber || ''
      }));
    } else if (tabName === 'apvpVFMSVendors') {
      return data.map(i => ({
        // seqNum: i.Rank__c,
        accountName: i.worker && i.worker.contact && i.worker.contact.account && i.worker.contact.account.Name,
        vendorSubCommunityType: i.worker && i.worker.contact && i.worker.contact.account && i.worker.contact.account.Community_Type__c,
        vendorAccountSfdcId: i.worker && i.worker.contact && i.worker.contact.account && i.worker.contact.account.sfdcId,
        status: i.status,
        metroVirtualVendorPool: i.metroVirtualVendorPool,
        projectPriorityRating: i.Project_Worker_Rating__c,
        routingRating: i.routingRating,
        ratingWeightedAverageAuto: i.Rating_Weighted_Average_Auto__c,
        poolGeneralHealthRating: i.routingProfile && i.routingProfile.Pool_General_Health_Rating__c,
        vendorProgramPerformanceHealthRating: i.vendorProgramPerformanceHealthRating__c
      }));
    } else if (tabName === 'apvpVFMSWorkers') {
      return data.map(i => ({
        name: i.Name,
        workerAccount: i.worker && i.worker.contact && i.worker.contact.account && i.worker.contact.account.Name,
        workerNum: i.worker && i.worker.Dispatch_Worker_num__c,
        workerName: i.worker && i.worker.Name,
        enabledServiceo: i.worker && i.worker.contact && i.worker.contact.Enable_App_Login__c ? this.getEnableLoginIcon(i.worker.contact.Enable_App_Login__c) : this.getEnableLoginIcon(false),
        wmId: i.worker && i.worker.WM_Worker_Id__c,
        workerContactType: i.Worker_Contact_Type__c,
        projectWorkerRating: i.Project_Worker_Rating__c,
        ratingWeightedAverageAuto: i.Rating_Weighted_Average_Auto__c,
        createdDate: i.CreatedDate && this.transform(i.CreatedDate),
        jobRatingCount: i.worker && i.worker.Job_Rating_Count__c,
        professionalismRatingAvg: i.worker && i.worker.Professionalism_Rating_Average__c,
        satisfactionRatingAvg: i.worker && i.worker.Satisfaction_Rating_Average__c,
        onTimeRatingAvg: i.worker && i.worker.On_Time_Rating_Average__c,
        deliverablesRatingAvg: i.worker && i.worker.Deliverables_Rating_Average__c,
        communicationRatingAvg: i.worker && i.worker.Communication_Rating_Average__c,
        qualityRatingAvg: i.worker && i.worker.Quality_Rating_Average__c
      }));
    } else if (tabName === 'mvvpWorkers') {
      return data.map(i => ({
        vendorSiteName: i.Vendorsite__Name,
        accountName: i.W__AccountName,
        workerNum: i.W__DispatchWorkerNum,
        wmId: i.W__WMWorkerId,
        enabledServiceo: i.Enable_App_Login__c ? this.getEnableLoginIcon(i.Enable_App_Login__c) : this.getEnableLoginIcon(false),
        workerName: i.W__Name,
        region: i.GM__Region,
        country: i.GM__Country,
        state: i.GM__State,
        city: i.GM__City,
        geoMetro: i.GM__Name,
        workerRegistrationStatus: i.AWV__WorkerRegistrationStatus
      }));
    } else if (tabName === 'vendorJobPreference') {
      if (this.fromServiceDetailPage) {
        return data.map(i => ({
          region: i.GM__Region,
          country: i.GM__Country,
          state: i.GM__State,
          city: i.GM__City,
          program: i.routingProfile && i.routingProfile.Name,
          vendorName: i.APVPAccount__Name,
          workerNum: i.W__DispatchWorkerNum,
          workerName: i.W__Name,
          wmId: i.W__WMWorkerId,
          workerRating: i.W__WorkerRating,
          vendorSubCommunityType: i.APVPAccount__CommunityType,
          vendorMinimumHoursCommited: i.VendorAccount__VendorMinimumHoursCommitted,
          overallVendorMinimumHours: i.VendorAccount__OverallVendorMinHrsGeoAllocated,
          remainingHours: i.VendorAccount__RemainingHoursGeoMetroUnallocated,
          vendorMinimumHours: i.Vendorsite__VendorMinimumHoursGeoMetroAllocated,
          totalBilledHoursCurrentMonth: i.Vendorsite__TotalBilledHoursGeoCurrentMonth,
          totalScheduledHoursCurrentMonth: i.Vendorsite__TotalScheduledHoursGeoCurrentMonth,
          vendorRemainingHoursCurrentMonth: i.VendorAccount__VendorRemainingHoursCurrentMonth,
          vendorAssignmentPriority: i.VendorAccount__VendorAssignmentPriority,
          overallBilledHoursLast3Months: i.Vendorsite__OverallBilledHoursGeoLast3Month,
          overallHoursLastCalendarMonth: i.Vendorsite__OverallHoursGeoMetroLastCalMnth
        }));
      } else {
        return data.map(i => ({
          region: i.GM__Region,
          country: i.GM__Country,
          state: i.GM__State,
          city: i.GM__City,
          program: this.jobDetails && this.jobDetails.program && this.jobDetails.program.Name,
          vendorName: i.APVPAccount__Name,
          workerNum: i.W__DispatchWorkerNum,
          workerName: i.W__Name,
          wmId: i.W__WMWorkerId,
          enabledServiceo: i.Enable_App_Login__c ? this.getEnableLoginIcon(i.Enable_App_Login__c) : this.getEnableLoginIcon(false),
          workerRating: i.W__WorkerRating,
          vendorSubCommunityType: i.APVPAccount__CommunityType,
          vendorMinimumHoursCommited: i.APVPAccount__VendorMinimumHoursCommitted,
          overallVendorMinimumHours: i.APVPAccount__OverallVendorMinHrsGeoAllocated,
          remainingHours: i.APVPAccount__RemainingHoursGeoMetroUnallocated,
          vendorMinimumHours: i.Vendorsite__VendorMinimumHoursGeoMetroAllocated,
          totalBilledHoursCurrentMonth: i.Vendorsite__TotalBilledHoursGeoCurrentMonth,
          totalScheduledHoursCurrentMonth: i.Vendorsite__TotalScheduledHoursGeoCurrentMonth,
          vendorRemainingHoursCurrentMonth: i.APVPAccount__VendorRemainingHoursCurrentMonth,
          vendorAssignmentPriority: i.APVPAccount__VendorAssignmentPriority,
          overallBilledHoursLast3Months: i.Vendorsite__OverallBilledHoursGeoLast3Month,
          overallHoursLastCalendarMonth: i.worker && i.worker.vendorsite && i.worker.vendorsite.Overall_Hours_Geo_Metro_Last_Cal_Mnth__c
        }));
      }
    } else if (tabName === 'apvpVMSWorkers') {
      return data.map(i => ({
        vendorSiteName: i.worker && i.worker.vendorsite && i.worker.vendorsite.Name,
        accountName: i.worker && i.worker.Account_Name__c,
        workerNum: i.worker && i.worker.Dispatch_Worker_num__c,
        wmId: i.worker && i.worker.WM_Worker_Id__c,
        enabledServiceo: i.worker && i.worker.contact && i.worker.contact.Enable_App_Login__c ? this.getEnableLoginIcon(i.worker.contact.Enable_App_Login__c) : this.getEnableLoginIcon(false),
        workerName: i.worker && i.worker.Name,
        region: i.worker && i.worker.vendorsite && i.worker.vendorsite.GeoMetro && i.worker.vendorsite.GeoMetro.Region__c,
        country: i.worker && i.worker.vendorsite && i.worker.vendorsite.GeoMetro && i.worker.vendorsite.GeoMetro.Country__c,
        state: i.worker && i.worker.vendorsite && i.worker.vendorsite.GeoMetro && i.worker.vendorsite.GeoMetro.State_Province__c,
        city: i.worker && i.worker.vendorsite && i.worker.vendorsite.GeoMetro && i.worker.vendorsite.GeoMetro.City__c,
        geoMetro: i.worker && i.worker.vendorsite && i.worker.vendorsite.GeoMetro && i.worker.vendorsite.GeoMetro.Name,
        workerRegistrationStatus: i.Worker_Registration_Status__c,
        jobRatingCount: i.worker && i.worker.Job_Rating_Count__c,
        professionalismRatingAvg: i.worker && i.worker.Professionalism_Rating_Average__c,
        satisfactionRatingAvg: i.worker && i.worker.Satisfaction_Rating_Average__c,
        onTimeRatingAvg: i.worker && i.worker.On_Time_Rating_Average__c,
        deliverablesRatingAvg: i.worker && i.worker.Deliverables_Rating_Average__c,
        communicationRatingAvg: i.worker && i.worker.Communication_Rating_Average__c,
        qualityRatingAvg: i.worker && i.worker.Quality_Rating_Average__c,
      }));
    } else if (tabName === 'fteWorker') {
      return data.map(i => ({
        vendorSiteName: i.vendorsiteName || '',
        accountName: i.Account_Name__c || '',
        workerNum: i.Dispatch_Worker_num__c || '',
        workerName: i.workerName || '',
        region: i.Region__c || '',
        country: i.GEO_Country__c || '',
        state: i.State_Province__c || '',
        city: i.City__c || '',
        geoMetro: i.geoName || '',
        workerRegistrationStatus: '',
        jobRatingCount: i.Job_Rating_Count__c || '',
        professionalismRatingAvg: i.Professionalism_Rating_Average__c || '0.00',
        satisfactionRatingAvg: i.Satisfaction_Rating_Average__c || '0.00',
        onTimeRatingAvg: i.On_Time_Rating_Average__c || '0.00',
        deliverablesRatingAvg: i.Deliverables_Rating_Average__c || '0.00',
        communicationRatingAvg: i.Communication_Rating_Average__c || '0.00',
        qualityRatingAvg: i.Quality_Rating_Average__c || '0.00'
      }));
    }
  }

  loadData(modelName, offset?, additional?) {
    const serviceContract = [
      {
        relation: 'serviceContractLineItem',
        scope: {
          fields: ['sfdcId', 'Jobsite__c', 'Worker__c'],
          include: [
            {
              relation: 'worker',
              scope: {
                fields: ['sfdcId', 'Account_Name__c', 'Dispatch_Worker_num__c',
                  'Name', 'Job_Rating_Count__c', 'Professionalism_Rating_Average__c',
                  'Satisfaction_Rating_Average__c', 'On_Time_Rating_Average__c', 'Deliverables_Rating_Average__c',
                  'Communication_Rating_Average__c', 'Quality_Rating_Average__c',
                  'Vendorsite__c'],
                include: [
                  {
                    relation: 'vendorsite',
                    scope: {
                      fields: ['sfdcId', 'Name', 'GEO_Metro__c'],
                      include: [
                        {
                          relation: 'GeoMetro',
                          scope: {
                            fields: ['sfdcId', 'Name']
                          }
                        }
                      ]
                    }
                  }
                ]
              }
            },
            {
              relation: 'jobsite',
              scope: {
                fields: ['sfdcId', 'GEO_Metro__c'],
                include: [
                  {
                    relation: 'GeoMetro',
                    scope: {
                      fields: ['sfdcId', 'Country_Code__c', 'State_Province__c', 'City__c'],
                      include: [
                        {
                          relation: 'countrycode',
                          scope: {
                            fields: ['sfdcId', 'Region__c', 'Country__c']
                          }
                        }
                      ]
                    }
                  }
                ]
              }
            }
          ]
        }
      }
    ]
    if (modelName === 'ApprovedProjectVendorPool') {
      return this._approvedProjectVendorPoolApi.find({
        fields: ['sfdcId', 'Vendor__c', 'Status__c', 'Metro_Rating__c', 'Rating_Weighted_Average_Auto__c',
          'Vendor_Program_Performance_Health_Rating__c', 'Project__c', 'Metro_Virtual_Vendor_Pool__c', 'Rank__c', 'Project_Priority_Rating__c'],
        where: { Project__c: this.projectProfileSfdcId, Status__c: 'Approved' },
        include: [
          {
            relation: 'account',
            scope: {
              fields: ['Name', 'Community_Type__c']
            }
          },
          {
            relation: 'MetroVirtualVendorPool',
            scope: {
              fields: ['Name']
            }
          },
          {
            relation: 'project',
            scope: {
              fields: ['sfdcId', 'Pool_General_Health_Rating__c']
            }
          },
        ],
        skip: offset,
        limit: this.itemsPerBatch
      });
    } else if (modelName === 'ApvpWorkersVms') {
      return this._apvpWorkersVmsApi.getApvpWorkerListData({
        where: {
          Project_Routing_Profile__c: this.projectProfileSfdcId,
          Onboarding_Status__c: 'Completed',
          Vendor_Account_Id: this.jobDetails && this.jobDetails.Job && this.jobDetails.Job.Vendor__c ? this.jobDetails.Job.Vendor__c : '',
          Requires_Registered_Workers__c: this.jobDetails.program && this.jobDetails.program.Program_Requires_Registered_Workers__c ?
            this.jobDetails.program.Program_Requires_Registered_Workers__c : false,
          Status_Worker__c: 'Approved',
        },
        skip: offset,
        limit: this.itemsPerBatch
      })
      // return this._apvpWorkersVmsApi.find({
      //   fields: ['APVP_Accounts_VMS__c', 'Project_Routing_Profile__c', 'Worker__c', 'Project_Worker_Rating__c', 'CreatedDate', 'Worker_Registration_Status__c'],
      //   where: { Project_Routing_Profile__c: this.projectProfileSfdcId, Onboarding_Status__c: 'Completed', Status_Worker__c: 'Approved', ...(additional && additional.where) },
      //   include: [
      //     {
      //       relation: 'apvpAccount',
      //       scope: {
      //         fields: ['sfdcId', 'Vendor__c', 'Rank__c', 'Rating_Weighted_Average_Auto__c', 'Status__c',
      //           'Vendor_Program_Performance_Health_Rating__c'],
      //         include: [
      //           {
      //             relation: 'account',
      //             scope: {
      //               fields: ['Name', 'Community_Type__c', 'Vendor_minimum_Hours_Committed__c',
      //                 'Overall_Vendor_min_Hrs_Geo_Allocated__c', 'Remaining_Hours_Geo_Metro_Unallocated__c',
      //                 'Vendor_Remaining_Hours_Current_Month__c', 'Vendor_Assignment_Priority__c']
      //             }
      //           }
      //         ]
      //       }
      //     },
      //     {
      //       relation: 'worker',
      //       scope: {
      //         fields: ['sfdcId', 'Dispatch_Worker_num__c', 'Name', 'Worker_Rating__c', 'Vendorsite__c', 'Account_Name__c', 'Worker__c', 'WM_Worker_Id__c', 'Contact__c',
      //           ...(additional && additional.workerFields)],
      //         include: [
      //           {
      //             relation: 'vendorsite',
      //             scope: {
      //               fields: ['GEO_Metro__c', 'Name', 'Vendor_minimum_Hours_GeoMetro_Allocated__c',
      //                 'Total_Billed_Hours_Geo_Current_Month__c', 'Total_Scheduled_Hours_Geo_Current_month__c',
      //                 'Overall_Billed_Hours_Geo_Last_3_Month__c', 'Overall_Hours_Geo_Metro_Last_Cal_Mnth__c', 'Vendor__c'],
      //               include: [
      //                 {
      //                   relation: 'GeoMetro',
      //                   scope: {
      //                     fields: ['Region__c', 'Country__c', 'State_Province__c', 'City__c', 'Name']
      //                   }
      //                 },
      //                 {
      //                   relation: 'Account',
      //                   scope: {
      //                     fields: ['Vendor_minimum_Hours_Committed__c', 'Overall_Vendor_min_Hrs_Geo_Allocated__c', 'Remaining_Hours_Geo_Metro_Unallocated__c',
      //                       'Vendor_Remaining_Hours_Current_Month__c', 'Vendor_Assignment_Priority__c']
      //                   }
      //                 }
      //               ]
      //             }
      //           },
      //           {
      //             relation: 'availability',
      //             scope: {
      //               fields: ['sfdcId'],
      //               where: { 'Available__c': true, Source__c: 'MWA' },
      //             }
      //           },
      //           {
      //             relation: 'appointment',
      //             scope: {
      //               fields: ['sfdcId', 'Job__c', 'Worker_Arrival_DateTime_Cust_Requested__c', 'Worker_Arrival_Date_Customer_Req_End__c'],
      //               // where: {
      //               //   "or": [
      //               //     { "Worker_Arrival_DateTime_Cust_Requested__c": { between: [this.Worker_Arrival_DateTime_Cust_Requested__c, this.Worker_Arrival_Date_Customer_Req_End__c] } },
      //               //     { "Worker_Arrival_Date_Customer_Req_End__c": { between: [this.Worker_Arrival_DateTime_Cust_Requested__c, this.Worker_Arrival_Date_Customer_Req_End__c] } }
      //               //   ]
      //               // },
      //               include: [
      //                 {
      //                   relation: 'Job',
      //                   scope: {
      //                     fields: ['Iron_Job_num__c', 'Job_Status_Internal__c', 'Jobsite_Name__c','Jobsite__c'],
      //                     include: [
      //                       {
      //                         relation: 'jobsite',
      //                         scope: {
      //                           fields: ['Time_Zone__c', 'sfdcId']
      //                         }
      //                       }
      //                     ]
      //                   }
      //                 }
      //               ]
      //             }
      //           },
      //           {
      //             relation: 'contact',
      //             scope: {
      //               fields: ['sfdcId', 'Enable_App_Login__c'],
      //               where: { 'Available__c': true, Source__c: 'MWA' },
      //             }
      //           },
      //           {
      //             relation: 'projectWorker',
      //             scope: {
      //               fields: ['sfdcId', 'Blocked__c'],
      //               where: { 'Blocked__c': true },
      //             }
      //           }
      //         ]
      //       }
      //     },
      //     {
      //       relation: 'routingProfile',
      //       scope: {
      //         fields: ['Pool_General_Health_Rating__c', 'Name']
      //       }
      //     }
      //   ],
      //   skip: offset,
      //   limit: this.itemsPerBatch
      // });
    } else if (modelName === 'ProjectApvpWorkersVms') {
      const query = {
        project: this.jobDetails && this.jobDetails.program && this.jobDetails.program.sfdcId,
        filter: {
          fields: ['APVP_Accounts_VMS__c', 'Project_Routing_Profile__c', 'Worker__c', 'Project_Worker_Rating__c', 'CreatedDate', 'Worker_Registration_Status__c'],
          where: { Project_Routing_Profile__c: this.projectProfileSfdcId, Worker_Registration_Status__c: 'Registered' },
          include: [
            {
              relation: 'apvpAccount',
              scope: {
                fields: ['sfdcId', 'Vendor__c', 'Rank__c', 'Rating_Weighted_Average_Auto__c', 'Status__c',
                  'Vendor_Program_Performance_Health_Rating__c'],
                include: [
                  {
                    relation: 'account',
                    scope: {
                      fields: ['Name', 'Community_Type__c', 'Vendor_minimum_Hours_Committed__c',
                        'Overall_Vendor_min_Hrs_Geo_Allocated__c', 'Remaining_Hours_Geo_Metro_Unallocated__c',
                        'Vendor_Remaining_Hours_Current_Month__c', 'Vendor_Assignment_Priority__c']
                    }
                  }
                ]
              }
            },
            {
              relation: 'worker',
              scope: {
                fields: ['sfdcId', 'Dispatch_Worker_num__c', 'Name', 'Worker_Rating__c', 'Vendorsite__c', 'Account_Name__c', 'Worker__c',
                  'Job_Rating_Count__c', 'Satisfaction_Rating_Average__c', 'Deliverables_Rating_Average__c', 'WM_Worker_Id__c', 'Contact__c'],
                include: [
                  {
                    relation: 'vendorsite',
                    scope: {
                      fields: ['GEO_Metro__c', 'Name', 'Vendor_minimum_Hours_GeoMetro_Allocated__c',
                        'Total_Billed_Hours_Geo_Current_Month__c', 'Total_Scheduled_Hours_Geo_Current_month__c',
                        'Overall_Billed_Hours_Geo_Last_3_Month__c', 'Overall_Hours_Geo_Metro_Last_Cal_Mnth__c', 'Vendor__c'],
                      include: [
                        {
                          relation: 'GeoMetro',
                          scope: {
                            fields: ['Region__c', 'Country__c', 'State_Province__c', 'City__c', 'Name']
                          }
                        },
                        {
                          relation: 'Account',
                          scope: {
                            fields: ['Vendor_minimum_Hours_Committed__c', 'Overall_Vendor_min_Hrs_Geo_Allocated__c', 'Remaining_Hours_Geo_Metro_Unallocated__c',
                              'Vendor_Remaining_Hours_Current_Month__c', 'Vendor_Assignment_Priority__c']
                          }
                        }
                      ]
                    }
                  },
                  {
                    relation: 'availability',
                    scope: {
                      fields: ['sfdcId'],
                      where: { 'Available__c': true, Source__c: 'MWA' },
                    }
                  },
                  {
                    relation: 'contact',
                    scope: {
                      fields: ['sfdcId', 'Enable_App_Login__c'],
                      where: { 'Available__c': true, Source__c: 'MWA' },
                    }
                  }
                ]
              }
            },
            {
              relation: 'routingProfile',
              scope: {
                fields: ['Pool_General_Health_Rating__c', 'Name']
              }
            }
          ],
          skip: offset,
          limit: this.itemsPerBatch
        }
      }
      return this._apvpWorkersVmsApi.getProjectApvpWorkers(query);
    } else if (modelName === 'ApvpDistributionListWorkers') {
      return this._apvpDistributionListWorkersApi.find({
        fields: ['Status__c', 'Worker_Account__c', 'Project_Worker_Rating__c', 'Rating_Weighted_Average_Auto__c',
          'Routing_Profile__c', 'Worker__c', 'Worker_Contact_Type__c', 'Name', 'CreatedDate'],
        where: { Routing_Profile__c: this.projectProfileSfdcId },
        include: [
          {
            relation: 'routingProfile',
            scope: {
              fields: ['Pool_General_Health_Rating__c']
            }
          },
          {
            relation: 'worker',
            scope: {
              fields: ['Dispatch_Worker_num__c', 'Name', 'Contact__c', 'Job_Rating_Count__c', 'Professionalism_Rating_Average__c',
                'Satisfaction_Rating_Average__c', 'On_Time_Rating_Average__c', 'Deliverables_Rating_Average__c', 'WM_Worker_Id__c', 'Contact__c',
                'Communication_Rating_Average__c', 'Quality_Rating_Average__c'],
              include: [
                {
                  relation: 'contact',
                  scope: {
                    fields: ['AccountId', 'Enable_App_Login__c'],
                    include: [
                      {
                        relation: 'account',
                        scope: {
                          fields: ['sfdcId', 'Name', 'Community_Type__c']
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ],
        skip: offset,
        limit: this.itemsPerBatch
      });
    } else if (modelName === 'ProjectWorker') {
      return this._projectWorkersApi.find({
        fields: ['Worker__c'],
        where: { Project__c: this.jobDetails && this.jobDetails.program && this.jobDetails.program.sfdcId },
      });
    } else if (modelName === 'Project') {
      return this._projectApi.find({
        fields: ['Number_of_Vendors_3__c', 'At_least_one_VFMS_Worker__c', 'Health_Vendor_Status__c'],
        where: { Project_Routing_Profile__c: this.projectProfileSfdcId },
      });
    } else if (modelName === 'FTEWorker') {
      return this._serviceContractLineItemApi.getFteWorker({
        GEO_Code__c: this.jobDetails && this.jobDetails.jobsite && this.jobDetails.jobsite.GEO_Metro__c
      });
    }
  }

  getData() {
    this.isLoading = true;
    this._loader.showPreloader();
    forkJoin(
      this.loadData('ApprovedProjectVendorPool', 0),
      this.loadData('ApvpWorkersVms', 0),
      this.getTotalCount('ApprovedProjectVendorPool', { Project__c: this.projectProfileSfdcId }),
      this.getTotalCount('ApvpWorkersVms'),
      this.loadData('ApvpDistributionListWorkers', 0),
      this.getTotalCount('ApvpDistributionListWorkers'),
      this.getTotalCount('ApprovedProjectVendorPool', { Status__c: 'Approved' }),
      // this.loadData('ProjectWorker'), 
      this.loadData('ProjectApvpWorkersVms'),
      this.getTotalCount('ProjectApvpWorkersVms', {}))
      .flatMap((results) => {
        const observables = [of(results)];
        if (results[4] && results[4].length) {
          const vendorAccountSfdcIds = results[4].reduce((a, c) => {
            if (c.worker && c.worker.contact && c.worker.contact.account && c.worker.contact.account.sfdcId &&
              a.indexOf(c.worker.contact.account.sfdcId) === -1) {
              a.push(c.worker.contact.account.sfdcId);
            }
            return a;
          }, []);
          if (vendorAccountSfdcIds.length) {
            observables.push(this.getCorrespondingApvpRecordDetails(vendorAccountSfdcIds));
          }
        }

        // if (results[7] && results[7].length) {
        //   const workersUnderCurrentProgram = results[7].reduce((a, c) => {
        //     if (c && c.Worker__c) {
        //         if (a.indexOf(c.Worker__c) === -1) {
        //             a.push(c.Worker__c);
        //         }
        //     }
        //     return a;
        //     }, []);
        // if (workersUnderCurrentProgram.length) {
        // const where = { Worker_Registration_Status__c: 'Registered', Worker__c: { inq: workersUnderCurrentProgram }};
        // this.additionalFindObjForApvpVMSWorkers = { where,
        // workerFields: ['Job_Rating_Count__c', 'Satisfaction_Rating_Average__c', 'Deliverables_Rating_Average__c']
        // };
        // observables.push(this.loadData('ApvpWorkersVms', 0,
        // this.additionalFindObjForApvpVMSWorkers
        // ));
        // observables.push(this.getTotalCount('ProjectApvpWorkersVms', where));
        // }
        // }
        return forkJoin(...observables);
      })
      .subscribe(
        ([[apvp, apvpWorkerVms, apvpCountObj, apvpWorkerVmsCountObj, apvpVFMSVendors, apvpVFMSVendorsCountObj,
          approvedApvpVendorCountObj, apvpVMSWorkers, apvpVMSWorkersCountObj], correspondingApvpRecordDetails]: any) => {
          if (apvp.length) {
            this.tableData.vendorAssignment = this.prepareData('vendorAssignment', apvp);
            this.tableData.apvpVendors = [...this.tableData.vendorAssignment];
          }
          this.totalCount.vendorAssignment = apvpCountObj.count;
          this.noRecords.vendorAssignment = apvp.length < this.itemsPerBatch ? true : false;
          this.allItems.vendorAssignment = this.tableData.vendorAssignment.slice();
          this.totalCount.apvpVendors = apvpCountObj.count;
          this.noRecords.apvpVendors = apvp.length < this.itemsPerBatch ? true : false;
          this.allItems.apvpVendors = this.tableData.vendorAssignment.slice();
          if (apvpWorkerVms && apvpWorkerVms.length) {
            this.tableData.workerAssignment = this.prepareData('workerAssignment', apvpWorkerVms);
            this.tableData.apvpWorkersSummary = [...this.tableData.workerAssignment];
            this.tableData.mvvpWorkers = this.prepareData('mvvpWorkers', apvpWorkerVms);
            this.tableData.vendorJobPreference = this.prepareData('vendorJobPreference', apvpWorkerVms);
          }
          this.totalCount.workerAssignment = apvpWorkerVmsCountObj.count;
          this.noRecords.workerAssignment = apvpWorkerVms && apvpWorkerVms.length < this.itemsPerBatch ? true : false;
          this.allItems.workerAssignment = this.tableData.workerAssignment.slice();
          this.totalCount.apvpWorkersSummary = apvpWorkerVmsCountObj.count;
          this.noRecords.apvpWorkersSummary = apvpWorkerVms && apvpWorkerVms.length < this.itemsPerBatch ? true : false;
          this.allItems.apvpWorkersSummary = this.tableData.workerAssignment.slice();
          this.totalCount.mvvpWorkers = apvpWorkerVmsCountObj.count;
          this.noRecords.mvvpWorkers = apvpWorkerVms && apvpWorkerVms.length < this.itemsPerBatch ? true : false;
          this.allItems.mvvpWorkers = this.tableData.mvvpWorkers.slice();
          this.totalCount.vendorJobPreference = apvpWorkerVmsCountObj.count;
          this.noRecords.vendorJobPreference = apvpWorkerVms && apvpWorkerVms.length < this.itemsPerBatch ? true : false;
          this.allItems.vendorJobPreference = this.tableData.vendorJobPreference.slice();
          if (apvpVFMSVendors && apvpVFMSVendors.length) {
            apvpVFMSVendors.forEach((i) => {
              const apvpRecord = correspondingApvpRecordDetails && correspondingApvpRecordDetails.find((apvpRec) => apvpRec.Vendor__c ===
                (i.worker && i.worker.contact && i.worker.contact.account && i.worker.contact.account.sfdcId));
              if (apvpRecord) {
                i.status = apvpRecord.Status__c;
                i.routingRating = apvpRecord.Metro_Rating__c;
                i.metroVirtualVendorPool = apvpRecord.MetroVirtualVendorPool && apvpRecord.MetroVirtualVendorPool.Name;
              }
            });
            this.tableData.apvpVFMSVendors = this.prepareData('apvpVFMSVendors', apvpVFMSVendors);
            this.tableData.apvpVFMSWorkers = this.prepareData('apvpVFMSWorkers', apvpVFMSVendors);
          }
          this.totalCount.apvpVFMSVendors = apvpVFMSVendorsCountObj ? apvpVFMSVendorsCountObj.count : 0;
          this.noRecords.apvpVFMSVendors = apvpVFMSVendors && apvpVFMSVendors.length < this.itemsPerBatch ? true : false;
          this.allItems.apvpVFMSVendors = this.tableData.apvpVFMSVendors.slice();
          this.totalCount.apvpVFMSWorkers = apvpVFMSVendorsCountObj ? apvpVFMSVendorsCountObj.count : 0;
          this.noRecords.apvpVFMSWorkers = apvpVFMSVendors && apvpVFMSVendors.length < this.itemsPerBatch ? true : false;
          this.allItems.apvpVFMSWorkers = this.tableData.apvpVFMSWorkers.slice();
          this.approvedApvpVendorCount = approvedApvpVendorCountObj.count;

          if (apvpVMSWorkers && apvpVMSWorkers.length) {
            this.tableData.apvpVMSWorkers = this.prepareData('apvpVMSWorkers', apvpVMSWorkers);
          }
          this.totalCount.apvpVMSWorkers = apvpVMSWorkersCountObj ? apvpVMSWorkersCountObj.count : 0;
          this.noRecords.apvpVMSWorkers = apvpVMSWorkers && apvpVMSWorkers.length < this.itemsPerBatch ? true : false;
          this.allItems.apvpVMSWorkers = this.tableData.apvpVMSWorkers.slice();
          this.setProjectProfileHealthSummary();
          this.getWorkerAvailabilityData();
        },
        (error) => {
          this.isLoading = false;
          this._loader.hidePreloader();
        },
        () => {
          this.isLoading = false;
          this._loader.hidePreloader();
        }
      );
  }

  getDataFromServiceManager() {
    this.isLoading = true;
    this._loader.showPreloader();
    forkJoin(
      this.loadData('ApprovedProjectVendorPool', 0),
      this.loadData('ApvpWorkersVms', 0),
      this.getTotalCountForSMC('ApprovedProjectVendorPool', { Project__c: this.projectProfileSfdcId, Status__c: 'Approved' }),
      this.getTotalCountForSMC('ApprovedProjectVendorPool', { Project__c: this.projectProfileSfdcId }),
      this.getTotalCountForSMC('ApvpWorkersVms'),
      this.loadData('ApvpDistributionListWorkers', 0),
      this.getTotalCountForSMC('ApvpDistributionListWorkers', { Routing_Profile__c: this.projectProfileSfdcId }),
      this.getTotalCountForSMC('ApprovedProjectVendorPool', { Status__c: 'Approved' }),
      this.loadData('Project'),
      // this.loadData('ProjectWorker'),
      this.loadData('FTEWorker', 0),
      this.loadData('ProjectApvpWorkersVms'),
      this.getTotalCount('ProjectApvpWorkersVms', {})
    ).flatMap((results) => {
      const observables = [of(results)];
      if (results[5] && results[5].length) {
        const vendorAccountSfdcIds = results[5].reduce((a, c) => {
          if (c.worker && c.worker.contact && c.worker.contact.account && c.worker.contact.account.sfdcId &&
            a.indexOf(c.worker.contact.account.sfdcId) === -1) {
            a.push(c.worker.contact.account.sfdcId);
          }
          return a;
        }, []);
        if (vendorAccountSfdcIds.length) {
          observables.push(this.getCorrespondingApvpRecordDetails(vendorAccountSfdcIds));
        }
      }

      // if (results[8] && results[9].length) {
      //   const workersUnderCurrentProgram = results[9].reduce((a, c) => {
      //     if (c && c.Worker__c) {
      //       if (a.indexOf(c.Worker__c) === -1) {
      //         a.push(c.Worker__c);
      //       }
      //     }
      //     return a;
      //   }, []);
      //   if (workersUnderCurrentProgram.length) {
      //     const where = { Worker_Registration_Status__c: 'Registered', Worker__c: { inq: workersUnderCurrentProgram } };
      //     this.additionalFindObjForApvpVMSWorkers = {
      //       where,
      //       workerFields: ['Job_Rating_Count__c', 'Satisfaction_Rating_Average__c', 'Deliverables_Rating_Average__c']
      //     };
      //     observables.push(this.loadData('ApvpWorkersVms', 0,
      //       this.additionalFindObjForApvpVMSWorkers
      //     ));
      //     observables.push(this.getTotalCount('ApvpWorkersVms', where));
      //   }
      // }
      return forkJoin(...observables);
    })
      .subscribe(
        ([[apvp, apvpWorkerVms, apvpApprovedCountObj, apvpCountObj, apvpWorkerVmsCountObj, apvpVFMSVendors, apvpVFMSVendorsCountObj,
          approvedApvpVendorCountObj, project, FTEWorker, apvpVMSWorkers, apvpVMSWorkersCountObj
        ],
          correspondingApvpRecordDetails]: any) => {
          let approvedApvp;
          // For APVP Approved and all vendor start here
          if (apvp.length) {
            approvedApvp = apvp.filter(a => a.Status__c === 'Approved');
            const apvpData = this.prepareData('vendorAssignment', apvp);
            this.tableData.vendorAssignment = apvpData.filter(a => a.status === 'Approved');
            this.tableData.apvpVendors = [...apvpData];
          }
          this.totalCount.vendorAssignment = apvpApprovedCountObj.count;
          this.noRecords.vendorAssignment = (approvedApvp && approvedApvp.length) < this.itemsPerBatch ? true : false;
          this.allItems.vendorAssignment = this.tableData.vendorAssignment.slice();
          this.totalCount.apvpVendors = apvpCountObj.count;
          this.noRecords.apvpVendors = apvp.length < this.itemsPerBatch ? true : false;
          this.allItems.apvpVendors = this.tableData.apvpVendors.slice();
          // End here

          // APVP Worker Data Start here
          if (apvpWorkerVms && apvpWorkerVms.length) {
            this.tableData.workerAssignment = this.prepareData('workerAssignment', apvpWorkerVms);
            this.tableData.apvpWorkersSummary = [...this.tableData.workerAssignment];
            this.tableData.mvvpWorkers = this.prepareData('mvvpWorkers', apvpWorkerVms);
            this.getWorkerJobCount();
          }
          this.totalCount.workerAssignment = apvpWorkerVmsCountObj.count;
          this.noRecords.workerAssignment = apvpWorkerVms && apvpWorkerVms.length < this.itemsPerBatch ? true : false;
          this.allItems.workerAssignment = this.tableData.workerAssignment.slice();
          this.totalCount.apvpWorkersSummary = apvpWorkerVmsCountObj.count;
          this.noRecords.apvpWorkersSummary = apvpWorkerVms && apvpWorkerVms.length < this.itemsPerBatch ? true : false;
          this.allItems.apvpWorkersSummary = this.tableData.workerAssignment.slice();
          this.totalCount.mvvpWorkers = apvpWorkerVmsCountObj.count;
          this.noRecords.mvvpWorkers = apvpWorkerVms && apvpWorkerVms.length < this.itemsPerBatch ? true : false;
          this.allItems.mvvpWorkers = this.tableData.mvvpWorkers.slice();


          // APVP VFMS Vendor Start here
          if (apvpVFMSVendors && apvpVFMSVendors.length) {
            apvpVFMSVendors.forEach((i) => {
              const apvpRecord = correspondingApvpRecordDetails && correspondingApvpRecordDetails.find((apvpRec) => apvpRec.Vendor__c ===
                (i.worker && i.worker.contact && i.worker.contact.account && i.worker.contact.account.sfdcId));
              if (apvpRecord) {
                i.status = apvpRecord.Status__c;
                i.routingRating = apvpRecord.Metro_Rating__c;
                i.vendorProgramPerformanceHealthRating__c = apvpRecord.Vendor_Program_Performance_Health_Rating__c;
                i.metroVirtualVendorPool = apvpRecord.MetroVirtualVendorPool && apvpRecord.MetroVirtualVendorPool.Name;
              }
            });
            this.tableData.apvpVFMSVendors = this.prepareData('apvpVFMSVendors', apvpVFMSVendors);
            this.tableData.apvpVFMSWorkers = this.prepareData('apvpVFMSWorkers', apvpVFMSVendors);
          }
          this.totalCount.apvpVFMSVendors = apvpVFMSVendorsCountObj ? apvpVFMSVendorsCountObj.count : 0;
          this.noRecords.apvpVFMSVendors = apvpVFMSVendors && apvpVFMSVendors.length < this.itemsPerBatch ? true : false;
          this.allItems.apvpVFMSVendors = this.tableData.apvpVFMSVendors.slice();
          this.totalCount.apvpVFMSWorkers = apvpVFMSVendorsCountObj ? apvpVFMSVendorsCountObj.count : 0;
          this.noRecords.apvpVFMSWorkers = apvpVFMSVendors && apvpVFMSVendors.length < this.itemsPerBatch ? true : false;
          this.allItems.apvpVFMSWorkers = this.tableData.apvpVFMSWorkers.slice();
          this.approvedApvpVendorCount = approvedApvpVendorCountObj.count;

          if (apvpVMSWorkers && apvpVMSWorkers.length) {
            this.tableData.apvpVMSWorkers = this.prepareData('apvpVMSWorkers', apvpVMSWorkers);
          }
          this.totalCount.apvpVMSWorkers = apvpVMSWorkersCountObj ? apvpVMSWorkersCountObj.count : 0;
          this.noRecords.apvpVMSWorkers = apvpVMSWorkers && apvpVMSWorkers.length < this.itemsPerBatch ? true : false;
          this.allItems.apvpVMSWorkers = this.tableData.apvpVMSWorkers.slice();
          if (project && project.length) {
            this._healthData = project[0];
            this.setPostHealthCheckParams();
          }

          // Vendor Job Preference Start here
          if (apvpWorkerVms && apvpWorkerVms.length) {
            this.tableData.vendorJobPreference = this.prepareData('vendorJobPreference', apvpWorkerVms);
          }
          this.totalCount.vendorJobPreference = apvpWorkerVmsCountObj.count;
          this.noRecords.vendorJobPreference = apvpWorkerVms && apvpWorkerVms.length < this.itemsPerBatch ? true : false;
          this.allItems.vendorJobPreference = this.tableData.vendorJobPreference.slice();
          // Vendor Job Preference End here

          // FTE worker list code start here
          if (FTEWorker && FTEWorker.activeFTE && FTEWorker.activeFTE.length) {
            this.tableData.fteActive = this.prepareData('fteWorker', FTEWorker.activeFTE);
          }
          this.totalCount.fteActive = this.tableData.fteActive ? this.tableData.fteActive.length : 0;
          this.noRecords.fteActive = this.tableData.fteActive && this.tableData.fteActive.length < this.itemsPerBatch ? true : false;
          this.allItems.fteActive = this.tableData.fteActive.slice();

          if (FTEWorker && FTEWorker.inActiveFTE && FTEWorker.inActiveFTE.length) {
            this.tableData.fteInActive = this.prepareData('fteWorker', FTEWorker.inActiveFTE);
          }
          this.totalCount.fteInActive = this.tableData.fteInActive ? this.tableData.fteInActive.length : 0;
          this.noRecords.fteInActive = this.tableData.fteInActive && this.tableData.fteInActive.length < this.itemsPerBatch ? true : false;
          this.allItems.fteInActive = this.tableData.fteInActive.slice();

          if (FTEWorker && FTEWorker.expiredFTE && FTEWorker.expiredFTE.length) {
            this.tableData.fteExpired = this.prepareData('fteWorker', FTEWorker.expiredFTE);
          }
          this.totalCount.fteExpired = this.tableData.fteExpired ? this.tableData.fteExpired.length : 0;
          this.noRecords.fteExpired = this.tableData.fteExpired && this.tableData.fteExpired.length < this.itemsPerBatch ? true : false;
          this.allItems.fteExpired = this.tableData.fteExpired.slice();
          this.getWorkerAvailabilityData();
          // FTE Worker List Code End here
        },
        (error) => {
          this.isLoading = false;
          this._loader.hidePreloader();
        },
        () => {
          this.isLoading = false;
          this._loader.hidePreloader();
        }
      );
  }

  getWorkerJobCount() {
    const workerSfdcID = this.tableData.workerAssignment.map(w => w.workerSfdcId)
    const req = {
      Jobsite__c: this.jobDetails && this.jobDetails.jobsite && this.jobDetails.jobsite.sfdcId,
      Dispatch_Worker_Name__c: workerSfdcID.map(i => `'${i}'`)
    }

    if (this.jobDetails && this.jobDetails.appointment && this.jobDetails.appointment.length) {
      const appointment = this.jobDetails.appointment[0];
      let hrs = appointment.Customer_Appointment_Start_HRS_Scheduled__c;
      const startDateFormat = new Date(appointment.Customer_Appointment_Start_Scheduled__c);
      startDateFormat.setHours(appointment.Customer_Appointment_Start_HRS_Scheduled__c)
      startDateFormat.setMinutes(appointment.Customer_Apptmnt_Start_Minutes_Scheduled__c);
      req['startDate'] = moment(startDateFormat).format('YYYY-MM-DD');
      req['startDateTime'] = moment(startDateFormat).format('YYYY-MM-DD HH:mm:ss');

      if (this.jobDetails && this.jobDetails.quantity) {
        hrs = Number(hrs) + this.jobDetails.quantity;
        const endDateFormat = new Date(appointment.Customer_Appointment_Start_Scheduled__c);
        endDateFormat.setHours(hrs);
        req['endDateTime'] = moment(endDateFormat).format('YYYY-MM-DD HH:mm:ss');
      }
    }

    this._serviceContractLineItemApi.getWorkerJobCount(req).subscribe(res => {
      console.log('Re s>>>>>>>>', res);
      if (res && Object.keys(res).length) {
        this.tableData.workerAssignment = this.tableData.workerAssignment.map(w => {
          let index;
          if (res.l7DJobs && res.l7DJobs.some((e, i) => { index = i; return e.workerSfdcId == w.workerSfdcId })) {
            w.jobsiteWorkerJobs_7D = res.l7DJobs[index].count;
          }

          if (res.currentMonthJobs && res.currentMonthJobs.some((e, i) => { index = i; return e.workerSfdcId == w.workerSfdcId })) {
            w.jobsiteCurrentMonth = res.currentMonthJobs[index].count;
          }

          if (res.allJobsforJobsite && res.allJobsforJobsite.some((e, i) => { index = i; return e.workerSfdcId == w.workerSfdcId })) {
            w.JobsiteWorkerJobsTotal = res.allJobsforJobsite[index].count;
          }

          if (res.allL7DJobs && res.allL7DJobs.some((e, i) => { index = i; return e.workerSfdcId == w.workerSfdcId })) {
            w.WorkerJobsLastSevenDays = res.allL7DJobs[index].count;
          }

          if (res.allCurrentMonthJobs && res.allCurrentMonthJobs.some((e, i) => { index = i; return e.workerSfdcId == w.workerSfdcId })) {
            w.WorkerJobsCurrentMonth = res.allCurrentMonthJobs[index].count;
          }

          if (res.allJobs && res.allJobs.some((e, i) => { index = i; return e.workerSfdcId == w.workerSfdcId })) {
            w.WorkerJobsTotal = res.allJobs[index].count;
          }

          if (res.availabilityMWA && res.availabilityMWA.some((e, i) => { index = i; return e.Worker__c == w.workerSfdcId })) {
            w.workerAvailability = res.availabilityMWA[index].Available__c ? 'available' : 'notAvailable';
          }

          if (res.availabilityMWS && res.availabilityMWS.some((e, i) => { index = i; return e.Worker__c == w.workerSfdcId })) {
            w.workerMySchedule = res.availabilityMWS[index].Available__c ? 'available' : 'notAvailable';
          }
          return w;
        });
      }
    }, err => {
      console.log(err);
    });
  }

  getCorrespondingApvpRecordDetails(vendorAccountSfdcIds) {
    return this._approvedProjectVendorPoolApi.find({
      fields: ['Status__c', 'Metro_Virtual_Vendor_Pool__c', 'Metro_Rating__c', 'Vendor__c', 'Vendor_Program_Performance_Health_Rating__c'],
      include: [
        {
          relation: 'MetroVirtualVendorPool',
          scope: {
            fields: ['Name']
          }
        }
      ],
      where: { Vendor__c: { inq: vendorAccountSfdcIds } }
    });
  }

  setProjectProfileHealthSummary() {
    this.healthCheckParam[0]['status'] = this.tableData.vendorAssignment.length > 3 ? 'success' : 'failed';
    this.healthCheckParam[1]['status'] = this.tableData.apvpVFMSWorkers.length > 0 ? 'success' : 'failed';
    this.healthCheckParam[2]['status'] = this.approvedApvpVendorCount > 0 ? 'success' : 'failed';
    this.healthCheckParam[3]['status'] = this.tableData.apvpVFMSVendors.length > 0 ? 'success' : 'failed';
  }

  setPostHealthCheckParams() {
    const healthCheckData = [];
    this.postHealthCheckParam.forEach((res: any) => {
      res.value = this._healthData[res.field];
      if (res.value && res.value.toString().match('<img src=(.*)/>')) {
        const regex = /<img.*?src="(.*?)"/;
        const src = regex.exec(res.value) ? regex.exec(res.value)[1] : '';
        const imgFileName = src.split('/').pop();
        if (imgFileName === 'confirm32.png') {
          res.status = 'success';
        } else if (imgFileName === 'error32.png') {
          res.status = 'failed';
        } else {
          // uncheckedStatus32.png
          res.status = 'unchecked';
        }
        res['imgSrc'] = 'assets' + src;
      } else {
        if (typeof res.value === 'boolean') {
          res.status = res.value ? 'success' : 'failed'
        } else {
          res.status = 'failed';
        }
      }
      healthCheckData.push(res);
    });
    this.postHealthCheckParam = healthCheckData;
  }

  getTotalCount(modelName, whereCondition?) {
    if (modelName === 'ApprovedProjectVendorPool') {
      return this._approvedProjectVendorPoolApi.count(whereCondition);
    } else if (modelName === 'ApvpWorkersVms') {
      return this._apvpWorkersVmsApi.count(whereCondition);
    } else if (modelName === 'ProjectApvpWorkersVms') {
      const condition = {
        type: 'count', project: this.jobDetails && this.jobDetails.program && this.jobDetails.program.sfdcId,
        filter: { where: { Project_Routing_Profile__c: this.projectProfileSfdcId, Worker_Registration_Status__c: 'Registered' } }
      };

      return this._apvpWorkersVmsApi.getProjectApvpWorkers(condition);
    }
    else if (modelName === 'ApvpDistributionListWorkers') {
      return this._apvpDistributionListWorkersApi.count(whereCondition);
    }
  }

  getTotalCountForSMC(modelName, whereCondition?, additional?) {
    if (modelName === 'ApprovedProjectVendorPool') {
      return this._approvedProjectVendorPoolApi.count(whereCondition);
    } else if (modelName === 'ApvpWorkersVms') {
      whereCondition = { Project_Routing_Profile__c: this.projectProfileSfdcId, ...(additional && additional.where) }
      return this._apvpWorkersVmsApi.count(whereCondition);
    } else if (modelName === 'ApvpDistributionListWorkers') {
      return this._apvpDistributionListWorkersApi.count(whereCondition);
    } else if (modelName === 'ServiceContractForActive') {
      return this._serviceContractApi.count(whereCondition);
    } else if (modelName === 'ServiceContractInactive') {
      return this._serviceContractApi.count(whereCondition);
    } else if (modelName === 'ServiceContractExpired') {
      return this._serviceContractApi.count(whereCondition);
    }
  }

  loadMoreRecords(tabName) {
    this._loader.showPreloader();
    this.timesLoadedMore[tabName] += 1;
    let modelName = '';
    switch (tabName) {
      case 'vendorAssignment':
      case 'apvpVendors':
        modelName = 'ApprovedProjectVendorPool';
        break;
      case 'workerAssignment':
      case 'apvpWorkersSummary':
      case 'mvvpWorkers':
      case 'vendorJobPreference':
      case 'apvpVMSWorkers':
        modelName = 'ApvpWorkersVms';
        break;
      case 'apvpVFMSVendors':
      case 'apvpVFMSWorkers':
        modelName = 'ApvpDistributionListWorkers';
        break;
      case 'fteActive':
      case 'fteExpired':
      case 'fteInActive':
    }
    if (tabName === 'apvpVFMSVendors') {
      this.loadData(modelName, this.itemsPerBatch * this.timesLoadedMore[tabName])
        .flatMap(apvpVFMSVendors => {
          const vendorAccountSfdcIds: any = apvpVFMSVendors.reduce((a: any, c: any) => {
            if (c.worker && c.worker.contact && c.worker.contact.account && c.worker.contact.account.sfdcId &&
              a.indexOf(c.worker.contact.account.sfdcId) === -1) {
              a.push(c.worker.contact.account.sfdcId);
            }
            return a;
          }, []);
          if (vendorAccountSfdcIds.length) {
            return forkJoin(of(apvpVFMSVendors), this.getCorrespondingApvpRecordDetails(vendorAccountSfdcIds));
          } else {
            return of(apvpVFMSVendors);
          }
        }).subscribe(([apvpVFMSVendors, correspondingApvpRecordDetails]: any) => {
          if (apvpVFMSVendors.length) {
            apvpVFMSVendors.forEach((i) => {
              const apvpRecord = correspondingApvpRecordDetails && correspondingApvpRecordDetails.find((apvpRec) => apvpRec.Vendor__c ===
                (i.worker && i.worker.contact && i.worker.contact.account && i.worker.contact.account.sfdcId));
              if (apvpRecord) {
                i.status = apvpRecord.Status__c;
                i.routingRating = apvpRecord.Metro_Rating__c;
                i.metroVirtualVendorPool = apvpRecord.MetroVirtualVendorPool && apvpRecord.MetroVirtualVendorPool.Name;
              }
            });
            this.appendData(tabName, apvpVFMSVendors);
          }
        }, error => {
          this._loader.hidePreloader();
        },
          () => {
            this._loader.hidePreloader();
          });
    } else {
      this.loadData(modelName, this.itemsPerBatch * this.timesLoadedMore[tabName], tabName === 'apvpVMSWorkers' ?
        this.additionalFindObjForApvpVMSWorkers : null).subscribe(
          (data) => {
            const currentData = this.prepareData(tabName, data);
            this.appendData(tabName, currentData);
          },
          (error) => {
            this._loader.hidePreloader();
          },
          () => {
            this._loader.hidePreloader();
          }
        );
    }
  }

  appendData(tabName, currentData) {
    currentData.forEach((c) => {
      this.allItems[tabName].push(c);
    });
    this.tableData[tabName] = [...this.allItems[tabName]];
    this.allItems[tabName] = this.tableData[tabName].slice();
  }

  onTabBtnClick(tab) {
    const index = this.activeTabs.indexOf(tab);
    if (index !== -1) {
      this.activeTabs.splice(index, 1);
    } else {
      this.activeTabs.push(tab);
    }
  }

  getEnableLoginIcon(status) {
    return status ?
      this.sanitizer.bypassSecurityTrustHtml('<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="#2ed775" d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z" /></svg>') :
      this.sanitizer.bypassSecurityTrustHtml('<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="#fc3d39" d="M19,3H16.3H7.7H5A2,2 0 0,0 3,5V7.7V16.4V19A2,2 0 0,0 5,21H7.7H16.4H19A2,2 0 0,0 21,19V16.3V7.7V5A2,2 0 0,0 19,3M15.6,17L12,13.4L8.4,17L7,15.6L10.6,12L7,8.4L8.4,7L12,10.6L15.6,7L17,8.4L13.4,12L17,15.6L15.6,17Z" /></svg>')
  }

  getJobSlot(appointments: any[]) {
    let WorkerArrivalDateTimeCustRequested = new Date(this.Worker_Arrival_DateTime_Cust_Requested__c).getTime();
    let WorkerArrivalDateCustomerReqEnd = new Date(this.Worker_Arrival_Date_Customer_Req_End__c).getTime();
    let slot;
    for(let item of appointments){
      let localJobWorkerArrivalDateTimeCustRequested = new Date(item['Worker_Arrival_DateTime_Cust_Requested__c']).getTime();
      let localJobWorkerArrivalDateCustomerReqEnd = new Date(item['Worker_Arrival_Date_Customer_Req_End__c']).getTime();
      if((WorkerArrivalDateTimeCustRequested >=  localJobWorkerArrivalDateTimeCustRequested && 
      WorkerArrivalDateTimeCustRequested <= localJobWorkerArrivalDateTimeCustRequested) ||
      WorkerArrivalDateCustomerReqEnd >= localJobWorkerArrivalDateCustomerReqEnd &&
      WorkerArrivalDateCustomerReqEnd <= localJobWorkerArrivalDateCustomerReqEnd) {
          slot = item.Job.Iron_Job_num__c + '-' + item.Job.Jobsite_Name__c +
          (item.Worker_Arrival_DateTime_Cust_Requested__c ? '-' + this._commonService.dateFormate(item.Worker_Arrival_DateTime_Cust_Requested__c,item.Job.jobsite.Time_Zone__c,'L LT'):'') + 
          (item.Worker_Arrival_Date_Customer_Req_End__c ? '-' + this._commonService.dateFormate(item.Worker_Arrival_Date_Customer_Req_End__c,item.Job.jobsite.Time_Zone__c,'L LT'):'');
         break;
        }
    };
    return slot;
  }

  prepareJobfilter() {
    let Jobfilter = {}
    if (this.jobDetails) {
      if (this.jobDetails.Job) {
        Jobfilter = {
          'id': this.jobDetails.Job.id || '',
          'sfdcId': this.jobDetails.Job.sfdcId || '',
          'Type_of_Work__c': this.jobDetails.Job.Type_of_Work__c || '',
          'CKSW_BASE__Street__c': this.jobDetails.Job.CKSW_BASE__Street__c || '',
          'CKSW_BASE__City__c': this.jobDetails.Job.CKSW_BASE__City__c || '',
          'CKSW_BASE__State__c': this.jobDetails.Job.CKSW_BASE__State__c || '',
          'CKSW_BASE__Zip__c': this.jobDetails.Job.CKSW_BASE__Zip__c || '',
          'CKSW_BASE__Country__c': this.jobDetails.Job.CKSW_BASE__Country__c || '',
          'Iron_Job_num__c': this.jobDetails.Job.Iron_Job_num__c || '',
          'Jobsite__c': this.jobDetails.Job.Jobsite__c || '',
          'Jobsite_Name__c': this.jobDetails.Job.Jobsite_Name__c || '',
          'smsUrl': this.jobDetails.Job.smsUrl || '',
          'shortUrl': this.jobDetails.Job.shortUrl || '',
          'Dispatch_Worker_Name__c': this.jobDetails.Job.Dispatch_Worker_Name__c || '',
          'Case__c': this.jobDetails.Job.Case__c || '',
          'Service_Dispatch_SLA_Priority__c': this.jobDetails.Job.Service_Dispatch_SLA_Priority__c || '',
          'Job_Status_Internal__c': this.jobDetails.Job.Job_Status_Internal__c || '',
          'Vendor__c': this.jobDetails.Job.Vendor__c || '',
          'Project_SOP__c': this.jobDetails.Job.Project_SOP__c || '',
          'Project__c': this.jobDetails.Job.Project__c || '',
          'Work_Order__c': this.jobDetails.Job.Work_Order__c || '',
          'Vendorsite__c': this.jobDetails.Job.Vendorsite__c || '',
        }
      }
      if (this.jobDetails.jobsite) {
        Jobfilter = {
          ...Jobfilter,
          'jobsite': {
            'sfdcId': this.jobDetails.jobsite.sfdcId || '',
            'Name': this.jobDetails.jobsite.Name || '',
            'Region__c': this.jobDetails.jobsite.Region__c || this.jobDetails.jobsite.GeoMetro && this.jobDetails.jobsite.GeoMetro.Region__c || '',
            'Time_Zone__c': this.jobDetails.jobsite.Time_Zone__c || ''
          },
        }
      }
      if (this.jobDetails.appointment) {
        Jobfilter = {
          ...Jobfilter,
          'appointment': {
            'sfdcId': this.jobDetails.appointment.sfdcId || '',
            'Customer_Appointment_DateTime_Scheduled__c': this.jobDetails.appointment.Customer_Appointment_DateTime_Scheduled__c || '',
          },
        }
      }
    }
    return Jobfilter;
  }

  checkAvailability() {
    const Jobfilter = this.prepareJobfilter();
    let workerList = []
    if (this.selectedWorkersList) {
      this.selectedWorkersList.forEach(item => {
        if (!(item.blacklisted == 'Yes') && !(item.tempBlocked == 'Yes') && item.workerPhoneNumber && !workerList.includes(item.workerSfdcId) && !(item.checkAvailability && item.checkAvailability.length)) {
          workerList.push(item.workerSfdcId);
        }
      });
    }
    if (workerList.length) {
      let payload = {};
      payload = {
        job: Jobfilter,
        worker: workerList
      };
      this._workerApi.checkWorkerAvailability(payload).subscribe(res => {
        if (res && Object.keys(res).length) {
          this._alertService.success(res);
          this.clearSelectedWorkersList();
          setTimeout(() => {
            this.getWorkerAvailabilityData();
          }, 10000);
        }
      }, err => {
        console.log(err);
        this._alertService.error("Missing Required data.");

      });
    } else {
      this._alertService.error('Worker Not selected');
    }
  }

  onSelect({ selected }) {
    this.selectedWorkersList.splice(0, this.selectedWorkersList.length);
    this.selectedWorkersList.push(...selected);
  }
  displayCheck(row) {
    let check = true;
    if (row.blacklisted == 'Yes' || row.tempBlocked == 'Yes' || !row.workerPhoneNumber) {
      check = false;
    }
    if (row.checkAvailability && row.checkAvailability.length) {
      check = false;
    }
    return check;
  }

  getWorkerAvailabilityData() {
    const filters = { "MessageCommunity": { "inq": ["013VMS"]}, "JobNumber": { "inq": [this.jobDetails['Job'].Iron_Job_num__c] } }
    this._notificationService.getScheduleSMS(1, filters, 200, 'id', 'asc').then(async res => {
      if (res && res['data'] && res['data'].length) {
        const length = this.tableData.workerAssignment.length;
        res['data'].forEach((item) => {
          if (item && item.WorkerId) {
            let i = 0;
            for (i = 0; i <= length; i++) {
              if (item && this.tableData.workerAssignment[i] && this.tableData.workerAssignment[i].workerSfdcId === item.WorkerId) {
                this.tableData.workerAssignment[i].MessageSendTo = item.MessageSendTo;
                this.tableData.workerAssignment[i].MessageSentDateTime = (item && item.MessageSentDateTime) ? this.transform(item.MessageSentDateTime) : '';
                this.tableData.workerAssignment[i].MessageReplyDateTime = (item && item.MessageReplyDateTime) ? this.transform(item.MessageReplyDateTime) : '';
                this.tableData.workerAssignment[i].MessageReplyFrom = item.MessageReplyFrom;
                this.tableData.workerAssignment[i].MessageReplyText = item.MessageReplyText;
                this.tableData.workerAssignment[i].MessageStatus = (item && item.MessageStatus) ? item.MessageStatus[0].toUpperCase() + item.MessageStatus.slice(1) : '';
                if (item.MessageReplyText && item.MessageReplyText.substr(item.MessageReplyText.length - 1) == "2") {
                  this.tableData.workerAssignment[i].checkAvailability = "Not Available"
                } else if (item.MessageReplyText && item.MessageReplyText.substr(item.MessageReplyText.length - 1) == "1") {
                  this.tableData.workerAssignment[i].checkAvailability = "Available"
                } else {
                  this.tableData.workerAssignment[i].checkAvailability = "Waiting Response"
                }
              }
            }
          }
        });
        this.columnInitialize()
      }
    })
  }

  clearSelectedWorkersList() {
    this.selectedWorkersList = [];
  }
}
