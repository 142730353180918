import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';
import { JobOrderApi } from 'shared/sdk';

@Component({
  selector: 'app-job-order-lookup',
  templateUrl: './job-order-lookup.component.html',
  styleUrls: ['./job-order-lookup.component.css']
})
export class JobOrderLookupComponent implements OnInit {

  @Input() inCloneFeature = false;
  @Input() setGlobalFilterValue = true;
  @Input() boxedLayout = false;
  @Input() set resetData(isClear) {
    if (isClear) {
      this.jobOrderSelectedList = [];
      // this.jobOrderOptionList = [];
    }
  }
  @Input() placeholder = 'Job Order';
  @Input() isdisabled;

  @Input()
  set isMultipleSelected(e) {
    this._multipleSelect = e;
    this.maxSelectedItems = e ? 20 : 1;
  }

  @Input() set setValueInEditMode(sfdcId) {
    if(this.inCloneFeature){
      this.jobOrderId = sfdcId; // no need to show that job order because we have to clone application in different JO
    }else{
      if(sfdcId) {
        const filter = {
          where: {sfdcId: sfdcId},
          fields: ['sfdcId', 'Name', 'ts2__Job_Number__c']
        }
       
        this._jobOrderApi.find(filter).subscribe(res => {
          res.forEach((item: any) => {
            item.label = item.ts2__Job_Number__c ? item.ts2__Job_Number__c + ' - ' : '';
            item.label += item.Name;
          })
          this.jobOrderOptionList = res;
          this.jobOrderSelectedList = sfdcId;
        });
      }
    }
    
}

  @Output() getJobOrderObj: EventEmitter<any> = new EventEmitter;
  public searchInput = new EventEmitter<string>();
  selectedJobOrder: any;
  whereObj = {};
  _multipleSelect = true;
  maxSelectedItems = 1;

  jobOrderOptionList = [];
  jobOrderSelectedList = [];
  isRmc = false;
  jobOrderId = ''
  /**
   * @constructor
   * Represents a TaskLookupComponent.
   */
  constructor(
    private _jobOrderApi: JobOrderApi,
    private router : Router
  ) {
  }

  ngOnInit() {
    if(this.router.url.indexOf('/app/marketplace-recruitment-jobs') !== -1){
      this.isRmc = true;
    }
    if (this.setGlobalFilterValue) {
      const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
      if (preselected && preselected['jobOrders'] && preselected['jobOrders'].length) {
        this.jobOrderSelectedList = preselected['jobOrders'];
        this.onJobOrderChange();
        const whereObj = this.whereObj;
        whereObj['sfdcId'] = { inq: this.jobOrderSelectedList };
        const filterObj = {
          where: whereObj,
          fields: ['id', 'sfdcId', 'Name', 'ts2__Job_Number__c'],
          order: 'createdAt desc',
          limit: 200
        }
        this._jobOrderApi.find(filterObj).subscribe(data => {
          if (data && data.length) {
            data.forEach((item: any) => {
              item.label = item.ts2__Job_Number__c ? item.ts2__Job_Number__c + ' - ' : '';
              item.label += item.Name;
            })
            this.jobOrderOptionList = data;
          } else {
            this.jobOrderOptionList = [];
          }
        }, error => {
          this.jobOrderOptionList = [];
          console.log(error);
        })
      }

      this.getJobOrderObj.emit(this.jobOrderSelectedList);
      
      if (this.selectedJobOrder) {
        this.jobOrderSelectedList = this.selectedJobOrder && this.selectedJobOrder.sfdcId;
        this.formatSelectedJobOrders();
      }

    }   
    
    this.getJobOrderList();

    // if (preselected && preselected['milestones'] && preselected['milestones'].length) {
    //   this.whereObj['PgMO_Milestones__c'] = { inq: preselected['milestones'] };
    // }
    // if (preselected && preselected['projects'] && preselected['projects'].length) {
    //   this.whereObj['PgMO_Projects__c'] = { inq: preselected['projects'] };
    // }
    // this.getJobOrders(this.whereObj);    
  }

  getJobOrderList() {
    this.searchInput
      .pipe(
        filter(search => search && search.trim().length > 2),
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(search =>
          this.getJobOrders(search)
        )
      ).subscribe(
        data => {
          if (data && data.length) {
            data.forEach((item: any) => {
              item.label = item.ts2__Job_Number__c ? item.ts2__Job_Number__c + ' - ' : '';
              item.label += item.Name;
            })
            this.jobOrderOptionList = data;

          } else {
            this.jobOrderOptionList = [];
          }
        },
        err => {
          console.log(err);
          this.jobOrderOptionList = [];
        }
      );
  }


  getJobOrders(search) {
    let wherecond = { ts2__Job_Number__c: { like: '%' + search.trim() + '%', options: 'i' } };
    if(this.isRmc){
      wherecond['and'] = [{
        'or': [{ 'Employment_Type__c': 'EOR' }, { 'Employment_Type__c': 'Vendor' }]
      }];
      //,{ 'Employment_Type__c': '1099' }, { 'Employment_Type__c': 'W2' }  
      wherecond['ts2__Post_Job__c'] = true;
      wherecond['and'] = [{
        'or': [{ 'Passive_Job__c': false }, { 'Passive_Job__c': null }]
      }];
    }
    if (this.inCloneFeature) {
      wherecond['sfdcId'] = {neq:this.jobOrderId} // no need to show the current job order
      wherecond['ts2__Status__c'] = 'Open';
    }
    const filterObj = {
      where: wherecond,
      fields: ['id', 'sfdcId', 'Name', 'ts2__Job_Number__c'],
      order: 'createdAt desc',
      limit: 200
    }
    return this._jobOrderApi.find(filterObj);
  }

  formatSelectedJobOrders() {
    this.selectedJobOrder.label = this.selectedJobOrder.ts2__Job_Number__c ? this.selectedJobOrder.ts2__Job_Number__c + ' - ' : '';
    this.selectedJobOrder.label += this.selectedJobOrder.Name;
    this.jobOrderOptionList = [];
    this.jobOrderOptionList.push(this.selectedJobOrder);
    this.getJobOrderObj.emit(this.jobOrderSelectedList);
  }

  onJobOrderChange() {
    this.getJobOrderObj.emit(this.jobOrderSelectedList);
  }

  clearJobOrder() {
    this.jobOrderSelectedList = [];
    this.getJobOrderObj.emit([]);
  }
}