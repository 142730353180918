/* tslint:disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter,  } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApvpWorkersVms } from '../../models/ApvpWorkersVms';
import { SocketConnection } from '../../sockets/socket.connections';
import { ApprovedProjectVendorPool } from '../../models/ApprovedProjectVendorPool';
import { Worker } from '../../models/Worker';
import { Project } from '../../models/Project';


/**
 * Api services for the `ApvpWorkersVms` model.
 */
@Injectable()
export class ApvpWorkersVmsApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SocketConnection) protected connection: SocketConnection,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http,  connection,  models, auth, errorHandler);
  }

  /**
   * Fetches belongsTo relation apvpAccount.
   *
   * @param {any} id ApvpWorkersVms id
   *
   * @param {boolean} refresh 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ApvpWorkersVms` object.)
   * </em>
   */
  public getApvpAccount(id: any, refresh: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/ApvpWorkersVms/:id/apvpAccount";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null) _urlParams.refresh = refresh;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Fetches belongsTo relation worker.
   *
   * @param {any} id ApvpWorkersVms id
   *
   * @param {boolean} refresh 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ApvpWorkersVms` object.)
   * </em>
   */
  public getWorker(id: any, refresh: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/ApvpWorkersVms/:id/worker";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null) _urlParams.refresh = refresh;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Fetches belongsTo relation routingProfile.
   *
   * @param {any} id ApvpWorkersVms id
   *
   * @param {boolean} refresh 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ApvpWorkersVms` object.)
   * </em>
   */
  public getRoutingProfile(id: any, refresh: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/ApvpWorkersVms/:id/routingProfile";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null) _urlParams.refresh = refresh;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Patch an existing model instance or insert a new one into the data source.
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - Model instance data
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ApvpWorkersVms` object.)
   * </em>
   */
  public patchOrCreate(data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/ApvpWorkersVms";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   * @param {any} id ApvpWorkersVms id
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - An object of model property name/value pairs
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ApvpWorkersVms` object.)
   * </em>
   */
  public patchAttributes(id: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/ApvpWorkersVms/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

   /**
   * Create ApvpWorkersVms of Project.
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ApvpWorkersVms` object.)
   * </em>
   */
  public saveApvpWorkersVms(req: any, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/ApvpWorkersVms/saveApvpWorkersVms";
    let _routeParams: any = {};
    let _postBody: any = {
      req: req
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

    /**
   * Create ApvpWorkersVms of Project.
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ApvpWorkersVms` object.)
   * </em>
   */
     public getProjectApvpWorkers(req: any, customHeaders?: Function): Observable<any> {
      let _method: string = "POST";
      let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
      "/ApvpWorkersVms/getProjectApvpWorkers";
      let _routeParams: any = {};
      let _postBody: any = {
        req: req
      };
      let _urlParams: any = {};
      let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
      return result;
    }
  
    public getAllApvpWorkersData(req: any, customHeaders?: Function): Observable<any> {
      let _method: string = "POST";
      let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
      "/ApvpWorkersVms/getAllApvpWorkersData";
      let _routeParams: any = {};
      let _postBody: any = {
        req: req
      };
      let _urlParams: any = {};
      let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
      return result;
    }
  
    public getAllApvpWorkersList(req: any, customHeaders?: Function): Observable<any> {
      let _method: string = "POST";
      let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
      "/ApvpWorkersVms/getAllApvpWorkersList";
      let _routeParams: any = {};
      let _postBody: any = {
        req: req
      };
      let _urlParams: any = {};
      let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
      return result;
    }
  
    public getApvpWorkerListData(req: any, customHeaders?: Function): Observable<any> {
      let _method: string = "POST";
      let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
      "/ApvpWorkersVms/getApvpWorkerListData";
      let _routeParams: any = {};
      let _postBody: any = {
        req: req
      };
      let _urlParams: any = {};
      let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
      return result;
    }
  

  /**
   * The name of the model represented by this $resource,
   * i.e. `ApvpWorkersVms`.
   */
  public getModelName() {
    return "ApvpWorkersVms";
  }
}
