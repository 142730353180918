/* tslint:disable */
/**
* @module SDKModule
* @author Jonathan Casarrubias <t:@johncasarrubias> <gh:jonathan-casarrubias>
* @license MIT 2016 Jonathan Casarrubias
* @version 2.1.0
* @description
* The SDKModule is a generated Software Development Kit automatically built by
* the LoopBack SDK Builder open source module.
*
* The SDKModule provides Angular 2 >= RC.5 support, which means that NgModules
* can import this Software Development Kit as follows:
*
*
* APP Route Module Context
* ============================================================================
* import { NgModule }       from '@angular/core';
* import { BrowserModule }  from '@angular/platform-browser';
* // App Root 
* import { AppComponent }   from './app.component';
* // Feature Modules
* import { SDK[Browser|Node|Native]Module } from './shared/sdk/sdk.module';
* // Import Routing
* import { routing }        from './app.routing';
* @NgModule({
*  imports: [
*    BrowserModule,
*    routing,
*    SDK[Browser|Node|Native]Module.forRoot()
*  ],
*  declarations: [ AppComponent ],
*  bootstrap:    [ AppComponent ]
* })
* export class AppModule { }
*
**/
import { ErrorHandler } from './services/core/error.service';
import { LoopBackAuth } from './services/core/auth.service';
import { LoggerService } from './services/custom/logger.service';
import { SDKModels } from './services/custom/SDKModels';
import { InternalStorage, SDKStorage } from './storage/storage.swaps';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CookieBrowser } from './storage/cookie.browser';
import { StorageBrowser } from './storage/storage.browser';
import { SocketBrowser } from './sockets/socket.browser';
import { SocketDriver } from './sockets/socket.driver';
import { SocketConnection } from './sockets/socket.connections';
import { RealTime } from './services/core/real.time';
import { AccountApi } from './services/custom/Account';
import { JobApi } from './services/custom/Job';
import { JobInstructionApi } from './services/custom/JobInstruction';
import { JobCommentApi } from './services/custom/JobComment';
import { TimecardApi } from './services/custom/Timecard';
import { UsersApi } from './services/custom/Users';
import { PaymentApi } from './services/custom/Payment';
import { InvoiceApi } from './services/custom/Invoice';
import { PurchaseOrderApi } from './services/custom/PurchaseOrder';
import { PurchaseOrderItemApi } from './services/custom/PurchaseOrderItem';
import { PricelistApi } from './services/custom/Pricelist';
import { PricelistItemApi } from './services/custom/PricelistItem';
import { JobsiteApi } from './services/custom/Jobsite';
import { DocumentApi } from './services/custom/Document';
import { DocumentShareApi } from './services/custom/DocumentShare';
import { TimezoneApi } from './services/custom/Timezone';
import { UserTypeApi } from './services/custom/UserType';
import { CountryApi } from './services/custom/Country';
import { DeliverableApi } from './services/custom/Deliverable';
import { VendorsiteApi } from './services/custom/Vendorsite';
import { VendorsiteContactApi } from './services/custom/VendorsiteContact';
import { AppointmentApi } from './services/custom/Appointment';
import { WorkerApi } from './services/custom/Worker';
import { ContainerApi } from './services/custom/Container';
import { DocumentCategoryApi } from './services/custom/DocumentCategory';
import { DocumentTitleApi } from './services/custom/DocumentTitle';
import { SkillApi } from './services/custom/Skill';
import { UserSkillApi } from './services/custom/UserSkill';
import { ProjectApi } from './services/custom/Project';
import { CaseApi } from './services/custom/Case';
import { JobsiteProjectsApi } from './services/custom/JobsiteProjects';
import { ProductApi } from './services/custom/Product';
import { ApprovedProjectVendorPoolApi } from './services/custom/ApprovedProjectVendorPool';
import { ContactApi } from './services/custom/Contact';
import { CountryCodeApi } from './services/custom/CountryCode';
import { GeoMetroApi } from './services/custom/GeoMetro';
import { JobsiteContactApi } from './services/custom/JobsiteContact';
import { TalentTypeApi } from './services/custom/TalentType';
import { WorkOrderApi } from './services/custom/WorkOrder';
import { QuoteLineManagerApi } from './services/custom/QuoteLineManager';
import { QuoteManagerApi } from './services/custom/QuoteManager';
import { MetroVirtualVendorPoolApi } from './services/custom/MetroVirtualVendorPool';
import { JobOrderItemApi } from './services/custom/JobOrderItem';
import { ServiceCategorySettingApi } from './services/custom/ServiceCategorySetting';
import { RecordTypeApi } from './services/custom/RecordType';
import { CaseCommentApi } from './services/custom/CaseComment';
import { WorkflowApi } from './services/custom/Workflow';
import { WorkflowStageApi } from './services/custom/WorkflowStage';
import { WorkflowStatusApi } from './services/custom/WorkflowStatus';
import { WorkflowTransitionApi } from './services/custom/WorkflowTransition';
import { OrderApi } from './services/custom/Order';
import { FilterServiceApi } from './services/custom/FilterService';
import { DashboardApi } from './services/custom/Dashboard';
import { RequestFormReceiptApi } from './services/custom/RequestFormReceipt';
import { RequestFormReceiptLineApi } from './services/custom/RequestFormReceiptLine';
import { IronCustomFieldApi } from './services/custom/IronCustomField';
import { AssetApi } from './services/custom/Asset';
import { SkillingApi } from './services/custom/Skilling';
import { CsqdCaseCommentApi } from './services/custom/CsqdCaseComment';
import { AssetGroupApi } from './services/custom/AssetGroup';
import { ContractApi } from './services/custom/Contract';
import { AttachmentApi } from './services/custom/Attachment';
import { PmsIccPmcRecordTypeMappingApi } from './services/custom/PmsIccPmcRecordTypeMapping';
import { GeoMessageApi } from './services/custom/GeoMessage';
import { SupportedAssetModelNumbersApi } from './services/custom/SupportedAssetModelNumbers';
import { FSLFRUTransactionListApi } from './services/custom/FSLFRUTransactionList';
import { MapSettingsApi } from './services/custom/MapSettings';
import { NeighboringCountriesApi } from './services/custom/NeighboringCountries';
import { NeighboringCountriesHeaderApi } from './services/custom/NeighboringCountriesHeader';
import { GeoSessionLogApi } from './services/custom/GeoSessionLog';
import { CsqdActivityApi } from './services/custom/CsqdActivity';
import { ServiceActivityMappingApi } from './services/custom/ServiceActivityMapping';
import { GoogleServiceApi } from './services/custom/GoogleService';
import { WorkflowStageLogApi } from './services/custom/WorkflowStageLog';
import { VatMasterApi } from './services/custom/VatMaster';
import { AlertApi } from './services/custom/Alert';
import { UserAlertApi } from './services/custom/UserAlert';
import { ActivityApi } from './services/custom/Activity';
import { ProjectWorkerApi } from './services/custom/ProjectWorker';
import { CommentApi } from './services/custom/Comment';
import { GlobalTalentPoolApi } from './services/custom/GlobalTalentPool';
import { DepartmentApi } from './services/custom/Department';
import { EmailServiceApi } from './services/custom/EmailService';
import { OrderItemApi } from './services/custom/OrderItem';
import { ContactTypeApi } from './services/custom/ContactType';
import { AccessControlApi } from './services/custom/AccessControl';
import { AssignmentApi } from './services/custom/Assignment';
import { DepartmentRoleApi } from './services/custom/DepartmentRole';
import { GroupApi } from './services/custom/Group';
import { MemberRoleApi } from './services/custom/MemberRole';
import { MilestoneApi } from './services/custom/Milestone';
import { ProgramGroupApi } from './services/custom/ProgramGroup';
import { ProgramApi } from './services/custom/Program';
import { Project2Api } from './services/custom/Project2';
import { TaskApi } from './services/custom/Task';
import { WorkerShiftApi } from './services/custom/WorkerShift';
import { ChannelApi } from './services/custom/Channel';
import { ConversationFileApi } from './services/custom/ConversationFile';
import { ConversationParticipantApi } from './services/custom/ConversationParticipant';
import { ConversationRelationApi } from './services/custom/ConversationRelation';
import { ConversationTrackerApi } from './services/custom/ConversationTracker';
import { ConversationApi } from './services/custom/Conversation';
import { FileCategoryApi } from './services/custom/FileCategory';
import { FilesApi } from './services/custom/Files';
import { FavoriteApi } from './services/custom/Favorite';
import { LibraryDetailApi } from './services/custom/LibraryDetail';
import { LibraryHeaderApi } from './services/custom/LibraryHeader';
import { LibraryApi } from './services/custom/Library';
import { TaskAttributeApi } from './services/custom/TaskAttribute';
import { TaskGroupAttributeApi } from './services/custom/TaskGroupAttribute';
import { TaskGroupApi } from './services/custom/TaskGroup';
import { EdiTransactionApi } from './services/custom/EdiTransaction';
import { BitlyServiceApi } from './services/custom/BitlyService';
import { SMSServiceApi } from './services/custom/SMSService';
import { TalentPoolWorkersApi } from './services/custom/TalentPoolWorkers';
import { ReturnOrderApi } from './services/custom/ReturnOrder';
import { ReturnOrderLineItemApi } from './services/custom/ReturnOrderLineItem';
import { LocationApi } from './services/custom/Location';
import { ProductRequestApi } from './services/custom/ProductRequest';
import { ProductRequestLineItemApi } from './services/custom/ProductRequestLineItem';
import { ProductTransferApi } from './services/custom/ProductTransfer';
import { ServiceContractApi } from './services/custom/ServiceContract';
import { ServiceContractLineItemApi } from './services/custom/ServiceContractLineItem';
import { JobApplicationApi } from './services/custom/JobApplication';
import { UserDeviceApi } from './services/custom/UserDevice';
import { StateApi } from './services/custom/State';
import { JobDataApi } from './services/custom/JobData';
import { FeedbackApi } from './services/custom/Feedback';
import { AppVersionApi } from './services/custom/AppVersion';
import { TalentInformationApi } from './services/custom/TalentInformation';
import { TalentProfileApi } from './services/custom/TalentProfile';
import { IronAttachmentApi } from './services/custom/IronAttachment';
import { TeamShiftApi } from './services/custom/TeamShift';
import { RequestorDepartmentApi } from './services/custom/RequestorDepartment';
import { RequestFormReceiptEscalationApi } from './services/custom/RequestFormReceiptEscalation';
import { VoiceServiceApi } from './services/custom/VoiceService';
import { JobWorkerTrackingApi } from './services/custom/JobWorkerTracking';
import { ApiKeyApi } from './services/custom/ApiKey';
import { TimeClockManagerApi } from './services/custom/TimeClockManager';
import { ExpenseReportApi } from './services/custom/ExpenseReport';
import { PublicAccessApi } from './services/custom/PublicAccess';
import { ExpenseCodeApi } from './services/custom/ExpenseCode';
import { MonthlyInvoiceApi } from './services/custom/MonthlyInvoice';
import { VendorPPEHoursApi } from './services/custom/VendorPPEHours';
import { ApvpDistributionListWorkersApi } from './services/custom/ApvpDistributionListWorkers';
import { ApvpWorkersVmsApi } from './services/custom/ApvpWorkersVms';
import { WorkerVendorsiteApi } from './services/custom/WorkerVendorsite';
import { VCSQDLeadApi } from './services/custom/VCSQDLead';
import { SkillingVerifyApi } from './services/custom/SkillingVerify';
import { CSQDSessionDataApi } from './services/custom/CSQDSessionData';
import { TimeBreakApi } from './services/custom/TimeBreak';
import { SkillingBackgroundCheckApi } from './services/custom/SkillingBackgroundCheck';
import { JobScheduleApi } from './services/custom/JobSchedule';
import { NoteApi } from './services/custom/Note';
import { TimeChangeRequestApi } from './services/custom/TimeChangeRequest';
import { InvoiceAdjustmentApi } from './services/custom/InvoiceAdjustment';
import { MonthlyPaymentApi } from './services/custom/MonthlyPayment';
import { MonthlyInvoiceItemApi } from './services/custom/MonthlyInvoiceItem';
import { CsqdleadTalenttypeMappingApi } from './services/custom/CsqdleadTalenttypeMapping';
import { AdjustmentReasonCodeApi } from './services/custom/AdjustmentReasonCode';
import { MilestoneLibraryApi } from './services/custom/MilestoneLibrary';
import { TaskLibraryApi } from './services/custom/TaskLibrary';
import { RequestFormReceiptTemplateApi } from './services/custom/RequestFormReceiptTemplate';
import { TemplateHeaderApi } from './services/custom/TemplateHeader';
import { MilestoneTaskLibraryMapApi } from './services/custom/MilestoneTaskLibraryMap';
import { TemplateDetailApi } from './services/custom/TemplateDetail';
import { AddressApi } from './services/custom/Address';
import { ServiceTerritoryApi } from './services/custom/ServiceTerritory';
import { JobSearchAlertApi } from './services/custom/JobSearchAlert';
import { AnswersApi } from './services/custom/Answers';
import { CertificationApi } from './services/custom/Certification';
import { EducationHistoryApi } from './services/custom/EducationHistory';
import { EmploymentHistoryApi } from './services/custom/EmploymentHistory';
import { EngagementActivityApi } from './services/custom/EngagementActivity';
import { EngagementLinkApi } from './services/custom/EngagementLink';
import { EngagementProgramApi } from './services/custom/EngagementProgram';
import { EngagementStageApi } from './services/custom/EngagementStage';
import { InterviewFeedbackApi } from './services/custom/InterviewFeedback';
import { JobBoardSetupApi } from './services/custom/JobBoardSetup';
import { JobInterviewsApi } from './services/custom/JobInterviews';
import { JobLocationApi } from './services/custom/JobLocation';
import { JobOffersApi } from './services/custom/JobOffers';
import { JobOpportunityMappingApi } from './services/custom/JobOpportunityMapping';
import { JobOpportunityApi } from './services/custom/JobOpportunity';
import { JobOrderJobBoardAssociationApi } from './services/custom/JobOrderJobBoardAssociation';
import { JobOrderApi } from './services/custom/JobOrder';
import { JobPlacementsApi } from './services/custom/JobPlacements';
import { JobPrescreenApi } from './services/custom/JobPrescreen';
import { JobSkillsApi } from './services/custom/JobSkills';
import { JobSubmittalsApi } from './services/custom/JobSubmittals';
import { JobTemplateApi } from './services/custom/JobTemplate';
import { QualificationApi } from './services/custom/Qualification';
import { QuestionSetApi } from './services/custom/QuestionSet';
import { QuestionsApi } from './services/custom/Questions';
import { ReferenceApi } from './services/custom/Reference';
import { ReferralApi } from './services/custom/Referral';
import { StandardQuestionApi } from './services/custom/StandardQuestion';
import { StateManagerApi } from './services/custom/StateManager';
import { UserAnalyticsApi } from './services/custom/UserAnalytics';
import { ReleaseNotesApi } from './services/custom/ReleaseNotes';
import { PageViewMappingApi } from './services/custom/PageViewMapping';
import { SourceApi } from './services/custom/Source';
import { DocumentRoleApi } from './services/custom/DocumentRole';
import { ConversationDeleteTrackerApi } from './services/custom/ConversationDeleteTracker';
import { ProblemTaskReasonCodeApi } from './services/custom/ProblemTaskReasonCode';
import { ChangeAssignedToGroupApi } from './services/custom/ChangeAssignedToGroup';
import { ChangeAssignedToVendorWorkerApi } from './services/custom/ChangeAssignedToVendorWorker';
import { ProjectGeoApi } from './services/custom/ProjectGeo';
import { ServiceoProblemReasonCodesApi } from './services/custom/ServiceoProblemReasonCodes';
import { TaxonomiesApi } from './services/custom/Taxonomies';
import { TaxonomySkillsApi } from './services/custom/TaxonomySkills';
import { AnswerSequenceItemApi } from './services/custom/AnswerSequenceItem';
import { AnswerSequenceApi } from './services/custom/AnswerSequence';
import { LearningAssignmentApi } from './services/custom/LearningAssignment';
import { LearningCategoryApi } from './services/custom/LearningCategory';
import { LearningVersionApi } from './services/custom/LearningVersion';
import { LearningApi } from './services/custom/Learning';
import { LearningQuestionApi } from './services/custom/LearningQuestion';
import { QuizApi } from './services/custom/Quiz';
import { ResponseApi } from './services/custom/Response';
import { TrainingPlanCategoryApi } from './services/custom/TrainingPlanCategory';
import { TrainingPlanSectionItemApi } from './services/custom/TrainingPlanSectionItem';
import { TrainingPlanSectionApi } from './services/custom/TrainingPlanSection';
import { TrainingPlanApi } from './services/custom/TrainingPlan';
import { TrainingResourceApi } from './services/custom/TrainingResource';
import { QuestionLineApi } from './services/custom/QuestionLine';
import { TrainingPlanAssignmentApi } from './services/custom/TrainingPlanAssignment';
import { TrainingPlanAccountApi } from './services/custom/TrainingPlanAccount';
import { TrainingPlanFeedbackApi } from './services/custom/TrainingPlanFeedback';
import { AchievementApi } from './services/custom/Achievement';
import { TrainingPlanAchievementApi } from './services/custom/TrainingPlanAchievement';
import { TrainingPlanSectionAssignmentApi } from './services/custom/TrainingPlanSectionAssignment';
import { JobWorkerRatingApi } from './services/custom/JobWorkerRating';
import { SiteVisitReportApi } from './services/custom/SiteVisitReport';
import { VendorRatingApi } from './services/custom/VendorRating';
import { CustomerCsatApi } from './services/custom/CustomerCsat';
import { LocationJobRecruitmentApi } from './services/custom/LocationJobRecruitment';
import { ResourceApi } from './services/custom/Resource';
import { ServiceTypeApi } from './services/custom/ServiceType';
import { PayslipManagerApi } from './services/custom/PayslipManager';
import { PayslipItemApi } from './services/custom/PayslipItem';
import { MaintenanceScheduleApi } from './services/custom/MaintenanceSchedule';
import { CustomPricebookEntryApi } from './services/custom/CustomPricebookEntry';
import { SkillingInsuranceApi } from './services/custom/SkillingInsurance';
import { MyWorkLeaveApprovalSettingApi } from './services/custom/MyWorkLeaveApprovalSetting';
import { MyWorkLeaveApprovalApi } from './services/custom/MyWorkLeaveApproval';
import { MyWorkLeaveEntitlementSettingApi } from './services/custom/MyWorkLeaveEntitlementSetting';
import { MyWorkLeaveRequestApi } from './services/custom/MyWorkLeaveRequest';
import { MyWorkLeaveTypeSettingApi } from './services/custom/MyWorkLeaveTypeSetting';
import { MyWorkLeaveType2ApprovalSettingApi } from './services/custom/MyWorkLeaveType2ApprovalSetting';
import { MyWorkLeaveWorkerBalanceApi } from './services/custom/MyWorkLeaveWorkerBalance';
import { MyWorkplaceTemplateSchedulerApi } from './services/custom/MyWorkplaceTemplateScheduler';
import { SkillingLicensesApi } from './services/custom/SkillingLicenses';
import { PasscodeApi } from './services/custom/Passcode';
import { CorrectiveActionRequestApi } from './services/custom/CorrectiveActionRequest';
import { CorrectiveActionRequestLineApi } from './services/custom/CorrectiveActionRequestLine';
import { AssociatedTaskApi } from './services/custom/AssociatedTask';
import { ExportLogApi } from './services/custom/ExportLog';
import { PageViewGroupApi } from './services/custom/PageViewGroup';
import { PageViewTitleApi } from './services/custom/PageViewTitle';
import { PeriodApi } from './services/custom/Period';
import { SkillingCertificationApi } from './services/custom/SkillingCertification';
import { NotesAttachmentApi } from './services/custom/NotesAttachment';
import { JobAssessmentApi } from './services/custom/JobAssessment';
import { ApplicationAssessmentApi } from './services/custom/ApplicationAssessment';
import { CheckInCalendarApi } from './services/custom/CheckInCalendar';
import { SharedLoginLogApi } from './services/custom/SharedLoginLog';
import { SprintApi } from './services/custom/Sprint';
import { SprintMasterApi } from './services/custom/SprintMaster';
import { MeetingApi } from './services/custom/Meeting';
import { MeetingAttendeesApi } from './services/custom/MeetingAttendees';
import { MeetingParticipantApi } from './services/custom/MeetingParticipant';
import { MeetingMinutesApi } from './services/custom/MeetingMinutes';
import { MeetingsKRApi } from './services/custom/MeetingsKR';
import { WSCRequestHeaderApi } from './services/custom/WSCRequestHeader';
import { WSCRequestDetailApi } from './services/custom/WSCRequestDetail';
import { ProgramHolidayApprovedCalendarApi } from './services/custom/ProgramHolidayApprovedCalendar';
import { ApplicantPreviewApi } from './services/custom/ApplicantPreview';
import { ApplicantResumeParseApi } from './services/custom/ApplicantResumeParse';
import { JobOrderJobBoardPostingApi } from './services/custom/JobOrderJobBoardPosting';
import { BusinessUnitApi } from './services/custom/BusinessUnit';
import { RequestFormJobsiteApi } from './services/custom/RequestFormJobsite';
import { PrePostTaskCaseApi } from './services/custom/PrePostTaskCase';
import { ApplicantFavouriteApi } from './services/custom/ApplicantFavourite';
import { ExpenseLineItemApi } from './services/custom/ExpenseLineItem';
import { SOWDetailApi } from './services/custom/SOWDetail';
import { JobPostingApi } from './services/custom/JobPosting';
import { JobAlertEmailTrackingApi } from './services/custom/JobAlertEmailTracking';


/**
* @module SDKBrowserModule
* @description
* This module should be imported when building a Web Application in the following scenarios:
*
*  1.- Regular web application
*  2.- Angular universal application (Browser Portion)
*  3.- Progressive applications (Angular Mobile, Ionic, WebViews, etc)
**/
@NgModule({
  imports:      [ CommonModule, HttpClientModule ],
  declarations: [ ],
  exports:      [ ],
  providers:    [
    ErrorHandler,
    SocketConnection
  ]
})
export class SDKBrowserModule {
  static forRoot(internalStorageProvider: any = {
    provide: InternalStorage,
    useClass: CookieBrowser
  }): ModuleWithProviders {
    return {
      ngModule  : SDKBrowserModule,
      providers : [
        LoopBackAuth,
        LoggerService,
        SDKModels,
        RealTime,
        AccountApi,
        JobApi,
        JobInstructionApi,
        JobCommentApi,
        TimecardApi,
        UsersApi,
        PaymentApi,
        InvoiceApi,
        PurchaseOrderApi,
        PurchaseOrderItemApi,
        PricelistApi,
        PricelistItemApi,
        JobsiteApi,
        DocumentApi,
        DocumentShareApi,
        TimezoneApi,
        UserTypeApi,
        CountryApi,
        DeliverableApi,
        VendorsiteApi,
        VendorsiteContactApi,
        AppointmentApi,
        WorkerApi,
        ContainerApi,
        DocumentCategoryApi,
        DocumentTitleApi,
        SkillApi,
        UserSkillApi,
        ProjectApi,
        CaseApi,
        JobsiteProjectsApi,
        ProductApi,
        ApprovedProjectVendorPoolApi,
        ContactApi,
        CountryCodeApi,
        GeoMetroApi,
        JobsiteContactApi,
        TalentTypeApi,
        WorkOrderApi,
        QuoteLineManagerApi,
        QuoteManagerApi,
        MetroVirtualVendorPoolApi,
        JobOrderItemApi,
        ServiceCategorySettingApi,
        RecordTypeApi,
        CaseCommentApi,
        WorkflowApi,
        WorkflowStageApi,
        WorkflowStatusApi,
        WorkflowTransitionApi,
        OrderApi,
        FilterServiceApi,
        DashboardApi,
        RequestFormReceiptApi,
        RequestFormReceiptLineApi,
        IronCustomFieldApi,
        AssetApi,
        SkillingApi,
        CsqdCaseCommentApi,
        AssetGroupApi,
        ContractApi,
        AttachmentApi,
        PmsIccPmcRecordTypeMappingApi,
        GeoMessageApi,
        SupportedAssetModelNumbersApi,
        FSLFRUTransactionListApi,
        MapSettingsApi,
        NeighboringCountriesApi,
        NeighboringCountriesHeaderApi,
        GeoSessionLogApi,
        CsqdActivityApi,
        ServiceActivityMappingApi,
        GoogleServiceApi,
        WorkflowStageLogApi,
        VatMasterApi,
        AlertApi,
        UserAlertApi,
        ActivityApi,
        ProjectWorkerApi,
        CommentApi,
        GlobalTalentPoolApi,
        DepartmentApi,
        EmailServiceApi,
        OrderItemApi,
        ContactTypeApi,
        AccessControlApi,
        AssignmentApi,
        DepartmentRoleApi,
        GroupApi,
        MemberRoleApi,
        MilestoneApi,
        ProgramGroupApi,
        ProgramApi,
        Project2Api,
        TaskApi,
        WorkerShiftApi,
        ChannelApi,
        ConversationFileApi,
        ConversationParticipantApi,
        ConversationRelationApi,
        ConversationTrackerApi,
        ConversationApi,
        FileCategoryApi,
        FilesApi,
        FavoriteApi,
        LibraryDetailApi,
        LibraryHeaderApi,
        LibraryApi,
        TaskAttributeApi,
        TaskGroupAttributeApi,
        TaskGroupApi,
        EdiTransactionApi,
        BitlyServiceApi,
        SMSServiceApi,
        TalentPoolWorkersApi,
        ReturnOrderApi,
        ReturnOrderLineItemApi,
        LocationApi,
        ProductRequestApi,
        ProductRequestLineItemApi,
        ProductTransferApi,
        ServiceContractApi,
        ServiceContractLineItemApi,
        JobApplicationApi,
        UserDeviceApi,
        StateApi,
        JobDataApi,
        FeedbackApi,
        AppVersionApi,
        TalentInformationApi,
        TalentProfileApi,
        IronAttachmentApi,
        TeamShiftApi,
        RequestorDepartmentApi,
        RequestFormReceiptEscalationApi,
        VoiceServiceApi,
        JobWorkerTrackingApi,
        ApiKeyApi,
        TimeClockManagerApi,
        ExpenseReportApi,
        PublicAccessApi,
        ExpenseCodeApi,
        MonthlyInvoiceApi,
        VendorPPEHoursApi,
        ApvpDistributionListWorkersApi,
        ApvpWorkersVmsApi,
        WorkerVendorsiteApi,
        VCSQDLeadApi,
        SkillingVerifyApi,
        CSQDSessionDataApi,
        TimeBreakApi,
        SkillingBackgroundCheckApi,
        JobScheduleApi,
        NoteApi,
        TimeChangeRequestApi,
        InvoiceAdjustmentApi,
        MonthlyPaymentApi,
        MonthlyInvoiceItemApi,
        CsqdleadTalenttypeMappingApi,
        AdjustmentReasonCodeApi,
        MilestoneLibraryApi,
        TaskLibraryApi,
        RequestFormReceiptTemplateApi,
        TemplateHeaderApi,
        MilestoneTaskLibraryMapApi,
        TemplateDetailApi,
        AddressApi,
        ServiceTerritoryApi,
        JobSearchAlertApi,
        AnswersApi,
        CertificationApi,
        EducationHistoryApi,
        EmploymentHistoryApi,
        EngagementActivityApi,
        EngagementLinkApi,
        EngagementProgramApi,
        EngagementStageApi,
        InterviewFeedbackApi,
        JobBoardSetupApi,
        JobInterviewsApi,
        JobLocationApi,
        JobOffersApi,
        JobOpportunityMappingApi,
        JobOpportunityApi,
        JobOrderJobBoardAssociationApi,
        JobOrderApi,
        JobPlacementsApi,
        JobPrescreenApi,
        JobSkillsApi,
        JobSubmittalsApi,
        JobTemplateApi,
        QualificationApi,
        QuestionSetApi,
        QuestionsApi,
        ReferenceApi,
        ReferralApi,
        StandardQuestionApi,
        StateManagerApi,
        UserAnalyticsApi,
        ReleaseNotesApi,
        PageViewMappingApi,
        SourceApi,
        DocumentRoleApi,
        ConversationDeleteTrackerApi,
        ProblemTaskReasonCodeApi,
        ChangeAssignedToGroupApi,
        ChangeAssignedToVendorWorkerApi,
        ProjectGeoApi,
        ServiceoProblemReasonCodesApi,
        TaxonomiesApi,
        TaxonomySkillsApi,
        AnswerSequenceItemApi,
        AnswerSequenceApi,
        LearningAssignmentApi,
        LearningCategoryApi,
        LearningVersionApi,
        LearningApi,
        LearningQuestionApi,
        QuizApi,
        ResponseApi,
        TrainingPlanCategoryApi,
        TrainingPlanSectionItemApi,
        TrainingPlanSectionApi,
        TrainingPlanApi,
        TrainingResourceApi,
        QuestionLineApi,
        TrainingPlanAssignmentApi,
        TrainingPlanAccountApi,
        TrainingPlanFeedbackApi,
        AchievementApi,
        TrainingPlanAchievementApi,
        TrainingPlanSectionAssignmentApi,
        JobWorkerRatingApi,
        SiteVisitReportApi,
        VendorRatingApi,
        CustomerCsatApi,
        LocationJobRecruitmentApi,
        ResourceApi,
        ServiceTypeApi,
        CorrectiveActionRequestApi,
        CorrectiveActionRequestLineApi,
        AssociatedTaskApi,
        CustomPricebookEntryApi,
        internalStorageProvider,
        PayslipManagerApi,
        PayslipItemApi,
        SkillingInsuranceApi,
        MaintenanceScheduleApi,
        CustomPricebookEntryApi,
        SkillingLicensesApi,
        internalStorageProvider,
        MyWorkLeaveApprovalSettingApi,
        MyWorkLeaveApprovalApi,
        MyWorkLeaveEntitlementSettingApi,
        MyWorkLeaveRequestApi,
        MyWorkLeaveTypeSettingApi,
        MyWorkLeaveType2ApprovalSettingApi,
        MyWorkLeaveWorkerBalanceApi,
        MyWorkplaceTemplateSchedulerApi,
        ExportLogApi,
        PasscodeApi,
        PageViewGroupApi,
        PageViewTitleApi,
        PeriodApi,
        CheckInCalendarApi,
        SkillingCertificationApi,
        NotesAttachmentApi,
        JobAssessmentApi,
        ApplicationAssessmentApi,
        SharedLoginLogApi,
        SprintApi,
        SprintMasterApi,
        MeetingApi,
        MeetingAttendeesApi,
        MeetingParticipantApi,
        MeetingMinutesApi,
        MeetingsKRApi,
        WSCRequestHeaderApi,
        WSCRequestDetailApi,
        ProgramHolidayApprovedCalendarApi,
        ApplicantPreviewApi,
        ApplicantResumeParseApi,
        JobOrderJobBoardPostingApi,
        BusinessUnitApi,
        RequestFormJobsiteApi,
        PrePostTaskCaseApi,
        ApplicantFavouriteApi,
        ExpenseLineItemApi,
        SOWDetailApi,
        JobPostingApi,
        JobAlertEmailTrackingApi,
        { provide: SDKStorage, useClass: StorageBrowser },
        { provide: SocketDriver, useClass: SocketBrowser }
      ]
    };
  }
}
/**
* Have Fun!!!
* - Jon
**/
export * from './models/index';
export * from './services/index';
export * from './lb.config';
export * from './storage/storage.swaps';
export { CookieBrowser } from './storage/cookie.browser';
export { StorageBrowser } from './storage/storage.browser';

