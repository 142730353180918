import { Component, OnInit, OnDestroy, Input, AfterViewInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { PreloaderService } from '../../services/preloader.service';
import { ModalService } from './../../services/modal.service';
import { AppStateService } from '../../services/app-state.service';
import { AlertService } from '../../services/alert.service';
import { ConfirmDialogService } from '../../services/confirm-dialog.service';
import { CommonService } from '../../services/common.service';
import { TimecardApi } from 'shared/sdk';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'app-timecard-list',
    templateUrl: './timecard-list.component.html',
    styleUrls: ['./timecard-list.component.css']
})

export class TimecardListComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('timecardModal') timeSheetModalRef: ElementRef;

    @Input() modelName: string;
    @Input() modelId: string;
    @Input() timeZone: any = '';
    @Input() IsActiveLink = false;
    @Input() view = '';
    @Input() saveAnalytics = true;
    @Output() removeTimeCard: EventEmitter<any> = new EventEmitter;

    timecardId = '';
    Title = 'Time Card List';
    currentExpandViewId: any;
    modalTitle: any;
    query = {};
    isInternalUser = false;
    /*Boot-Datatable params */
    timecardList = [];
    orderBy = 'CreatedDate DESC';
    ExpandViewTitle: any;
    userRole: any;
    roleSub: Subscription;
    /*ngb pagination settings */
    total = 0;
    pageSize = 10;
    currentPage = 1;
    offset = 0;
    isPagination: Boolean = false;
    isLoading = false;
    userType = '';
    @Input() isFteJob = false;
    viewAsVendor = false;

    constructor(
        private _appState: AppStateService,
        private _modalService: ModalService,
        private _timeCardApi: TimecardApi,
        private _loader: PreloaderService,
        private _commonService: CommonService,
        private _confirmDialogService: ConfirmDialogService,
        private _alertService: AlertService,
        private _activatedRoute: ActivatedRoute,
        private _ChangeDetectorRef:ChangeDetectorRef

    ) {
        this._commonService.timeCardAdd.subscribe((res) => {
            if (res) {
                this.getTimeCardList(this.query, 0);
                // this.getTimecardListing();
            }
        })
    }

    ngOnChanges(){
        this._commonService.timeCardAdd.subscribe((res) => {
            if (res) {
                this.getTimeCardList(this.query, 0);
            }
        })
    }

    updateData(params) {
        if (params && params['type'] === 'Add') {
            this.getTimeCardList(this.query, 0);
        } else {
            const savedTimeCard = params['_data'];
            this.timecardList.filter((value, index) => {
                if (value['id'] === savedTimeCard['id']) {
                    return this.timecardList[index] = this.changeData(savedTimeCard);
                }
            });
        }
    }

    ngAfterViewInit() { }

    ngOnInit() {
        if (this.saveAnalytics) {
            this._commonService.saveCurrentPageAnalytics('Job Manager Console - Details', 'Time Card');
        }
        this.userType = this._appState.getAccessType();
        this.modelName = this.modelName.toLowerCase();
        this.roleSub = this._appState.getJobDetailUserRole().subscribe(role => {
            if (role && role['userRole']) {
                this.userRole = role['userRole'];
            }
        })
        this.columnFormation();
        this.getTimeCardList(this.query, 0);
        this._appState.getViewAsVendorObject().subscribe((res: any) => {
            if(res && res['accessType']) {
              if(res['accessType'] === 'vendor') {
                // this.viewAsVendorUserType = res.userTypeSlug;
                this.viewAsVendor = true;
              }          
            } else {
              this.viewAsVendor = false;
            }
          })
    }

    ngOnDestroy() {
        if (this.roleSub) {
            this.roleSub.unsubscribe();
        }
    }

    columnFormation() {
        const accessType = this._appState.getAccessType();
        if (accessType && accessType === 'internal') {
            this.isInternalUser = true;
        }
        if (this.modelName && this.modelName === 'job') {
            this.query =
            {
                and: [
                    { Service_Dispatch__c: this.modelId },
                    { or: [{ 'IsServiceODelete': 0 }, { 'IsServiceODelete': null }] }
                ]
            }
        } else if (this.modelName && this.modelName === 'timecard') {
            this.query = { or: [{ IsServiceODelete: 0 }, { IsServiceODelete: null }] };
        }

        else if (this.modelName && this.modelName === 'purchaseorder') {
            this._activatedRoute.queryParams.subscribe(params => {
                if (params) {                   
                    this.query =
                    {
                        and: [
                            { Purchase_Order_Ref__c: params.purchaseOrderId },
                            { or: [{ 'IsServiceODelete': 0 }, { 'IsServiceODelete': null }] }
                        ]
                    }
                }
            });
            
        }
    }

    refresh(){
        this._ChangeDetectorRef.detectChanges();
        this.getTimeCardList(this.query, 0);
    }

    getTimeCardList(params: any, offset: number) {
        this._loader.showPreloader();
        this.isLoading = true;
        const req = {
            'where': params,
            'jobId': this.modelId,
            'limit': this.pageSize,
            'order': this.orderBy,
            'skip': offset,
            'count': true
        }
        this._timeCardApi.getTimeCardList(req).subscribe(
            results => {
                if (results.data && results.data.length > 0) {
                    this.timecardList = this.modifyData(results.data);
                    this.total = results.count;
                    this.isPagination = (this.total <= this.pageSize) ? false : true;
                    this._loader.hidePreloader();
                    this.isLoading = false;
                } else {
                    this._loader.hidePreloader();
                    this.timecardList = [];
                    this.isLoading = false;
                }
            },
            error => {
                this.isLoading = false;
                this._loader.hidePreloader();
            }
        );
    }

    modifyData(data) {
        if (data.length) {
            data.forEach(item => {
                this.changeData(item)
            });
            return data;
        }
    }

    changeData(item) {
        const timeFormat = 'dddd, L, LT z';
        item['Name'] = (item.Name) ? item.Name : '';
        item['Customer_Approval_Status__c'] = item.Customer_Approval_Status__c ? item.Customer_Approval_Status__c : '';
        item['Final_Timecard__c'] = item.Final_Timecard__c ? item.Final_Timecard__c : '';
        item['Purchase_Order_Ref__c'] = item.Purchase_Order_Ref__c ? item.Purchase_Order_Ref__c : '';
        item['Vendor__c'] = item.Vendor__c ? item.Vendor__c : '';
        item['Visit_Number_Calc__c'] = item.Visit_Number_Calc__c ? item.Visit_Number_Calc__c : '';
        item['sfdcId'] = item.sfdcId ? item.sfdcId : '';
        item['id'] = item.id ? item.id : '';
        item['Total_Worked_Hours_Without_Offset__c'] = item.Total_Worked_Hours_Without_Offset__c ?
            item.Total_Worked_Hours_Without_Offset__c : '';
        item['Customer_Site_Signoff_Name__c'] = item.Customer_Site_Signoff_Name__c ? item.Customer_Site_Signoff_Name__c : '';
        item['Final_Timecard'] = (item.Final_Timecard__c === true) ? 'Yes' : 'No';
        item['workerName'] = (item.worker) ? item.worker.Name : '';
        item['workersfdcId'] = (item.worker) ? item.worker.sfdcId : '';
        item['Iron_Job_num__c'] = (item.job) ? item.job.Iron_Job_num__c : '';
        item['Work_Order_num__c'] = (item.purchaseOrder) ? item.purchaseOrder.Work_Order_num__c : '';
        item['vendorName'] = (item.vendor) ? item.vendor.Name : '';
        item['vendorsfdcId'] = (item.vendor) ? item.vendor.sfdcId : '';
        item['Vendor_Time_Card_Time_in_Actual__c'] = (item.Vendor_Time_Card_Time_in_Actual__c) ?
            this._commonService.applyNewTimeZone(item.Vendor_Time_Card_Time_in_Actual__c, this.timeZone) : '';
        item['Vendor_Time_Card_Time_Out_Actual__c'] = (item.Vendor_Time_Card_Time_Out_Actual__c) ?
            this._commonService.applyNewTimeZone(item.Vendor_Time_Card_Time_Out_Actual__c, this.timeZone) : '';
        item['CreatedDate'] = (item.CreatedDate) ?
            this._commonService.applyNewTimeZone(item.CreatedDate, this.timeZone) : '';
        item['CreatedOn'] = (item.createdAt) ?
            this._commonService.applyNewTimeZone(item.createdAt, this.timeZone) : '';
        item['createdAt'] = this._commonService.dateFormate(item['createdAt'], this.timeZone, timeFormat);
        item['submittedTimecard'] = (item.job) ? item.job.submittedTimecard : false;
        item['Equipment_Serial_Performed_on__c'] = item.Equipment_Serial_Performed_on__c ? item.Equipment_Serial_Performed_on__c : '';
        item['Equipment_Make_Model_Performed_on__c'] = item.Equipment_Make_Model_Performed_on__c ? item.Equipment_Make_Model_Performed_on__c : '';
        item['Worker_Mobile_OS_Android_iOS__c'] = item.Worker_Mobile_OS_Android_iOS__c ? item.Worker_Mobile_OS_Android_iOS__c : '';
        item['LastModifiedDate'] = (item.updatedAt) ? this._commonService.applyNewTimeZone(item.updatedAt, this.timeZone) : ''; 
        item['LastModifiedBy'] = (item.lastModifiedBy && item.lastModifiedBy.sfdcId) ? item.lastModifiedBy.firstname + ' ' + item.lastModifiedBy.lastname : '';
        if (item && item.job) {
            delete item.job;
        }
        if (item && item.vendor) {
            delete item.vendor;
        }
        if (item && item.purchaseOrder) {
            delete item.purchaseOrder;
        }
        if (item && item.worker) {
            delete item.worker;
        }
        return item;
    }

    editTimecard(content, size, id) {
        this.ExpandViewTitle = 'Update Timecard';
        this.currentExpandViewId = id;
        this.modalTitle = 'Update Timecard';
        this._modalService.open(content, size);
    }

    addTimecard(content, size) {
        this._modalService.open(content, size);
    }

    deleteTimecard(timecard) {
        const _thisEvnt = this;
        this._confirmDialogService.confirmThis(
            {
                title: 'Warning!!',
                titleIcon: 'mdi mdi-alert text-warning',
                text: 'Do you really want to delete the timecard ?'
            },
            function () {
                _thisEvnt._loader.showPreloader();
                _thisEvnt._timeCardApi.patchAttributes(timecard.id, { IsServiceODelete: true }).subscribe(
                    data => {
                        if (data) {
                            const tmpTimeCard = _thisEvnt.timecardList;
                            _thisEvnt.timecardList = [];
                            _thisEvnt.timecardList = tmpTimeCard.filter((val, index) => {
                                if (val['id'] !== data.id) {
                                    return val;
                                } else {
                                    delete _thisEvnt.timecardList[index];
                                }
                            });
                            _thisEvnt._loader.hidePreloader();
                            _thisEvnt._alertService.success('Timecard has been deleted successfully.');
                            window.scrollTo(0, 0);
                            _thisEvnt.removeTimeCard.emit(true);
                        } else {
                            _thisEvnt._alertService.warn('Something went wrong.');
                            _thisEvnt._loader.hidePreloader();
                        }
                    },
                    err => {
                        this._alertService.warn(err);
                        window.scrollTo(0, 0);
                        _thisEvnt._loader.hidePreloader();
                    }
                );
            },
            function () {
                // Do nothing on cancel
                _thisEvnt._loader.hidePreloader();
            }
        );
    }

    refreshList(event) {
        console.log(event);
        if (event && event.type === "Add") {
            this.timecardList.push(event._data);
        }
    }

    pageChange(pageNo) {
        this.currentPage = pageNo;
        this.offset = (pageNo - 1) * this.pageSize;
        this.getTimeCardList(this.query, this.offset);
    }

    openDetailPage(id) {
        if(this.isInternalUser && !this.viewAsVendor) {
            this.timecardId = id;
            this._modalService.open(this.timeSheetModalRef, 'xxl');
        }
    }

    apiCallEventHandler() {
        this._modalService.closed();
    }
}
