import { CustomerCsatApi } from './../sdk/services/custom/CustomerCsat';
import { AppStateService } from 'shared/services/app-state.service';
import { Injectable, OnInit, Pipe } from '@angular/core';
import { Subject, BehaviorSubject, Observable, from, forkJoin } from 'rxjs';
import * as moment from 'moment-timezone'; 

import { ContainerApi } from '../sdk/services/custom/Container';
import { DocumentCategoryApi } from '../sdk/services/custom/DocumentCategory';
import { CountryCodeApi } from '../sdk/services/custom/CountryCode';
import { DatePipe, JsonPipe } from '@angular/common';
import { environment } from '../../environments/environment';
import { StateApi } from '../sdk/services/custom/State';
import {ContactApi, NoteApi, StateManagerApi, UsersApi, JobOrderApi} from 'shared/sdk';
import { saveAs } from 'file-saver';
import { PmsIccPmcRecordTypeMappingApi, RecordTypeApi, AssignmentApi, WorkerApi, MemberRoleApi, UserAnalyticsApi, PageViewMappingApi, AccountApi } from '../../shared/sdk/index';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { TeamShiftApi } from 'shared/sdk/services/custom/TeamShift';
import { ApiKeyApi } from './../sdk/services/custom/ApiKey';
import { Router } from '@angular/router';
import { PreloaderService } from './preloader.service';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas';
import { AbstractControl } from '@angular/forms';
@Injectable()
export class CommonService {
    public userProfile: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public jobRecordTypeSub: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private arrPrevNext: Subject<any> = new Subject<any>();
    // arrPrevNext$= this.arrPrevNext.asObservable();
    private accountTypeList = new BehaviorSubject<object>(null);
    private accountTypefilter = new BehaviorSubject<object>(null);
    private customAccountTypeList = new BehaviorSubject<object>(null);
    private customAccountTypefilter = new BehaviorSubject<object>(null);
    timeCardAdd: Subject<any> = new Subject<any>();
    private contactList = new BehaviorSubject<object>(null);
    private contactfilter = new BehaviorSubject<object>(null);
    public expenseTypeList: Subject<any> = new Subject<any>();
    private serviceCatalogInfo = new BehaviorSubject<object>(null);
    public rightSidebarState: Subject<any> = new Subject<any>();
    private jobTypeList = new BehaviorSubject<object>(null);
    private appTypeList = new BehaviorSubject<object>(null);
    private jobTypefilter = new BehaviorSubject<object>(null);
    private appTypefilter = new BehaviorSubject<object>(null);
    private customJobTypeList = new BehaviorSubject<object>(null);
    private customAppTypeList = new BehaviorSubject<object>(null);
    private customJobTypefilter = new BehaviorSubject<object>(null);
    private customAppTypefilter = new BehaviorSubject<object>(null);
    jobRecordType: any = {};
    pmsRecordType: any = {};
    teamShiftData: Array<any> = [];
    teamShiftIccCaseNumber = {}
    private timeZone: string;
    pmcAllRecordType = [];
    pageViewMapData = [];
    oldPageAnalyticsData = {};
    initialLoad = true;
    localAnalyticsObj = {};
    truncateUrlIdsTab = [
        'Payment Manager Console Details', 'Timecard-Details', 'Talent Overview', 'Talent Overview Edit', 'Worker Skilling Profile', 'Add Skill Profile',
        'BGV / Drug Test', 'Add BGV Drug Test', 'Identity Verification', 'Add New Identity Proof',
        'Add New Contact', 'Other Contact Information', 'PMS Case Details', 'Appointment', 'Worker Status', 'Summary', 'Manager', 'Hardware Info', 'Global Program Instructions',
        'Time Card', 'Billing', 'Vendor Info', 'Worker Info', 'Files', 'Related Jobs', 'Activity Feeds Listing',
        'Account Profile - Company Overview', 'Account Profile - Company Capabilities', 'Account Profile - Banking Tax Information', 'Account Profile - Account Onboarding Documents', 'Account Profile - Vendor Contacts', 'Global Talent Pool Detail View',
        'Overview', 'Project GeoList', 'Approved Project Vendor Pool Accounts - VMS List', 'APVP Worker List - VMS List', 'APVP Worker List - VFMS List',
        'Projects (Project Routing Profile)', 'Add Worker VFMS', 'Edit Worker VFMS', 'New APVP Workers - VMS', 'Update APVP Workers - VMS', 'New Project GEO', 'Update Project GEO',
        'FSE Status Audit', 'FRU Status Audit', 'PMS Case', 'Work Order', 'PMC/Triage Case', '3PS/ICC Case', '3PL/ICC Case', 'Worker List (APVP)', 'Job Detail',
        'VFMS/WM Information Sent (ISG)', 'Worker List (APVP)', 'B2B Message', 'PMS Case Print', 'PMS Case Editing', 'Hardware List',
        'Hardware Assest', 'Hardware Parts Dispatch', 'Hardware Asset Recovery', 'Hardware Asset Returns', 'Hardware Depot', 'Hardware All', 'Warehouse and Logistics', 'Return Order', 'Service Contract', 'Service Manager FSE Escalation',
        'Billing - Partner Info', 'Billing - Sales Order', 'Billing - Time Card', 'Billing - Sales Invoice', 'Billing - Payment', 'Billing - Vendor Info', 'Billing - Purchase Order', 'Billing - Purchase Invoice',
        'Worker - Info', 'Worker - Appointment', 'Worker - Vendor Info', 'Worker - Dispatch FSE', 'Worker - Dedicated FTE', 'FSE Console - File Manager', 'FSE Console - Messages', 'Return Order - Edit', 'Return Order - Print',
        'APVP Overview - Delete', 'APVP Overview - Edit', 'APVP Overview - Refresh VMS Worker', 'APVP Overview - Populate APVP', 'APVP Overview - Clone','Job Order Detail','Applications','Interviews','Placements','Apply to this Job',
        'Candidate CAQ', 'Edit Hiring Details', 'Edit Application Details', 'Send-Email', 'Public CAQ Form', 'ServiceO', 'Career Builder', 'Naukri', 'Indeed', 'LinkedIn', 'Submittals', 'Offers', 'Application Overview', 'Submittal Overview', 'Interview Overview',
        'Offer Overview', 'Placement Overview', 'All Conversation','Job Order Detail Via Email'

    ]
    skipUrlIdsTab = [
        'app/feeds'
    ]
    pageScrolled = false;
    private leftSideFilters: Subject<any> = new Subject<any>();
    public messagePostFileUploaded: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    public isMobileAnalytics: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    public removeRecordTypeIds: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    private applicationCount = new BehaviorSubject<object>(null);
    public programId  = new BehaviorSubject<string>(null);
    constructor(
        private containerApi: ContainerApi,
        private documentCategoryApi: DocumentCategoryApi,
        private _countryCode: CountryCodeApi,
        private _state: StateApi,
        private _stateManagerApi: StateManagerApi,
        private _pmsIccPmcRecordTypeMappingApi: PmsIccPmcRecordTypeMappingApi,
        private _recordTypeApi: RecordTypeApi,
        private _teamShiftApi: TeamShiftApi,
        private _assignment: AssignmentApi,
        private _apiKey: ApiKeyApi,
        private _workerApi: WorkerApi,
        private _appState: AppStateService,
        private _memberRole: MemberRoleApi,
        private _router: Router,
        private _userAnalyticsApi: UserAnalyticsApi,
        private _pageViewMappingApi: PageViewMappingApi,
        private _accountApi: AccountApi,
        private _customerCsatApi: CustomerCsatApi,
        private _preloaderService: PreloaderService,
        private _usersApi: UsersApi,
        private _contactApi: ContactApi,
        private _noteApi: NoteApi,
        private _jobOrderApi: JobOrderApi
    ) {
        this.userProfile.subscribe(user => {
            if (user) {
                this.timeZone = user['timezone'];
            }
        });
    }

    setExpenseTypeList(expenseList: any) {
        this.expenseTypeList.next(expenseList);
    }

    setRightSidebarStateState(state:any) {
        this.rightSidebarState.next(state);
    }
    getRightSidebarStateState() {
       return this.rightSidebarState;
    }
    setAccountTypeList(accountList: any) {
        this.accountTypeList.next(accountList);
    }
    getAccountTypeList() {
        return this.accountTypeList;
    }
    setAccountTypeFilter(filterCondition: any) {
        this.accountTypefilter.next(filterCondition);
    }
    getAccountTypeFilter() {
        return this.accountTypefilter;
    }

    setCustomAccountTypeList(accountList: any, controlName: string) {
        this.customAccountTypeList.next({
            ...this.customAccountTypeList.value,
            [controlName]: accountList
        });
    }
    getCustomAccountTypeList() {
        return this.customAccountTypeList;
    }
    setCustomAccountTypeFilter(filterCondition: any, controlName: string) {
        this.customAccountTypefilter.next({
            ...this.customAccountTypefilter.value,
            [controlName]: filterCondition
        });
    }
    getCustomAccountTypeFilter() {
        return this.customAccountTypefilter;
    }
    setAppTypeList(accountList: any) {
        this.appTypeList.next(accountList);
    }
    getAppTypeList() {
        return this.appTypeList;
    }
    setJobTypeList(accountList: any) {
        this.jobTypeList.next(accountList);
    }
    getJobTypeList() {
        return this.jobTypeList;
    }
    setJobTypeFilter(filterCondition: any) {
        this.jobTypefilter.next(filterCondition);
    }
    getJobTypeFilter() {
        return this.jobTypefilter;
    }
    setCustomJobTypeList(accountList: any, controlName: string) {
        this.customJobTypeList.next({
            ...this.customJobTypeList.value,
            [controlName]: accountList
        });
    }
    getCustomJobTypeList() {
        return this.customJobTypeList;
    }
    setCustomJobTypeFilter(filterCondition: any, controlName: string) {
        this.customJobTypefilter.next({
            ...this.customJobTypefilter.value,
            [controlName]: filterCondition
        });
    }
    getCustomJobTypeFilter() {
        return this.customJobTypefilter;
    }
    setAppTypeFilter(filterCondition: any) {
        this.appTypefilter.next(filterCondition);
    }
    getAppTypeFilter() {
        return this.appTypefilter;
    }
    setCustomAppTypeList(accountList: any, controlName: string) {
        this.customAppTypeList.next({
            ...this.customAppTypeList.value,
            [controlName]: accountList
        });
    }
    getCustomAppTypeList() {
        return this.customAppTypeList;
    }
    setCustomAppTypeFilter(filterCondition: any, controlName: string) {
        this.customAppTypefilter.next({
            ...this.customAppTypefilter.value,
            [controlName]: filterCondition
        });
    }
    getCustomAppTypeFilter() {
        return this.customAppTypefilter;
    }
    setContactList(contactList: any) {
        this.contactList.next(contactList);
    }
    getContactList() {
        return this.contactList;
    }
    setContactFilter(filterCondition: any) {
        this.contactfilter.next(filterCondition);
    }
    getContactFilter() {
        return this.accountTypefilter;
    }

    getUserProfile(): Observable<any> {
        return this.userProfile.asObservable();
    }

    setUserProfile(userProfile: any, isDelete = false) {
        if (!isDelete) {
            const imgpath = environment.baseUrl + '/' + environment.apiVersion;
            const imageUrl = (userProfile['url'] && userProfile['profileImage']) ?
                imgpath + '/Containers' + userProfile['url'] + '' + userProfile['profileImage'] : '';
            if (imageUrl) {
                this.imageExists(imageUrl, function (exists: any) {
                    userProfile['isProfile'] = (exists) ? imageUrl : '';
                });
            } else { userProfile['isProfile'] = ''; }
        } else {
            userProfile['isProfile'] = '';
        }
        this.userProfile.next(userProfile);
    }

    fetchApiKey() {
        this._apiKey.find({ where: { apiName: 'osmMapKey', isActive: true } }).subscribe(res => {
            if (res && res.length) {
                localStorage.setItem('osmMapObjKey', JSON.stringify(res[0]));
            }
        }, err => {
            console.log(err);
        });
    }

    fetchMapBoxTileLayerUrl() {
        const osmObj = JSON.parse(localStorage.getItem('osmMapObjKey'));
        // tslint:disable-next-line:max-line-length
        return `https://api.mapbox.com/styles/v1/${osmObj.webAccountName}/${osmObj.webStyleKey}/tiles/256/{z}/{x}/{y}?access_token=${osmObj.webApiKey}`
    }

    /*check if file exist in respective container*/
    fileExist(container, fileName) {
        return this.containerApi.getFile(container, fileName).map(res => res);
    }

    removeFile(container, fileName) {
        return this.containerApi.removeFile(container, fileName).map(res => res);
    }

    // check file type
    isImage(file: File): boolean {
        return /^image\//.test(file.type);
    }

    /**
     * get all category for listing page
     */
    getCategory(modelName) {
        return this.documentCategoryApi
            .find({
                where: {
                    isActive: 1,
                    modelName: modelName
                },
                fields: {
                    id: true,
                    title: true,
                    parentId: true
                }
            })
            .map(data => data);
    }

    setPrevNext(arrId) {
        const inArrID = [];
        arrId.forEach((item, index) => {
            inArrID.push(item['id']);
        });
        this.arrPrevNext.next(inArrID);
    }

    getPrevNext() {
        return this.arrPrevNext.asObservable();
    }

    getCountries() {
        return this._countryCode
            .find({ fields: {sfdcId: true, Name: true, Country__c: true }, order: 'Country__c' })
            .map(data => data);
    }
    /**
     *
     * @param fileName
     * @param allowedExtensions
     */
    validateExtention(fileName, allowedExtensions) {
        if (fileName) {
            const parts = fileName.split('.'),
                extension = parts[parts.length - 1];
            if (allowedExtensions.indexOf(extension.toLowerCase()) === -1) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    // Method to convert given time into different timezone with changeing time according to timezone. It will return ISO string.
    applyTimeZone(value: string, timeZone = '') {
        if (!timeZone) {
            timeZone = this.timeZone;
        }
        if (value) {
            const datePipe = new DatePipe('en-US');
            value = datePipe.transform(value, 'short', timeZone);
            return new Date(value).toISOString();
        } else {
            return value;
        }
    }

    // Method to convert date in timezone and given formate. It use moment timezone for convert timezone.
    convertTimeZone(value: string, timeZone='', format = 'L LT') {
        if (value) {
            let time = timeZone && timeZone.length ? moment(value).tz(timeZone): moment(value);
            return format ? time.format(format) : time.format();
        } else {
            return value;
        }
    }

    // Method to get same given time in different timezone
    setNewTimeZone(value: string, timeZone: string ,isSecond = true) {
        if (value && timeZone) {
            const time = moment(value);
            let newDate = (isSecond) ? time.format('MMM D, YYYY, h:mm:ss A') : time.format('MMM D, YYYY, h:mm A');            
            const tz = time.tz(timeZone).format('Z');
            newDate = newDate + ' ' + tz;
            const newTime = moment(new Date(newDate));
            return newTime.isValid() ? newTime.toISOString() : value;
        } else {
            return value;
        }
    }

    // Convert timezone of give ISO string and return new time in ISOString
    applyNewTimeZone(value: string, timeZone = '') {
        if (!timeZone) {
            timeZone = this.timeZone;
        }
        if (value && timeZone) {
            const time = moment(value);
            const tz = timeZone ? time.tz(timeZone).format('MMM D, YYYY, h:mm:ss A') : value;
            return new Date(tz).toISOString();
        } else {
            return value
        }
    }
    // format date based on momemnt. if timezone is available then also return timezone
    dateFormate(value: string | Date, timeZone = '', format = 'MMM D, YYYY, h:mm:ss A') {
        const time = moment(value);
        if (value && time.isValid()) {
            return timeZone ? time.tz(timeZone).format(format) : time.format(format);
        } else {
            return value;
        }
    }

    // format date based on momemnt in utc
    utcDateFormate(value: string, format = 'MMM D, YYYY, h:mm:ss A') {
        const time = moment(value).utc();
        if (value && time.isValid()) {
            return time.format(format);
        } else {
            return value;
        }
    }

    /** Method to add Time 
     *  Type option : [y, m, d, h, m, s]
     **/

    addTime(value, addValue, type = 'h', cleanSecond = false) {
        if (cleanSecond) {
            return moment(value).add(addValue, type).set({ second: 0 });
        }
        return moment(value).add(addValue, type);
    }

    /**
     * Method to create date range from subtracting given value by type to present day, to be used in any filter condition.
     * @param value | pass numeric value to subtract.
     * @param type | options to pass - years, quarters, months, weeks, days, hours, minutes, seconds, milliseconds.
     * @param startOfType | options - year, month, quarter, week, isoWeek, day, date, hour, minute, second.
     * @param endOfType | options - year, month, quarter, week, isoWeek, day, date, hour, minute, second.
    */
    CreateDateRangeBySubtract(value, type = 'days', startOfType = 'day', endOfType = 'day') {
        const startDate = moment().startOf(startOfType).subtract(value, type).format();
        const endDate = moment().endOf(endOfType).format();
        return { between: [startDate, endDate] };
    }

    /** Method to get difference between two dates
     *  Type option : [year, months, date, hour, minutes, seconds]
     **/

    getTimeDiff(dt1, dt2, type = 'minutes') {
        const date1 = dt1 ? moment(dt1) : moment();
        const date2 = dt2 ? moment(dt2) : moment();
        return date1.diff(date2, type);
    }

    concatinateStrings(dest, src, delimiter = ', ') {
        if (!src || src == '') return dest;
        if (!dest || dest == '') return src;
        return dest + delimiter + src;
    }
    /**
     *  Method to get date range between given interval.
     * @param value : start time.
     * @param interval : Type of interval: Day, Week, Month, Year
     * @param format : returning date format. Default ISO Time format.
     * @param tillToday: if true then return today date if end date is future date
     * @returns Array of two dates between interval
     */

    getInterval(value, interval = 'week', format = '', tillToday = false) {
        const date = moment(value);
        if (date.isValid()) {
            if(!tillToday) {
                return format ? { start: date.startOf(interval).format(format), end: date.endOf(interval).format(format) } :
                { start: date.startOf(interval).toISOString(), end: date.endOf(interval).toISOString()}
            } else {
                const start = date.startOf(interval);
                let end = date.endOf(interval);
                const today = moment();
                end = (end.toISOString() > today.toISOString()) ? today : end;
                return format ? {start: start.format(format), end: end.format(format)} : { start: start.toISOString(), end: end.toISOString() }
            }
        } else {
            return { start: '', end: '' }
        }
    }

    getCurrentWeekInterval() {
        const date = moment(new Date());
        return { end: date.toISOString(), start: date.startOf('week').toISOString()}
    }

    getDateFromString(date, format) {
        let momentDate;
        if (date) {
            momentDate = format ? moment(date, format) : moment(date);
        }
        return momentDate && momentDate.isValid() ? momentDate.toDate() : null;
    }

    getDateDifference(start, end) {
        const startDate = moment(start);
        const endDate = moment(end);
        return endDate.diff(startDate, 'months');
    }

    getDateDifferenceDuration(date) {
        var now = moment(new Date());
        var end = moment(new Date(date));
        var duration = moment.duration(end.diff(now));
        return duration;
    }

    /**
     * 
     * @param url 
     * @param callback 
     */
    imageExists(url, callback) {
        var img = new Image();
        img.onload = function () { return callback(true); };
        img.onerror = function () { return callback(false); };
        img.src = url;
    }

    /**
     * 
     * @param name pass coountry's Name to get state list
     */
    getState(name: string) {
        return this._state
            .find({ where: { country_code: name }, order: 'name' })
            .map(data => data);
    } 

       /**
     * 
     * @param sfdcId pass coountry's sfdcId to get state list
     */
    getStateManager(sfdcId: string) {
        return this._stateManagerApi
            .find({ where: { Country_Code__c: sfdcId }, order: 'Country__c' })
            .map(data => data);
    }

    /**
* get All worker relared information
*/
    getWorkerServiceContract() {
        return new Promise((resolve, reject) => {
            const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
            const viewAsVendor = (viewAsInfo && viewAsInfo.viewAsType === 'Vendor');

            let userSfdcId;
            if(viewAsVendor) {
                userSfdcId = viewAsInfo.userSfdcId;
            } else {
                const appData = JSON.parse(localStorage.getItem('appData'));
                userSfdcId = appData && appData.user && appData.user.sfdcId;
            }
            const assistGrant = JSON.parse(localStorage.getItem('assistGrant'));
            if (!viewAsVendor && assistGrant && assistGrant.contract && assistGrant.sfdcId === userSfdcId && assistGrant.frequency) {
                resolve({ isServiceContract: true, frequency: assistGrant.frequency });
            } else {
                const serviceContractWhere = { Status: 'Active' }
                if ((!viewAsVendor && this._appState.getAccessType() === 'internal')) {
                    serviceContractWhere['Program__c'] = environment.programId;
                }
                const filter = {
                    where: { Contact__c: userSfdcId },
                    fields: ['sfdcId', 'Contact__c'],
                    include: [
                        {
                            relation: 'serviceContract',
                            scope: {
                                fields: ['sfdcId', 'Worker__c', 'createdAt', 'id', 'Status', 'Timesheet_frequency__c'],
                                order: 'CreatedDate DESC',
                                where: serviceContractWhere
                            }
                        }
                    ],
                    skip: 0,
                    limit: 1,
                    order: 'createdAt DESC'
                }
                this._workerApi.find(filter).subscribe(res => {
                    let isServiceContract = false;
                    let timesheetFrequency = '';
                    if (res && res.length && res[0]['serviceContract'] && res[0]['serviceContract']['length']) {
                        isServiceContract = true;
                        timesheetFrequency = res[0]['serviceContract'][0]['Timesheet_frequency__c'];
                    }
                    if(!viewAsVendor) {
                        localStorage.setItem('assistGrant', JSON.stringify({ contract: isServiceContract, sfdcId: userSfdcId, frequency: timesheetFrequency }));
                    }
                    resolve({ isServiceContract: isServiceContract, frequency: timesheetFrequency });
                });
            }
        })
    }


    /**
     *
     * @returns CSAT Feedback
     */
    getCSATFORPMS() {
        return new Promise((resolve, reject) => {
            const appData = JSON.parse(localStorage.getItem('appData'));
            if (appData.user.sfdcId && appData.user.accessType === 'partner') {
                this._customerCsatApi.find({
                    where: {
                        'or': [{ 'Customer_Delivery_Manager__c': appData.user.sfdcId }, { 'Customer_PM__c': appData.user.sfdcId }],
                        'Overall_CSAT_Sta__c': { neq: 'CSAT Submitted' }
                    },
                    fields: ['sfdcId']
                }).subscribe((res: any) => {
                    if (res && res.length) {
                        resolve({ csatSfdcId: res[0].sfdcId })
                    } else {
                        resolve({ csatSfdcId: '' })
                    }
                }, err => {
                    console.log(err);
                    resolve({ csatSfdcId: '' })
                })
            } else {
                resolve({ csatSfdcId: '' })
            }
        })
    }

    /**
       *
       * @param file
       * action list to perform download files
       */
    download(path, name) {
        saveAs(path, name);
    }

    /**
     * Method to download the multi files
     * @param files 
     */
    downloadMultiFiles(files: { path: string, name: string }[]) {
        for (let i = 0; i < files.length; i++) {
            this.download(files[i].path, files[i].name)
        }
    }

    /**
     * Method to download the multifiles as a zip
     * @param files 
     * @param zipFileName 
     */
    downloadFilesZip(files: { path: string, name: string }[], zipFileName = "serviceo-files.zip") {
        let map = new Map();
        // if files have same name file, then we will change the name
        files = files.map((item) => {
            if (map.has(item.name)) {
                // let ext = item['path'].split('.').pop()
                map.set(item.name, map.get(item.name) + 1);
                if (map.get(item.name) > 0) {
                    let baseName = item['name'].split('.')[0];
                    let extName = item['name'].split('.')[1];
                    item['name'] = baseName + ` (${map.get(item.name)})` + '.' + extName;
                }
            } else {
                map.set(item.name, 0);
            }
            return item;
        });

        var zip = new JSZip();
        var count = 0;

        files.forEach(function ({ path, name }) {
            let filename = name || path.split('/').pop() || 'untitled';

            // loading a file and add it in a zip file
            JSZipUtils.getBinaryContent(path, function (err, data) {
                if (err) {
                    throw err; // or handle the error
                }
                zip.file(filename, data, { binary: true });
                count++;
                if (count == files.length) {
                    zip.generateAsync({ type: 'blob' }).then(function (content) {
                        saveAs(content, zipFileName);
                    });
                }
            });
        });
    }

    /**
     * 
     * @param start 
     * @param end 
     * @param interval 
     * @param format 
     * use to change date at system's timezone to filter data.
     */
    getDateInterval(start, end, interval = '', format = '') {
        const startDate = moment(start);
        const endDate = moment(end);
        if (startDate.isValid() && endDate.isValid()) {
            return format ? { start: startDate.startOf(interval).format(format), end: endDate.endOf(interval).format(format) } :
                { start: startDate.startOf(interval).toISOString(), end: endDate.endOf(interval).toISOString() }
        } else if (startDate.isValid()) {
            return format ? { start: startDate.startOf(interval).format(format) } :
                { start: startDate.startOf(interval).toISOString() }
        } else {
            return { start: '', end: '' }
        }
    }
    /**
     * Method to fetch the teamshift data and store the response in @var teamShiftData 
     */
    fetchTeamShiftData(): Observable<any[]> {
        if (this.teamShiftData && this.teamShiftData.length > 0) {
            return Observable.of(this.teamShiftData);
        } else {
            return this._teamShiftApi.getTeamShiftData()
                .do(data => {
                    this.teamShiftData = data;
                    if (this.teamShiftData && this.teamShiftData.length > 0) {
                        let teamShiftSfdcIds = this.teamShiftData.map(item => item.sfdcId);
                        this.getTeamShiftIccCaseNumber(teamShiftSfdcIds);
                    }
                })
                .catch(this.handleError);
        }
    }

    private handleError(error: Response) {
        console.error(error);
        return Observable.throw(error.json() || 'Server error');
    }


    /**
     * Method to fetch the @var Task Model's @var ICC_Case_Number__c data corresponde to the teamShiftIds
     * and store the response in @var teamShiftIccCaseNumber 
     * @param teamShiftIds 
     */
    getTeamShiftIccCaseNumber(teamShiftIds: Array<string>) {
        const reqObj = {

            where: {
                and: [
                    {
                        Assigned_to_Team_Shift__c: {
                            inq: teamShiftIds
                        }
                    },
                    { or: [{ PgMO_Tasks__c: { neq: null } }, { PgMO_Tasks__c: { neq: '' } }] }
                ]
            },
            order: 'CreatedDate DESC',
            fields: ['sfdcId', 'PgMO_Tasks__c', 'Assigned_to_Team_Shift__c'],
            include: [
                {
                    relation: 'task',
                    scope: {
                        fields: ['sfdcId', 'ICC_Case_Number__c'],
                        where: { ICC_Case_Number__c: { neq: null } }
                    },
                },
            ],
            limit: 2000
        };
        let mapTeamShiftIds = teamShiftIds.reduce((map, key) => {
            map[key] = [];
            return map;
        }, {});

        this._assignment.find(reqObj).subscribe(
            (data) => {
                if (data && data.length > 0) {
                    data.forEach((item) => {
                        if ((item['Assigned_to_Team_Shift__c'] in mapTeamShiftIds) && item['task']) {
                            mapTeamShiftIds[item['Assigned_to_Team_Shift__c']].push(item['task']['ICC_Case_Number__c']);
                        }
                    })
                }
                this.teamShiftIccCaseNumber = mapTeamShiftIds;
            },
            err => {
                console.log('erro occured', err)
            }
        );
    }

    /**
     * Method to fetch the Job type Information
     * and store the response in @var pmcAllRecordType, @var pmsRecordType 
     * @param teamShiftIds 
     */
    fetchJobTypeInfo() {
        const reqObj = {
            'where': {
                or: [
                    { 'IsPMS__c': true },
                    { 'IsPMC__c': true },
                ]
            },
            'fields': ['sfdcId', 'RecordTypes__c', 'IsPMS__c', 'IsPMC__c', 'X3PL__c', 'IsFTE__c', 'IsHMS__c'],
            'include': [
                {
                    'relation': 'recordtype'
                }]
        };
        this._pmsIccPmcRecordTypeMappingApi.find(reqObj).subscribe((filterList: any) => {
            // added logic for the pmc data
            let pmcData = filterList.filter(data => data.IsPMC__c);
            let pmsData = filterList.filter(data => data.IsPMS__c);

            this.pmsRecordType['all'] = [];
            this.pmcAllRecordType = pmcData && pmcData.length > 0 ? pmcData.map(item => item.recordtype && item.recordtype.sfdcId) : [];
            const fte = [];
            const fru = [];
            const fse = [];
            for (let zz = 0; zz < pmsData.length; zz++) {
                const el = pmsData[zz];
                if (el && el.recordtype && el.recordtype.sfdcId) {
                    this.pmsRecordType['all'].push(el.recordtype.sfdcId);
                }
                if (el.IsFTE__c == true) {
                    fte.push(el.recordtype.sfdcId);
                } else if (el.X3PL__c == true) {
                    fru.push(el.recordtype.sfdcId);
                } else if (!el.X3PL__c && !el.IsFTE__c && !el.IsHMS__c) {
                    fse.push(el.recordtype.sfdcId);
                }

            }
            this.pmsRecordType['fte'] = fte;
            this.pmsRecordType['fru'] = fru;
            this.pmsRecordType['fse'] = fse;

        }, error => {
            console.log("Error occured:", error);
        });
        const reqObj2 = {
            'where': {
                'SobjectType': 'CKSW_BASE__Service__c'
            },
            'fields': ['sfdcId', 'Name']
        };
        this._recordTypeApi.find(reqObj2).subscribe((jobTypeList: any) => {
            jobTypeList.forEach(element => {
                switch (element.Name) {
                    case "FTE Dispatch":
                        this.jobRecordType['fte'] = element.sfdcId;
                        break;
                    case "FSE Dispatch":
                        this.jobRecordType['fse'] = element.sfdcId;
                        break;
                    case "FRU Dispatch":
                        this.jobRecordType['fru'] = element.sfdcId;
                        break;
                }
            });
            this.jobRecordTypeSub.next({ jobRecordType: this.jobRecordType });
        });
    }

    /**
    * apply default partner account selection in global filters
    */
    appliedDefaultPartner() {
        const filterCondition = JSON.parse(localStorage.getItem('filterCondition'));
        const filterObj = JSON.parse(localStorage.getItem('filterObj'));
        const appData = JSON.parse(localStorage.getItem('appData'));
        const isFilterAlreadyApplied = filterCondition && filterCondition['CKSW_BASE__Account__c'] &&
            filterCondition['CKSW_BASE__Account__c']['inq'] && filterCondition['CKSW_BASE__Account__c']['inq'].length;
        if (!isFilterAlreadyApplied && appData && appData['user'] && appData['user']['AccountId']) {
            filterObj['accounts'] = [appData['user']['AccountId']];
            filterCondition['CKSW_BASE__Account__c'] = { inq: [appData['user']['AccountId']] };
            localStorage.setItem('filterObj', JSON.stringify(filterObj));
            localStorage.setItem('filterCondition', JSON.stringify(filterCondition));
        }
    }

    /**
    * Store timecard record type in local storage if not there and return it back
    */
    getTimecardRecordTypeIds() {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem('timecardRecordTypeIds') && JSON.parse(localStorage.getItem('timecardRecordTypeIds'))
            && JSON.parse(localStorage.getItem('timecardRecordTypeIds'))['dispatch']
            && JSON.parse(localStorage.getItem('timecardRecordTypeIds'))['contract']
            && JSON.parse(localStorage.getItem('timecardRecordTypeIds'))['dispatch'].length
            && JSON.parse(localStorage.getItem('timecardRecordTypeIds'))['contract'].length) { return resolve("we already have timecard record type ids saved"); }
            const data = {};
            this._recordTypeApi.find({
                fields: ['sfdcId', 'DeveloperName'],
                where: { 'SobjectType': 'Timecard__c' }
            }).subscribe(reply => {
                const dispatchIds = [];
                const contractIds = [];
                reply.forEach(item => {
                    if (item['DeveloperName'] && item['DeveloperName'] === 'Timecard') {
                        dispatchIds.push(item['sfdcId']);
                    }
                    if (item['DeveloperName'] && item['DeveloperName'] === 'Timesheet') {
                        contractIds.push(item['sfdcId']);
                    }
                })
                data['dispatch'] = dispatchIds;
                data['contract'] = contractIds;
                localStorage.setItem('timecardRecordTypeIds', JSON.stringify(data));
            resolve(data);
          }, err => {
            resolve({});
          });
        });
      }

    /**
    * Store all filter in local storage
    * @param filterObj 
    */
    setGlobalFilter(filterObj) {
        let filterCondition = {};        
        if (filterObj.projectManagers && filterObj.projectManagers.length) {
            filterCondition['projectManagers'] = { inq: filterObj.projectManagers };
        }
        if (filterObj.checkInStatus && filterObj.checkInStatus.length) {
            filterCondition['Check_In_Status__c'] = { inq: filterObj.checkInStatus };
        }
        if (filterObj.checkInFrequency && filterObj.checkInFrequency.length) {
            filterCondition['Frequency__c'] = { inq: filterObj.checkInFrequency };
        }
        if (filterObj.objective && filterObj.objective.length) {
            filterCondition['objective'] = { inq: filterObj.objective };
        }
        if (filterObj.keyResult && filterObj.keyResult.length) {
            filterCondition['keyResult'] = { inq: filterObj.keyResult };
        }
        if (filterObj.initiative && filterObj.initiative.length) {
            filterCondition['initiative'] = { inq: filterObj.initiative };
        }
        if (filterObj.period && filterObj.period.length) {
            filterCondition['Period__c'] = { inq: filterObj.period };
        }
        if (filterObj.objectiveType && filterObj.objectiveType.length) {
            filterCondition['Objective_Assignment_Type__c'] = { inq: filterObj.objectiveType };
        }
        if (filterObj.objectiveStatus && filterObj.objectiveStatus.length) {
            filterCondition['Objective_Status__c'] = { inq: filterObj.objectiveStatus };
        }
        if (filterObj.contractCategory && filterObj.contractCategory.length) {
            filterCondition['ContractCategory'] = { inq: filterObj.contractCategory };
        }
        if (filterObj.orderTypes && filterObj.orderTypes.length) {
            filterCondition['OrderType'] = { inq: filterObj.orderTypes.map(item => item.sfdcId) };
        }
        if (filterObj.regions && filterObj.regions.length) {
            filterCondition['Region'] = { inq: filterObj.regions.map(item => item.sfdcId) };
        }
        if (filterObj.countries && filterObj.countries.length) {
            filterCondition['CountryCode'] = { inq: filterObj.countries.map(item => item.Name) };
        }
        if (filterObj.states && filterObj.states.length) {
            filterCondition['StateName'] = { inq: filterObj.states };
        }
        if (filterObj.statesManager && filterObj.statesManager.length) {
            filterCondition['StateManager__c'] = { inq: filterObj.statesManager.map(item => item.sfdcId) };
        }
        if (filterObj.vendorAccounts && filterObj.vendorAccounts.length) {
            filterCondition['Vendor__c'] = { inq: filterObj.vendorAccounts };
        }
        if (filterObj.contactAccounts && filterObj.contactAccounts.length) {
            filterCondition['contactAccount__c'] = { inq: filterObj.contactAccounts };
        }
        if (filterObj.documentCategory && filterObj.documentCategory.length) {
            filterCondition['categoryId'] = { inq: filterObj.documentCategory };
        }
        if (filterObj.pageViewGroup && filterObj.pageViewGroup.length) {
            filterCondition['pageViewGroupId'] = { inq: filterObj.pageViewGroup };
        }
        if (filterObj.accounts && filterObj.accounts.length) {
            filterCondition['CKSW_BASE__Account__c'] = { inq: filterObj.accounts };
        }
        if (filterObj.programs && filterObj.programs.length) {
            filterCondition['Project_SOP__c'] = { inq: filterObj.programs };
        }
        if (filterObj.jobSites && filterObj.jobSites.length) {
            filterCondition['Jobsite__c'] = { inq: filterObj.jobSites };
        }
        if (filterObj.jobs && filterObj.jobs.length) {
            filterCondition['Iron_Job_num__c'] = { inq: filterObj.jobs };
        }
        if (filterObj.Tcr && filterObj.Tcr.length) {
            filterCondition['Tcr'] = { inq: filterObj.Tcr };
        }
        if (filterObj.jobsfdcIds && filterObj.jobsfdcIds.length) {
            filterCondition['jobsfdcIds'] = { inq: filterObj.jobsfdcIds };
        }

        if (filterObj.statgeStatus && filterObj.statgeStatus.length) {
            filterCondition['Dispatch_Service_Resolution_Status__c'] = { inq: filterObj.statgeStatus };
        }
        if (filterObj.jobTypes && filterObj.jobTypes.length) {
            filterCondition['and'] = [{ "or": filterObj.recordTypeIds }];
        }
        if (filterObj.caseTypes && filterObj.caseTypes.length) {
            filterCondition['caseType'] = [{ "or": filterObj.pmsRecordTypeIds }];
        }
        if (filterObj.talentTypes && filterObj.talentTypes.length) {
            filterCondition['Talent_Type__c'] = { inq: filterObj.talentTypes };
        }
        if (filterObj.workerTalentTypes && filterObj.workerTalentTypes.length) {
            filterCondition['Worker_Talent_Type__c'] = { inq: filterObj.workerTalentTypes };
        }

        if (filterObj.dateOptions && filterObj.dateOptions[0]) {
            const startDate = filterObj.dateOptions[0];
            filterCondition['CreatedDate'] = { between: [startDate[0], startDate[1]] };
        }

        if (filterObj.jobCreatedDate && filterObj.jobCreatedDate[0]) {
            const startDate = filterObj.jobCreatedDate[0];
            filterCondition['jobCreatedDate'] = { between: [startDate[0], startDate[1]] };
        }

        if (filterObj.customerScheduled && filterObj.customerScheduled[0]) {
            const startDate = filterObj.customerScheduled[0];
            filterCondition['Customer_Appointment_DateTime_Scheduled__c'] = { between: [startDate[0], startDate[1]] };
        }

        if (filterObj.dateClosed && filterObj.dateClosed[0]) {
            const startDate = filterObj.dateClosed[0];
            filterCondition['ClosedDate'] = { between: [startDate[0], startDate[1]] };
        }

        if (filterObj.workerScheduled && filterObj.workerScheduled[0]) {
            const startDate = filterObj.workerScheduled[0];
            filterCondition['WorkerScheduled'] = { between: [startDate[0], startDate[1]] };
        }
        if (filterObj.caseCategory && filterObj.caseCategory.length) {
            filterCondition['RecordTypeId'] = { inq: filterObj.caseCategory };
        }

        if (filterObj.priority && filterObj.priority.length) {
            filterCondition['Service_Dispatch_SLA_Priority__c'] = { inq: filterObj.priority };
        }

        if (filterObj.jobStatus && filterObj.jobStatus.length) {
            filterCondition['Job_Status_Internal__c'] = { inq: filterObj.jobStatus };
        }

        if (filterObj.workerNames && filterObj.workerNames.length) {
            filterCondition['Dispatch_Worker_Name__c'] = { inq: filterObj.workerNames };
        }

        if (filterObj.workerIds && filterObj.workerIds.length) {
            filterCondition['Dispatch_Worker_num__c'] = { inq: filterObj.workerIds };
        }

        if (filterObj.vendorSites && filterObj.vendorSites.length) {
            filterCondition['Vendorsite__c'] = { inq: filterObj.vendorSites };
        }

        if (filterObj.geoMetro && filterObj.geoMetro.length) {
            let jobsiteSfdcIds = [];
            jobsiteSfdcIds = jobsiteSfdcIds.concat(filterObj.jobSites, filterObj.geoMetroJobSite);
            jobsiteSfdcIds = Array.from(new Set(jobsiteSfdcIds));
            filterCondition['Jobsite__c'] = { inq: jobsiteSfdcIds };
        }
        // It's use for Team Shift
        if (filterObj.teamShifts && filterObj.teamShifts.length && filterObj.teamShiftUsers) {
            filterCondition['Case__c'] = { inq: filterObj.teamShiftUsers };
        }

        if (filterObj.pmsCaseStatus && filterObj.pmsCaseStatus.length) {
            filterCondition['PMS_Case_Status'] = { inq: filterObj.pmsCaseStatus };
        }
        /// direct search from case table pmsCaseNumbers
        if (filterObj.pmsCaseNumbers && filterObj.pmsCaseNumbers.length) {
            filterCondition['PMS_Case_Number'] = { inq: filterObj.pmsCaseNumbers };
        }
        /// direct search from case table pmcCaseNumbers
        if (filterObj.pmcCaseNumbers && filterObj.pmcCaseNumbers.length) {
            filterCondition['PMC_Case_Number'] = { inq: filterObj.pmcCaseNumbers };
        }
        // escalation type filter
        if (filterObj.escalationType && filterObj.escalationType.length) {
            filterCondition['Escalation_Type'] = filterObj.escalationType;
        }

        // project filter
        if (filterObj.projects && filterObj.projects.length) {
            filterCondition['PgMO_Projects__c'] = { inq: filterObj.projects };
        }
        // project filter
        if (filterObj.projectCategorySfdcId && filterObj.projectCategorySfdcId.length) {
            filterCondition['projectCategorySfdcId'] = { inq: filterObj.projectCategorySfdcId };
        }

        // milestone filter
        if (filterObj.milestones && filterObj.milestones.length) {
            filterCondition['PgMO_Milestones__c'] = { inq: filterObj.milestones };
        }

        // task type filter
        if (filterObj.tasks && filterObj.tasks.length) {
            filterCondition['PgMo_Task__c'] = { inq: filterObj.tasks };
        }

        // taskStatus type filter
        if (filterObj.taskStatus && filterObj.taskStatus.length) {
            filterCondition['Task_Status__c'] = { inq: filterObj.taskStatus };
        }

        // problemresolution status type filter
        if (filterObj.problemTaskStatuses && filterObj.problemTaskStatuses.length) {
            filterCondition['Problem_Resolution_Status__c'] = { inq: filterObj.problemTaskStatuses };
        }

        // taskRequestor type filter
        if (filterObj.taskRequestors && filterObj.taskRequestors.length) {
            filterCondition['Requestor__c'] = { inq: filterObj.taskRequestors };
        }

        // taskTypes type filter
        if (filterObj.taskTypes && filterObj.taskTypes.length) {
            filterCondition['Request_Type__c'] = { inq: filterObj.taskTypes };
        }

        // project type filter
        if (filterObj.projectTypes && filterObj.projectTypes.length && filterObj.projectTypes.filter(Boolean).length) {
            filterCondition['Project_Type__c'] = { inq: filterObj.projectTypes };
        }

        // project2 type filter
        if (filterObj.teamProjectTypes && filterObj.teamProjectTypes.length && filterObj.teamProjectTypes.filter(Boolean).length) {
            filterCondition['TeamProjectType'] = { inq: filterObj.teamProjectTypes };
        }
        // Either Account or Task Account filter will be active at a time. both can never be enable at the same time
        if (filterObj.taskAccounts && filterObj.taskAccounts.length) {
            filterCondition['Internal_Account_Id'] = { inq: filterObj.taskAccounts };
        }

        // Employee Type Filter
        //removing employee type filter when all is selected in employee type
        if (filterObj.employeeType && filterObj.employeeType.length && (!filterObj.employeeType.includes('') || filterObj.fromPage === 'leave-approval-list')) {
            filterCondition['Dispatch_worker_type'] = { inq: filterObj.employeeType };
        }
        
        if (filterObj.leaveStatusType && filterObj.leaveStatusType.length) {
            filterCondition['leaveStatusType'] = { inq: filterObj.leaveStatusType };
        }

        // Implementing the timecard global filters
        if (filterObj.ironApprovalStatuses && filterObj.ironApprovalStatuses.length) {
            filterCondition['IRON_Approval_Status__c'] = { inq: filterObj.ironApprovalStatuses };
        }
        if (filterObj.customerApprovalStatuses && filterObj.customerApprovalStatuses.length) {
            filterCondition['Customer_Approval_Status__c'] = { inq: filterObj.customerApprovalStatuses };
        }
        if (filterObj.actualCoverageHours && filterObj.actualCoverageHours.length) {
            filterCondition['Actual_Coverage_Hour__c'] = { inq: filterObj.actualCoverageHours };
        }
        if (filterObj.coverageHours && filterObj.coverageHours.length) {
            filterCondition['Coverage_Hours__c'] = { inq: filterObj.coverageHours };
        }
        // if (filterObj.actualEndTimeCoverages && filterObj.actualEndTimeCoverages.length) {
        //     filterCondition['Actual_End_Time_Coverage__c'] = { inq: filterObj.actualEndTimeCoverages };
        // }
        // if (filterObj.actualCoverageOveralls && filterObj.actualCoverageOveralls.length) {
        //     filterCondition['Actual_Coverage_Overall__c'] = { inq: filterObj.actualCoverageOveralls };
        // }
        //apply filter on worker_billing_Type_C worker under timecard
        if (filterObj.workerBillingTypes && filterObj.workerBillingTypes.ids && filterObj.workerBillingTypes.ids.length) {
            filterCondition['Worker_Billing_Type__c'] = { inq: filterObj.workerBillingTypes.ids };
        }

        if (filterObj.purchaseOrder && filterObj.purchaseOrder.length) {
            filterCondition['Work_Order_num__c'] = { inq: filterObj.purchaseOrder };
        }

        if (filterObj.serviceContractNumbers && filterObj.serviceContractNumbers.length) {
            filterCondition['Service_Contract__c'] = { inq: filterObj.serviceContractNumbers };
        }

        if (filterObj.serviceContractCategory && filterObj.serviceContractCategory.length) {
            filterCondition['Service_Contract_Category__c'] = { inq: filterObj.serviceContractCategory };
        }

        // set filter condition for purchase Invoice Status
        if (filterObj.purchaseInvoiceStatus && filterObj.purchaseInvoiceStatus.length) {
            filterCondition['Purchase_Invoice_Status'] = { inq: filterObj.purchaseInvoiceStatus };
        }

        if (filterObj.divisions && filterObj.divisions.length) {
            filterCondition['Division__c'] = { inq: filterObj.divisions };
        }

        if (filterObj.departments && filterObj.departments.length) {
            filterCondition['Department__c'] = { inq: filterObj.departments };
        }

        if (filterObj.technicalLevels && filterObj.technicalLevels.length) {
            filterCondition['Technical_Level__c'] = { inq: filterObj.technicalLevels };
        }
        if (filterObj.pgmo_departments && filterObj.pgmo_departments.length) {
            filterCondition['PgMO_Departments__c'] = { inq: filterObj.pgmo_departments };
        }
        if (filterObj.taskLibraries && filterObj.taskLibraries.length) {
            filterCondition['PgMO_Task_Library__c'] = { inq: filterObj.taskLibraries };
        }

        if (filterObj.milestoneLibraries && filterObj.milestoneLibraries.length) {
            filterCondition['PgMO_Milestone_Library__c'] = { inq: filterObj.milestoneLibraries };
        }

        if (filterObj.tmpltLibraryTypes && filterObj.tmpltLibraryTypes.length) {
            filterCondition['Library_Type_Selected__c'] = { inq: filterObj.tmpltLibraryTypes };
        }

        if (filterObj.expenseTypes && filterObj.expenseTypes.length) {
            filterCondition['Expense_Type__c'] = { inq: filterObj.expenseTypes };
        }

        if (filterObj.expenseSubTypes && filterObj.expenseSubTypes.length) {
            filterCondition['Expense_sub_Type__c'] = { inq: filterObj.expenseSubTypes };
        }

        if (filterObj.paymentTypes && filterObj.paymentTypes.length) {
            filterCondition['Payment_Type__c'] = { inq: filterObj.paymentTypes };
        }

        //FOR ACCOUNT COMMUNITY
        if (filterObj.accountCommunities && filterObj.accountCommunities.length) {
            filterCondition['Account_Type__c'] = { inq: filterObj.accountCommunities };
        }
        if (filterObj.activityType && filterObj.activityType.length) {
            filterCondition['Activity_Type__c'] = { inq: filterObj.activityType };
        }
        if (filterObj.workerType && filterObj.workerType.length) {
            filterCondition['Worker_Type__c'] = { inq: filterObj.workerType };
        }
        if (filterObj.workerTypeSub && filterObj.workerTypeSub.length) {
            filterCondition['Worker_Type_Sub__c'] = { inq: filterObj.workerTypeSub };
        }
        if (filterObj.customerDateOptions && filterObj.customerDateOptions[0]) {
            const startDate = filterObj.customerDateOptions[0];
            filterCondition['Customer_Appointment_Start_Scheduled__c'] = { between: [startDate[0], startDate[1]] };
        }
        if (filterObj.taskPriorities && filterObj.taskPriorities.length) {
            filterCondition['Priority__c'] = { inq: filterObj.taskPriorities };
        }
        if (filterObj.taskStatuses && filterObj.taskStatuses.length) {
            filterCondition['Task_Status__c'] = { inq: filterObj.taskStatuses };
        }
        if (filterObj.users && filterObj.users.length) {
            filterCondition['Worker__c'] = { inq: filterObj.users };
        }
        if (filterObj.teams && filterObj.teams.length) {
            filterCondition['PgMO_Groups__c'] = { inq: filterObj.teams };
        }
        if (filterObj.dateFrom && filterObj.dateFrom.length) {
            filterCondition['Start_Date__c'] = { inq: filterObj.dateFrom };
        }
        if (filterObj.startFromDateOptions && filterObj.startFromDateOptions[0]) {
            const startDate = filterObj.startFromDateOptions[0];
            filterCondition['startFromDate'] = { between: [startDate[0], startDate[1]] };
        }
        if (filterObj.teamTypes && filterObj.teamTypes.length) {
            filterCondition['Team_Type'] = { inq: filterObj.teamTypes };
        }
        if (filterObj.departmentRoleAccesses && filterObj.departmentRoleAccesses.length) {
            filterCondition['Access__c'] = { inq: filterObj.departmentRoleAccesses };
        }
        if (filterObj.customers && filterObj.customers.length) {
            filterCondition['Customer__c'] = { inq: filterObj.customers };
        }
        if (filterObj.workOrderCaseIds && filterObj.workOrderCaseIds.length) {
            filterCondition['Job_Order__c'] = { inq: filterObj.workOrderCaseIds };
        }
        if (filterObj.templateCategories && filterObj.templateCategories.length) {
            filterCondition['Category__c'] = { inq: filterObj.templateCategories };
        }
        if (filterObj.subCategories && filterObj.subCategories.length) {
            filterCondition['Sub_Catergory__c'] = { inq: filterObj.subCategories };
        }
        if (filterObj.templateSubCategories && filterObj.templateSubCategories.length) {
            filterCondition['Sub_Category__c'] = { inq: filterObj.templateSubCategories };
        }
        if (filterObj.apvpGroup && filterObj.apvpGroup.length) {
            filterCondition['APVP_Group_Number__c'] = { inq: filterObj.apvpGroup };
        }
        if (filterObj.communityType && filterObj.communityType.length) {
            filterCondition['Community_Type__c'] = { inq: filterObj.communityType };
        }
        if (filterObj.slaGroup && filterObj.slaGroup.length) {
            filterCondition['SLA_Group_Name__c'] = { inq: filterObj.slaGroup };
        }
        if (filterObj.wslAccountStatus && filterObj.wslAccountStatus.length) {
            filterCondition['FSL_Location_Status__c'] = { inq: filterObj.wslAccountStatus }
        }
        if (filterObj.wslAccounts && filterObj.wslAccounts.length) {
            filterCondition['WSL_Accounts'] = { inq: filterObj.wslAccounts }
        }
        if (filterObj.address && filterObj.address.length) {
            filterCondition['address'] = { inq: filterObj.address };
        }

        if (filterObj.geoCode && filterObj.geoCode.length) {
            filterCondition['GeoCode'] = { inq: filterObj.geoCode };
        }

        if (filterObj.city && filterObj.city.length) {
            filterCondition['City__c'] = { inq: filterObj.city };
        }

        if (filterObj.workerStatus && filterObj.workerStatus.length) {
            filterCondition['Worker_Status'] = { inq: filterObj.workerStatus };
        }
        if (filterObj.radius && filterObj.radius.length) {
            filterCondition['radius'] = { inq: filterObj.radius };
        }
        if (filterObj.driving_distance && filterObj.driving_distance.length) {
            const dd = filterObj.driving_distance[0];
            filterCondition["DrivingDistance"] = {
                between: [dd['min'], dd['max']],
            };
        }
        if (filterObj.salary && filterObj.salary.length) {
            const salary = filterObj.salary[0];
            filterCondition["Salary"] = {
                between: [salary['min'], salary['max']],
            };
        }

        if (filterObj.targetCost && filterObj.targetCost.length) {
            const targetCost = filterObj.targetCost[0];
            if (targetCost.min !== '' && targetCost.max !== ''){
                filterCondition["Target_Cost__c"] = {
                    between: [parseInt(targetCost['min']) ? parseInt(targetCost['min']) : 1, parseInt(targetCost['max'])],
                };
            }
        }

        if (filterObj.gtpResourceType && filterObj.gtpResourceType.length) {
            filterCondition['gtpResourceType'] = { inq: filterObj.gtpResourceType };
        }

        if (filterObj.gtpPoolAccessType && filterObj.gtpPoolAccessType.length) {
            filterCondition['gtpPoolAccessType'] = { inq: filterObj.gtpPoolAccessType };
        }

        if (filterObj.gtpMemberType && filterObj.gtpMemberType.length) {
            filterCondition['gtpMemberType'] = { inq: filterObj.gtpMemberType };
        }

        if (filterObj.gtpCommunityType && filterObj.gtpCommunityType.length) {
            filterCondition['gtpCommunityType'] = { inq: filterObj.gtpCommunityType };
        }

        if (filterObj.backFillWorkerService && filterObj.backFillWorkerService.length) {
            filterCondition['backFillWorkerService'] = { inq: filterObj.backFillWorkerService };
        }

        if (filterObj.displayCoverageType && filterObj.displayCoverageType.length) {
            filterCondition['displayCoverageType'] = { inq: filterObj.displayCoverageType };
        }


        if (filterObj.category && filterObj.category.length) {
            filterCondition['Expense_Type__c'] = { inq: filterObj.category };
        }

        if (filterObj.isBillable && filterObj.isBillable.length) {
            filterCondition['Is_Billable__c'] = { inq: filterObj.isBillable };
        }


        if (filterObj.isTestUser && filterObj.isTestUser.length) {
            filterCondition['isTest'] = filterObj.isTestUser.toString();
        }
        if (filterObj.conversationSource && filterObj.conversationSource.length) {
            filterCondition['conversationSource__c'] = filterObj.conversationSource.toString();
        }

        if (filterObj.includeTestAccount && filterObj.includeTestAccount.length) {
            filterCondition['Is_Test_Account__c'] = filterObj.includeTestAccount.toString();
        }

        if (filterObj.isServiceoLogin && filterObj.isServiceoLogin.length) {
            filterCondition['isServiceoLogin'] = filterObj.isServiceoLogin.toString();
        }

        if (filterObj.uniqueUser && filterObj.uniqueUser.length) {
            filterCondition['uniqueUser'] = filterObj.uniqueUser.toString();
        }

        if (filterObj.isActiveUser && filterObj.isActiveUser.length) {
            filterCondition['isActive'] = filterObj.isActiveUser.toString();
        }

        if (filterObj.vendorSubTypeCommunity && filterObj.vendorSubTypeCommunity.length) {
            filterCondition['vendorSubTypeCommunity'] = { inq: filterObj.vendorSubTypeCommunity };
        }

        if (filterObj.covrageHoursAccountType && filterObj.covrageHoursAccountType.length) {
            filterCondition['covrageHoursAccountType'] = { inq: filterObj.covrageHoursAccountType };
        }

        if (filterObj.slaAccountType && filterObj.slaAccountType.length) {
            filterCondition['slaAccountType'] = { inq: filterObj.slaAccountType };
        }

        if (filterObj.pcUserCount && filterObj.pcUserCount.length) {
            filterCondition['pcUserCount'] = filterObj.pcUserCount.toString();
        }

        if (filterObj.pageType && filterObj.pageType.length) {
            filterCondition['pageType'] = filterObj.pageType.toString();
        }
        if (filterObj.weeklyDateOption && filterObj.weeklyDateOption.length) {
            filterCondition['weeklyDateOption'] = filterObj.weeklyDateOption.toString();
        }

        if (filterObj.displayVendorsiteType && filterObj.displayVendorsiteType.length) {
            filterCondition['displayVendorsiteType'] = { inq: filterObj.displayVendorsiteType };
        }

        if (filterObj.vendorStatusType && filterObj.vendorStatusType.length) {
            filterCondition['vendorStatusType'] = { inq: filterObj.vendorStatusType };
        }

        if (filterObj.mvvpDisplayType && filterObj.mvvpDisplayType.length) {
            filterCondition['mvvpDisplayType'] = { inq: filterObj.mvvpDisplayType };
        }
        if (filterObj.candidateType && filterObj.candidateType.length) {
            filterCondition['candidateType'] = { inq: filterObj.candidateType };
        }
        if (filterObj.slaLabourCloudType && filterObj.slaLabourCloudType.length) {
            filterCondition['slaLabourCloudType'] = { inq: filterObj.slaLabourCloudType };
        }
        if (filterObj.coverHoursMvvp && filterObj.coverHoursMvvp.length) {
            filterCondition['coverHoursMvvp'] = { inq: filterObj.coverHoursMvvp };
        }
        if (filterObj.jobOrderStatus && filterObj.jobOrderStatus.length) {
            filterCondition['jobOrderStatus'] = { inq: filterObj.jobOrderStatus };
        }
        if (filterObj.jobOrderStage && filterObj.jobOrderStage.length) {
            filterCondition['jobOrderStage'] = { inq: filterObj.jobOrderStage };
        }
        if (filterObj.jobOrderPriority && filterObj.jobOrderPriority.length) {
            filterCondition['jobOrderPriority'] = { inq: filterObj.jobOrderPriority };
        }
        if (filterObj.jobOrders && filterObj.jobOrders.length) {
            filterCondition['job_Order__c'] = { inq: filterObj.jobOrders };
        }
        if (filterObj.applications && filterObj.applications.length) {
            filterCondition['Application__c'] = { inq: filterObj.applications };
        }

        if (filterObj.priceListCoverageHours && filterObj.priceListCoverageHours.length) {
            filterCondition['Coverage_Hours__c'] = { inq: filterObj.priceListCoverageHours };
        }

        if (filterObj.priceListTechnicalLevel && filterObj.priceListTechnicalLevel.length) {
            filterCondition['Service_Technical_Level__c'] = { inq: filterObj.priceListTechnicalLevel };
        }

        if (filterObj.priceListSLA && filterObj.priceListSLA.length) {
            filterCondition['SLA__c'] = { inq: filterObj.priceListSLA };
        }

        if (filterObj.servicetalentTypes && filterObj.servicetalentTypes.length) {
            filterCondition['Service_Talent_Type__c'] = { inq: filterObj.servicetalentTypes };
        }
        if (filterObj.pageName && filterObj.pageName.length) {
            filterCondition['pageName'] = { inq: filterObj.pageName };
        }
        if (filterObj.userIdLookup && filterObj.userIdLookup.length) {
            filterCondition['userIdLookup'] = { inq: filterObj.userIdLookup };
        }
        if (filterObj.userTypeId && filterObj.userTypeId.length) {
            filterCondition['userTypeId'] = { inq: filterObj.userTypeId };
        }
        if (filterObj.member && filterObj.member.length) {
            filterCondition['member'] = { inq: filterObj.member };
        }
        if(filterObj.locations && filterObj.locations.length) {
            filterCondition['Location_List_New__c'] = {inq: filterObj.locations};
        }
        if(filterObj.cityList && filterObj.cityList.length) {
            filterCondition['cityList'] = {inq: filterObj.cityList};
        }
        if(filterObj.messageType && filterObj.messageType.length) {
            filterCondition['Is_System_Generated__c'] = {inq: filterObj.messageType};
        }

        if (filterObj.candidatePayRateRequested && filterObj.candidatePayRateRequested.length) {
            const candidatePayRateRequested = filterObj.candidatePayRateRequested[0];
            if (candidatePayRateRequested.min !== '' && candidatePayRateRequested.max !== ''){
                filterCondition["candidatePayRateRequested"] = {
                    between: [parseInt(candidatePayRateRequested['min']) ? parseInt(candidatePayRateRequested['min']) : 1, parseInt(candidatePayRateRequested['max'])],
                };
            }
        }

        if (filterObj.candidateCurrentSalary && filterObj.candidateCurrentSalary.length) {
            const candidateCurrentSalary = filterObj.candidateCurrentSalary[0];
            if (candidateCurrentSalary.min !== '' && candidateCurrentSalary.max !== ''){
                filterCondition["candidateCurrentSalary"] = {
                    between: [parseInt(candidateCurrentSalary['min']) ? parseInt(candidateCurrentSalary['min']) : 1, parseInt(candidateCurrentSalary['max'])],
                };
            }
        }
        if(filterObj.jobTitles && filterObj.jobTitles.length)  {
            filterCondition['jobTitles'] = {inq: filterObj.jobTitles};
        }

        if(filterObj.appStatus && filterObj.appStatus.length) {
            filterCondition['appStatus'] = {inq: filterObj.appStatus};
        }
        
        if(filterObj.noticePeriod && filterObj.noticePeriod.length) {
            filterCondition['noticePeriod'] = {inq: filterObj.noticePeriod};
        }

        if (filterObj.employeeTypeList && filterObj.employeeTypeList.length) {
            filterCondition['employeeTypeList'] = { inq: filterObj.employeeTypeList };
        }

        if (filterObj.timeChangeRequestStatus && filterObj.timeChangeRequestStatus.length) {
            filterCondition['timeChangeRequestStatus'] = { inq: filterObj.timeChangeRequestStatus };
          }
        if (filterObj.jobOrderStatusRmc && filterObj.jobOrderStatusRmc.length) {
            filterCondition['jobOrderStatusRmc'] = { inq: filterObj.jobOrderStatusRmc };
        }

        if (filterObj.requirementType && filterObj.requirementType.length) {
            let requirementType = [];
            filterObj.requirementType.forEach(element => {
                if (element === 'All Primary') {
                    requirementType.push('Primary New','Primary Replacement');
                } else if (element === 'All Backfill') {
                    requirementType.push('Backfill New','Backfill Replacement');
                } else if (element === 'All Dispatch') {
                    requirementType.push('Dispatch New','Dispatch Replacement');
                } else {
                    requirementType.push(element)
                }
            });
            filterCondition['Type_of_Requirement__c'] = { inq: _.uniq(requirementType) };
        }

        if (filterObj.employmentFilter && filterObj.employmentFilter.length) {
            filterCondition['employmentFilter'] = { inq: filterObj.employmentFilter };
        }

        if (filterObj.jobCreationReason && filterObj.jobCreationReason.length) {
            filterCondition['Job_Creation_Reason__c'] = { inq: filterObj.jobCreationReason };
        }
        if (filterObj.approvalStatus && filterObj.approvalStatus.length) {
            filterCondition['Approval_Status__c'] = { inq: filterObj.approvalStatus };
        }
        if (filterObj.jobAssigned && filterObj.jobAssigned.length) {
            filterCondition['jobAssigned'] = { inq: filterObj.jobAssigned };
        }
        if (filterObj.jobPostingType && filterObj.jobPostingType.length) {
            filterCondition['jobPostingType'] = { inq: filterObj.jobPostingType };
        }
        if (filterObj.completedStatus && filterObj.completedStatus.length) {
            filterCondition['completedStatus'] = { inq: filterObj.completedStatus };
        }
        if (filterObj.stageStatus && filterObj.stageStatus.length) {
            filterCondition['stageStatus'] = { inq: filterObj.stageStatus };
        }
        if (filterObj.showOnlyJobType && filterObj.showOnlyJobType.length) {
            filterCondition['showOnlyJobType'] = { inq: filterObj.showOnlyJobType };
        }
        if (filterObj.fileNames && filterObj.fileNames.length) {
            filterCondition['fileNames'] = { inq: filterObj.fileNames };
        }

        // console.log(filterObj);
        if (filterObj.excludeMagicLink && filterObj.excludeMagicLink[0] && filterObj.excludeMagicLink[0] === true) {
            filterCondition['excludeMagicLink'] = { neq: environment.excludeMagicLink };
        }
        if (filterObj.businessRequestor && filterObj.businessRequestor.length) {
            filterCondition['Business_Requestor__c'] = { inq: filterObj.businessRequestor };
        }
        if (filterObj.formName && filterObj.formName.length) {
            filterCondition['Form_Name__c'] = { inq: filterObj.formName };
        }
        if (filterObj.workOrderDateOptions && filterObj.workOrderDateOptions.length) {
            const startDate = filterObj.workOrderDateOptions[0];
            filterCondition['Work_Order_Plan_Date__c'] = { between: [startDate[0], startDate[1]] };
        }
        if (filterObj.milestoneTask && filterObj.milestoneTask.length) {
            filterCondition['Show_Milestones__c'] = { inq: filterObj.milestoneTask };
        }
        if (filterObj.recruitmentManager && filterObj.recruitmentManager.length) {
            filterCondition['recruitmentManager'] = { inq: filterObj.recruitmentManager };
        }
        if (filterObj.recruiter && filterObj.recruiter.length) {
            filterCondition['recruiter'] = { inq: filterObj.recruiter };
        }
        if (filterObj.businessUnit && filterObj.businessUnit.length) {
            filterCondition['businessUnit'] = { inq: filterObj.businessUnit };
        }
        if (filterObj.interviewFeedback && filterObj.interviewFeedback.length) {
            filterCondition['interviewFeedback'] = { inq: filterObj.interviewFeedback };
        }
        if (filterObj.job && filterObj.job.length) {
            filterCondition['job'] = { inq: filterObj.job };
        }
        if (filterObj.interviewRound && filterObj.interviewRound.length) {
            filterCondition['interviewRound'] = { inq: filterObj.interviewRound };
        }
        if (filterObj.singleDate && filterObj.singleDate.length) {
            filterCondition['singleDate'] = { inq: filterObj.singleDate };
        }
        if (filterObj.communityTypeRmc && filterObj.communityTypeRmc.length) {
            filterCondition['Community_Type_Rmc__c'] = { inq: filterObj.communityTypeRmc };
        }
        
        if (filterObj.releaseNotesTypes && filterObj.releaseNotesTypes.length) {
            filterCondition['types'] = { inq: filterObj.releaseNotesTypes };
        }
        if (filterObj.releaseNotesVersions && filterObj.releaseNotesVersions.length) {
            filterCondition['Version'] = { inq: filterObj.releaseNotesVersions };
        }
        if (filterObj.talentTypeList && filterObj.talentTypeList.length) {
            filterCondition['talentTypeList'] = { inq: filterObj.talentTypeList };
        }
        if (filterObj.talentLevels && filterObj.talentLevels.length) {
            filterCondition['talentLevels'] = { inq: filterObj.talentLevels };
        }
        if (filterObj.talentTypesName && filterObj.talentTypesName.length) {
            filterCondition['talentTypesName'] = { inq: filterObj.talentTypesName };
        }
        if (filterObj.workerEmail && filterObj.workerEmail.length) {
            filterCondition['Email__c'] = { inq: filterObj.workerEmail };
        }        
        if (filterObj.isVendorApplied && filterObj.isVendorApplied.length) {
            filterCondition['isVendorApplied'] = filterObj.isVendorApplied;
        }
        if (filterObj.nptProgram && filterObj.nptProgram.length) {
            filterCondition['NPT_program__c'] = { inq: filterObj.nptProgram };
        }
        if (filterObj.custReqDate && filterObj.custReqDate[0]) {
            const startDate = filterObj.custReqDate[0];
            filterCondition['Customer_Appointment_Schedule_Start_Date__c'] = { between: [startDate[0], startDate[1]] };
        }
        if (filterObj.passiveJob && filterObj.passiveJob.length) {
            filterCondition['passiveJob'] = { inq: filterObj.passiveJob };
        }
        if (filterObj.smsType && filterObj.smsType.length) {
            filterCondition['messageName'] = { inq: filterObj.smsType };
        }
        
        if (filterObj.smsStatus && filterObj.smsStatus.length) {
            filterCondition['MessageStatus'] = { inq: filterObj.smsStatus };
        }
        
        if (filterObj.messageScheduledDate && filterObj.messageScheduledDate[0]) {
            const startDate = filterObj.messageScheduledDate[0];
            filterCondition['messageScheduledDateTime'] = { between: [startDate[0], startDate[1]] };
        }

        if (filterObj.messageSentTo) {
            filterCondition['MessageSentTo'] = filterObj.messageSentTo;
        }
        if (filterObj.messageSentFrom) {
            filterCondition['MessageSentFrom'] = filterObj.messageSentFrom;
        }

        if (filterObj.messageCommunity && filterObj.messageCommunity.length) {
            filterCondition['MessageCommunity'] = { inq: filterObj.messageCommunity };
        }

        // console.log(filterObj);
        // set local storage for mantain search filter on page changed
        localStorage.setItem('filterCondition', JSON.stringify(filterCondition));
    }
    /**
     * Read all filter from local storage
     * @param enableFilters
     */
    getGlobalFilter(enableFilters = []) {
        
        let filterObjCondition = JSON.parse(localStorage.getItem('filterCondition'));
        let filterObj = JSON.parse(localStorage.getItem('filterObj'));

        if (localStorage.getItem('backupfilterCondition')) {
            filterObjCondition = JSON.parse(localStorage.getItem('backupfilterCondition'));
        }
        if (localStorage.getItem('backupFilterObj')) {
            filterObj = JSON.parse(localStorage.getItem('backupFilterObj'));
        }
        const globalFilterData = filterObjCondition;
        const globalFilterObjData = filterObj;
        if (globalFilterData && Object.keys(globalFilterData).length) {
            if (enableFilters && enableFilters.length) {
                const filterCondition = {};
                if (globalFilterData.projectManagers && enableFilters.indexOf('projectManagers') > -1) {
                    filterCondition['projectManagers'] = globalFilterData.projectManagers;
                }
                if (globalFilterData.checkInStatus && enableFilters.indexOf('checkInStatus') > -1) {
                    filterCondition['Check_In_Status__c'] = globalFilterData.checkInStatus;
                }
                if (globalFilterData.checkInFrequency && enableFilters.indexOf('checkInFrequency') > -1) {
                    filterCondition['Frequency__c'] = globalFilterData.checkInFrequency;
                }
                if (globalFilterData.objective && enableFilters.indexOf('objective') > -1) {
                    filterCondition['objective'] = globalFilterData.objective;
                }
                if (globalFilterData.keyResult && enableFilters.indexOf('keyResult') > -1) {
                    filterCondition['keyResult'] = globalFilterData.keyResult;
                }
                if (globalFilterData.initiative && enableFilters.indexOf('initiative') > -1) {
                    filterCondition['initiative'] = globalFilterData.initiative;
                }
                if (globalFilterData.Period__c && enableFilters.indexOf('period') > -1) {
                    filterCondition['Period__c'] = globalFilterData.Period__c;
                }
                if (globalFilterData.Objective_Assignment_Type__c && enableFilters.indexOf('objectiveType') > -1) {
                    filterCondition['Objective_Assignment_Type__c'] = globalFilterData.Objective_Assignment_Type__c;
                }
                if (globalFilterData.Objective_Status__c && enableFilters.indexOf('objectiveStatus') > -1) {
                    filterCondition['Objective_Status__c'] = globalFilterData.Objective_Status__c;
                }
                if (globalFilterData.Vendor__c && enableFilters.indexOf('vendorAccounts') > -1) {
                    filterCondition['Vendor__c'] = globalFilterData.Vendor__c;
                }
                if (globalFilterData.contactAccount__c && enableFilters.indexOf('contactAccounts') > -1) {
                    filterCondition['contactAccount__c'] = globalFilterData.contactAccount__c;
                }
                if (globalFilterData.documentCategory && enableFilters.indexOf('documentCategory') > -1) {
                    filterCondition['categoryId'] = globalFilterData.documentCategory;
                }
                if (globalFilterData.pageViewGroup && enableFilters.indexOf('pageViewGroup') > -1) {
                    filterCondition['pageViewGroupId'] = globalFilterData.pageViewGroup;
                }
                // Either Account or Task Account filter will be active at a time. both can never be enable at the same time
                if (globalFilterData.CKSW_BASE__Account__c && enableFilters.indexOf('accounts') > -1) {
                    filterCondition['CKSW_BASE__Account__c'] = globalFilterData.CKSW_BASE__Account__c;
                }
                if (globalFilterData.Internal_Account_Id && enableFilters.indexOf('taskAccounts') > -1) {
                    filterCondition['Internal_Account_Id'] = globalFilterData.Internal_Account_Id;
                }
                if (globalFilterData.Project_SOP__c && enableFilters.indexOf('programs') > -1) {
                    filterCondition['Project_SOP__c'] = globalFilterData.Project_SOP__c;
                }
                // if josite filter is enabled
                if (globalFilterData.Jobsite__c && enableFilters.indexOf('jobSites') > -1) {
                    //if geo metro filter is also enabled and we have data in geo metro filter obj
                    if (enableFilters.indexOf('geoMetro') > -1 && globalFilterObjData['geoMetroJobSite']
                        && globalFilterObjData['geoMetroJobSite'].length) {
                        // if we have data in jobsite filter obj as well as in geo metro filter obj, going to extract common
                        if (globalFilterObjData['jobSites'] && globalFilterObjData['jobSites'].length > 0) {
                            filterCondition['Jobsite__c'] = {
                                inq: globalFilterObjData['geoMetroJobSite'].filter(geoItem =>
                                    globalFilterObjData['jobSites'].includes(geoItem)
                                )
                            }
                        } else {
                            // when we don't have data in jobsite filter obj
                            filterCondition['Jobsite__c'] = { inq: globalFilterObjData['geoMetroJobSite'] };
                        }
                    } else {
                        // only jobsite filter is enabled or we don't have data in geo metro filter obj
                        if (globalFilterObjData['jobSites'] && globalFilterObjData['jobSites'].length > 0) {
                            filterCondition['Jobsite__c'] = { inq: globalFilterObjData['jobSites'] };
                        }
                    }
                }
                if (globalFilterData.Iron_Job_num__c && enableFilters.indexOf('jobs') > -1) {
                    filterCondition['Iron_Job_num__c'] = globalFilterData.Iron_Job_num__c;
                }
                if (globalFilterData.sfdcId && enableFilters.indexOf('jobsfdcIds') > -1) {
                    filterCondition['jobsfdcIds'] = globalFilterData.sfdcId;
                }
                if (globalFilterData.Dispatch_Service_Resolution_Status__c && enableFilters.indexOf('statgeStatus') > -1) {
                    filterCondition['Dispatch_Service_Resolution_Status__c'] = globalFilterData.Dispatch_Service_Resolution_Status__c;
                }
                if (globalFilterData.and && enableFilters.indexOf('jobTypes') > -1) {
                    filterCondition['and'] = globalFilterData.and;
                }
                if (globalFilterData.caseType && enableFilters.indexOf('caseTypes') > -1) {
                    filterCondition['caseType'] = globalFilterData.caseType;
                }
                if (globalFilterData.Talent_Type__c && enableFilters.indexOf('talentTypes') > -1) {
                    filterCondition['Talent_Type__c'] = globalFilterData.Talent_Type__c;
                }
                if (globalFilterData.Worker_Talent_Type__c && enableFilters.indexOf('workerTalentTypes') > -1) {
                    filterCondition['Worker_Talent_Type__c'] = globalFilterData.Worker_Talent_Type__c;
                }
                if (globalFilterData.CreatedDate && enableFilters.indexOf('dateOptions') > -1) {
                    filterCondition['CreatedDate'] = globalFilterData.CreatedDate;
                }
                if (globalFilterData.CreatedDate && enableFilters.indexOf('jobCreatedDate') > -1) {
                    filterCondition['jobCreatedDate'] = globalFilterData.jobCreatedDate;
                }
                if (globalFilterData.Customer_Appointment_DateTime_Scheduled__c && enableFilters.indexOf('customerScheduled') > -1) {
                    filterCondition['Customer_Appointment_DateTime_Scheduled__c'] = globalFilterData.Customer_Appointment_DateTime_Scheduled__c;
                }
                if (globalFilterData.WorkerScheduled && enableFilters.indexOf('workerScheduled') > -1) {
                    filterCondition['WorkerScheduled'] = globalFilterData.WorkerScheduled;
                }
                if (globalFilterData.RecordTypeId && enableFilters.indexOf('caseCategory') > -1) {
                    filterCondition['RecordTypeId'] = globalFilterData.RecordTypeId;
                }
                if (globalFilterData.Service_Dispatch_SLA_Priority__c && enableFilters.indexOf('priority') > -1) {
                    filterCondition['Service_Dispatch_SLA_Priority__c'] = globalFilterData.Service_Dispatch_SLA_Priority__c;
                }
                if (globalFilterData.Job_Status_Internal__c && enableFilters.indexOf('jobStatus') > -1) {
                    filterCondition['Job_Status_Internal__c'] = globalFilterData.Job_Status_Internal__c;
                }
                if (globalFilterData.Dispatch_Worker_Name__c && enableFilters.indexOf('workerNames') > -1) {
                    filterCondition['Dispatch_Worker_Name__c'] = globalFilterData.Dispatch_Worker_Name__c;
                }
                if (globalFilterData.Dispatch_Worker_num__c && enableFilters.indexOf('workerIds') > -1) {
                    filterCondition['Dispatch_Worker_num__c'] = globalFilterData.Dispatch_Worker_num__c;
                }
                if (globalFilterData.Vendorsite__c && enableFilters.indexOf('vendorSites') > -1) {
                    filterCondition['Vendorsite__c'] = globalFilterData.Vendorsite__c;
                }
                if (globalFilterData.Jobsite__c && enableFilters.indexOf('geoMetro') > -1 &&
                    enableFilters.indexOf('jobSites') === -1) {
                    filterCondition['Jobsite__c'] = { inq: globalFilterObjData['geoMetroJobSite'] };
                }
                // WHEN JOBSITE AND GEO-METRO BOTH ARE AVAILABLE BUT ONLY ONE APPLIED
                if (globalFilterData.Jobsite__c && globalFilterData.Jobsite__c.inq &&
                    !globalFilterData.Jobsite__c.inq.length) {
                    filterCondition['Jobsite__c'] = { inq: [] }

                }
                if (globalFilterData.Case__c && enableFilters.indexOf('teamShiftUsers') > -1) {
                    filterCondition['Case__c'] = globalFilterData.Case__c;
                }
                if (globalFilterData.PMS_Case_Status && enableFilters.indexOf('pmsCaseStatus') > -1) {
                    filterCondition['PMS_Case_Status'] = globalFilterData.PMS_Case_Status;
                }
                if (globalFilterData.PMS_Case_Number && enableFilters.indexOf('pmsCaseNumbers') > -1) {
                    filterCondition['PMS_Case_Number'] = globalFilterData.PMS_Case_Number;
                }
                if (globalFilterData.PMC_Case_Number && enableFilters.indexOf('pmcCaseNumbers') > -1) {
                    filterCondition['PMC_Case_Number'] = globalFilterData.PMC_Case_Number;
                }
                if (globalFilterData.CountryCode && enableFilters.indexOf('countries') > -1) {
                    filterCondition['CountryCode'] = globalFilterData.CountryCode;
                }
                if (globalFilterData.StateName && enableFilters.indexOf('states') > -1) {
                    filterCondition['StateName'] = globalFilterData.StateName;
                }
                if (globalFilterData.StateManager__c && enableFilters.indexOf('statesManager') > -1) {
                    filterCondition['StateManager__c'] = globalFilterData.StateManager__c;
                }

                if (globalFilterData.Region && enableFilters.indexOf('regions') > -1) {
                    filterCondition['Region'] = globalFilterData.Region;
                }
                // key is in global filters
                if (globalFilterData.OrderType && enableFilters.indexOf('orderTypes') > -1) {
                    filterCondition['OrderType'] = globalFilterData.OrderType;
                }
                // key is in pms global filters
                if (globalFilterData.ContractCategory && enableFilters.indexOf('contractCategory') > -1) {
                    filterCondition['ContractCategory'] = globalFilterData.ContractCategory;
                }

                // escalation type filter
                if (globalFilterData.Escalation_Type && enableFilters.indexOf('escalationType') > -1) {
                    filterCondition['Escalation_Type'] = globalFilterData.Escalation_Type;
                }

                if (globalFilterData.PgMO_Projects__c && enableFilters.indexOf('projects') > -1) {
                    filterCondition['PgMO_Projects__c'] = globalFilterData.PgMO_Projects__c;
                }
                if (globalFilterData.projectCategorySfdcId && enableFilters.indexOf('projectCategory') > -1) {
                    filterCondition['projectCategorySfdcId'] = globalFilterData.projectCategorySfdcId;
                }
                if (globalFilterData.PgMO_Milestones__c && enableFilters.indexOf('milestones') > -1) {
                    filterCondition['PgMO_Milestones__c'] = globalFilterData.PgMO_Milestones__c;
                }
                if (globalFilterData.PgMo_Task__c && enableFilters.indexOf('tasks') > -1) {
                    filterCondition['PgMo_Task__c'] = globalFilterData.PgMo_Task__c;
                }
                if (globalFilterData.Task_Status__c && enableFilters.indexOf('taskStatuses') > -1) {
                    filterCondition['Task_Status__c'] = globalFilterData.Task_Status__c;
                }

                if (globalFilterData.Problem_Resolution_Status__c && enableFilters.indexOf('problemTaskStatuses') > -1) {
                    filterCondition['Problem_Resolution_Status__c'] = globalFilterData.Problem_Resolution_Status__c;
                }

                if (globalFilterData.Requestor__c && enableFilters.indexOf('taskRequestors') > -1) {
                    filterCondition['Requestor__c'] = globalFilterData.Requestor__c;
                }
                if (globalFilterData.Request_Type__c && enableFilters.indexOf('taskTypes') > -1) {
                    filterCondition['Request_Type__c'] = globalFilterData.Request_Type__c;
                }
                if (globalFilterData.Project_Type__c && enableFilters.indexOf('projectTypes') > -1) {
                    filterCondition['Project_Type__c'] = globalFilterData.Project_Type__c;
                }
                if (globalFilterData.TeamProjectType && enableFilters.indexOf('teamProjectTypes') > -1) {
                    filterCondition['TeamProjectType'] = globalFilterData.TeamProjectType;
                }

                if (globalFilterData.Dispatch_worker_type && enableFilters.indexOf('employeeType') > -1) {
                    filterCondition['Dispatch_worker_type'] = globalFilterData.Dispatch_worker_type;
                }

                if (globalFilterData.leaveStatusType && enableFilters.indexOf('leaveStatusType') > -1) {
                    filterCondition['leaveStatusType'] = globalFilterData.leaveStatusType;
                } 
                
                if (globalFilterData.Technical_Level__c && enableFilters.indexOf('technicalLevels') > -1) {
                    filterCondition['Technical_Level__c'] = globalFilterData.Technical_Level__c;
                }
                // Timecard filters
                if (globalFilterData.IRON_Approval_Status__c && enableFilters.indexOf('ironApprovalStatuses') > -1) {
                    filterCondition['IRON_Approval_Status__c'] = globalFilterData.IRON_Approval_Status__c;
                }
                if (globalFilterData.Customer_Approval_Status__c && enableFilters.indexOf('customerApprovalStatuses') > -1) {
                    filterCondition['Customer_Approval_Status__c'] = globalFilterData.Customer_Approval_Status__c;
                }
                if (globalFilterData.Actual_Coverage_Hour__c && enableFilters.indexOf('actualCoverageHours') > -1) {
                    filterCondition['Actual_Coverage_Hour__c'] = globalFilterData.Actual_Coverage_Hour__c;
                }
                if (globalFilterData.Coverage_Hours__c && enableFilters.indexOf('coverageHours') > -1) {
                    filterCondition['Coverage_Hours__c'] = globalFilterData.Coverage_Hours__c;
                }
                if (globalFilterData.Actual_End_Time_Coverage__c && enableFilters.indexOf('actualEndTimeCoverages') > -1) {
                    filterCondition['Actual_End_Time_Coverage__c'] = globalFilterData.Actual_End_Time_Coverage__c;
                }
                if (globalFilterData.Actual_Coverage_Overall__c && enableFilters.indexOf('actualCoverageOveralls') > -1) {
                    filterCondition['Actual_Coverage_Overall__c'] = globalFilterData.Actual_Coverage_Overall__c;
                }
                if (globalFilterData.Worker_Billing_Type__c && enableFilters.indexOf('workerBillingTypes') > -1) {
                    filterCondition['Worker_Billing_Type__c'] = globalFilterData.Worker_Billing_Type__c;
                }
                if (globalFilterData.Work_Order_num__c && enableFilters.indexOf('purchaseOrder') > -1) {
                    filterCondition['Work_Order_num__c'] = globalFilterData.Work_Order_num__c;
                }
                if (globalFilterData.Service_Contract__c && enableFilters.indexOf('serviceContractNumbers') > -1) {
                    filterCondition['Service_Contract__c'] = globalFilterData.Service_Contract__c;
                }
                if (globalFilterData.Service_Contract_Category__c && enableFilters.indexOf('serviceContractCategory') > -1) {
                    filterCondition['Service_Contract_Category__c'] = globalFilterData.Service_Contract_Category__c;
                }
                // set filter condition for puchase Invoice Status
                if (globalFilterData.Purchase_Invoice_Status && enableFilters.indexOf('purchaseInvoiceStatus') > -1) {
                    filterCondition['Purchase_Invoice_Status'] = globalFilterData.Purchase_Invoice_Status;
                }
                if (globalFilterData.Division__c && enableFilters.indexOf('divisions') > -1) {
                    filterCondition['Division__c'] = globalFilterData.Division__c;
                }
                if (globalFilterData.Department__c && enableFilters.indexOf('departments') > -1) {
                    filterCondition['Department__c'] = globalFilterData.Department__c;
                }
                if (globalFilterData.PgMO_Departments__c && enableFilters.indexOf('pgmo_departments') > -1) {
                    filterCondition['PgMO_Departments__c'] = globalFilterData.PgMO_Departments__c;
                }
                if (globalFilterData.PgMO_Task_Library__c && enableFilters.indexOf('taskLibraries') > -1) {
                    filterCondition['PgMO_Task_Library__c'] = globalFilterData.PgMO_Task_Library__c;
                }
                if (globalFilterData.PgMO_Milestone_Library__c && enableFilters.indexOf('milestoneLibraries') > -1) {
                    filterCondition['PgMO_Milestone_Library__c'] = globalFilterData.PgMO_Milestone_Library__c;
                }
                if (globalFilterData.Library_Type_Selected__c && enableFilters.indexOf('tmpltLibraryTypes') > -1) {
                    filterCondition['Library_Type_Selected__c'] = globalFilterData.Library_Type_Selected__c;
                }
                if (globalFilterData.Expense_Type__c && enableFilters.indexOf('expenseTypes') > -1) {
                    filterCondition['Expense_Type__c'] = globalFilterData.Expense_Type__c;
                }
                if (globalFilterData.Expense_sub_Type__c && enableFilters.indexOf('expenseSubTypes') > -1) {
                    filterCondition['Expense_sub_Type__c'] = globalFilterData.Expense_sub_Type__c;
                }
                if (globalFilterData.Payment_Type__c && enableFilters.indexOf('paymentTypes') > -1) {
                    filterCondition['Payment_Type__c'] = globalFilterData.Payment_Type__c;
                }
                if (globalFilterData.Account_Type__c && enableFilters.indexOf('accountCommunities') > -1) {
                    filterCondition['Account_Type__c'] = globalFilterData.Account_Type__c;
                }
                if (globalFilterData.Activity_Type__c && enableFilters.indexOf('activityType') > -1) {
                    filterCondition['Activity_Type__c'] = globalFilterData.Activity_Type__c;
                }
                if (globalFilterData.Worker_Type__c && enableFilters.indexOf('workerType') > -1) {
                    filterCondition['Worker_Type__c'] = globalFilterData.Worker_Type__c;
                }
                if (globalFilterData.Worker_Type_Sub__c && enableFilters.indexOf('workerTypeSub') > -1) {
                    filterCondition['Worker_Type_Sub__c'] = globalFilterData.Worker_Type_Sub__c;
                }
                if (globalFilterData.Customer_Appointment_Start_Scheduled__c && enableFilters.indexOf('customerDateOptions') > -1) {
                    filterCondition['Customer_Appointment_Start_Scheduled__c'] = globalFilterData.Customer_Appointment_Start_Scheduled__c;
                }
                if (globalFilterData.Priority__c && enableFilters.indexOf('taskPriorities') > -1) {
                    filterCondition['Priority__c'] = globalFilterData.Priority__c;
                }
                if (globalFilterData.Worker__c && enableFilters.indexOf('users') > -1) {
                    filterCondition['Worker__c'] = globalFilterData.Worker__c;
                }
                if (globalFilterData.PgMO_Groups__c && enableFilters.indexOf('teams') > -1) {
                    filterCondition['PgMO_Groups__c'] = globalFilterData.PgMO_Groups__c;
                }
                if (globalFilterData.Start_Date__c && enableFilters.indexOf('dateFrom') > -1) {
                    filterCondition['Start_Date__c'] = globalFilterData.Start_Date__c;
                }
                if (globalFilterData.startFromDate && enableFilters.indexOf('startFromDateOptions') > -1) {
                    filterCondition['startFromDate'] = globalFilterData.startFromDate;
                }
                // this key was not in global filters
                if (globalFilterData.Team_Type && enableFilters.indexOf('teamTypes') > -1) {
                    filterCondition['Team_Type'] = globalFilterData.Team_Type;
                }
                if (globalFilterData.Access__c && enableFilters.indexOf('departmentRoleAccesses') > -1) {
                    filterCondition['Access_c'] = globalFilterData.Access__c;
                }
                if (globalFilterData.Customer__c && enableFilters.indexOf('customers') > -1) {
                    filterCondition['Customer__c'] = globalFilterData.Customer__c;
                }
                if (globalFilterData.Job_Order__c && enableFilters.indexOf('workOrderCaseIds') > -1) {
                    filterCondition['Job_Order__c'] = globalFilterData.Job_Order__c;
                }
                if (globalFilterData.Category__c && enableFilters.indexOf('templateCategories') > -1) {
                    filterCondition['Category__c'] = globalFilterData.Category__c;
                }
                if (globalFilterData.Sub_Catergory__c && enableFilters.indexOf('subCategories') > -1) {
                    filterCondition['Sub_Catergory__c'] = globalFilterData.Sub_Catergory__c;
                }
                if (globalFilterData.APVP_Group_Number__c && enableFilters.indexOf('apvpGroup') > -1) {
                    filterCondition['APVP_Group_Number__c'] = globalFilterData.APVP_Group_Number__c;
                }
                if (globalFilterData.Community_Type__c && enableFilters.indexOf('communityType') > -1) {
                    filterCondition['Community_Type__c'] = globalFilterData.Community_Type__c;
                }
                if (globalFilterData.SLA_Group_Name__c && enableFilters.indexOf('slaGroup') > -1) {
                    filterCondition['SLA_Group_Name__c'] = globalFilterData.SLA_Group_Name__c;
                }
                if (globalFilterData.FSL_Location_Status__c && enableFilters.indexOf('wslAccountStatus') > -1) {
                    filterCondition['FSL_Location_Status__c'] = globalFilterData.FSL_Location_Status__c;
                }
                if (globalFilterData.WSL_Accounts && enableFilters.indexOf('wslAccounts') > -1) {
                    filterCondition['WSL_Accounts'] = globalFilterData.WSL_Accounts;
                }
                if (globalFilterData.address && enableFilters.indexOf('address') > -1) {
                    filterCondition['address'] = globalFilterData.address;
                }
                if (globalFilterData.GeoCode && enableFilters.indexOf('geoCode') > -1) {
                    filterCondition['GeoCode'] = globalFilterData.GeoCode;
                }
                if (globalFilterData.City__c && enableFilters.indexOf('city') > -1) {
                    filterCondition['City__c'] = globalFilterData.City__c;
                }
                if (globalFilterData.Worker_Status && enableFilters.indexOf('workerStatus') > -1) {
                    filterCondition['Worker_Status'] = globalFilterData.Worker_Status;
                }
                if (globalFilterData.radius && enableFilters.indexOf('radius') > -1) {
                    filterCondition['radius'] = globalFilterData.radius;
                }
                if (globalFilterData.DrivingDistance && enableFilters.indexOf('driving_distance') > -1) {
                    filterCondition['DrivingDistance'] = globalFilterData.DrivingDistance;
                }
                if (globalFilterData.Salary && enableFilters.indexOf('salary') > -1) {
                    filterCondition['Salary'] = globalFilterData.Salary;
                }
                if (globalFilterData.Target_Cost__c && enableFilters.indexOf('targetCost') > -1) {
                    filterCondition['Target_Cost__c'] = globalFilterData.Target_Cost__c;
                }
                if (globalFilterData.gtpResourceType && enableFilters.indexOf('gtpResourceType') > -1) {
                    filterCondition['gtpResourceType'] = globalFilterData.gtpResourceType;
                }
                if (globalFilterData.gtpPoolAccessType && enableFilters.indexOf('gtpPoolAccessType') > -1) {
                    filterCondition['gtpPoolAccessType'] = globalFilterData.gtpPoolAccessType;
                }
                if (globalFilterData.gtpMemberType && enableFilters.indexOf('gtpMemberType') > -1) {
                    filterCondition['gtpMemberType'] = globalFilterData.gtpMemberType;
                }
                if (globalFilterData.gtpCommunityType && enableFilters.indexOf('gtpCommunityType') > -1) {
                    filterCondition['gtpCommunityType'] = globalFilterData.gtpCommunityType;
                }

                if (globalFilterData.backFillWorkerService && enableFilters.indexOf('backFillWorkerService') > -1) {
                    filterCondition['backFillWorkerService'] = globalFilterData.backFillWorkerService;
                }

                if (globalFilterData.displayCoverageType && enableFilters.indexOf('displayCoverageType') > -1) {
                    filterCondition['displayCoverageType'] = globalFilterData.displayCoverageType;
                }

                if (globalFilterData.Expense_Type__c && enableFilters.indexOf('category') > -1) {
                    filterCondition['Expense_Type__c'] = globalFilterData.Expense_Type__c;
                }

                if (globalFilterData.Is_Billable__c && enableFilters.indexOf('isBillable') > -1) {
                    filterCondition['Is_Billable__c'] = globalFilterData.Is_Billable__c;
                }

                if (globalFilterData.isTest && enableFilters.indexOf('isTestUser') > -1) {
                    filterCondition['isTest'] = globalFilterData.isTest;
                }
                if (globalFilterData.conversationSource__c && enableFilters.indexOf('conversationSource') > -1) {
                    filterCondition['conversationSource__c'] = globalFilterData.conversationSource;
                }
                if (globalFilterData.Is_Test_Account__c && enableFilters.indexOf('includeTestAccount') > -1) {
                    filterCondition['Is_Test_Account__c'] = globalFilterData.Is_Test_Account__c;
                }
                if (globalFilterData.isServiceoLogin && enableFilters.indexOf('isServiceoLogin') > -1) {
                    filterCondition['isServiceoLogin'] = globalFilterData.isServiceoLogin;
                }
                if (globalFilterData.isTest && enableFilters.indexOf('uniqueUser') > -1) {
                    filterCondition['uniqueUser'] = globalFilterData.uniqueUser;
                }

                if (globalFilterData.isActive && enableFilters.indexOf('isActiveUser') > -1) {
                    filterCondition['isActive'] = globalFilterData.isActive;
                }

                if (globalFilterData.vendorSubTypeCommunity && enableFilters.indexOf('vendorSubTypeCommunity') > -1) {
                    filterCondition['vendorSubTypeCommunity'] = globalFilterData.vendorSubTypeCommunity;
                }

                if (globalFilterData.covrageHoursAccountType && enableFilters.indexOf('covrageHoursAccountType') > -1) {
                    filterCondition['covrageHoursAccountType'] = globalFilterData.covrageHoursAccountType;
                }

                if (globalFilterData.slaAccountType && enableFilters.indexOf('slaAccountType') > -1) {
                    filterCondition['slaAccountType'] = globalFilterData.slaAccountType;
                }

                if (globalFilterData.displayVendorsiteType && enableFilters.indexOf('displayVendorsiteType') > -1) {
                    filterCondition['displayVendorsiteType'] = globalFilterData.displayVendorsiteType;
                }
                if (globalFilterData.vendorStatusType && enableFilters.indexOf('vendorStatusType') > -1) {
                    filterCondition['vendorStatusType'] = globalFilterData.vendorStatusType;
                }
                if (globalFilterData.mvvpDisplayType && enableFilters.indexOf('mvvpDisplayType') > -1) {
                    filterCondition['mvvpDisplayType'] = globalFilterData.mvvpDisplayType;
                }
                if (globalFilterData.candidateType && enableFilters.indexOf('candidateType') > -1) {
                    filterCondition['candidateType'] = globalFilterData.candidateType;
                }
                if (globalFilterData.slaLabourCloudType && enableFilters.indexOf('slaLabourCloudType') > -1) {
                    filterCondition['slaLabourCloudType'] = globalFilterData.slaLabourCloudType;
                }
                if (globalFilterData.coverHoursMvvp && enableFilters.indexOf('coverHoursMvvp') > -1) {
                    filterCondition['coverHoursMvvp'] = globalFilterData.coverHoursMvvp;
                }
                if (globalFilterData.jobOrderStatus && enableFilters.indexOf('jobOrderStatus') > -1) {
                    filterCondition['jobOrderStatus'] = globalFilterData.jobOrderStatus;
                }
                if (globalFilterData.jobOrderStage && enableFilters.indexOf('jobOrderStage') > -1) {
                    filterCondition['jobOrderStage'] = globalFilterData.jobOrderStage;
                }
                if (globalFilterData.pcUserCount && enableFilters.indexOf('pcUserCount') > -1) {
                    filterCondition['pcUserCount'] = globalFilterData.pcUserCount;
                }

                if (globalFilterData.pageType && enableFilters.indexOf('pageType') > -1) {
                    filterCondition['pageType'] = globalFilterData.pageType;
                }

                if (globalFilterData.weeklyDateOption && enableFilters.indexOf('weeklyDateOption') > -1) {
                    filterCondition['weeklyDateOption'] = globalFilterData.weeklyDateOption;
                }
                
                if (globalFilterData.jobOrderPriority && enableFilters.indexOf('jobOrderPriority') > -1) {
                    filterCondition['jobOrderPriority'] = globalFilterData.jobOrderPriority;
                }
                if (globalFilterData.job_Order__c && enableFilters.indexOf('jobOrders') > -1) {
                    filterCondition['job_Order__c'] = globalFilterData.job_Order__c;
                }
                if (globalFilterData.Application__c && enableFilters.indexOf('applications') > -1) {
                    filterCondition['Application__c'] = globalFilterData.Application__c;
                }
                if (globalFilterData.ClosedDate && enableFilters.indexOf('dateClosed') > -1) {
                    filterCondition['ClosedDate'] = globalFilterData.ClosedDate;
                }

                if (globalFilterData.Coverage_Hours__c && enableFilters.indexOf('priceListCoverageHours') > -1) {
                    filterCondition['Coverage_Hours__c'] = globalFilterData.Coverage_Hours__c;
                }

                if (globalFilterData.Service_Technical_Level__c && enableFilters.indexOf('priceListTechnicalLevel') > -1) {
                    filterCondition['Service_Technical_Level__c'] = globalFilterData.Service_Technical_Level__c;
                }

                if (globalFilterData.SLA__c && enableFilters.indexOf('priceListSLA') > -1) {
                    filterCondition['SLA__c'] = globalFilterData.SLA__c;
                }

                if (globalFilterData.Service_Talent_Type__c && enableFilters.indexOf('servicetalentTypes') > -1) {
                    filterCondition['Service_Talent_Type__c'] = globalFilterData.Service_Talent_Type__c;
                }

                if (globalFilterData.member && enableFilters.indexOf('member') > -1) {
                    filterCondition['firstname'] = globalFilterData.member;
                }

                if(globalFilterData.Location_List_New__c && enableFilters.indexOf('locations') > -1) {
                    filterCondition['Location_List_New__c'] = globalFilterData.Location_List_New__c;
                }

                if(globalFilterData.cityList && enableFilters.indexOf('cityList') > -1) {
                    filterCondition['cityList'] = globalFilterData.cityList;
                }

                if (globalFilterData.Is_System_Generated__c && enableFilters.indexOf('messageType') > -1) {
                    filterCondition['Is_System_Generated__c'] = globalFilterData.Is_System_Generated__c;
                }

                if(globalFilterData.jobTitles && enableFilters.indexOf('jobTitles') > -1) {
                    filterCondition['jobTitles'] = globalFilterData.jobTitles;
                }                
                if(globalFilterData.appStatus && enableFilters.indexOf('appStatus') > -1) {
                    filterCondition['appStatus'] = globalFilterData.appStatus;
                }
                if (globalFilterData.Type_of_Requirement__c && enableFilters.indexOf('requirementType') > -1) {
                    filterCondition['Type_of_Requirement__c'] = globalFilterData.Type_of_Requirement__c;
                }
                if (globalFilterData.employmentFilter && enableFilters.indexOf('employmentFilter') > -1) {
                    filterCondition['employmentFilter'] = globalFilterData.employmentFilter;
                }
                if (globalFilterData.Job_Creation_Reason__c && enableFilters.indexOf('jobCreationReason') > -1) {
                    filterCondition['Job_Creation_Reason__c'] = globalFilterData.Job_Creation_Reason__c;
                }
                if (globalFilterData.Approval_Status__c && enableFilters.indexOf('approvalStatus') > -1) {
                    filterCondition['Approval_Status__c'] = globalFilterData.Approval_Status__c;
                }
                if(globalFilterData.candidateCurrentSalary && enableFilters.indexOf('candidateCurrentSalary') > -1) {
                    filterCondition['candidateCurrentSalary'] = globalFilterData.candidateCurrentSalary;
                }
                if(globalFilterData.candidatePayRateRequested && enableFilters.indexOf('candidatePayRateRequested') > -1) {
                    filterCondition['candidatePayRateRequested'] = globalFilterData.candidatePayRateRequested;
                }
                if(globalFilterData.noticePeriod && enableFilters.indexOf('noticePeriod') > -1) {
                    filterCondition['noticePeriod'] = globalFilterData.noticePeriod;
                }
                if (globalFilterData.employeeTypeList && enableFilters.indexOf('employeeTypeList') > -1) {
                    filterCondition['employeeTypeList'] = globalFilterData.employeeTypeList;
                }

                if (globalFilterData.timeChangeRequestStatus && enableFilters.indexOf('timeChangeRequestStatus') > -1) {
                    filterCondition['timeChangeRequestStatus'] = globalFilterData.timeChangeRequestStatus;
                  }
                
                  if (globalFilterData.employeeTypeList && enableFilters.indexOf('employeeTypeList') > -1) {
                    filterCondition['employeeTypeList'] = globalFilterData.employeeTypeList;
                  }
                if (globalFilterData.jobOrderStatusRmc && enableFilters.indexOf('jobOrderStatusRmc') > -1) {
                    filterCondition['jobOrderStatusRmc'] = globalFilterData.jobOrderStatusRmc;
                }
                if (globalFilterData.userTypeId && enableFilters.indexOf('userTypeId') > -1) {
                    filterCondition['userTypeId'] = globalFilterData.userTypeId;
                }
                if (globalFilterData.jobAssigned && enableFilters.indexOf('jobAssigned') > -1) {
                    filterCondition['jobAssigned'] = globalFilterData.jobAssigned;
                }
                if (globalFilterData.jobPostingType && enableFilters.indexOf('jobPostingType') > -1) {
                    filterCondition['jobPostingType'] = globalFilterData.jobPostingType;
                }
                if (globalFilterData.stageStatus && enableFilters.indexOf('stageStatus') > -1) {
                    filterCondition['stageStatus'] = globalFilterData.stageStatus;
                }
                if (globalFilterData.completedStatus && enableFilters.indexOf('completedStatus') > -1) {
                    filterCondition['completedStatus'] = globalFilterData.completedStatus;
                }
                if (globalFilterData.showOnlyJobType && enableFilters.indexOf('showOnlyJobType') > -1) {
                    filterCondition['showOnlyJobType'] = globalFilterData.showOnlyJobType;
                }
                if (globalFilterData.fileNames && enableFilters.indexOf('fileNames') > -1) {
                    filterCondition['fileNames'] = globalFilterData.fileNames;
                }
                if (globalFilterData.excludeMagicLink && enableFilters.indexOf('excludeMagicLink') > -1) {
                    filterCondition['ExcludeMagicLink'] = globalFilterData.excludeMagicLink;
                }
                if (globalFilterData.Business_Requestor__c && enableFilters.indexOf('businessRequestor') > -1) {
                    filterCondition['Business_Requestor__c'] = globalFilterData.Business_Requestor__c;
                }
                if (globalFilterData.Form_Name__c && enableFilters.indexOf('formName') > -1) {
                    filterCondition['Form_Name__c'] = globalFilterData.Form_Name__c;
                }
                if (globalFilterData.Work_Order_Plan_Date__c && enableFilters.indexOf('workOrderDateOptions') > -1) {
                    filterCondition['Work_Order_Plan_Date__c'] = globalFilterData.Work_Order_Plan_Date__c;
                }
                if (globalFilterData.Show_Milestones__c && enableFilters.indexOf('milestoneTask') > -1) {
                    filterCondition['Show_Milestones__c'] = globalFilterData.Show_Milestones__c;
                }
                if (globalFilterObjData['recruitmentManager'] && globalFilterObjData['recruitmentManager'].length > 0) {
                    filterCondition['recruitmentManager'] = { inq: globalFilterObjData['recruitmentManager'] };
                }
                if (globalFilterObjData['recruiter'] && globalFilterObjData['recruiter'].length > 0) {
                    filterCondition['recruiter'] = { inq: globalFilterObjData['recruiter'] };
                }
                if (globalFilterObjData['businessUnit'] && globalFilterObjData['businessUnit'].length > 0) {
                    filterCondition['businessUnit'] = { inq: globalFilterObjData['businessUnit'] };
                }
                if (globalFilterObjData['interviewFeedback'] && globalFilterObjData['interviewFeedback'].length > 0) {
                    filterCondition['interviewFeedback'] = { inq: globalFilterObjData['interviewFeedback'] };
                }
                if (globalFilterObjData['job'] && globalFilterObjData['job'].length > 0) {
                    filterCondition['job'] = { inq: globalFilterObjData['job'] };
                }
                if (globalFilterObjData['interviewRound'] && globalFilterObjData['interviewRound'].length > 0) {
                    filterCondition['interviewRound'] = { inq: globalFilterObjData['interviewRound'] };
                }
                if (globalFilterObjData['singleDate'] && globalFilterObjData['singleDate'].length > 0) {
                    filterCondition['singleDate'] = { inq: globalFilterObjData['singleDate'] };
                }
                if (globalFilterData.Community_Type_Rmc__c && enableFilters.indexOf('communityTypeRmc') > -1) {
                    filterCondition['Community_Type_Rmc__c'] = globalFilterData.Community_Type_Rmc__c;
                }
                if (globalFilterData.types && enableFilters.indexOf('releaseNotesTypes') > -1) {
                    filterCondition['types'] = globalFilterData.types;
                }
                if (globalFilterData.Version && enableFilters.indexOf('releaseNotesVersions') > -1) {
                    filterCondition['Version'] = globalFilterData.Version;
                }                
                if (globalFilterObjData['Tcr'] && globalFilterObjData['Tcr'].length > 0) {
                    filterCondition['Tcr'] = { inq: globalFilterObjData['Tcr'] };
                }
                if (globalFilterObjData['talentLevels'] && globalFilterObjData['talentLevels'].length > 0) {
                    filterCondition['talentLevels'] = { inq: globalFilterObjData['talentLevels'] };
                }
                if (globalFilterObjData['talentTypeList'] && globalFilterObjData['talentTypeList'].length > 0) {
                    filterCondition['talentTypeList'] = { inq: globalFilterObjData['talentTypeList'] };
                }
                if (globalFilterObjData['talentTypesName'] && globalFilterObjData['talentTypesName'].length > 0) {
                    filterCondition['talentTypesName'] = { inq: globalFilterObjData['talentTypesName'] };
                }
                if (globalFilterObjData['workerEmail'] && globalFilterObjData['workerEmail'].length > 0) {
                    filterCondition['Email__c'] = { inq: globalFilterObjData['workerEmail'] };
                }
                if (globalFilterData.isVendorApplied && enableFilters.indexOf('isVendorApplied') > -1) {
                    filterCondition['isVendorApplied'] = globalFilterData.isVendorApplied;
                }
                if (globalFilterData.NPT_program__c && enableFilters.indexOf('nptProgram') > -1) {
                    filterCondition['NPT_program__c'] = globalFilterData.NPT_program__c;
                }
                if (globalFilterData.Customer_Appointment_Schedule_Start_Date__c && enableFilters.indexOf('custReqDate') > -1) {
                    filterCondition['Customer_Appointment_Schedule_Start_Date__c'] = globalFilterData.Customer_Appointment_Schedule_Start_Date__c;
                }
                if (globalFilterData.passiveJob && enableFilters.indexOf('passiveJob') > -1) {
                    filterCondition['Passive_Job__c'] = globalFilterData.passiveJob;
                }
                if (globalFilterData.messageName && enableFilters.indexOf('smsType') > -1) {
                    filterCondition['messageName'] = globalFilterData.messageName;
                }
                if (globalFilterData.MessageStatus && enableFilters.indexOf('smsStatus') > -1) {
                    filterCondition['MessageStatus'] = globalFilterData.MessageStatus;
                }
                
                if (globalFilterData.messageScheduledDateTime && enableFilters.indexOf('messageScheduledDate') > -1) {
                    filterCondition['messageScheduledDateTime'] = globalFilterData.messageScheduledDateTime;
                }

                if (globalFilterData.MessageSentTo && enableFilters.indexOf('messageSentTo') > -1) {
                    filterCondition['MessageSentTo'] = globalFilterData.MessageSentTo;
                }
                if (globalFilterData.MessageSentFrom && enableFilters.indexOf('messageSentFrom') > -1) {
                    filterCondition['MessageSentFrom'] = globalFilterData.MessageSentFrom;
                }
                
                if (globalFilterData.MessageCommunity && enableFilters.indexOf('messageCommunity') > -1) {
                    filterCondition['MessageCommunity'] = globalFilterData.MessageCommunity;
                }

                return filterCondition;
            } else {
                return globalFilterData;
            }
        } else {
            return {};
        }
    };

    setTimeCardAddSubject(count: any) {
        this.timeCardAdd.next(count);
    };

    replaceBreakWithComma(property: any): string {
        property = property.replace(/<br\/>/g, ', ');
        property = property.substring(0, property.length - 2);
        return property;
    }

    logger(...data) {
        if (localStorage.getItem('loggerEnable') !== null && localStorage.getItem('loggerEnable') == 'enable') {
            console.log(data);
        }
    }
    /**
     * Method to count the number of decimal places in a number
     * @param value {Number}
     */
    countDecimals(value) {
        if (Math.floor(value) !== value)
            return value.toString().split(".")[1].length || 0;
        return 0;
    }


    /**
     * Method to validate the decimal places count; and if it is greater then the scale
     * return the new value with the given scale
     * @param val {Number}
     * @param scale {Number}
     */
    checkScaleValidityForDecimals(val, scale = 2) {
        if (Math.floor(val) === val) return val;

        if (val) {
            let decL = this.countDecimals(val);
            if (decL > scale) {
                let newVal = Math.floor(val) + (Number(val.toString().split(".")[1].slice(0, scale)) / Math.pow(10, scale));
                return newVal;
            }
        }
        return val;
    }

    //METHOD TO CLEAR THE LOCAL STORAGE ONCE COMPONENT IS DESTROYED
    removedFromLocalStorage(filterObjKeys, filterConditionKeys): void {
        const filterObj = JSON.parse(localStorage.getItem('filterObj'));
        const filterCondition = JSON.parse(localStorage.getItem('filterCondition'));
        filterObjKeys.forEach(element => {
            if (filterObj && filterObj[element]) {
                delete filterObj[element];
            }
        });
        filterConditionKeys.forEach(element => {
            if (filterCondition && filterCondition[element]) {
                delete filterCondition[element];
            }
        });
        localStorage.setItem('filterObj', JSON.stringify(filterObj));
        localStorage.setItem('filterCondition', JSON.stringify(filterCondition));
    }

    /**
      * Prepair User profile photo
      */
    prepairProfileImg(user) {
        let imgpath = environment.baseUrl + '/' + environment.apiVersion;
        if (imgpath.indexOf('localhost') !== -1) {
            imgpath = 'https://den.dev.serviceo.me/api/v1';
        }
        if (user) {
            if (user.profileImage && user.url) {
                return `${imgpath}/Containers${user.url}${user.profileImage}`;
            } else if (user.gender && user.gender === 'Female') {
                return `assets/images/female-circle.png`;
            } else {
                return `assets/images/male-circle.png`;
            }
        } else {
            return `assets/images/computer.png`;
        }
    };

    /**
  * 
  * @param date 
  * return month as string
  */
    getMonthFromDate(date) {
        if (date) {
            var d = moment(date);
            d.month();
            return d.format('MMMM');
        } else {
            return '';
        }
    }

    getPageViewMapData(reqObj?) {
        this.pageViewMapData = (this.pageViewMapData.length) ? this.pageViewMapData : this._appState.getPageViewMapData();
        if (!this.pageViewMapData.length) {
            this._pageViewMappingApi.find({
                where: { id: { 'neq': null }, or: [{ isMobile: 0 }, { isMobile: null }] }
            }).subscribe(res => {
                if (res && res.length) {
                    const accessType = this._appState.getAccessType();
                    this.pageViewMapData = this._appState.getPageViewMapData();
                    if (!this.pageViewMapData.length) {
                        this.pageViewMapData = res;
                        this._appState.setPageViewMapData(res);
                        // const analyticsData = JSON.parse(localStorage.getItem('customAnalyticsObj')) || {};
                        // if (!reqObj && this.initialLoad && this.localAnalyticsObj && Object.keys(this.localAnalyticsObj).length) {
                        //     this.saveCurrentPageAnalytics(this.localAnalyticsObj['pageName'], this.localAnalyticsObj['tabName'])
                        // }
                    }
                    if (reqObj) {
                        this.saveCurrentPageAnalytics(reqObj['pageName'], reqObj['tabName'])
                    }
                }
                this.initialLoad = false;
            }, err => {
                this.initialLoad = false;
                console.log(err);
            });
        }
    };


    async saveCurrentPageAnalytics(pageName, tabName?, resolve?, scroll?) {
        let skipEntry = false;
        this.pageScrolled = (scroll) ? true : false;
        const analyticsData = JSON.parse(localStorage.getItem('customAnalyticsObj')) || {};
        if (analyticsData && analyticsData.tabName && analyticsData.tabName === tabName) {
            return;
        }
        if (resolve) {
            if (analyticsData && Object.keys(analyticsData).length && analyticsData.id) {
                this.updateAnalyticRecord(analyticsData, resolve);
            } else {
                resolve();
            }
        } else {
            const appData = JSON.parse(localStorage.getItem('appData'));
            const url = this._router.url;
            let relativeUrl = url && url.split('?')[0].substring(1) || '';            
            if (tabName && (this.truncateUrlIdsTab.indexOf(tabName) !== -1) && (this.skipUrlIdsTab.indexOf(relativeUrl) === -1)) {
                relativeUrl = relativeUrl.substr(0, relativeUrl.lastIndexOf("/"));
            }
            let reqObj: any = {};
            this.pageViewMapData = (this.pageViewMapData.length) ? this.pageViewMapData : this._appState.getPageViewMapData();
            const pageViewObj = this.pageViewMapData.find(data => {
                if (tabName) {
                    return ((data.URL === relativeUrl) && (data.Tab === tabName))
                } else {
                    return ((data.URL === relativeUrl) && (!data.Tab))
                }
            })
            reqObj.UserId = appData.user['id'];
            reqObj.userAccount = appData.user['AccountId'];
            reqObj.userAccessType = appData.user['accessType'];
            reqObj.userTypeId = appData.user['userTypeId'];
            reqObj.Contact = appData.user['sfdcId'];
            reqObj.URL = url || '';
            reqObj.pageName = pageName ? pageName : '';
            reqObj.tabName = tabName ? tabName : '';
            reqObj.Account_Id = this.getCurrentViewData('account');
            reqObj.Program_Id = this.getCurrentViewData('program');
            if (tabName === 'Create Case2Order') {
                reqObj.Action = 'Case2order Submission'
            }
            if (pageViewObj && pageViewObj['id']) {
                reqObj.Page_Screens_To = pageViewObj['id'];
                reqObj.URL_To = url || '';
                reqObj.Access_Start_Date_Time = new Date();
                if (analyticsData && Object.keys(analyticsData).length && analyticsData.Page_Screens_To && (reqObj.Page_Screens_To != analyticsData.Page_Screens_To) && analyticsData.id) {
                    this.updateAnalyticRecord(analyticsData);
                    reqObj.Page_Screen_From = analyticsData.Page_Screens_To || '';
                    reqObj.URL_From = analyticsData.URL_To || '';
                }
                if (reqObj && reqObj.pageName && reqObj.pageName === 'VMS Dashboard' && !this.pageScrolled && !reqObj.URL_From) {
                    skipEntry = true;
                }
                if (!skipEntry) {
                    this.createAnalyticRecord(reqObj);
                    localStorage.setItem('customAnalyticsObj', JSON.stringify(reqObj));
                }
            }
            if (!skipEntry) {
                this.localAnalyticsObj = JSON.parse(JSON.stringify(reqObj));
            }
            if (!this.pageViewMapData.length) {
                this.getPageViewMapData(this.localAnalyticsObj)
            }

        }
    }
    loggedInUser() {
        const appState: any = this._appState.getAppState();
        return (appState && appState.value && appState.value.user) || {}
    }
    loggedInWorker() {
        const {workers} = this.loggedInUser();
        return (workers && workers[0]) || {};
    }
    loggedInContact() {
        const {contact} = this.loggedInUser();
        return contact || {};
    }
    createAnalyticRecord(reqObj) {
        this._userAnalyticsApi.create(reqObj).subscribe(res => {
            localStorage.setItem('customAnalyticsObj', JSON.stringify(res));
            console.log('current page tracking created.')
        }, err => {
            console.log(err)
        })
    }
    updateAnalyticRecord(analyticsData, resolve?) {
        analyticsData.Access_End_Date_Time = new Date();
        let durationString = '';
        let durationObj = {
            'hours': 0,
            'minutes': 0,
            'seconds': 0
        }
        durationObj.seconds = this.getTimeDiff(analyticsData.Access_End_Date_Time, new Date(analyticsData.Access_Start_Date_Time), 'seconds') || 0;
        durationObj.minutes = this.getTimeDiff(analyticsData.Access_End_Date_Time, new Date(analyticsData.Access_Start_Date_Time)) || 0;
        durationObj.hours = this.getTimeDiff(analyticsData.Access_End_Date_Time, new Date(analyticsData.Access_Start_Date_Time), 'hours') || 0;
        analyticsData.Duration = (durationObj.hours) + ':' + ((durationObj.minutes > 60) ? ((durationObj.minutes) % 60) : durationObj.minutes) + ':' + ((durationObj.seconds > 60) ? ((durationObj.seconds) % 60) : durationObj.seconds);
        this._userAnalyticsApi.upsert(analyticsData).subscribe(res => {
            console.log('current page tracking updated.')
            if (resolve) {
                resolve();
            }
        }, err => {
            if (resolve) {
                resolve();
            }
            console.log(err)
        })
    }

    // Method to set helpdesk left filters
    setleftSideFilters(filters: any) {
        this.leftSideFilters.next(filters);
    }

    // Method to set helpdesk left filters
    getLeftSideFilters() {
        return this.leftSideFilters.asObservable();
    }

    getUserAssignedProjectList() {
        // filter user assigned projects from member roles table
        return new Promise((resolve, reject) => {
            const userProjectsArr = [];
            const appData = JSON.parse(localStorage.getItem('appData'));
            if (appData && appData.user && appData.user.workers && appData.user.workers.length) {
                this._memberRole.find({ where: { Member__c: appData.user.workers[0].sfdcId } }).subscribe(res => {
                    res.map((item: any) => {
                        if (userProjectsArr.indexOf(item.Project__c) === -1) {
                            userProjectsArr.push(item.Project__c)
                        }
                    })
                    resolve(userProjectsArr);
                }, err => {
                    console.log('err >>', err)
                })
            } else {
                resolve(userProjectsArr);
            }
        })
    }

    scrollToElement(elementId: string, timeout: number = 0) {
        setTimeout(() => {
            let element: any = document.getElementById(elementId);
            if (element) {
                let offset = 0;
                do {
                    offset += element.offsetTop;
                    element = element.offsetParent;
                } while (element.offsetParent)
                window.scrollTo({
                    top: (offset || 70) - 70, // Subtracting height of header
                    behavior: 'smooth'
                });
            }
        }, timeout);
    }

    getCurrentViewData(type) {
        const viewAsVmsObj = (localStorage.getItem('ImpersonationId')) || '';
        const viewAsPmsObj = JSON.parse(localStorage.getItem('viewAsInfo')) || {};
        let id = '';
        if (type === 'account') {
            id = viewAsVmsObj ?
                viewAsVmsObj : this.checkEmptyObject(viewAsPmsObj) ?
                    (viewAsPmsObj.account && viewAsPmsObj.account.sfdcId) || '' : '';
        } else if (type === 'program') {
            id = this.checkEmptyObject(viewAsPmsObj) ? (viewAsPmsObj.program && viewAsPmsObj.program.sfdcId) || '' : '';
        }
        return id;
    }

    checkEmptyObject(obj) {
        return Object.keys(obj).length;
    }

    getVendorAccountRecord() {
        return new Promise((resolve, reject) => {
            this._recordTypeApi.find({ fields: ['sfdcId'], where: { Name: 'Vendor', SobjectType: 'Account' } }).subscribe(
                res => {
                    const obj = {
                        fields: ['sfdcId', 'Name'],
                        where: {
                            "Name": { "like": "A%", "options": "i" },
                            RecordTypeId: res[0]['sfdcId']
                        }
                    }
                    this._accountApi.findOne(obj).subscribe(results => {
                        const vendorAccountObj = {
                            'AccountId': results['sfdcId'],
                            'accountArr': [results['sfdcId']],
                            'accountNameArr': [results['Name']],
                            'recordTypeId': res[0]['sfdcId'],
                        }
                        resolve(vendorAccountObj);
                    });
                },
                err => {
                    resolve({});
                    console.log(err);
                });
        });
    }

    getDuplicateWorker(filterObj, cb) {
        this._preloaderService.showPreloader();
        this._workerApi.find(filterObj).subscribe(obj => {
            this._preloaderService.hidePreloader();
            cb(obj)
        })
    }

    getMatcherCriteria(worker, filterObj) {
        const returnObj = [];
        let index = 1;
        if (worker && filterObj.where.or.length) {
            filterObj.where.or.forEach(element => {
                if (element['Name'] && worker.Name) {
                    const Name = element['Name'].inq[0].toLowerCase();
                    if (Name === worker.Name.toLowerCase()) {
                        returnObj.push((index++) + '. Name <br/>');
                    }
                }
                if (element['Email__c'] && worker.Email__c) {
                    const email = element['Email__c'].inq[0].toLowerCase();
                    if (email === worker.Email__c.toLowerCase()) {
                        returnObj.push((index++) + '. Primary Email <br/>');
                    }
                }
                if (element['Personal_Email_ID__c'] && worker.Personal_Email_ID__c) {
                    const personal_email = element['Personal_Email_ID__c'].inq[0].toLowerCase();
                    if (personal_email === worker.Personal_Email_ID__c.toLowerCase()) {
                        returnObj.push((index++) + '. Personal Email <br/>');
                    }
                }
                if (element['Mobile_Phone_Number__c'] && worker.Mobile_Phone_Number__c) {
                    const phone = element['Mobile_Phone_Number__c'].inq[0].toLowerCase();
                    if (phone === worker.Mobile_Phone_Number__c.toLowerCase()) {
                        returnObj.push((index++) + '. Phone <br/>');
                    }
                }
            });
        }
        return returnObj.join('');
    }

    setServiceCatalogInfo(info: any) {
     this.serviceCatalogInfo.next(info);
    }
    
    getServiceCatalogInfo() {
        return this.serviceCatalogInfo;
    }
    setApplicationCount(value:any) {
        this.applicationCount.next(value);
    }
    getApplicationCount() {
        return this.applicationCount;
    }
 
   /**
  * 
  * @param truncateUrlIdsTab 
  * remove from list while visiting application tab direct from recruitment page
  */
    removetruncateUrlIdsList(){
        const truncateUrlIdsTab = this.truncateUrlIdsTab;
        truncateUrlIdsTab.splice(1,100);
        truncateUrlIdsTab.splice(1,101);
        truncateUrlIdsTab.splice(1,102);
        truncateUrlIdsTab.splice(1,103);
        truncateUrlIdsTab.splice(1,104);
        truncateUrlIdsTab.splice(1,105);
        truncateUrlIdsTab.splice(1,106);
        truncateUrlIdsTab.splice(1,107);
        truncateUrlIdsTab.splice(1,108);
        truncateUrlIdsTab.splice(1,109);
        this.truncateUrlIdsTab = truncateUrlIdsTab;
    }
    addtruncateUrlIdsList(){
        const overveiw = ['Application Overview','CAQ','Skill','Work History','Education','References','Add Education History','Add Referral','Add Work History','Add Skills'];
        this.truncateUrlIdsTab = this.truncateUrlIdsTab.concat(overveiw);
    }

    isMagicLinkTest() {
        const appState: any = this._appState.getAppState();
        return !!(appState.value.user.isMagiclinkTest);
    }

    prepareHealthCheckData(data) {
        if (data) {
            const salaryTerm = data.salaryTerm ? data.salaryTerm : data.candidate_salary_term__c;
            let healthCheck = false;
            let checkTPMO = false;
            if (data.SubmittalTPMO_Approval__c != undefined || data.SubmittalTPMO_Approval__c != null) {
               checkTPMO = true;
            }
            if ((data.notice_period__c && data.notice_period__c.indexOf('confirm32.png') !== -1) &&
            (data.candidate_salary__c && data.candidate_salary__c.indexOf('confirm32.png') !== -1) &&
            (salaryTerm && salaryTerm.indexOf('confirm32.png') !== -1) &&
            (data.candidate_currency__c && data.candidate_currency__c.indexOf('confirm32.png') !== -1) &&
            (data.Pay_Rate__c && data.Pay_Rate__c.indexOf('confirm32.png') !== -1) &&
            (data.Job_Order_Open__c && data.Job_Order_Open__c.indexOf('confirm32.png') !== -1) &&
            (data.LinkedIn_Profile_Health__c && data.LinkedIn_Profile_Health__c.indexOf('confirm32.png') !== -1) &&
            (data.Interview_Feedback__c && data.Interview_Feedback__c.indexOf('confirm32.png') !== -1) &&
            (data.Job_Stability_Rating_Health_Check__c && data.Job_Stability_Rating_Health_Check__c.indexOf('confirm32.png') !== -1) &&
            (data.Overall_Health_Check__c && data.Overall_Health_Check__c.indexOf('confirm32.png') !== -1) &&
            (data.Vaccine_Status && data.Vaccine_Status.indexOf('confirm32.png') !== -1)) {
              healthCheck = true;
          } else if(healthCheck && checkTPMO && data.SubmittalTPMO_Approval__c.indexOf('confirm32.png') !== -1){
            healthCheck = true;
          }else {
            healthCheck = false;
          }
          return healthCheck;
        }
    }

    /** Function to Prepare filter and relation object befor hitting the jobplacement API  */
    async preparefilterObj(source , enableFilterList) {
      const appData = JSON.parse(localStorage.getItem('appData'));
      let viewAsVendor = false;
      let vendorAccessTypes;
      this._appState.getViewAsVendorObject().subscribe((res: any) => {
        if(res && res['accessType']) {
            vendorAccessTypes = res;
            viewAsVendor = true;
        } else {
          viewAsVendor = false;
          vendorAccessTypes = null;
        }
      })
      const whereConst = await this.getWhereCondition(source , enableFilterList);  
      whereConst['sfdcId'] = { neq: null }
      if((appData.user.accessType === 'vendor') || viewAsVendor){
        whereConst['ts2__Agency__c'] = !viewAsVendor ? appData.user.AccountId : vendorAccessTypes.userObj && vendorAccessTypes.userObj.AccountId;
      }
      
      return whereConst;
  }

  /**
   * Returns filter condition based on the applied enable filter on the page.
   */
   async getWhereCondition(source , enableFilterList) {
    if (source && source === 'applicationList') {
      const globalFilters = this.getGlobalFilter(enableFilterList);
      if (globalFilters['Activity_Type__c']) {
        delete globalFilters['Activity_Type__c'];
      }
      if (globalFilters['CountryCode']) {
        const countrysfdcIdList: any =  await this.getCountrySfdcIdByCode(globalFilters['CountryCode']['inq']);
        globalFilters['countryCodeIds'] = { inq: countrysfdcIdList };
        delete globalFilters['CountryCode'];
      }
      return globalFilters;
    } else {
      return {}
    }
  }

  /**
     * To get country Names based on country codes passed in the parameter
     */
   getCountrySfdcIdByCode(countryCodeList: String[]) {
    const searchObj = {
      where: { Name: { inq: [countryCodeList] } },
      fields: ['sfdcId', 'Name']
    }
    this._preloaderService.showPreloader();
    return new Promise((resolve, reject) => {
      this._countryCode.find(searchObj).subscribe(res => {
        this._preloaderService.hidePreloader();
        res = res.map(obj => obj['sfdcId']);
        resolve(res);
      }, err => {
        this._preloaderService.hidePreloader();
        reject();
      });
    });
  }
  
  timeConvert(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + " hr(s) " + rminutes + " mins.";
  }

  /* to make local filters */
  setLocalStorage(from, to) {
    const filterObjFrom = JSON.parse(localStorage.getItem(from)) || {};
    const filterObjto = JSON.parse(localStorage.getItem(to)) || {};
    if (filterObjFrom && from === 'backupFilterObj' && Object.keys(filterObjFrom).length > 0 && filterObjto.constructor === Object) {
      localStorage.setItem('filterObj', JSON.stringify(filterObjFrom));
    //   this.setGlobalFilter(filterObjFrom);
      localStorage.removeItem('backupFilterObj');

    } else if (from === 'filterObj') {
      localStorage.setItem('backupFilterObj', JSON.stringify(filterObjFrom));
    }
    if (filterObjFrom && from === 'backupfilterCondition' && Object.keys(filterObjFrom).length > 0 && filterObjto.constructor === Object) {
      localStorage.setItem('filterCondition', JSON.stringify(filterObjFrom));
    //   this.setGlobalFilter(filterObjFrom);
      localStorage.removeItem('backupfilterCondition');

    } else if (from === 'filterCondition') {
      localStorage.setItem('backupfilterCondition', JSON.stringify(filterObjFrom));
    }
  }

    prepareSystemInfo(overviewData,type?:string) {
        let createdName = '';
        let lastModifiedName = '';
        let dateFormat = 'MM/DD/YYYY';
        if(type === 'jobOrder'){
            dateFormat = 'MM/DD/YYYY hh:mm A';
        }
        if (overviewData['createdBy'] && overviewData['createdBy']['FirstName']) {
            createdName += overviewData['createdBy']['FirstName'];
        }
        if (overviewData['createdBy'] && overviewData['createdBy']['LastName']) {
            createdName += ' ' + overviewData['createdBy']['LastName'];
        }

        if (overviewData['lastModifiedBy'] && overviewData['lastModifiedBy']['FirstName']) {
            lastModifiedName += overviewData['lastModifiedBy']['FirstName'];
        }
        if (overviewData['lastModifiedBy'] && overviewData['lastModifiedBy']['LastName']) {
            lastModifiedName += ' ' + overviewData['lastModifiedBy']['LastName'];
        }

        let createdNameInfo = (createdName) ? createdName :'Resume Power User';
        let lastModifiedNameInfo = (lastModifiedName) ? lastModifiedName :'Resume Power User';
        return {
            createdBy: (createdNameInfo) ? (createdNameInfo + ' ' + (overviewData['CreatedDate'] ? this.dateFormate(overviewData['CreatedDate'], 'America/Los_Angeles', dateFormat) : '')) : '',
            lastModified: (lastModifiedNameInfo) ? (lastModifiedNameInfo + ' ' + (overviewData['LastModifiedDate'] ? this.dateFormate(overviewData['LastModifiedDate'], 'America/Los_Angeles', dateFormat) : '')) : '',
        }
    }

    getUserDetail(whereCondition) {
        let filterObj = {
            where: whereCondition,
            include: [
              {
                relation: 'userType',
                scope: {
                  fields: ['id', 'slug'],
                },
              },
              {
                relation: 'contact',
                scope: {
                  fields: ['id', 'Send_RFI_Email__c', 'UserId', 'LastLogin__c','ReportsToId','Contact_Name__c','Department'],
                  include:[{
                    relation:'reportingManagerList',
                    scope: {
                      fields: ['sfdcId','Name']
                    }
                  }]
                }
              },
              {
                relation: 'workers',
                scope: {
                  fields: ['id', 'sfdcId', 'Worker_Type__c', 'RecordTypeId' ,'Primary_Worker_Skilling_Profile__c'],
                }
              },
              {
                relation: 'account',
                scope: {
                  fields: ['id', 'Name', 'Primary_VCSQDLead_Ref__c', 'Default_Pricelist__c', 'CurrencyIsoCode'],
                  include: [{
                    relation: 'accountRFI',
                    scope: {
                      fields: ['Iron_Status__c']
                    }
                  }]
                }
              }
            ],
        };
        return new Promise((resolve, reject) => {
            this._usersApi.findOne(filterObj).subscribe((res) => {
                resolve(res);
            },
            (err) => {
                reject(err);
            })
        });
        
    }
    
    calculateBusinessDays(d1, d2) {
        d1 = moment(d1);
        d2 = moment(d2);
        const days = d2.diff(d1, "days") + 1;
        let newDay: any = d1.toDate(),
            workingDays: number = 0,
            sundays: number = 0,
            saturdays: number = 0;
        for (let i = 0; i < days; i++) {
            const day = newDay.getDay();
            newDay = d1.add(1, "days").toDate();
            const isWeekend = ((day % 6) === 0);
            if (!isWeekend) {
                workingDays++;
            }
            else {
                if (day === 6) saturdays++;
                if (day === 0) sundays++;
            }
        }
        // console.log("Total Days:", days, "workingDays", workingDays, "saturdays", saturdays, "sundays", sundays);
        return workingDays || 1;
    }

    getDateIntervalWithSubtraction(toSubtract, type, interval = 'week', format = '') {
        let curerentMonthLastDay = '';
        if (toSubtract === 0) {
            let date = moment(); 
            const dow = date.day();
            let subtractVal = dow === 1 ? 0 : 1;
            curerentMonthLastDay = moment().subtract(subtractVal, "days").endOf('day').format(format)
        }
        let lastMonthFirstDay = moment().subtract(toSubtract, type).startOf(interval).format(format);
        let lastMonthLastDay = (toSubtract == 0) ? curerentMonthLastDay : moment().subtract(toSubtract, type).endOf(interval).format(format);

        return { start: lastMonthFirstDay, end: lastMonthLastDay }
    }

    getDateIntervalWithoutSubtraction(type, interval = 'week', format = '') {
        let FirstDay = moment().startOf(interval).format(format);
        let LastDay = moment().endOf('day').format(format);
        return { start: FirstDay, end: LastDay }
    }

    getCurrentWeekBusinessdays(format = 'YYYY-MM-DD HH:mm:ss') {
        const date = moment();
        const dow = date.day();
        let subtractVal = dow === 1 ? 0 : 1;
        const endDate =  date.subtract(subtractVal, "days").endOf('day').format(format);
        const startDate = date.startOf('week').format(format);
        // return { start: date.startOf('week').format(format), end: date.subtract(subtractVal, "days").endOf('day').format(format) }
        return {start: startDate, end: endDate}
    }
    getQuarter(type,format = 'YYYY-MM-DD HH:mm:ss') {
        let currentQuarter = moment().quarter();
        let quarterVal = (type === 'current') ? currentQuarter : currentQuarter - 1;
        return { start: moment().quarter(quarterVal).startOf('quarter').format(format), end: moment().quarter(quarterVal).endOf('quarter').format(format) }
    }

    getDate(date, format = '') {
        return moment(date).format(format);
    }

    getAnalyticsDateIntervalRange(toSubtract, type, interval = 'week', format = '') {
        let curerentMonthLastDay = '';
        if (toSubtract === 0) {
            curerentMonthLastDay = moment().endOf('day').format(format);
        } 
        let lastMonthFirstDay = moment().subtract(toSubtract, type).startOf(interval).format(format);
        let lastMonthLastDay = (toSubtract == 0) ? curerentMonthLastDay : moment().subtract(toSubtract, type).endOf(interval).format(format);
        return { start: lastMonthFirstDay, end: lastMonthLastDay }
    }

    getAnalyticsCurrentWeekdays(format = 'YYYY-MM-DD HH:mm:ss') {
        const date = moment();
        const endDate =  date.endOf('day').format(format);
        const startDate = date.startOf('week').format(format);
        return {start: startDate, end: endDate}
    }
    
    storingKeysToModifyHealthCheck(data){
        const salaryTerm = data.salaryTerm ? data.salaryTerm : data.candidate_salary_term__c;
        const regex = /<img.*?src="(.*?)"/;
        let keys = [
          (data['notice_period__c']) ? regex.exec(data['notice_period__c'])[1].split('/').pop() : 'error32.png',
          (data['candidate_salary__c']) ? regex.exec(data['candidate_salary__c'])[1].split('/').pop() : 'error32.png',
          (salaryTerm) ? regex.exec(salaryTerm)[1].split('/').pop() : 'error32.png',
          (data['candidate_currency__c']) ? regex.exec(data['candidate_currency__c'])[1].split('/').pop() : 'error32.png',
          (data['Pay_Rate__c']) ? regex.exec(data['Pay_Rate__c'])[1].split('/').pop() : 'error32.png',
          (data['Job_Order_Open__c']) ? regex.exec(data['Job_Order_Open__c'])[1].split('/').pop() : 'error32.png',
          (data['LinkedIn_Profile_Health__c']) ? regex.exec(data['LinkedIn_Profile_Health__c'])[1].split('/').pop() : 'error32.png',
          (data['Interview_Feedback__c']) ? regex.exec(data['Interview_Feedback__c'])[1].split('/').pop() : 'error32.png',
          (data['Job_Stability_Rating_Health_Check__c']) ? regex.exec(data['Job_Stability_Rating_Health_Check__c'])[1].split('/').pop() : 'error32.png',
          (data['Finance_Approval__c']) ? regex.exec(data['Finance_Approval__c'])[1].split('/').pop() : 'error32.png',
          (data['Candidate_Prescreening_Health_Check__c']) ? regex.exec(data['Candidate_Prescreening_Health_Check__c'])[1].split('/').pop() : 'error32.png'
        ];
        if(data['SubmittalTPMO_Approval__c'] !=undefined || data['SubmittalTPMO_Approval__c']!=null){
            keys.push(regex.exec(data['SubmittalTPMO_Approval__c'])[1].split('/').pop())
        }        
        return keys;
    }

    getWeekNumber(format) {
        let weeks = [];
        const startOfMonth = moment().startOf('month').format(format);
        const firstWeek = moment(startOfMonth).week();
        const currentWeek = moment().week();
        console.log('weeks', firstWeek, currentWeek);
        if (currentWeek > firstWeek) {
            let i = firstWeek;
            while (i <= currentWeek) {
                weeks.push(i);
                i++;
            }
        } else {
            weeks.push(currentWeek);
        }
        return weeks;
    }

    getLastMonthWeekNumber(format) {
        let weeks = [];
        const lastDayOfLastMonth = moment().subtract(1, 'months').endOf('month').format(format);
        const firstWeekCurrentMonth = moment(lastDayOfLastMonth).week();

        return firstWeekCurrentMonth;
    }

    getWeekWise(value, format?, prevYear = false) {
        let weeksRange = [];
        let now = moment();
        let dateObj = prevYear ? moment().subtract(1, 'year') : moment();
        value.forEach((weekNumber) => {
            let first = dateObj.week(weekNumber).startOf('week').format(format);
            let last = dateObj.week(weekNumber).endOf('week') > now ? now.format(format) : dateObj.week(weekNumber).endOf('week').format(format);
            weeksRange.push({ start: first, end: last });
        });
        return weeksRange;
    }

    calculateAvgJobSch(jbSch) {
        const totalJobScheculeHours = parseInt(jbSch['Monday_Hours__c']) + parseInt(jbSch['Tuesday_Hours__c']) + parseInt(jbSch['Wednesday_Hours__c']) + parseInt(jbSch['Thursday_Hours__c']) + parseInt(jbSch['Friday_Hours__c']) + parseInt(jbSch['Saturday_Hours__c']) + parseInt(jbSch['Sunday_Hours__c']);
        let totalJobScheculeDay = 0;
        if (parseInt(jbSch['Monday_Hours__c']) > 0) {
            totalJobScheculeDay += 1;
        }
        if (parseInt(jbSch['Tuesday_Hours__c']) > 0) {
            totalJobScheculeDay += 1;
        }
        if (parseInt(jbSch['Wednesday_Hours__c']) > 0) {
            totalJobScheculeDay += 1;
        }
        if (parseInt(jbSch['Thursday_Hours__c']) > 0) {
            totalJobScheculeDay += 1;
        }
        if (parseInt(jbSch['Friday_Hours__c']) > 0) {
            totalJobScheculeDay += 1;
        }
        if (parseInt(jbSch['Saturday_Hours__c']) > 0) {
            totalJobScheculeDay += 1;
        }
        if (parseInt(jbSch['Sunday_Hours__c']) > 0) {
            totalJobScheculeDay += 1;
        }
        return { totalJobScheculeDay: totalJobScheculeDay, averageJobScheduleHours: totalJobScheculeHours / totalJobScheculeDay }
    }

    getCost(term, cost, averageJobScheduleHours, totalJobScheculeDay, anualRate) {
        let hourlyCost = 0;
        if (term == 'Hourly') {
            hourlyCost = cost;
        }
        if (term == 'Monthly') {
            hourlyCost = cost / (averageJobScheduleHours * totalJobScheculeDay * anualRate);
        }
        if (term == 'Yearly') {
            hourlyCost = cost / (averageJobScheduleHours * totalJobScheculeDay * anualRate * 12);
        }
        if (term == 'Daily') {
            hourlyCost = cost / averageJobScheduleHours;
        }
        if (term == 'Half Day') {
            hourlyCost = cost / (averageJobScheduleHours / 2);
        }
        if (term == 'Weekly') {
            hourlyCost = (cost / averageJobScheduleHours)* 5;
        }
        return hourlyCost;
    }

    rateCalulation(type, targetCostHourly, averageJobScheduleHours, totalJobScheculeDay, anualRate) {
        if (type === 'Hourly_Rate') {
            return targetCostHourly.toFixed(2);
        }
        else if (type === 'Half_Day_Rate') {
            return ((targetCostHourly * averageJobScheduleHours) / 2).toFixed(2);
        }
        else if (type === 'Daily_Rate_Standard') {
            return (targetCostHourly * averageJobScheduleHours).toFixed(2);
        }
        else if (type === 'Annual_Rate') {
            return (targetCostHourly * averageJobScheduleHours * totalJobScheculeDay * anualRate * 12).toFixed(2);
        }
        else if (type === 'Hourly_Rate_Holiday') {
            return (targetCostHourly * averageJobScheduleHours * 2).toFixed(2);
        }
        else if (type === 'Hourly_Rate_Overtime') {
            return (targetCostHourly * averageJobScheduleHours * 1.5).toFixed(2);
        }

        else if (type === 'Monthly_Rate') {
            return (targetCostHourly * averageJobScheduleHours * totalJobScheculeDay * anualRate).toFixed(2);
        }
        else if(type ==='Weekly_Rate'){
            return (targetCostHourly / averageJobScheduleHours * 5);
        }
    }
    generatePDF(fileName , id='pdfContent'){
        const that = this;
        setTimeout(async function() {     
          await html2canvas(document.getElementById(id), {
            onclone: function (clonedDoc) {
              // that._preloaderService.showPreloader();
              //The div hidden and here changing it to visible
              clonedDoc.getElementById(id).style.display = 'block';
              //that.isContentUpdated = '';
            }
          }).then((canvas) => {
            that._preloaderService.hidePreloader();
            const image = { type: 'jpeg', quality: 0.98 };
            const margin = [0.5, 0.5];      
      
            var imgWidth = 8.5;
            var pageHeight = 11;
      
            var innerPageWidth = imgWidth - margin[0] * 2;
            var innerPageHeight = pageHeight - margin[1] * 2;
      
            // Calculate the number of pages.
            var pxFullHeight = canvas.height;
            var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
            var nPages = Math.ceil(pxFullHeight / pxPageHeight);
      
            // Define pageHeight separately so it can be trimmed on the final page.
            var pageHeight = innerPageHeight;
      
            // Create a one-page canvas to split up the full image.
            var pageCanvas = document.createElement('canvas');
            var pageCtx = pageCanvas.getContext('2d');
            pageCanvas.width = canvas.width;
            pageCanvas.height = pxPageHeight;
      
            // Initialize the PDF.
            var pdf = new jsPDF('p', 'in', [8.5, 11]);
            pdf.setProperties({ title: fileName });
            for (var page = 0; page < nPages; page++) {
              // Trim the final page to reduce file size.
              if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
                pageCanvas.height = pxFullHeight % pxPageHeight;
                pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
              }
      
              // Display the page.
              var w = pageCanvas.width;
              var h = pageCanvas.height;
              pageCtx.fillStyle = 'white';
              pageCtx.fillRect(0, 0, w, h);
              pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);
      
              // Add the page to the PDF.
              if (page > 0) pdf.addPage();     
              var imgData = pageCanvas.toDataURL('image/' + image.type, image.quality);
              pdf.addImage(imgData, image.type, margin[1], margin[0], innerPageWidth, pageHeight);
            }
            // as large content in not opening in modal
            var blob = pdf.output("blob");
            window.open(URL.createObjectURL(blob),"_blank","top=0,left=0,width=700,height=950");
          })
        }, 200);
    }

    // detectURL(text: string): string {
    //     var res = text.replace(/<[p>]+>/g, '');
    //     var res = text.replace(/<[/p>]+>/g, ' ');
    //     if(/(http(s?)):\/\//i.test(res)){
    //         let stylizedText: string = '';
    //         if (res && res.length > 0) {
    //         for (let line of res.split("\n")) {
    //             for (let t of line.split(" ")) {
    //             const link = t.replace(/<[^>]+>/g, '');
    //             if (t.startsWith("http") && t.length>7) {  
    //                 stylizedText += `<a href="${link}" target="_blank">${t}</a> `;
    //             }else if(t.startsWith("www")){
    //                 stylizedText += `<a href="https://${link}" target="_blank">${t}</a> `;
    //             }
    //             else
    //                 stylizedText += t + " ";
    //             }
    //             stylizedText += '<br>';
    //         }
    //         return stylizedText;
    //         }
    //     }
    //     else return text;
    // }

    detectURL(text: string): string {
        var urlRegex = /(((https?:\/\/)|(www\.))[^\s\<\"]+)/g;
        return text.replace(urlRegex, function(url,b,c) {
            let indexOfUrl = text.indexOf(url);
            // console.log('indexOfUrl >>> ', indexOfUrl);
            // console.log('indexOfUrl - 2 >>> ', text[indexOfUrl - 2]);
            // console.log('indexOfUrl - 3 >>> ', text[indexOfUrl - 3]);
            if(text[indexOfUrl - 2] === '=' || text[indexOfUrl - 3] === '='){
                // console.log('if entered >>> ');
                return url;
            } else {
                // console.log('else entered >>> ');
                var url2 = (c == 'www.') ?  'http://' +url : url;
                return '<a href="' +url2+ '" target="_blank">' + url + '</a>';
            }
        })
    }

    getEditPermission(stage,currentStage){
        const appData = JSON.parse(localStorage.getItem('appData'));
        let isEditAllowed = false;
        let accessType = appData&& appData['user'] && appData['user']['accessType'] ? appData['user']['accessType'] : '';
        let userTypeId = appData&& appData['user'] && appData['user']['userTypeId'] ? appData['user']['userTypeId'] : '';
        let accessOption = appData&& appData['user'] && appData['user']['workers'] && appData['user']['workers'][0] && appData['user']['workers'][0]['Operation_Project_Management_Assignment__c'] ? appData['user']['workers'][0]['Operation_Project_Management_Assignment__c'] :'';        
        if((accessType === 'internal' && userTypeId===2) || (stage!==currentStage && accessOption.indexOf("Recruitment JO Helpdesk Access")!==-1) || stage===currentStage){
            isEditAllowed = true;
        }
        return isEditAllowed;
    }    
    setProgramIDUserFilter(programID: any) {
        this.programId.next(programID);
    }
    getProgramIDUserFilter() {
        return this.programId;
    }

    // TODO :: Create directives for removing white spaces
    removeWhiteSpaces(control: AbstractControl) {
        if (control && control.value && !control.value.replace(/\s/g, '').length) {
          control.setValue('');
          return { required: true }
        }
    }

    setCandidateAsDNC(contactId, status=true) {
        // filter user assigned projects from member roles table
        return new Promise((resolve, reject) => {
            let today = new Date();
            let dateTime = moment(today).format('YYYY-MM-DD');
            this._contactApi.patchAttributes(contactId, { DoNotCall: status,LastModifiedDate: dateTime}).subscribe(res => {
                if(res){
                    resolve(true);
                }else{
                    resolve(false);
                }
            }, err => {
                console.log('err >>', err)
            })
        })
    }

    /** paste clipboard image **/
    disableImgOnPaste(e){
        const items = (e.clipboardData || e.originalEvent.clipboardData).items;
        let imgCheck = null;
        for (const item of items) {
            if (item.type.indexOf('image') === 0) {
                imgCheck = item.getAsFile();
                if(imgCheck){
                return false;
                }
            }
        }
    }
    isFileImage(file) {
        const acceptedImageTypes = ['jpg','gif', 'jpeg', 'png' , 'bmp','gif'];
        if(file && acceptedImageTypes.includes(file.toLowerCase())){
          return true;
        }else{
            return false;
        }
    }

    setDNCByReason(contactData, noteObj, status = 'ENABLEDNC') {
        return new Promise((resolve, reject) => {
            const contactId = contactData.id;
            forkJoin(
                this._contactApi.patchAttributes(contactId, { DoNotCall: status, LastModifiedDate: new Date() }),
                this._noteApi.create(noteObj)).subscribe(([contactRes, noteRes]) => {
                    if (contactRes && noteRes) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }, err => {
                    console.log('err >>', err)
                });
        })
    }


    getJobOrderById(jobOrderSfdcId) {
        return new Promise((resolve, reject) => {
            let fields = ['Type_of_Requirement__c', 'ts2__Job_Number__c', 'Job_Title__c', 'sfdcId', 'ts2__Post_Job__c'];
            this._jobOrderApi.find({ where: { sfdcId: jobOrderSfdcId }, fields: fields }).subscribe((res: any) => {
                if (res && res.length) {
                    res = res[0];
                    const jobInfo = {
                        jobOrderNumber: res.ts2__Job_Number__c,
                        jobOrderTypeRequirement: res.Type_of_Requirement__c,
                        jobTitle: res.Job_Title__c,
                        jobOrderSfdcId: res.sfdcId
                    }
                    localStorage.setItem('jobRequisitionRowData', JSON.stringify(jobInfo));
                    resolve(jobInfo);
                } else {
                    resolve({});
                }
            })
        })
    }  

}
