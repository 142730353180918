import { Component, OnInit, Input, Output, EventEmitter, AfterContentInit } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, filter, tap, catchError } from 'rxjs/operators';
import { JobsiteApi } from '../../../sdk';
import { AppStateService } from 'shared/services/app-state.service';

import { PreloaderService } from 'shared/services/preloader.service';
import { Subject, Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';

@Component({
    selector: 'app-jobsite-lookup',
    templateUrl: './jobsite-lookup.component.html',
    styleUrls: ['./jobsite-lookup.component.scss']
})
export class JobsiteLookupComponent implements OnInit, AfterContentInit {
    @Input() boxedLayout = false;
    @Input() isDisabled = false;
    @Input() prePopulateList = true;
    @Input() setGlobalFilterValue = true;
    @Input() isClearable = true;
    @Input() isSearchable = true;
    @Input() fromServiceReqInfo = false
    @Input() fromServiceReqAccount;
    @Input() preSelectedJobsite: any; // This is use only from case2order page
    @Output() siteListForServReqInfo: EventEmitter<any> = new EventEmitter;
    @Output() getJobsiteObj: EventEmitter<any> = new EventEmitter;
    @Input() set resetData(isClear) {
        this.jobsiteLoading = false;
        this.jobsiteLists = [];
        this.jobsiteSelected = [];
    }
    @Input() isMultipleSelected = true;
    @Input() set setJobsiteProgramObj(params) {
        this.allJobsiteArrObj = [];
        this.jobsiteLists = [];
        this.jobsiteSelected = [];
        this.jobsiteLoading = false;
        this.userType = this._appState.getAccessType();
        if (params && params.length) {
            this.selectedProgram = params;
            this.loadJobsiteByProgram(params);
            // this.loadJobsites({ Jobsite_Status__c: 'Active' })
        } else {
            this.selectedProgram = [];
        }
    }

    @Input() set setJobsiteAccountObj(params) {
        this.allJobsiteArrObj = [];
        this.jobsiteLists = [];
        this.jobsiteSelected = [];
        this.jobsiteLoading = false;
        this.userType = this._appState.getAccessType();

        if (this.userType === 'internal' && params && params.length) {
            this.selectedAccount = params;
        } else {
            this.selectedAccount = [];
        }
        if (params && params.length) {
            this.onJobsiteChange();
        }
    }
    @Input() set preselectJobsite(e) {
        this.jobsiteSelected = e;
        this.loadJobsites({ sfdcId: this.jobsiteSelected, Jobsite_Status__c: 'Active' });
    }

    @Input() set setValueInEditMode(sfdcId) {
        if(sfdcId) {
            this.jobsiteSelected = sfdcId;
            this.loadJobsites({ sfdcId: { inq: [this.jobsiteSelected] }, Jobsite_Status__c: 'Active' });
        }
    }

    public searchInput = new EventEmitter<string>();

    userType: String;
    jobsiteLoading = false;
    jobsites: Array<any>;
    jobsitesParents = {};
    jobsiteLists = [];
    jobsiteSelected: any;
    notFoundText: String = 'No Jobsite Found';
    selectedAccount: any;
    selectedProgram: any;
    allJobsiteArrObj: any;
    dataSearchLenght = 2;
    constructor(
        private _jobsiteApi: JobsiteApi,
        private _appState: AppStateService
    ) {
        this.jobsiteSelected = [];
    }
    ngOnInit() {
        // to show selected jobsite
        this.allJobsiteArrObj = [];
        if (this.fromServiceReqInfo) {
            this.dataSearchLenght = 0;
        }
    }

    ngAfterContentInit() {
        if (this.setGlobalFilterValue) {
            const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
            this.jobsiteSelected = (preselected && preselected['jobSites']) ? preselected['jobSites'] : [];
            if (this.jobsiteSelected && this.jobsiteSelected.length) {
                this.onJobsiteChange();
                this.loadJobsites({ sfdcId: { inq: this.jobsiteSelected }, Jobsite_Status__c: 'Active' });
            }
        }
        if (!this.selectedProgram || !this.selectedProgram.length) {
            this.getJobsites();
        }
    }

    getJobsites() {
        this.searchInput
            .pipe(
                filter(search => search && search.trim().length > this.dataSearchLenght),
                debounceTime(200),
                distinctUntilChanged(),
                switchMap(search => this.getJobsiteList(search))
            ).subscribe(
                data => {
                    if (data && !data['inLineSearch']) {
                    this.allJobsiteArrObj = data;
                    }
                    this.setJobsiteData(data);
                },
                err => {
                    console.log(err);
                    this.jobsiteLists = [];
                }

            );
    }

    // Method to set query to get program list
    getJobsiteList(search) {
        if (this.fromServiceReqInfo && this.allJobsiteArrObj && this.allJobsiteArrObj['data'] && this.allJobsiteArrObj['data'].jobsites) {
            return new Promise((resolve, reject) => {
                if (search && search.trim().length > 1) {
                    const iterableData = this.allJobsiteArrObj['data'].jobsites;
                    const filteredJobsites = [];
                    if (!isNullOrUndefined(iterableData) && iterableData.length) {
                        iterableData.forEach(jobsite => {
                            const searchData = jobsite['Name'] ? jobsite['Name'].toLowerCase().match(search.toLowerCase()) : '';
                            // tslint:disable-next-line:max-line-length
                            if (jobsite['Jobsite_ID__c'] && jobsite['Name'] && jobsite['sfdcId'] && jobsite['Jobsite_Approval_Status__c'] && searchData) {
                                filteredJobsites.push(jobsite);
                            }
                        });
                    }
                    resolve({ data: { jobsites: filteredJobsites }, inLineSearch: true });
                } else {
                    resolve(this.allJobsiteArrObj);
                }
            })
        } else if (this.selectedProgram && this.selectedProgram.length) {
            return this.getJobsiteByProgram(search);
        } else {
            let whereObj = {};

            if (this.selectedAccount && this.selectedAccount.length) {
                whereObj = {
                    and: [
                        { Jobsite_Status__c: 'Active' },
                        { Account__c: { inq: this.selectedAccount } },
                        {
                            or:
                                [
                                    { Name: { like: '%' + search.trim() + '%', options: 'i' } },
                                    { Jobsite_ID__c: { like: '%' + search.trim() + '%', options: 'i' } }
                                ]
                        }
                    ]
                };
            } else {
                whereObj = {
                    and: [
                        { Jobsite_Status__c: 'Active' },
                        {
                            or:
                                [
                                    { Name: { like: '%' + search.trim() + '%', options: 'i' } },
                                    { Jobsite_ID__c: { like: '%' + search.trim() + '%', options: 'i' } }
                                ]
                        }
                    ]
                };
            }

            const filterObj = {
                where: whereObj,
                fields: ['sfdcId', 'Name', 'GEO_Metro__c', 'Jobsite_ID__c' , 'Jobsite_Approval_Status__c'],
                order: 'Name ASC'
            }
            return this._jobsiteApi.find(filterObj);
        }
    }

    getJobsiteByProgram(search): any {
        const filterObj = {
            'programId': this.selectedProgram,
            'jobsiteName': search, 
            'order': 'CreatedDate DESC',
            'include': [
                {
                    relation: 'JobsiteProjects',
                    scope: {
                        fields: ['sfdcId', 'Jobsite__c', 'Project__c'],
                        'include': [{
                            'relation': 'Project',
                            'scope': {
                                'fields': ['sfdcId', 'Master_Project__c'],
                                'include': [{
                                    'relation': 'masterProject',
                                    'scope': {
                                        'fields': ['sfdcId', 'Name'],
                                    }
                                }
                                ]
                            }
                        }
                        ]
                    }
                }
            ],
        }
        if (this.fromServiceReqInfo) {
            // filterObj['skip'] = 0;
            // filterObj['limit'] = 999;
            filterObj['order'] = 'Name'
            filterObj['fromcase2orderAccount'] = this.fromServiceReqAccount ? this.fromServiceReqAccount : '';
            filterObj['source'] = 'list-jobsites-contact',
            filterObj['fields'] = ['GEO_Metro__c', 'Jobsite_ID__c', 'Name', 'Country__c', 'State__c', 'Zip__c', 'City__c', 'Street__c',
            'Jobsite_Description__c', 'Jobsite_Status__c', 'geolocation__Latitude__s', 'geolocation__Longitude__s', 'Type__c',
            'Jobsite_Approval_Status__c', 'sfdcId', 'Jobsite__c', 'Jobsite_Key_Contact__c', 'id', 'Account__c'];
            delete filterObj['include'];
        }

        return this._jobsiteApi.getJobsitesByMasterProject(filterObj);
    }

    // get jobsites data from selected program
    loadJobsites(whereObj) {
        this.jobsiteLoading = true;
        const filterObj = {
            fields: ['sfdcId', 'Name', 'GEO_Metro__c', 'Jobsite_ID__c', 'Jobsite_Approval_Status__c'],
        }

        if (whereObj) {
            filterObj['where'] = whereObj;
            // filterObj['limit'] = 200;
        }
        // clears previous jobsite selection
        this.jobsiteSelected = (this.jobsiteSelected && this.jobsiteSelected.length) ? this.jobsiteSelected : [];
        this._jobsiteApi.find(filterObj)
            .subscribe(
                result => {
                    this.setJobsiteData(result);
                    this.jobsiteLoading = false;
                },
                err => {
                    this.jobsiteLists = [];
                    this.jobsiteLoading = false;
                }
            );
    }

    loadJobsiteByProgram(programIds) {
        const filterObj = {
            'programId': programIds,
            'order': 'CreatedDate DESC',
            'include': [
                {
                    relation: 'JobsiteProjects',
                    scope: {
                        fields: ['sfdcId', 'Jobsite__c', 'Project__c'],
                        'include': [{
                            'relation': 'Project',
                            'scope': {
                                'fields': ['sfdcId', 'Master_Project__c'],
                                'include': [{
                                    'relation': 'masterProject',
                                    'scope': {
                                        'fields': ['sfdcId', 'Name'],
                                    }
                                }
                                ]
                            }
                        }
                        ]
                    }
                }
            ],
        }
        // This code block is to create request object to get jobsite list for case2order form.
        if (this.fromServiceReqInfo) {
            filterObj['skip'] = 0;
            filterObj['limit'] = 999;
            filterObj['order'] = 'Name';
            filterObj['fromcase2orderAccount'] = this.fromServiceReqAccount ? this.fromServiceReqAccount : '';
            filterObj['source'] = 'list-jobsites-contact',
            filterObj['fields'] = ['GEO_Metro__c', 'Jobsite_ID__c', 'Name', 'Country__c', 'State__c', 'Zip__c', 'City__c', 'Street__c',
                'Jobsite_Description__c', 'Jobsite_Status__c', 'geolocation__Latitude__s', 'geolocation__Longitude__s', 'Type__c',
                'Jobsite_Approval_Status__c', 'sfdcId', 'Jobsite__c', 'Jobsite_Key_Contact__c', 'id', 'Account__c'];
            delete filterObj['include'];
        }
        
        this._jobsiteApi.getJobsitesByMasterProject(filterObj).subscribe(
            data => {
                if (data && data.data && data.data.jobsites && this.prePopulateList) {
                    this.allJobsiteArrObj = data;
                    this.setJobsiteData(data.data.jobsites);
                }
                console.log(data);
                // To emit the Jobsite list data back to the calling/Parent component.
                this.siteListForServReqInfo.emit(data);
                // This is use only from case2order page
                if (this.preSelectedJobsite && this.preSelectedJobsite.Jobsite__c) {
                    this.jobsiteSelected = this.preSelectedJobsite.Jobsite__c;
                    this.onJobsiteChange();
                }
            },
            err => { console.log(err) }

        );
    }

    setJobsiteData(data) {
        const filteredJobsites = [];
        let iterableData = [];
        if (data && !Array.isArray(data)) {
            iterableData = data && data['data'] && data['data'].jobsites
        } else {
            iterableData = data;
        }
        if (!isNullOrUndefined(iterableData) && iterableData.length) {
            iterableData.forEach(jobsite => {
                if (jobsite['Jobsite_ID__c'] && jobsite['Name'] && jobsite['sfdcId'] && jobsite['Jobsite_Approval_Status__c']) {
                    const obj = {
                        name: jobsite['Jobsite_ID__c'] + ' (' + jobsite['Name'] + ')',
                        id: jobsite['sfdcId']
                    };
                    if (this.fromServiceReqInfo) {
                        obj['name'] = jobsite['Name'] + ' (' + jobsite['Jobsite_Approval_Status__c'] + ')' ;
                    }
                    filteredJobsites.push(obj);
                }
            });
        }
        this.jobsiteLists = filteredJobsites;
    }

    onJobsiteChange() {
        this.getJobsiteObj.emit(this.jobsiteSelected);
    }

    clearJobsite() {
        this.getJobsiteObj.emit((this.fromServiceReqInfo || !this.isMultipleSelected) ? null : []);
    }
}
